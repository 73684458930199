import * as React from 'react';
import {useState, forwardRef, useImperativeHandle} from 'react';
import { Box, Button, Drawer, Divider, Grid } from '@mui/material';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import CustomGridItem from '../low_level_components/CreateGridItem';

const drawerWidth = 360;
const drawerHeight = 400;

const AddPages = forwardRef(({ introInputs, configInputs, addPages, setAddPages, secondaryQuestion, setSecondaryQuestion, gridItems, setGridItems, screeningDOE }, ref) => {

    const areRequiredFieldsFilled = () => {
        // This function will check if all required fields are filled.

        const textAreas = Array.from(document.querySelectorAll('.AddPagesForm .required textarea'));
        const inputFields = Array.from(document.querySelectorAll('.AddPagesForm .required input'));

        // Concatenate the arrays
        const inputs = inputFields.concat(textAreas);

        for (let input of inputs) {
            if (!input.value) {
                console.log(input)
                console.log(input.name, "IS NOT SET")
                alert(input.name + " is not set.")
                return false;
            }
        }
        return true;
    }
    useImperativeHandle(ref, () => ({
        areRequiredFieldsFilled,
    }));


      const handleAddSecondaryQuestion = () => {
        setGridItems((prevGridItems) => {
          let newGridItems = [...prevGridItems];
      
          // Find the index of the last secondary question
          let lastSecondaryQuestionIndex = 0;
          for (let i = newGridItems.length - 1; i >= 0; i--) {
            if (newGridItems[i].title === "Secondary Survey Question") {
              lastSecondaryQuestionIndex = i;
              break;
            }
          }
      
          // Create a new secondary question with an id one more than the last secondary question
          const newSecondaryQuestion = {
            id: newGridItems[lastSecondaryQuestionIndex].id + 1,
            title: "Secondary Survey Question",
            defaultText: "Secondary Survey Question",
            showDelete: true,
          };
          console.log(newSecondaryQuestion.id)
      
          // Insert a new secondary question at the correct position
          newGridItems.splice(lastSecondaryQuestionIndex + 1, 0, newSecondaryQuestion);
      
          // Increment id values of the following items
          for (let i = lastSecondaryQuestionIndex + 2; i < newGridItems.length; i++) {
            newGridItems[i].id += 1;
          }
      
          return newGridItems;
        });
      
        // Add a new object to the secondary_survey_question array
        setAddPages((prevState) => {
          let newSecondarySurveyQuestionArray = [...prevState.secondary_survey_question];
        
          const newSecondarySurveyQuestion = {
            format: "",
            question: "",
            show_rating_label: false,
            minimum: null,
            maximum: null,
            step: null,
            shape: "",
            scale: null,
            fields: [],
            randomize: false,
            num_options: null,
          };
          
          newSecondarySurveyQuestionArray.push(newSecondarySurveyQuestion);
        
          return {
            ...prevState,
            secondary_survey_question: newSecondarySurveyQuestionArray,
          };
        });
        
      };

      const handleAddInstructionsForUse = () => {
        // setAddInstructionsDisabled(true)
        setGridItems((prevGridItems) => {
          let newGridItems = [...prevGridItems];

          const newInstruction = {
            id: gridItems.length+1,
            title: "Instructions for Use",
            defaultText: "Instructions for Use",
            showDelete: true,
          }
          newGridItems.push(newInstruction);
          return newGridItems;
        }
      )};

      const handleAddDemographicQuesion = () => {
        setGridItems((prevGridItems) => {
          let newGridItems = [...prevGridItems];

          const newDemographicQuestion = {
            id: gridItems.length+1,
            title: "Demographic Questions",
            defaultText: "Demographic Questions",
            showDelete: true,
          }
          newGridItems.push(newDemographicQuestion);
          return newGridItems;
        }
      )};

      const handleAddRecommendation = () => {
        // setAddInstructionsDisabled(true)
        setGridItems((prevGridItems) => {
          let newGridItems = [...prevGridItems];

          const newRecommendation = {
            id: gridItems.length+1,
            title: "ATOM Recommendations",
            defaultText: "ATOM Recommendations",
            showDelete: true,
          }
          newGridItems.push(newRecommendation);
          return newGridItems;
        }
      )};

      const handleDeleteItem = (idToDelete) => {
        setGridItems(prevGridItems => {
          const updatedGridItems = prevGridItems.filter(item => item.id !== idToDelete);

          // Adjust ids for the rest of the items
          updatedGridItems.forEach((item, index) => {
            item.id = index + 1;
          });

          return updatedGridItems;
        });

        setAddPages(prevState => {
          const updatedSecondarySurveyQuestion = prevState.secondary_survey_question.filter((_, index) => index !== idToDelete - 2);

          return {
            ...prevState,
            secondary_survey_question: updatedSecondarySurveyQuestion,
          };
        });
      };

      const disableAddButton = (title) => {
        return gridItems.some((item) => {
          if (item.title === title) {
            return true
          } else { return false }
        })
      }
      
    return (
        <Box sx={{ display: 'flex' }} className="AddPagesForm">
            {/* The drawer is where all of the survey settings are located */}
            <Drawer
                variant="permanent"
                containerstyle={{ height: 'calc(100%-64px)', top: 64 }}
                sx={{
                width: drawerWidth,
                flexShrink: 0,
                [`& .MuiDrawer-paper`]: { width: drawerWidth, height: drawerHeight, boxSizing: 'border-box', position: 'relative', zIndex: 'tootip' },
            }}
          >
    
            {/* This box holds all of the variable input fields. */}
            <Box sx={{ overflow: 'auto', position: 'absolute', top: 0, left: 30 }}>
                <Divider orientation='horizontal' sx={{ height: '15px' }} />
                <h5><b>Survey Pages</b></h5>
                    <Divider orientation='horizontal' sx={{ height: '10px' }} />
                        <Box display="flex" alignItems="end" justifyContent="space-between">
                            <span><h7>Add Primary Survey Question</h7></span>
                            <Button color="primary" size="large" disabled>
                                <AddCircleIcon />
                            </Button>
                        </Box>
                    <Divider orientation='horizontal' sx={{ height: '20px' }} />
                    <Box display="flex" alignItems="end" justifyContent="space-between">
                        <span><h7>Add Secondary Survey Question</h7></span>
                        <Button color="primary" size="large" onClick={handleAddSecondaryQuestion}>
                            <AddCircleIcon />
                        </Button>
                    </Box>
                <Divider orientation='horizontal' sx={{ height: '20px' }} />
                <Box display="flex" alignItems="end" justifyContent="space-between">
                        <span><h7>Add Instructions for Use</h7></span>
                        <Button color="primary" size="large" onClick={handleAddInstructionsForUse} disabled={disableAddButton("Instructions for Use")}>
                            <AddCircleIcon />
                        </Button>
                    </Box>
                <Divider orientation='horizontal' sx={{ height: '20px' }} />
                <Box display="flex" alignItems="end" justifyContent="space-between">
                        <span><h7>Add Demographics Survey Question</h7></span>
                        <Button color="primary" size="large" onClick={handleAddDemographicQuesion} disabled={disableAddButton("Demographic Questions")}>
                            <AddCircleIcon />
                        </Button>
                    </Box>
                <Divider orientation='horizontal' sx={{ height: '20px' }} />
                {introInputs.hardware === "Yes" && introInputs.atom_administer === true ?
                <Box display="flex" alignItems="end" justifyContent="space-between">
                        <span><h7>Add ATOM Recommendation</h7></span>
                        <Button color="primary" size="large" onClick={handleAddRecommendation} disabled={disableAddButton("ATOM Recommendations")}>
                            <AddCircleIcon />
                        </Button>
                    </Box>
                : <div></div>}
                <Divider orientation='horizontal' sx={{ height: '20px' }} />
                </Box>
            </Drawer>
            <Box sx={{ p: 2 }}>
                <h3>Survey Pages</h3>
                <Divider orientation='horizontal' sx={{ height: '10px' }} />
                {gridItems.map((item) => (
                <Grid container direction="column" justifyContent="space-evenly" sx={{ width: "350%" }} key={item.id}>
                    {item.title === "ATOM Recommendations" && introInputs.hardware === "No" ?
                    <div></div> :
                    <React.Fragment>
                    <CustomGridItem
                      id = {item.id}
                      title={item.title}
                      showDelete={item.showDelete}
                      onDelete={() => handleDeleteItem(item.id)}
                      introInputs={introInputs}
                      configInputs={configInputs}
                      addPages={addPages}
                      setAddPages={setAddPages}
                      secondaryQuestion={secondaryQuestion}
                      setSecondaryQuestion={setSecondaryQuestion}
                      screeningDOE={screeningDOE}
                    />
                    <Divider orientation='horizontal' sx={{ height: '10px' }} />
                    </React.Fragment>
                    }
                </Grid>
                ))}
            </Box>
        </Box>
    );
})

export default AddPages;
