import React, { useState, useEffect } from 'react';
import { TextField, Grid, Divider, Typography } from '@mui/material';

const Recommendation = ({ setState }) => {

  return (
    <div>
      <h1>Recommendation Page</h1>
        <h5>This page is under construction</h5>
        <button onClick={ () =>setState('survey_end')}>End Survey</button>
    </div>
  );
};

export default Recommendation;
