import * as React from 'react';
import { useState, useEffect } from 'react';
import ContinuousVariables from '../low_level_components/ContinuousVariables';
import DynamicModeling from '../low_level_components/dynamic_modeling';
import DesignType from '../low_level_components/DesignType';
import ConstantVariables from '../low_level_components/constants';
import AllVariables from '../low_level_components/AllVariables';
import Demo from '../low_level_components/demo';
import { Divider } from '@mui/material';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Table from 'react-bootstrap/Table';
import { InputLabel, Select, MenuItem } from '@mui/material';


const ConfigurationCustom = ({ value, survey_id, terms }) => {


    var num_terms = terms.terms.length
    console.log('in config custom');
    console.log(Array(num_terms).fill(0));

    terms.term_status = Array(num_terms).fill(0);

    console.log('setting terms selection initially: ')
    console.log(terms);

    // when a user selects 'Dont Include', 'Optional', or 'Required' for a term, change it here
    const handleSelectionChange = (e, index, terms) => {

        // using the dropdown index, push it into the array
        const selections = terms.term_status;
        selections[index] = e.target.value;
    }

    // for each term we will create a row to insert into the html
    // each row is a label of the term name and a dropdown to select the status of the term
    const rows = [];

    for (let index = 0; index < terms.terms.length; index++) {
        (
            rows.push(
                <tr>
                    <td>{terms.terms[index]}</td>
                    <td>
                        <InputLabel 
                            id="demo-simple-select-label">Model</InputLabel>
                        <Select
                            style={{ width: 150}}
                            // labelId=""
                            // id=""
                            // value="na"
                            // label="Term"
                            onChange={(e) => handleSelectionChange(e, index, terms)}
                        >
                            <MenuItem value={0}>Don't Include</MenuItem>
                            <MenuItem value={1}>Optional</MenuItem>
                            <MenuItem value={2}>Required</MenuItem>
                        </Select>
                    </td>
                </tr>
            )
        )
    }

    // only display the table if the tab is active
    if (value == 2) {

        return (
            <center><table class="table table-hover">
                <thead>
                    <tr>
                        <th scope="Variable">Variable</th>
                        <th scope="Selection">Selection</th>
                    </tr>
                </thead>
                <tbody>
                    {rows}

                </tbody>
            </table></center>
        );

    }
}


export default ConfigurationCustom;