import React from 'react';


const Home = () => (
    <div className='container-md pt-4'>
        <div class="jumbotron">
            <h1 class="display-4">Welcome to ATOM</h1>
            <p class="lead">Your place to model and build solutions</p>
            <p class="lead">Digitized T&N is your partner in building AI-based solutions that exceed customer needs and limit use of resources.</p>
            <hr class="my-4" />
            <p>Click the login button to get started</p>
            <p class="lead">
                <a class="btn btn-primary btn-lg" href="/login" role="button">Login</a>
            </p>
        </div>
    </div>
);

export default Home;