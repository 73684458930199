import React, { useState, useEffect }from 'react';
import 'sweetalert2/src/sweetalert2.scss';
import { Radar, RadarChart, PolarGrid, Legend, PolarAngleAxis, PolarRadiusAxis, ResponsiveContainer } from 'recharts';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Checkbox from '@mui/material/Checkbox';


const OptumChart = ({ data, radardata }) => {

    const [allVariables,setAllVariables] = useState(data)
    const [radarVariables,setAllRadarVariables] = useState(radardata)
    const [tableRows, setTableRows]= useState([])

    useEffect(() => {
        setTableRows([
            createData(false, 'ATOM Best 1', allVariables[0].AtomBest1, allVariables[1].AtomBest1, allVariables[2].AtomBest1, allVariables[3].AtomBest1, allVariables[4].AtomBest1, allVariables[5].AtomBest1, allVariables[6].AtomBest1, allVariables[7].AtomBest1, allVariables[8].AtomBest1),
            createData(false, 'ATOM Best 2', allVariables[0].AtomBest2, allVariables[1].AtomBest2, allVariables[2].AtomBest2, allVariables[3].AtomBest2, allVariables[4].AtomBest2, allVariables[5].AtomBest2, allVariables[6].AtomBest2, allVariables[7].AtomBest2, allVariables[8].AtomBest2),
            createData(false, 'ATOM Best 3', allVariables[0].AtomBest3, allVariables[1].AtomBest3, allVariables[2].AtomBest3, allVariables[3].AtomBest3, allVariables[4].AtomBest3, allVariables[5].AtomBest3, allVariables[6].AtomBest3, allVariables[7].AtomBest3, allVariables[8].AtomBest3),
            createData(false, 'ATOM Best Without Constraints', allVariables[0].AtomBestWoConstraint, allVariables[1].AtomBestWoConstraint, allVariables[2].AtomBestWoConstraint, allVariables[3].AtomBestWoConstraint, allVariables[4].AtomBestWoConstraint, allVariables[5].AtomBestWoConstraint, allVariables[6].AtomBestWoConstraint, allVariables[7].AtomBestWoConstraint, allVariables[8].AtomBestWoConstraint),
            createData(false, 'ATOM Worst 1', allVariables[0].AtomWorst1, allVariables[1].AtomWorst1, allVariables[2].AtomWorst1, allVariables[3].AtomWorst1, allVariables[4].AtomWorst1, allVariables[5].AtomWorst1, allVariables[6].AtomWorst1, allVariables[7].AtomWorst1, allVariables[8].AtomWorst1),
            createData(false, 'Custom Sample', allVariables[0].CustomSample1, allVariables[1].CustomSample1, allVariables[2].CustomSample1, allVariables[3].CustomSample1, allVariables[4].CustomSample1, allVariables[5].CustomSample1, allVariables[6].CustomSample1, allVariables[7].CustomSample1, allVariables[8].CustomSample1),
        ]);
    }, data)

    function createData(one, two, three, four, five, six, seven, eight, nine, ten, eleven) {
        return { one, two, three, four, five, six, seven, eight, nine, ten, eleven };
    }

    const handleChange = (event, index) => {
      const newState = [...tableRows];
      newState[index] = {
          ...newState[index],
          one: event.target.checked
      }
      setTableRows(newState)
    };

    const renderLegend = (props) => {
        const { payload } = props;
        return (
            <ul style={{border: '1px solid gray', width: '120px'}}>
            {
                payload.map((entry, index) => (
                <li style={{color: entry.payload.fill}} key={`item-${index}`}>{entry.value}</li>
                ))
            }
            </ul>
        );
    }

    if (!data || !tableRows || !allVariables) return <div>loading</div>

    return (
    <div>
    <TableContainer component={Paper}>
      <Table sx={{ minWidth: 650 }} size="small" aria-label="a dense table">
        <TableHead>
          <TableRow>
            <TableCell></TableCell>
            <TableCell></TableCell>
            {allVariables.map((row, index) => (
            <TableCell key={index}>{row.variable}</TableCell>
            ))}
            <TableCell></TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {tableRows.map((row, index) => (
            <TableRow
              key={index}
              sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
            >
              <TableCell component="th" scope="row" align="left"><Checkbox onChange={(e) => handleChange(e, index)} checked={row.one} /></TableCell>
              <TableCell align="left">{row.two}</TableCell>
              <TableCell align="left">{row.three}</TableCell>
              <TableCell align="left">{row.four}</TableCell>
              <TableCell align="left">{row.five}</TableCell>
              <TableCell align="left">{row.six}</TableCell>
              <TableCell align="left">{row.seven}</TableCell>
              <TableCell align="left">{row.eight}</TableCell>
              <TableCell align="left">{row.nine}</TableCell>
              <TableCell align="left">{row.ten}</TableCell>
              <TableCell align="left">{row.eleven}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>

    <RadarChart style={{margin: "auto"}} outerRadius={300} width={950} height={700} data={radarVariables.filter((x) => x.fullMark > 0)}>
        <PolarGrid />
        <PolarAngleAxis dataKey="variable" />
        <PolarRadiusAxis axisLine={false} tick={false} angle={18} domain={[0, 100]} />
        { tableRows[0]?.one == true &&
        <Radar name="Best1" dataKey="AtomBest1" stroke="#154360" fill="#3498DB" fillOpacity={0.4} />
        }
        { tableRows[1]?.one == true &&
        <Radar name="Best2" dataKey="AtomBest2" stroke="red" fill="pink" fillOpacity={0.3} />
        }
        { tableRows[2]?.one == true &&
        <Radar name="Best3" dataKey="AtomBest3" stroke="#186A3B" fill="#58D68D" fillOpacity={0.4} />
        }
        { tableRows[3]?.one == true &&
        <Radar name="Worst1" dataKey="AtomBestWoConstraint" stroke="#7D6608" fill="#F4D03F" fillOpacity={0.4} />
        }
        { tableRows[4]?.one == true &&
        <Radar name="Worst2" dataKey="AtomWorst1" stroke="#9C640C" fill="#E67E22" fillOpacity={0.4} />
        }
        { tableRows[5]?.one == true &&
        <Radar name="Worst3" dataKey="CustomSample1" stroke="#5B2C6F" fill="#A569BD" fillOpacity={0.4} />
        }
        <Legend wrapperStyle={{left: '20px', bottom: '80px'}} content={renderLegend}/>
    </RadarChart>
    </div>
    )
}

export default OptumChart;