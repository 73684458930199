// Prompt to set up VAS connect and make full screen if necessary

import React, { useState, useEffect } from 'react';
import { TextField, Grid, Divider, Typography } from '@mui/material';
import "./loadingIcon.css"

const LoadingIcon = ({ setState }) => {

  return (
    <div className={"loader"}>
    </div>
  );
};

export default LoadingIcon;
