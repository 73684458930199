import * as React from 'react';
import { useState, useEffect, Fragment } from 'react';
import TextField from '@mui/material/TextField';
import Divider from '@mui/material/Divider';
import Grid from '@mui/material/Grid';
import { IconButton, Button } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import AddIcon from '@mui/icons-material/Add';
import { FormControl, InputLabel, Select, MenuItem } from '@mui/material';
import Swal from 'sweetalert2';


const AllVariables = ({ configInputs, setConfigInputs, projectType }) => {

    function createVariable(configInputs, index) {
        return {
            variable_names: configInputs?.variable_names?.[index] || "", 
            variable_types: configInputs?.variable_types?.[index] || "Continuous", 
            variable_units: configInputs?.variable_units?.[index] || "", 
            variable_material_codes: configInputs?.variable_material_codes?.[index] || "", 
            variable_descriptions: configInputs?.variable_descriptions?.[index] || "",
            variable_lows: configInputs?.variable_lows?.[index] || null,
            variable_highs: configInputs?.variable_highs?.[index] || null,
            variable_categories: configInputs?.variable_categories?.[index] || Array(2).fill(""),
            levels: configInputs?.variable_categories?.[index]?.length || 2
        };
    }
    
    const initialVariables = (configInputs.variable_names?.length || 0) === 0
        ? [createVariable({}, 0), createVariable({}, 1)] 
        : configInputs.variable_names.map((_, index) => createVariable(configInputs, index));
    
    const [variablesList, setVariablesList] = useState(initialVariables);
    
    // when variablesList is modified, we change the values of setConfigInputs to make API call in CreateAdvanced
    useEffect(() => {
        let all_var_names = variablesList.map(d => d.variable_names).filter(n => n);
        let all_var_types = variablesList.map(d => d.variable_types).filter(n => n);
        let all_var_variable_lows = variablesList.map(d => d.variable_lows).filter(number => typeof number == 'number');
        let all_var_variable_highs = variablesList.map(d => d.variable_highs).filter(number => typeof number == 'number');
        let all_var_units = variablesList.map(d => d.variable_units).filter(n => n);
        let all_material_codes = variablesList.map(d => d.variable_material_codes).filter(n => n);
        let all_var_descriptions = variablesList.map(d => d.variable_descriptions).filter(n => n);
        let all_var_categories = variablesList.map(d => d.variable_categories.filter(category => category.trim() !== ""));
        all_var_categories = all_var_categories.filter(cat => cat.length !== 0);
        let all_var_levels = variablesList.map(d => d.levels).filter(number => typeof number == 'number');
        let categoricalVarLevels = variablesList.filter(d => d.variable_types === 'Categorical').map(d => d.levels).filter(number => typeof number === 'number');
        let discreteVarLevels = variablesList.filter(d => d.variable_types === 'Discrete').map(d => d.levels).filter(number => typeof number === 'number');
            
        setConfigInputs(prevState => ({
            ...prevState,
            variable_names: all_var_names,
            variable_types: all_var_types,
            variable_lows: all_var_variable_lows,
            variable_highs: all_var_variable_highs,
            variable_units: all_var_units,
            variable_material_codes: all_material_codes,
            variable_descriptions: all_var_descriptions,
            variable_categories: all_var_categories,
            levels: all_var_levels,
            categorical_variable_categories: categoricalVarLevels,
            discrete_variable_categories: discreteVarLevels
        }));
    }, [variablesList]);
    
    let addVariableFields = () => {
    setVariablesList([...variablesList, {
        variable_names: "", 
        variable_types: "Continuous", 
        variable_units: "", 
        variable_material_codes: "", 
        variable_descriptions: "",
        variable_categories: [],
    }])
    }

    let removeVariableFields = (i) => {
    let newVariables = [...variablesList];
    newVariables.splice(i, 1);
    setVariablesList(newVariables);
    }

    // This is what happens when the variables are changed in the variable fields
    const handleVariableChange = (e, index) => {
        const { name, value } = e.target;
        const list = [...variablesList];
    
        if (name === "variable_lows" || name === "variable_highs" || name === "levels") {
            list[index][name] = parseInt(value);
        } else {
            list[index][name] = value;
        }
    
        if (name === 'variable_types' && (value === 'Categorical' || value === 'Discrete')) {
            if (!list[index]['levels']) {
                list[index]['levels'] = 2;
            }
            if (!list[index]['variable_categories'] || !list[index]['variable_categories'].length) {
                list[index]['variable_categories'] = Array(2).fill('');
            }
        }
    
        setVariablesList(list);
        console.log(configInputs)
    };
    
    const handleLevelChange = (e, index, levelIndex) => {
        const newVariables = [...variablesList];
        newVariables[index].variable_categories[levelIndex] = e.target.value;
        setVariablesList(newVariables);
    };

    return (
        <Grid container sx={{ marginBottom: '30px'}}>
            <Grid item xs={12}>
                <h5>Variables</h5>
            </Grid>
            {variablesList.map((element, index) => (
                <Fragment key={`variable-field-${index}`}>
                <div key={`variable-field-${index}`} style={{ display: 'flex', justifyContent: 'space-between', marginBottom: '8px', marginTop: '12px' }}>
                    <Grid item xs={2}>
                    <TextField
                        // required
                        label="Name"
                        id="variable_names"
                        variant="outlined"
                        name="variable_names"
                        value={element.variable_names}
                        onChange={(e) => handleVariableChange(e, index)}
                        inputProps={{ autoComplete: 'off' }}
                    />
                    </Grid>
                    <Divider orientation='vertical' sx={{ width: '10px' }} />
                    <Grid item xs={2}>
                        <FormControl sx={{ minWidth: 180 }}>
                        <InputLabel variant="outlined" id="var-type-label">Type</InputLabel>
                        <Select
                            // required
                            label="Type *"
                            labelId="var-type-label"
                            id="var-type"
                            value={element.variable_types}
                            name="variable_types"
                            onChange={(e) => handleVariableChange(e, index)}
                            inputProps={{ autoComplete: 'off' }}
                        >
                            <MenuItem value="Continuous">Continuous</MenuItem>
                            <MenuItem value="Categorical">Categorical</MenuItem>
                            <MenuItem value="Discrete">Discrete</MenuItem>
                        </Select>
                        </FormControl>
                    </Grid>
                    <Divider orientation='vertical' sx={{ width: '10px' }} />
                    <Grid item xs={2}>
                    <TextField
                        label="Units (Optional)"
                        id="variable_units"
                        variant="outlined"
                        name="variable_units"
                        value={element.variable_units}
                        onChange={(e) => handleVariableChange(e, index)}
                        inputProps={{ autoComplete: 'off' }}
                    />
                    </Grid>
                    <Divider orientation='vertical' sx={{ width: '10px' }} />
                    <Grid item xs={2}>
                    <TextField
                        label="Material Code (Optional)"
                        id="variable_material_codes"
                        variant="outlined"
                        name="variable_material_codes"
                        value={element.variable_material_codes}
                        onChange={(e) => handleVariableChange(e, index)}
                        inputProps={{ autoComplete: 'off' }}
                    />
                    </Grid>
                    <Divider orientation='vertical' sx={{ width: '10px' }} />
                    <Grid item xs={2}>
                    <TextField
                        label="Descriptions"
                        id="variable_descriptions"
                        variant="outlined"
                        name="variable_descriptions"
                        value={element.variable_descriptions}
                        onChange={(e) => handleVariableChange(e, index)}
                        inputProps={{ autoComplete: 'off' }}
                    />
                    </Grid>
                    {element.variable_types === "Continuous" ?
                        <>
                            <Divider orientation='vertical' sx={{ width: '10px' }} />
                            <Grid item xs={2}>
                            <TextField
                                required
                                className="required"
                                label="Low Level"
                                inputProps={{ min: 0, max: 399 }}
                                error={element.variable_lows < 0 || element.variable_lows > 400 || element.variable_lows > element.variable_highs}
                                helperText={element.variable_lows < 0 ? 'Value cannot be negative' : element.variable_lows > 399 ? 'Value cannot be greater than 399' : element.variable_lows > element.variable_highs ? 'Value greater than high level' : ''}
                                sx={{ minWidth: 200 }}
                                id="variable_lows"
                                variant="outlined"
                                type="number"
                                name="variable_lows"
                                value={element.variable_lows}
                                onChange={(e) => handleVariableChange(e, index)}
                            />
                            </Grid>
                            <Divider orientation='vertical' sx={{ width: '10px' }} />
                            <Grid item xs={2}>
                            <TextField
                                required
                                inputProps={{ min: Number(element.variable_lows)+1, max: 400 }}
                                error={element.variable_highs > 400 || element.variable_lows > element.variable_highs }
                                helperText={element.variable_highs > 400 ? 'Value cannot be greater than 400' : element.variable_lows > element.variable_highs ? 'Value less than low level' : ''}
                                sx={{ minWidth: 200 }}
                                label="High Level"
                                id="variable_highs"
                                variant="outlined"
                                type="number"
                                name="variable_highs"
                                value={element.variable_highs}
                                onChange={(e) => handleVariableChange(e, index)}
                            />
                            </Grid>
                        </>
                    : element.variable_types === "Discrete" || element.variable_types === "Categorical" ?
                        <>
                        <Divider orientation='vertical' sx={{ width: '10px' }} />
                            <TextField
                                required
                                inputProps={{ min: 2, max: 10 }}
                                sx={{ minWidth: 100 }}
                                label="# of Levels"
                                id="levels"
                                variant="outlined"
                                type="number"
                                name="levels"
                                value={element.levels}
                                onChange={(e) => handleVariableChange(e, index)}
                            />
                        </>
                    : <div></div>}
                    <Grid item xs={2}>
                    {variablesList.length !== 2 &&
                        (<IconButton sx={{ display: 'block'}} aria-label="delete" className="remove-btn" onClick={() => removeVariableFields(index)}>
                            <DeleteIcon />
                        </IconButton>)}
                    </Grid>
                </div>
                {element.variable_types === "Discrete" || element.variable_types === "Categorical" ? (
                    <div key={`level-fields-${index}`} style={{ display: 'flex', marginBottom: '15px' }}>
                        {Array.from({ length: element.levels }, (_, levelIndex) => (
                        <React.Fragment key={`levelField-${index}-${levelIndex}`}>
                            <Grid item xs={12}>
                            <TextField
                                key={`levelField-${index}-${levelIndex}`}
                                id={`levelField-${index}-${levelIndex}`}
                                label={`Level ${levelIndex + 1}`}
                                variant="outlined"
                                name={`level${levelIndex + 1}`}
                                value={element.variable_categories[levelIndex]}
                                onChange={(e) => handleLevelChange(e, index, levelIndex)}
                                sx={{ width: 136 }}
                            />
                            </Grid>

                            <Divider key={`divider-${index}-${levelIndex}`} orientation='vertical' sx={{ width: '10px' }} />
                        </React.Fragment>
                        ))}
                    </div>
                    ) : (
                    <div key={`level-fields-${index}`} />
                    )}
                <Grid item xs={12}>
                <div style={{ display: 'flex', justifyContent: 'center' }}>
                {variablesList.length - 1 === index &&
                    (<Button sx={{ display: 'block' }} aria-label="add" variant="contained" className="add-btn" onClick={() => addVariableFields()} size="large">
                        <AddIcon />
                    </Button>)}
                </div>
                </Grid>
                </Fragment>
            ))}
        </Grid>
    );
}

export default AllVariables;
