import dsd from './dsd'

function customToLowerCase(str) {
    var lowercaseStr = "";
    var charCodeDiff = "a".charCodeAt(0) - "A".charCodeAt(0);
  
    for (var i = 0; i < str.length; i++) {
        var charCode = str.charCodeAt(i);
  
        // Check if the character is uppercase
        if (charCode >= 65 && charCode <= 90) {
        charCode += charCodeDiff; // Convert to lowercase
        }
  
        lowercaseStr += String.fromCharCode(charCode);
    }
  
    return lowercaseStr;
}
  

//  Function to calculate no of samples in ATOM 3.
function Calculate_NoSamples_ATOM3(No_Continious_Var, No_Categorical_Var, No_Discrete_Var, Cat_Var_Levels, Discrete_Var_Levels, Required, ModelType) {
        // Declare your variables here
        let NoMinSamplesRequired;
        let Recommended_No_Samples;
    
    /*  Terms are added in the following order: 
            linear - continious, 
            linear - categorical & discrete numeric,
            interactions - continious,
            interactions - categorical,
            interactions - continious & categorical,
            quadratic - continious terms. */
        
        // Switch case checking model types by lower casing all the letters.
        switch(customToLowerCase(ModelType)) {
    
            // If the model type in lowercase is 'dsd'.
            case 'dsd':
                // Initializing Best_Design, NoMinSamplesRequired, and Recommended_No_Samples.
                // let Best_Design = dsd(No_Continious_Var, No_Categorical_Var + No_Discrete_Var);
                // Recommended_No_Samples = NoMinSamplesRequired;
                // NoMinSamplesRequired = Best_Design.length;
                // break;
            
            // If the model type in lowercase is not 'dsd'.
            default:
                let Levels;
                // Initializing Levels, AdjustedLevels.
                if (No_Discrete_Var === 0) {
                    Levels = Cat_Var_Levels;
                } else {
                    Levels = Cat_Var_Levels.concat(Discrete_Var_Levels);
                }
                let AdjustedLevels = Levels.map(level => level - 1);
    
                // Initializing No_Categorfical_Discrete_Var, NoLinearTerms, NoQuadraticTerms.
                let No_Categorical_Discrete_Var = 0;
                for(let i = 0; i < AdjustedLevels.length; i++) {
                    No_Categorical_Discrete_Var += AdjustedLevels[i];
                }
                let NoLinearTerms = No_Continious_Var + No_Categorical_Discrete_Var;
                let NoQuadraticTerms = No_Continious_Var;
                
                // Initializing NoInteractionTerms_ContiniousVars, NoInteractionTerms_Cont_And_Categorical.
                let NoInteractionTerms_ContiniousVars = No_Continious_Var * (No_Continious_Var - 1) / 2;
                let NoInteractionTerms_Cont_And_Categorical = 0;
                for (let i = 0; i < AdjustedLevels.length; i++) {
                    NoInteractionTerms_Cont_And_Categorical += AdjustedLevels[i] * No_Continious_Var;
                }
                
                // Initializing Temp as per the AdjustedLevels.
                let Temp = 0;
                for (let i = 0; i < AdjustedLevels.length - 1; i++) {
                  for (let j = i + 1; j < AdjustedLevels.length; j++) {
                    Temp += AdjustedLevels[i] * AdjustedLevels[j];
                  }
                }
    
                // Initializing NoInteractionTerms_CategoricalVars as Temp and Recommended_No_Samples_Continious for later use.
                let NoInteractionTerms_CategoricalVars = Temp;        
                let Recommended_No_Samples_Continious = 0;
    
                // Initializing MultiplicationFactor, Constant for later use.
                let MultiplicationFactor = 0;
                let Constant = 0;
    
                // Calculating how many unique levels there are and sort them in the descending order ...
                // ... by using set object to get the unique values in array format, ... 
                // ... such that array is sorted in descending order by using comparision function.
                let UniqueLevels = [];
                let NoRepetitions = 0;
                for (let i = 0; i < UniqueLevels.length; i++) {
                    NoRepetitions = Levels.filter(level => level === UniqueLevels[i]).length;
                }

                // Calculating the number of min samples required as per the case ...
                switch(customToLowerCase(Required)) {
                    case 'linear': // ... if required in lowercase is linear.
                        NoMinSamplesRequired = NoLinearTerms + 1;
                        break;
                    case 'interactions':
                    case 'interaction': // ... if required in lowercase is interactions, or interaction.
                        NoMinSamplesRequired = NoLinearTerms + NoInteractionTerms_ContiniousVars + NoInteractionTerms_CategoricalVars + NoInteractionTerms_Cont_And_Categorical + 1;
                        break;
                    case 'purequadratic': // ... if required in lowercase is purequadratic.
                        NoMinSamplesRequired = NoLinearTerms + NoQuadraticTerms + 1;
                        break;
                    case 'quadratic': // ... if required in lowercase is quadratic.
                        NoMinSamplesRequired = NoLinearTerms + NoInteractionTerms_ContiniousVars + NoInteractionTerms_CategoricalVars + NoInteractionTerms_Cont_And_Categorical + NoQuadraticTerms + 1;
                        break;       
                    default: // ... if required in lowercase is anything else.
                        NoMinSamplesRequired = NoLinearTerms + 1;
                        break;
                }
    
                // Step 1 - Calculating the Recommended number of samples for Continious Variables if required in lowercase ...
                switch (customToLowerCase(Required)) {
                    case 'linear': // ... is linear.         
                        Recommended_No_Samples_Continious = (No_Continious_Var * 2) + 2;
                        break;
                    case 'interactions':
                    case 'interaction': // ... if required in lowercase is interactions, or interaction.
                        Recommended_No_Samples_Continious = No_Continious_Var + NoInteractionTerms_ContiniousVars + 5;
                        break;
                    case 'purequadratic': // ... if required in lowercase is purequadratic.
                        Recommended_No_Samples_Continious = NoMinSamplesRequired + 5;
                        break;
                    case 'quadratic': // ... if required in lowercase is quadratic.
                        Recommended_No_Samples_Continious = NoMinSamplesRequired;
                        break;       
                    default: // ... if required in lowercase is anything else.
                        Recommended_No_Samples_Continious = NoMinSamplesRequired + 5;
                        break;
                }
    
                // Step 2 - Calculating the Recommended number of samples for Categorical & 
                //          Discrete numeric variables if required in lowercase ...
                switch (customToLowerCase(Required)) {
    
                    case 'linear': // ... is linear.

                        // Initializing MultiplicationFactor, Constant for later use.
                        MultiplicationFactor = 0;
                        Constant = 0;
            
                        // Calculating how many unique levels there are and sort them in the descending order ...
                        // ... by using set object to get the unique values in array format, ... 
                        // ... such that array is sorted in descending order by using comparision function.
                        UniqueLevels = [...new Set(Levels)].sort((a, b) => b - a);
                        
                        // For each unique level ...
                        for (let i = 0; i < UniqueLevels.length; i++) {
    
                            // Calculate the number of repetitions for the current level
                            NoRepetitions = Levels.filter(level => level === UniqueLevels[i]).length;

                            // ... multiplication factor is the highest level i.e. the categorical variable with highest level.
                            MultiplicationFactor = UniqueLevels[0];

                            // Checking how many categorical variables have the same number of levels.
                            switch (NoRepetitions) {
                                case 1:
                                    if (i == 0) {
                                        Constant += 0;
                                    } else {
                                        Constant += 2;
                                    }
                                    break;
                                case 2:
                                case 3:
                                    Constant += 2;
                                    break;
                                case 4:
                                case 5:
                                    Constant += 4;
                                    break;
                                case 6:
                                case 7:
                                    Constant += 6;
                                    break;
                                case 8:
                                case 9:
                                    Constant += 8;
                                    break;
                                default:
                                    Constant += 10;
                            }
                        }

                        // console.log("Recommended_No_Samples_Continious")
                        // console.log(Recommended_No_Samples_Continious)
                        // console.log("2 * MultiplicationFactor")
                        // console.log(2 * MultiplicationFactor)
                        // console.log("Constant")
                        // console.log(Constant)
    
                        // Calculating the recommended number of samples.
                        Recommended_No_Samples = Recommended_No_Samples_Continious + (2 * MultiplicationFactor) + Constant;
                        break;
    
                    case 'interactions':
                    case 'interaction': // ... if required in lowercase is interactions, or interaction.
                    
                        // Calculating the recommended number of samples.
                        Recommended_No_Samples = NoMinSamplesRequired + 5;
                        break;
    
                    case 'purequadratic': // ... if required in lowercase is purequadratic.
                        UniqueLevels = [...new Set(Levels)].sort((a, b) => b - a);
    
                        // For each unique level ...
                        for (let i = 0; i < UniqueLevels.length; i++) {    
    
                            // Calculate the number of repetitions for the current level
                            NoRepetitions = Levels.filter(level => level === UniqueLevels[i]).length;

                            // ... multiplication factor is the highest level i.e. the categorical variable with highest level.
                            MultiplicationFactor = UniqueLevels[0];

                            // Checking how many categorical variables have the same number of levels.
                            switch (NoRepetitions) {
                                case 1:
                                    Constant += 0;
                                    break;
                                case 2:
                                case 3:
                                    Constant += 2;
                                    break;
                                case 4:
                                case 5:
                                    Constant += 4;
                                    break;
                                case 6:
                                case 7:
                                    Constant += 6;
                                    break;
                                case 8:
                                case 9:
                                    Constant += 8;
                                    break;
                                default:
                                    Constant += 10;
                            }
                        }

                        // console.log('NoMinSamplesRequired')
                        // console.log(NoMinSamplesRequired)
                        // console.log('MultiplicationFactor')
                        // console.log(MultiplicationFactor)
                        // console.log('Constant')
                        // console.log(Constant)

                        
                        // Calculating the Recommended_No_Samples based on max value between Recommended_No_Samples_Continious
                        // and NoMinSamplesRequired + (2 * MultiplicationFactor) + Constant and assigning that to it.
                        if (Recommended_No_Samples_Continious > (NoMinSamplesRequired + (2 * MultiplicationFactor) + Constant)) {
                            Recommended_No_Samples = Recommended_No_Samples_Continious;
                        } else {
                            Recommended_No_Samples = NoMinSamplesRequired + (2 * MultiplicationFactor) + Constant;
                        }
                        break;
    
                    case 'quadratic': // ... if required in lowercase is quadratic.
                        Recommended_No_Samples = NoMinSamplesRequired; // Calculating the recommended number of samples.
                        break;
    
                    default: // ... if required in lowercase is anything else.
                        Recommended_No_Samples_Continious = NoMinSamplesRequired + 5; // Calculating the recommended number of samples.
                        break;
                }
                break;
        }
    
        return {NoMinSamplesRequired, Recommended_No_Samples};
    }

    export default Calculate_NoSamples_ATOM3;
