import React, { useState } from 'react';
import { Link, redirect, Navigate } from 'react-router-dom';
import { connect } from 'react-redux';
import { login } from '../../actions/auth';
import array from './home-cards.json';
import FlipCard from './FlipCard';
import '../styles.scss'

const Dashboard = () => {

    const cards = array.homeCards;

        return (
            <div className='text-center'>
            <div className='blue_bg'>
            <div class="jumbotron">
                <h1 class="display-4 p-3">ATOM</h1>
                <p class=" h4 pb-1">Advanced Tools for Modeling</p>
                    <div className="container">
                        <div className="row">
                            <div className="col d-flex flex-column flex-md-row justify-content-around align-items-center">
                                {cards.map((card) => (
                                    <FlipCard key={card.id} card={card} />
                                ))}
                            </div>
                        </div>
                    </div>
            </div>
            </div>
            </div>
        )
    };

export default Dashboard;