import PropTypes from 'prop-types';
import { useTheme } from '@mui/material/styles';
import { AppBar, Tab, Tabs, Typography, Box, Button } from '@mui/material';
import React, { useEffect, useState, useRef } from 'react';
import Swal from 'sweetalert2';
import 'sweetalert2/src/sweetalert2.scss';
import { connect } from 'react-redux';

//menu components
import IntroMenu from './components/IntroMenu';
import Configuration from './components/Configuration';
import ShareAndSave from './components/ShareAndSave';
import ViewSamples from './components/ViewSamples';
import StyleEditor from './components/StyleEditor';
import AddPages from './components/AddPages';
import ConfigurationCustom from './components/ConfigurationCustom';
import { add } from 'mathjs';
import ScreeningViewSamples from './components/ScreeningViewSamples';


function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `full-width-tab-${index}`,
    'aria-controls': `full-width-tabpanel-${index}`,
  };
}

const fetchSurveyID = async (access_token, setSurveyID) => {
  // This function gets called when the survey is first created and assigned a unique survey ID.
  // It only gets called once at the beginning.
console.log('in fetchsurveyid')
  try {
    const response = await fetch(`${process.env.REACT_APP_API_URL}/survey/create/generate_survey_id`, {
      method: 'post',
      headers: {
        'content-type': 'application/json',
        'Authorization': `JWT ${access_token}`,
        'Accept': 'application/json'
      },
      body: JSON.stringify({})
    }).then(response => response.json())
      .then(response => {
        setSurveyID(response.survey_id) // set the survey id from the response
      })
  } catch (err) {
    console.log(err.message)
    alert("There was an error.")
    alert(err.message)
  }


}
/// Each sub-menu has a 'handleMenuSubmit const which handles making the api call and updating state accordingly /// 
// submit the intro menu form and return a survey id



const handleIntroSubmit = async (access_token, introInputs,surveyID, setSurveyID) => {

  const introInputsBody = { ...introInputs, "survey_id": surveyID }


  try {
    const response = await fetch(`${process.env.REACT_APP_API_URL}/survey/create/intro_menu`, {
      method: 'post',
      headers: {
        'content-type': 'application/json',
        'Authorization': `JWT ${access_token}`,
        'Accept': 'application/json'
      },
      body: JSON.stringify(introInputsBody)
    }).then(response => response.json())
      .then(response => {
        console.log(response)
      })
  } catch (err) {
    console.log(err.message)
    Swal.fire({
      icon: 'error',
      title: 'There was an issue creating your survey.',
      text: 'You are likely signed out and need to sign back in.'
    })
  }
}

// submit the config menu form
// if custom required or optional terms is selected, return the terms a user must select in config custom
const handleConfigSubmit = async (access_token, configInputs, setConfigInputs, surveyID, configCustom, setConfigCustom, samples, setSamples) => {

  console.log("in handleConfigSubmit");
  console.log(access_token)
  // add survey_id to a config body to submit all together
  const config_body = { ...configInputs, survey_id: surveyID }

  // check if user selected DOE (D-Optimality), if so set optional terms
  if (config_body.design_type === "DOE (D-Optimality)") {
    config_body.optional_terms = config_body.required_terms;
  }

  console.log("config_body");
  console.log(configInputs);
  console.log("in handleConfigSubmit");
  const swal = Swal.fire({
    title: 'Please wait...',
    text: 'Waiting on your Design of Experiment to build...',
    allowOutsideClick: false,
    backdrop: 'rgba(0, 0, 0, 0.5)',
    didOpen: () => {
      Swal.showLoading();
    }
  });
  try {



    const response = await fetch(`${process.env.REACT_APP_API_URL}/survey/create/add_configuration`, {
      method: 'post',
      headers: {
        'content-type': 'application/json',
        'Authorization': `JWT ${access_token}`,
        'Accept': 'application/json'
      },

      body: JSON.stringify(config_body)
    }
    );

    const data = await response.json();
    console.log('json response')
    console.log(data);


    // if the required or optional terms are custom, we will be returned an array of required terms
    if (configInputs.required_terms === "Custom" || configInputs.optional_terms === "Custom") {
      setConfigCustom({ ...configCustom, terms: data.required_terms });
      console.log(configCustom);
      swal.close();
    }
    else { // Add in the swal
      swal.close();
      setSamples(data.DoE);
    }
  } catch (err) {
    console.log(err.message);

    Swal.fire({
      icon: 'error',
      title: 'There was an issue creating your DoE.',
      text: `${err.message}`
    })
  }
}

// used to submit the custom config array and return the samples matrix
const handleCustomConfigSubmit = async (access_token, surveyID, configCustom, samples, setSamples) => {

  // manually set for now
  console.log("in handleCustomConfigSubmit")

  const custom_config_body = {
    "survey_id": surveyID,
    "custom_model": configCustom.term_status
  }


  console.log("in handleCustomConfigSubmit");
  console.log(custom_config_body);

  // access the api url in order to add the custom model to the survey and get samples back
  try {
    const response = await fetch(`${process.env.REACT_APP_API_URL}/survey/create/add_configuration_custom`, {
      method: 'post',
      headers: {
        'content-type': 'application/json',
        'Authorization': `JWT ${access_token}`,
        'Accept': 'application/json'
      },
      body: JSON.stringify(custom_config_body)
    }
    );

    const data = await response.json();
    console.log('json response');
    console.log(data.DoE);

    // from the json response, set the samples generated by matlab - SWAL
    setSamples(data.DoE);

    //print the error if the api call
  } catch (err) {
    console.log(err.message)
    Swal.fire({
      icon: 'error',
      title: 'There was an issue connecting to the API.',
      text: `${err.message}`
    })
  }
}

// used to submit the custom config array and return the samples matrix
const handleUpdateSamplesSubmit = async (access_token, surveyID, samples) => {

  // manually set for now
  //let temp_survey_id = "1b9e006c-3748-4e20-8e49-dc789369f971";

  // let custom_config_body = {
  //   "survey_id": surveyID,
  //   "custom_model": configCustom.term_status
  // }

  const samples_body = {
    "survey_id": surveyID,
    "doe_matrix": samples
  }

  console.log("in handleUpdateSamplesSubmit");
  console.log(samples_body);

  // access the api url in order to add the custom model to the survey and get samples back
  try {
    const response = await fetch(`${process.env.REACT_APP_API_URL}/survey/create/update_samples`, {
      method: 'post',
      headers: {
        'content-type': 'application/json',
        'Authorization': `JWT ${access_token}`,
        'Accept': 'application/json'
      },
      body: JSON.stringify(samples_body)
    }
    );

    const response_body = await response.json();
    console.log('json response');
    console.log(response_body);

    //print the error if the api call
  } catch (err) {
    console.log(err.message)

    Swal.fire({
      icon: 'error',
      title: 'There was an issue connecting to the API.',
      text: `${err.message}`
    })
  }
}

// used to submit the add pages for a survey
const handleAddPagesSubmit = async (access_token, surveyID, addPages) => {
  addPages['survey_id'] = surveyID;


  //add_pages
  try {
    const response = await fetch(`${process.env.REACT_APP_API_URL}/survey/create/add_pages`, {
      method: 'post',
      headers: {
        'content-type': 'application/json',
        'Authorization': `JWT ${access_token}`,
        'Accept': 'application/json'
      },
      body: JSON.stringify(addPages)
    }
    );

    const response_body = await response.json();
    console.log('json response');
    console.log(response_body);

    //print the error if the api call
  } catch (err) {
    console.log(err.message)
    Swal.fire({
      icon: 'error',
      title: 'There was an issue connecting to the API.',
      text: `${err.message}`
    })
  }
}

// used to submit the style editor for a survey
const handleStyleEditorSubmit = async (access_token, surveyID, styleEditor) => {
  console.log('in handleStyleEditorSubmit')

  const style_editor_body = { ...styleEditor, "survey_id": surveyID }


  //add_pages
  try {
    const response = await fetch(`${process.env.REACT_APP_API_URL}/survey/create/add_add_style`, {
      method: 'post',
      headers: {
        'content-type': 'application/json',
        'Authorization': `JWT ${access_token}`,
        'Accept': 'application/json'
      },
      body: JSON.stringify(style_editor_body)
    }
    );

    const response_body = await response.json();
    console.log('json response');
    console.log(response_body);

    //print the error if the api call
  } catch (err) {
    console.log(err.message)
    Swal.fire({
      icon: 'error',
      title: 'There was an issue connecting to the API.',
      text: `${err.message}`
    })
  }
}

// used to submit the share and save fields for a survey
const handleShareAndSave = async (access_token, surveyID, shareSave) => {
  console.log('in handleShareAndSave')

  const share_save_body = { ...shareSave, "survey_id": surveyID }



  try {
    const response = await fetch(`${process.env.REACT_APP_API_URL}/survey/create/add_share_save`, {
      method: 'post',
      headers: {
        'content-type': 'application/json',
        'Authorization': `JWT ${access_token}`,
        'Accept': 'application/json'
      },
      body: JSON.stringify(share_save_body)
    }
    );

    const res = await response.json();
    console.log('json response');
    console.log(res);

    //print the error if the api call
  } catch (err) {
    console.log(err.message)
    Swal.fire({
      icon: 'error',
      title: 'There was an issue connecting to the API.',
      text: `${err.message}`
    })
  }
}


const CreateAdvanced = ({ isAuthenticated, access_token }) => {
  // ALL CONSTS USED FOR EACH SUBMENU //
  // surveyID, set from intro page and sent for all subsequent API calls to identify survey
  const [surveyID, setSurveyID] = useState(null);


  // the configuration inputs which encompass variables, constants, dynamic modeling, model type, and terms

  const [configInputs, setConfigInputs] = useState({
    variable_names: [],
    variable_types: [],
    variable_lows: [],
    variable_highs: [],
    variable_units: [],
    variable_material_codes: [],
    variable_descriptions: [],
    variable_categories: [],
    levels: null,
    constant_names: [],
    constant_types: [],
    constant_amounts: [],
    constant_units: [],
    constant_material_codes: [],
    constant_descriptions: [],
    required_terms: null,
    optional_terms: null,
    design_type: null,
    dynamic_model: false,
    dynamic_sample_per_it: null,
    max_dynamic_it: null,
    num_samples: null
  })


  // ALL CONSTS USED FOR EACH SUBMENU //
  // surveyID, set from intro page and sent for all subsequent API calls to identify survey
  //const [surveyID, setSurveyID] = useState("empty");


  // set the surveyID
  useEffect(() => {

  console.log('in useEffect')
  setConfigInputs(prevState => ({ ...prevState, survey_id: surveyID }));
  }, [surveyID, setConfigInputs]);

  const AddPagesRef = useRef(); // This is to validate the required inputs have been set in each tab.
  const ConfigurationRef = useRef();
  const ConfigurationCustomRef = useRef();
  const IntroMenuRef = useRef();
  const ShareAndSaveRef = useRef();
  const StyleEditorRef = useRef();
  const ViewSamplesRef = useRef();
  const gridRef = useRef();
  const [gridData, setGridData] = useState([]);


  // menu fields from intro inputs
  const [introInputs, setIntroInputs] = useState({
    project_title: "",
    brief_number: "",
    flavor_type: "Not Applicable",
    project_objective: "",
    project_background: null,
    project_type: "Ingredient Replacement",
    hardware: "Yes",
    atom_administer: true,
    giv_collaborators: []
  })

  
  const [result, setResult] = useState({});
  const [numSamples, setNumSamples] = useState(result.Recommended_No_Samples);

  // if user selects custom survey for either required or optional, we set terms from api response and ask user to set term status
  const [configCustom, setConfigCustom] = useState({
    terms: [],
    term_status: []
  });

  // the DoE returned from matlab that a user can then edit
  const [samples, setSamples] = useState("");

  const [rowData, setRowData] = useState([]);

  // the DoE varibales for screening
  const [screeningDOE, setScreeningDOE] = useState([]);

  // addpages is used if a user wishes to use ATOM to deliver a survey, it covers the questions/instructions a user wants to display
  const [primaryQuestion, setPrimaryQuestion] = useState({
    primary_survey_question: [{
      question: "",
      show_rating_label: false,
      minimum: null,
      maximum: null,
      step: null,
      shape: "",
      rating_labels: []
    }]
  })

  const [secondaryQuestion, setSecondaryQuestion] = useState({
    secondary_survey_question: []
  })

  const [instructions, setInstructions] = useState({
    instructions: [{
      title: "",
      description: "",
    }]
  })

  const [demographicQuestions, setDemographicQuestions] = useState({
    demographic_questions: [{
      age_range: false,
      gender: false,
      race: false,
      question: "",      
      randomize: false,
      number_of_options: null,
      fields: []
    }]
  })

  const [addRecommendation, setAddRecommendation] = useState({
    recommendations: [{
      drivers_of_liking: false,
      show_recommendations: false,
      num_products: 3,
      number_of_recommendations: 3,
      recommendations: Array.from({ length: 3 }, () => ({ rec: '', variable_values: [] }))
    }]
  })

  const [gridItems, setGridItems] = useState ([
    {
    id: 1,
    title: "Primary Survey Question",
    defaultText: "How much do you like this aroma?",
    showDelete: false,
    },
  ]);

  const [addPages, setAddPages] = useState({
    primary_survey_question: primaryQuestion,
    secondary_survey_question: secondaryQuestion,
    instructions: instructions,
    demographic_questions: demographicQuestions,
    add_recommendation: addRecommendation
  })
  useEffect(() =>{
    fetchSurveyID(access_token, setSurveyID).then(() => {
        console.log(surveyID)
    })
  }, [])

  useEffect(() => {
    setAddPages(prevState => ({
      ...prevState,
      primary_survey_question: primaryQuestion.primary_survey_question,
      secondary_survey_question: secondaryQuestion.secondary_survey_question,
      instructions: instructions.instructions,
      demographic_questions: demographicQuestions.demographic_questions,
      add_recommendation: addRecommendation.recommendations
    }));
  }, [primaryQuestion, secondaryQuestion, instructions, demographicQuestions, addRecommendation]);

  const [styleEditor, setStyleEditor] = useState({
    survey_theme: null,
    page_background: null,
    page_title: null,
    description_text: null,
    question_text: null,
    answer_text: null,
    answer_description: null,
    error_text: null,
    progress_indicator: null,
    buttons: null,
    word_cloud: null,
    recommendation_name: null,
    recommendation_title: null,
    page_title_bold: null,
    page_title_italic: null,
    description_text_bold: null,
    description_text_italic: null,
    question_bold: null,
    question_italic: null,
    answer_bold: null,
    answer_italic: null,
    answer_description_bold: null,
    answer_description_italic: null,
    error_bold: null,
    error_italic: null,
    recommendation_name_bold: null,
    recommendation_name_italic: null,
    header_bold: null,
    header_italic: null
  })
    const getDateWeekAhead = () => {
        const date = new Date();
        date.setDate(date.getDate() + 7);
        return date.toISOString().split('T')[0];
    };
  // share and save is the final step, it converts a survey from draft to final and allows user to set sharing options and viewers
  const [shareSave, setShareSave] = useState({
    internal_share: false,
    customer_share: false,
    share_list: [],
    accessEndDate: getDateWeekAhead()
  });

  // These are here to handle the change of the tab panels
  const theme = useTheme();
  const [value, setValue] = useState(0);

  // const handleChange = (event, newValue) => {
  //   setValue(newValue);
  // };

  const handleChangeIndex = (index) => {
    setValue(index);
  };

  // why is this here?
  const handleInputChange = (e) => {

    // handle change for checkbox
    if (e.target.type === "checkbox") {

      setIntroInputs(
        {
          ...introInputs,
          [e.target.name]: e.target.checked
        }
      )
      // handle all other change types
    } else {

      setIntroInputs({
        ...introInputs,
        [e.target.name]: e.target.value,
      });
    }
  };

  // used to set the state of the menu
  // the index is used to set the active sub-menu open and trigger handleForm submits
  const handleNext = () => {

    // submit intro form when user clicks 'Next" on intro menu
    if (value === 0) {
      // Check that all required fields have been inputted.
      if(IntroMenuRef.current && IntroMenuRef.current.areRequiredFieldsFilled()) {
        handleIntroSubmit(access_token, introInputs, surveyID, setSurveyID);
        // go to next tab panel
        setValue((prevValue) => prevValue + 1);
      }
    }

    // submit config form when user clicks 'Next' on config menu
    if (value === 1) {
      if (ConfigurationRef.current && ConfigurationRef.current.areRequiredFieldsFilled()) {

        if (introInputs.project_type !== "Screening"){
        handleConfigSubmit(access_token, configInputs, setConfigInputs, surveyID, configCustom, setConfigCustom, samples, setSamples);
        }
        // if Custom is set for either required or optional terms, go to the config optional menu, else go straight to samples menu
        if (configInputs.required_terms === "Custom" || configInputs.optional_terms === "Custom") {
          setValue((prevValue) => prevValue + 1);
        } else {
          setValue((prevValue) => prevValue + 2);
        }
      }

    }
    //handle custom config menu when 'Next' is clicked
    if (value === 2) {

      // submit the custom terms alongside the survey id
      //console.log(configCustom);
      handleCustomConfigSubmit(access_token, surveyID, configCustom, samples, setSamples);
      //console.log(samples)

      // to the view samples page (3)
      setValue((prevValue) => prevValue + 1);
    }

    //handle update samples menu when 'Next is clicked
    if (value === 3) {

      handleUpdateSamplesSubmit(access_token, surveyID, samples)

      // move to the next view of Add Pages (4) only if atom_administer is true, else go to share and save
      if (introInputs.atom_administer === true) {
        setValue((prevValue) => prevValue + 1);
      } else {
        setValue((prevValue) => prevValue + 3);
      }
    }

    //handle update samples menu when 'Next is clicked
    if (value === 4) {
      if (AddPagesRef.current && AddPagesRef.current.areRequiredFieldsFilled()) {
        // check if all required fields are filled out

        // add add pages data to survey
        handleAddPagesSubmit(access_token, surveyID, addPages);

        // move to the next view of Style Editor
        setValue((prevValue) => prevValue + 1);
      }
    }

    //handle update style editor menu when Next is clicked
    if (value === 5) {

      // add add pages data to survey
      handleStyleEditorSubmit(access_token, surveyID, styleEditor)

      // move to the next view of Style Editor
      setValue((prevValue) => prevValue + 1);
    }

    //handle update share and save menu when Next is clicked
    if (value === 6) {

      // save the shareandsave screen that includes who to share with and email share invites
      handleShareAndSave(access_token, surveyID, shareSave);

      // SWAL to confirm survey is created and a user may return home
        Swal.fire( {
            title:"Your Project is Ready!",
            text: "You have taken the first step to building amazing solutions using artificial intelligence. Continue working with customers and collaborators to build amazing solutions and come back when you are either ready to administer a survey or add results."
         }).then(function() {
          window.location = `${process.env.REACT_APP_API_URL}/dashboard`;
      });
    }
    setFurthestTab(value + 1);
  }

  const handleBack = () => {
    if (value === 0) {
      return
    }
    setValue((prevValue) => prevValue - 1);
  }

  let conditional_addPages_styleEditor = null;
  if (introInputs.atom_administer === true) {
    conditional_addPages_styleEditor = false
  } else {
    conditional_addPages_styleEditor = true
  }

  const [furthestTab, setFurthestTab] = useState(0);
  const handleChange = (event, newValue) => {
    setValue(newValue);
    setFurthestTab(oldFurthestTab => Math.max(oldFurthestTab, newValue));
  };



  if (!surveyID) {
      return <div>Loading...</div>;  // or any other loading indicator you'd like
  }

  return (
    <Box sx={{ bgcolor: 'background.paper', mx: 'auto', width: '80%' }}>
      <AppBar position="static">
      <Tabs
          value={value}
          onChange={handleChange}
          indicatorColor="secondary"
          textColor="inherit"
          variant="fullWidth"
          aria-label="full width tabs example"
        >
          <Tab label="Introduction" {...a11yProps(0)} />
          <Tab label="Configuration" {...a11yProps(1)} disabled={furthestTab < 1} />
          <Tab label="Configuration-Custom" {...a11yProps(2)} disabled={furthestTab < 2} />
          <Tab label="View Samples" {...a11yProps(3)} disabled={furthestTab < 3} />
          <Tab label="Add Pages" {...a11yProps(4)} disabled={furthestTab < 4 || conditional_addPages_styleEditor} />
          <Tab label="Style Editor" {...a11yProps(5)} disabled={furthestTab < 5 || conditional_addPages_styleEditor} />
          <Tab label="Share & Save" {...a11yProps(6)} disabled={furthestTab < 6} />
        </Tabs>
      </AppBar>
      {/* This is the configuration page layout */}
      <TabPanel value={value} index={0} dir={theme.direction}  >
        {/* The variables and constants fields are both within the same Grid container as separate
            Grid items. The items are layed out by row, while the whole container is layed out as
            columns.  */}

        {/* <Grid container direction="column" justifyContent="space-evenly" alignItems="flex-start" spacing={2}> */}
        <IntroMenu handleIntroInputChange={handleInputChange} introInputs={introInputs} setIntroInputs={setIntroInputs} ref={IntroMenuRef}/>

      </TabPanel>
      {/* This is the Add Pages page layout */}
      <TabPanel value={value} index={1} dir={theme.direction}>

        <Configuration introInputs={introInputs} projectType={introInputs.project_type} atomAdminister={introInputs.atom_administer} hardware={introInputs.hardware} configInputs={configInputs} setConfigInputs={setConfigInputs} result={result} setResult={setResult} numSamples={numSamples} setNumSamples={setNumSamples}  ref={ConfigurationRef}/>
      </TabPanel>
      {/* This is the Add Pages page layout */}
      <TabPanel value={value} index={2} dir={theme.direction}>
        Configuration-Custom
        <ConfigurationCustom value={value} survey_id="1b9e006c-3748-4e20-8e49-dc789369f971" terms={configCustom} />
      </TabPanel>
      {/* This is the style editor page layout */}
      <TabPanel value={value} index={3} dir={theme.direction} >
        {introInputs.project_type !== "Screening" ?
            <ViewSamples
                handleConfigSubmit={handleConfigSubmit}
                access_token={access_token}
                configInputs={configInputs}
                setConfigInputs={setConfigInputs}
                surveyID={surveyID}
                configCustom={configCustom}
                setConfigCustom={setConfigCustom}
                samples={samples}
                setSamples={setSamples}
                gridRef={gridRef}
                setGridData={setGridData}
                rowData={rowData}
                setRowData={setRowData}
            />
            : 
            <ScreeningViewSamples
              setScreeningDOE={setScreeningDOE}
              handleConfigSubmit={handleConfigSubmit}
              access_token={access_token}
              configInputs={configInputs}
              setConfigInputs={setConfigInputs}
              surveyID={surveyID}
              configCustom={configCustom}
              setConfigCustom={setConfigCustom}
              samples={samples}
              setSamples={setSamples}
              gridRef={gridRef}
              setGridData={setGridData}
            />
        }
      </TabPanel>
      {/* This is the style editor page layout */}
      <TabPanel value={value} index={4} dir={theme.direction}>
        <AddPages introInputs={introInputs} configInputs={configInputs} addPages={addPages} setAddPages={setAddPages} secondaryQuestion={secondaryQuestion} setSecondaryQuestion={setSecondaryQuestion} gridItems={gridItems} setGridItems={setGridItems} ref={AddPagesRef} screeningDOE={screeningDOE} />
      </TabPanel>
      {/* This is the style editor page layout */}
      <TabPanel value={value} index={5} dir={theme.direction}>
        <StyleEditor setStyleEditor={setStyleEditor} addPages={addPages} />
      </TabPanel>
      {/* This is the style editor page layout */}
      <TabPanel value={value} index={6} dir={theme.direction}>
        <ShareAndSave setShareSave={setShareSave} gridData={gridData} />
      </TabPanel>

        {value === 0 ?
        <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: '40px' }}>
          <Button variant='contained' onClick={handleBack} disabled>Back</Button>
          <Button variant='contained' onClick={handleNext}>Next</Button>
        </div>
        : value === 6 ?
        <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: '40px' }}>
          <Button variant='contained' onClick={handleBack}>Back</Button>
          <Button variant='contained' onClick={handleNext}  style={{backgroundColor: '#0BDA51', color: '#FFF'}}>Submit</Button>
        </div>
        :
        <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: '40px' }}>
          <Button variant='contained' onClick={handleBack}>Back</Button>
          <Button variant='contained' onClick={handleNext}>Next</Button>
        </div>
        }
    </Box>
  );
}

const mapStateToProps = state => ({
  isAuthenticated: state.auth.isAuthenticated,
  access_token: state.auth.access
});


export default connect(mapStateToProps, {})(CreateAdvanced);
