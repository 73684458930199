import React, { useState, useEffect } from 'react';
const Stabilizing = ({  }) => {

  return (
    <div>
        <h1>Preparing the next aroma...</h1>
    </div>
  );
};

export default Stabilizing;