import {React, useEffect} from "react";
import { FormControl, RadioGroup, FormControlLabel, Radio } from "@mui/material";
import ShapeAndLabel from "./questioncomponents/ShapeAndLabel"

const Numeric = ({ question, style, questionResponseData, setQuestionResponseData, submitResponse, questionPayload }) => {

  // Input the response into "setQuestionResponseData" UseState.
  // the question object is a JSON with all the data needed to fill in the question inputs.
 // TODO: Some of these consts could probaby be generalized so other components can fetch them .


    const handleChange = (event) => {
        console.log("EVENT")
        console.log(event)
        setQuestionResponseData({
          [event.target.name]: event.target.value,
        });
      };

    useEffect(() => {
       // submitResponse(questionPayload)
    },  [questionPayload])
      //console.log(questionResponseData)

    const numericQuestionParentStyle = {
        display: 'flex',
        flexDirection: 'column', // to stack child elements vertically
        justifyContent: 'center', // to center items on the cross-axis (vertical, because of 'column')
        alignItems: 'center', // to center items on the main axis (horizontal, because of 'column')
        height: '100%', // to fill the parent height, necessary for centering vertically
    };

    const numericSecondaryQuestionStyle = {
        position: "absolute",
        display: 'flex',
        top: '20%',
        alignItems: 'center',
        justifyContent: 'center',
        color: style.question_text, // color value 'bodyText'
        fontFamily: style.fontFamily, // font family 'Font'
        fontWeight: style.question_bold ? 'bold' : 'normal', // add 'bold' if true, else 'normal'
        fontSize: '20px', // font size 'fontSize'
        fontStyle: style.question_italic ? 'italic' : 'normal', // add 'italic' if true, else 'normal'
        // For min/max font size, CSS does not directly support this
        // Consider using JavaScript logic to dynamically adjust based on conditions
    };

    const shapeDivStyle = {
        color: style.answer_text, // errorText color
        position: "absolute",
        top: '35%',
        fontFamily: style.fontFamily,
        fontWeight: "bold",
        fontSize: 35,
        fontStyle: "normal",
        display: "flex",
        justifyContent: "space-around", // change to space-around to distribute items evenly
        alignItems: "center",
        margin: 0,
        padding: 0,
        width: "100%", // add this to fill the parent width
        flexDirection: "row", // to make sure items are aligned horizontally
        gap: '5px' // This will add space between child elements
    };

    return (
    // <div style={numericQuestionParentStyle}>
    //             <div style={numericSecondaryQuestionStyle}>
    //                 <p>{`${question.question}`}</p>
    //             </div>
    //             <div style={shapeDivStyle}>
    //                 {Array(question.step).fill().map((_, i) =>
    //                     <ShapeAndLabel
    //                         style={style}
    //                         shapeMax={question.maximum}
    //                         shapeMin={question.minimum}
    //                         shape={question.shape}
    //                         steps={question.step}
    //                         index={i}
    //                         showRatingLabels={question.show_rating_label}
    //                         ratingLabels={question.fields}
    //                         key={i}
    //                     />
    //                 )}
    //             </div>
    //         </div>
    <div class="questionObject">
      <FormControl>
          <RadioGroup
              id="numeric_response"
              value={questionResponseData.numeric_response}
              name="numeric_response"
              onChange={handleChange}
              row
          >
              {question.fields.map((field, index) => (
                <FormControlLabel 
                    key={index}
                    value={`${question.minimum + (index * ((question.maximum - question.minimum) / (question.step - 1))) }`}
                    // control={<ShapeAndLabel
                    //             style={style}
                    //             shapeMax={question.maximum}
                    //             shapeMin={question.minimum}
                    //             shape={question.shape}
                    //             steps={question.step}
                    //             index={index}
                    //             // showRatingLabels={question.show_rating_label}
                    //             // ratingLabels={question.fields}
                    //             selected={questionResponseData.numeric_response === `${field}`}
                    //         />}
                    control={<Radio />}
                    label={`${field}`}
                    labelPlacement="top"
                />
              ))}
          </RadioGroup>
      </FormControl>
    </div>
  );
};

export default Numeric;
