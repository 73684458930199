import React, { useState, useCallback, useEffect } from 'react';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import * as XLSX from 'xlsx';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import { FormControl, IconButton } from '@mui/material';

// TODO: Get this data from the server
const panelists = Array(100).fill("User 1");
const samples = Array.from({length: 10}, (_, i) => `Sample ${i+1}`);
const order = Array.from({length: 10}, (_, i) => `${i+1}`);

const initialData = panelists.map((p, index) => ({
  id: `item-${index}`,
  panelist: p,
  sample: samples[index],
  order: order[index],
}));

function convertToLongFormat(data) {
  // Helper function
  const result = [];
  let uniqueOrdinalIndex = 1;

  for (const participantId in data) {
    const samples = data[participantId];

    samples.forEach((sampleId, index) => {
      result.push({
        id: uniqueOrdinalIndex.toString(),
        panelist: participantId,
        sample: sampleId,
        order: index + 1 // Assuming the order starts from 1
      });

      uniqueOrdinalIndex++;
    });
  }

  return result;
}

const SampleOrderRandom = ({configInputs, setConfigInputs}) => {
  const [data, setData] = useState(initialData);

  const onDragEnd = useCallback((result) => {
    if (!result.destination) {
      return;
    }

    const newData = Array.from(data);
    const [removed] = newData.splice(result.source.index, 1);
    newData.splice(result.destination.index, 0, removed);

    setData(newData);
  }, [data]);

    useEffect(() => {
      // Need to convert panelist order from short to long df

      let participant_data = convertToLongFormat(configInputs.user_sample_order);
      console.log("PARTICIPANT DATA")
      console.log(participant_data)
      setData(participant_data)

    }, [])
  // const handleFileUpload = useCallback((e) => {
  //   const file = e.target.files[0];
  //   const reader = new FileReader();
  //   reader.onload = (evt) => {
  //     const bstr = evt.target.result;
  //     const wb = XLSX.read(bstr, {type:'binary'});
  //     XLSX.writeFile(wb, "out.xlsx");
  //   };
  //   reader.readAsBinaryString(file);
  // }, []);

  const exportToExcel = useCallback(() => {
    const dataToExport = data.map(({ id, ...rest }) => rest); // this will create a new array without the id property
    const ws = XLSX.utils.json_to_sheet(dataToExport);
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "Sheet1");
    XLSX.writeFile(wb, "output.xlsx");
  }, [data]);


  return (
    <div style={{ display: 'flex', justifyContent: 'space-evenly', alignItems: 'flex-start' }}>
      <div>
        <h5>You Selected - Random Sample Presentation Order</h5>
        <h5>Samples will be presented to panelists in the following order.</h5>
      </div>
      <DragDropContext onDragEnd={onDragEnd}>
        <Droppable droppableId="droppable">
          {(provided) => (
            <table ref={provided.innerRef} {...provided.droppableProps} style={{ border: '1px solid black', borderCollapse: 'collapse', height: "400px", overflowY: 'scroll', display: 'block'  }}>
              <thead>
                <tr>
                  <th style={{ border: '1px solid black', padding: '10px' }}>Panelist</th>
                  <th style={{ border: '1px solid black', padding: '10px' }}>Sample</th>
                  <th style={{ border: '1px solid black', padding: '10px' }}>Order</th>
                </tr>
              </thead>
              <tbody>
                {data.map((item, index) => (
                  <Draggable key={item.id} draggableId={item.id} index={index}>
                    {(provided) => (
                      <tr ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps}>
                        <td style={{ border: '1px solid black', padding: '10px' }}>{item.panelist}</td>
                        <td style={{ border: '1px solid black', padding: '10px' }}>{item.sample}</td>
                        <td style={{ border: '1px solid black', padding: '10px' }}>{item.order}</td>
                      </tr>
                    )}
                  </Draggable>
                ))}
                {provided.placeholder}
              </tbody>
            </table>
          )}
        </Droppable>
      </DragDropContext>
      <FormControl>
        <h4>Export to Excel File</h4>
        <IconButton component="span" onClick={exportToExcel}>
          <AddCircleIcon color='primary' fontSize="large"/>
        </IconButton>
      </FormControl>
    </div>
  );
}

export default SampleOrderRandom;
