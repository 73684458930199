import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { alpha, Box, Table, Button, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow, TableSortLabel, Toolbar, Typography, Paper, Checkbox, IconButton, Tooltip, FormControlLabel, Switch } from '@mui/material';
import { visuallyHidden } from '@mui/utils';
import { Delete as DeleteIcon, FilterList as FilterListIcon } from '@mui/icons-material';
import { connect } from 'react-redux';
import { useNavigate } from 'react-router-dom';

function createData(survey_id, survey_name, survey_date_created, survey_owner, survey_number_of_panelists, survey_project_type, survey_VAS_used, survey_br_number, survey_flavour_type, survey_status) {
  return {
    survey_id,
    survey_name,
    survey_date_created,
    survey_owner,
    survey_number_of_panelists,
    survey_project_type,
    survey_VAS_used,
    survey_br_number,
    survey_flavour_type,
    survey_status,
  };
}

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

// Since 2020 all major browsers ensure sort stability with Array.prototype.sort().
// stableSort() brings sort stability to non-modern browsers (notably IE11). If you
// only support modern browsers you can replace stableSort(exampleArray, exampleComparator)
// with exampleArray.slice().sort(exampleComparator)
function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

const headCells = [
  {
    id: 'survey_name',
    numeric: false,
    disablePadding: true,
    label: 'Survey Name',
  },
  {
    id: 'survey_date_created',
    numeric: true,
    disablePadding: false,
    label: 'Date Created',
  },
  {
    id: 'survey_owner',
    numeric: true,
    disablePadding: false,
    label: 'Owner',
  },
  {
    id: 'survey_number_of_panelists',
    numeric: true,
    disablePadding: false,
    label: '# of Panelists',
  },
  {
    id: 'survey_project_type',
    numeric: true,
    disablePadding: false,
    label: 'Project Type',
  },
  {
    id: 'survey_VAS_used',
    numeric: true,
    disablePadding: false,
    label: 'VAS Used',
  },
  {
    id: 'survey_br_number',
    numeric: true,
    disablePadding: false,
    label: 'BR #',
  },
  {
    id: 'survey_flavour_type',
    numeric: true,
    disablePadding: false,
    label: 'Flavour Type',
  },
  {
    id: 'survey_status',
    numeric: true,
    disablePadding: false,
    label: 'Survey Status',
  },
];

function EnhancedTableHead(props) {
  const { onSelectAllClick, order, orderBy, numSelected, rowCount, onRequestSort } =
    props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        {/* <TableCell padding="checkbox">
          <Checkbox
            color="primary"
            indeterminate={numSelected > 0 && numSelected < rowCount}
            checked={rowCount > 0 && numSelected === rowCount}
            onChange={onSelectAllClick}
            inputProps={{
              'aria-label': 'select all desserts',
            }}
          />
        </TableCell> */}
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align='center'
            padding={headCell.disablePadding ? 'none' : 'normal'}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : 'asc'}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <Box component="span" sx={visuallyHidden}>
                  {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                </Box>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.oneOf(['asc', 'desc']).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};

function EnhancedTableToolbar(props) {
  const { numSelected } = props;

  return (
    <Toolbar
      sx={{
        pl: { sm: 2 },
        pr: { xs: 1, sm: 1 },
        ...(numSelected > 0 && {
          bgcolor: (theme) =>
            alpha(theme.palette.primary.main, theme.palette.action.activatedOpacity),
        }),
      }}
    >
      {numSelected > 0 ? (
        <Typography
          sx={{ flex: '1 1 100%' }}
          color="inherit"
          variant="subtitle1"
          component="div"
        >
          {numSelected} selected
        </Typography>
      ) : (
        <Typography
          sx={{ flex: '1 1 100%' }}
          variant="h6"
          id="tableTitle"
          component="div"
        >
        </Typography>
      )}

      {numSelected > 0 ? (
        <Tooltip title="Delete">
          <IconButton>
            <DeleteIcon />
          </IconButton>
        </Tooltip>
      ) : (
        <Tooltip title="Filter list">
          <IconButton>
            <FilterListIcon />
          </IconButton>
        </Tooltip>
      )}
    </Toolbar>
  );
}

EnhancedTableToolbar.propTypes = {
  numSelected: PropTypes.number.isRequired,
};

const SurveyList = ({isAuthenticated, access_token}) => {
  const navigate = useNavigate();
  const [loading, setLoading] = React.useState(false)
  const [order, setOrder] = React.useState('desc');
  const [orderBy, setOrderBy] = React.useState('survey_date_created');
  const [selected, setSelected] = React.useState([]);
  const [page, setPage] = React.useState(0);
  const [dense, setDense] = React.useState(false);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);

  const [rows, setRows] = useState([]);
  const [surveyID, setSurveyID] = useState(0)
  const [, forceUpdate] = useState();

  useEffect(() => {
    setLoading(true)
    const fetchData = async () => {
      try {
        const data = await fetch(`${process.env.REACT_APP_API_URL}/survey/view/my_projects`, {
          method: 'post',
          headers: {
            'content-type': 'application/json',
            'Authorization': `JWT ${access_token}`,
            'Accept': 'application/json'
          }
        })
        const response = await data.json()
        console.log(response);

        let r = []
        for (let i = 0; i < response.user_designs.length; i++) {
          r.push(createData(
            response['user_designs'][i]['survey_id'],
            response['user_designs'][i]['project_title'],
            response['user_designs'][i]['timestamp_started'], 
            'fixme', 
            'fixme', 
            'fixme', 
            'fixme', 
            response['user_designs'][i]['brief_number'],
            'fixme', // Add a placeholder or actual value for survey_flavour_type 
            response['user_designs'][i]['survey_status'] || 'Unknown' // This is the new data
          ));
        }     
        console.log(r); 
        setRows(r)
        setLoading(false)
        forceUpdate(n => !n);
      } catch (err) {
        console.log(err.message)
      }
    }
    fetchData();
  }, [])

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelected = rows.map((n) => n.survey_id);
      setSelected(newSelected);
      return;
    }
    setSelected([]);
  };  

  // const handleClick = (event, survey_name, survey_id) => {
  //   console.log(survey_id)
  //   setSurveyID(survey_id)
  //   const selectedIndex = selected.indexOf(survey_id);
  //   let newSelected = [];
  
  //   if (selectedIndex === -1) {
  //     newSelected = newSelected.concat(selected, survey_id);
  //   } else if (selectedIndex === 0) {
  //     newSelected = newSelected.concat(selected.slice(1));
  //   } else if (selectedIndex === selected.length - 1) {
  //     newSelected = newSelected.concat(selected.slice(0, -1));
  //   } else if (selectedIndex > 0) {
  //     newSelected = newSelected.concat(
  //       selected.slice(0, selectedIndex),
  //       selected.slice(selectedIndex + 1),
  //     );
  //   }
  
  //   setSelected(newSelected);
  // };  

  const handleClick = (event, survey_name, survey_id) => {
    console.log(survey_id);
    setSurveyID(survey_id);
    setSelected(survey_id);  // assuming setSelected now takes a single value
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleChangeDense = (event) => {
    setDense(event.target.checked);
  };

  const isSelected = (survey_name) => selected.indexOf(survey_name) !== -1;

  // Avoid a layout jump when reaching the last page with empty rows.
  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rows.length) : 0;

  const visibleRows = React.useMemo(
    () =>
      stableSort(rows, getComparator(order, orderBy))
        .filter(row => row.survey_name)  // Filter out rows without a survey_name
        .slice(
          page * rowsPerPage,
          page * rowsPerPage + rowsPerPage,
        ),
    [rows, order, orderBy, page, rowsPerPage],
  );

  const onBeginClick = () => {
    console.log("Navigating...")
    console.log(surveyID)
    navigate("/administer", {state: {surveyID}})
  }

  return (
    <div className="blue_bg">
      <Box sx={{width: '100%'}} style={{alignContent: 'center', textAlign: 'center'}}>
        <h1 class="p-3 display-4" >Select A Survey </h1>

        {loading ? (<h1>Loading....</h1>) : (
          <Box sx={{ width: '75%', margin: 'auto', padding: '10px' }}>
        <Paper sx={{ width: '100%', mb: 2 }}>
          <EnhancedTableToolbar numSelected={selected.length} />
          <TableContainer>
            <Table
              sx={{ minWidth: 750 }}
              aria-labelledby="tableTitle"
              size={dense ? 'small' : 'medium'}
            >
              <EnhancedTableHead
                numSelected={selected.length}
                order={order}
                orderBy={orderBy}
                onSelectAllClick={handleSelectAllClick}
                onRequestSort={handleRequestSort}
                rowCount={rows.length}
              />
              <TableBody>
              {visibleRows.map((row, index) => {
                console.log(visibleRows.length)

                  if (!row.survey_name) {
                    return null;
                  }

                  const isItemSelected = isSelected(row.survey_id);
                  const labelId = `enhanced-table-checkbox-${index}`;

                  return (
                    <TableRow
                      hover
                      onClick={(event) => handleClick(event, row.survey_name, row.survey_id)}
                      role="checkbox"
                      aria-checked={isItemSelected}
                      tabIndex={-1}
                      key={row.survey_name}
                      selected={isItemSelected}
                      sx={{ cursor: 'pointer' }}
                    >
                      {/* <TableCell padding="checkbox">
                        <Checkbox
                          color="primary"
                          checked={isItemSelected}
                          inputProps={{
                            'aria-labelledby': labelId,
                          }}
                        />
                      </TableCell> */}
                      <TableCell
                        component="th"
                        id={labelId}
                        scope="row"
                        padding="none"
                        align="center"
                      >
                        {row.survey_name}
                      </TableCell>
                      <TableCell hidden>{row.survey_id}</TableCell>
                      <TableCell align="center">
                        {(() => {
                          const date = new Date(row.survey_date_created);
                          const day = String(date.getUTCDate()).padStart(2, '0');
                          const month = String(date.getUTCMonth() + 1).padStart(2, '0');  // Months are 0-based, hence the +1
                          const year = date.getUTCFullYear();
                          
                          return `${month}/${day}/${year}`;
                        })()}
                      </TableCell>
                      <TableCell align="center">{row.survey_owner}</TableCell>
                      <TableCell align="center">{row.survey_number_of_panelists}</TableCell>
                      <TableCell align="center">{row.survey_project_type}</TableCell>
                      <TableCell align="center">{row.survey_VAS_used}</TableCell>
                      <TableCell align="center">{row.survey_br_number}</TableCell>
                      <TableCell align="center">{row.survey_flavour_type}</TableCell>
                      <TableCell align="center">{row.survey_status}</TableCell>
                    </TableRow>
                  );
                })}
                {emptyRows > 0 && (
                  <TableRow
                    style={{
                      height: (dense ? 33 : 53) * emptyRows,
                    }}
                  >
                    <TableCell colSpan={6} />
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            rowsPerPageOptions={[5, 10, 25]}
            component="div"
            count={rows.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Paper>
        <FormControlLabel hidden
          control={<Switch checked={dense} onChange={handleChangeDense} />}
          label="Dense padding"
        />
      </Box>
        )}
        {selected.length > 0 && (
        <Button variant="contained" style={{margin: 'auto', marginBottom: '20px'}} onClick={onBeginClick}>Begin</Button>)}
      </Box>
    </div>
  );
}
const mapStateToProps = state => ({
  isAuthenticated: state.auth.isAuthenticated,
  access_token: state.auth.access
});
export default connect(mapStateToProps, {})(SurveyList);
