import { styled } from '@mui/material/styles';
import { Grid } from '@mui/material';
import React from 'react';
import 'sweetalert2/src/sweetalert2.scss';
import Paper from '@mui/material/Paper';

const ProjectBackground = ({ design }) => {

    const Header = styled(Paper)(({ theme }) => ({
    backgroundColor: 'lightBlue',
    padding: theme.spacing(1),
    textAlign: 'center',
    color: 'black',
    borderStyle: 'solid',
    borderColor: 'dimgrey',
    borderWidth: 'thin'
    }));

    const ItemLabel = styled("div")(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: 'center',
    fontWeight: 'bold',
    color: 'black',
    }));

    const ItemData = styled("div")(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: 'center',
    color: 'black',
    }));

    return (

    <Grid container spacing={2}>          
    <Grid item xs={12}>
        <Header>Title</Header>
    </Grid>
    <Grid item xs={12}>
        <ItemData>{design.project_title}</ItemData>
    </Grid>
    <Grid item xs={3}>
        <ItemLabel>Project Owner</ItemLabel>
    </Grid>
    <Grid item xs={3}>
        <ItemLabel>Collaborators</ItemLabel>
    </Grid>
    <Grid item xs={3}>
        <ItemLabel>BR#</ItemLabel>
    </Grid>
    <Grid item xs={3}>
        <ItemLabel>Flavor Type</ItemLabel>
    </Grid>
    <Grid item xs={3}>
        <ItemData>{design.creator?.name}</ItemData>
    </Grid>
    <Grid item xs={3}>
        { design.giv_collaborators?.map((col, index) => (
        <ItemData key={index}>{col}</ItemData>
        ))}
    </Grid>
    <Grid item xs={3}>
        <ItemData>{design.brief_number}</ItemData>
    </Grid>
    <Grid item xs={3}>
        <ItemData>{design.flavor_type}</ItemData>
    </Grid>
    <Grid item xs={12}>
        <Header>Background</Header>
    </Grid>
    <Grid item xs={12}>
        <ItemData>{design.project_background}</ItemData>
    </Grid>
    <Grid item xs={12}>
        <Header>Goal</Header>
    </Grid>
    <Grid item xs={12}>
        <ItemData>{design.project_objective}</ItemData>
    </Grid>
    <Grid item xs={4}>
        <ItemLabel>Do you plan to use VAS?</ItemLabel>
    </Grid>
    <Grid item xs={4}>
        <ItemLabel>Do you plan to use ATOM to collect Data?</ItemLabel>
    </Grid>
    <Grid item xs={4}>
        <ItemLabel>Project Type</ItemLabel>
    </Grid>
    <Grid item xs={4}>
        <ItemData>{design.hardware}</ItemData>
    </Grid>
    <Grid item xs={4}>
        { design.atom_administer === false ?
            <ItemData>No</ItemData>
        :
            <ItemData>Yes</ItemData>
        }
    </Grid>
    <Grid item xs={4}>
        <ItemData>{design.project_type}</ItemData>
    </Grid>
    </Grid>

    )
}

export default ProjectBackground;