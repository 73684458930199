import PropTypes from 'prop-types';
import { useTheme } from '@mui/material/styles';
import { AppBar, Tab, Tabs, Typography, Box, Button } from '@mui/material';
import React, {useEffect, useRef, useState} from 'react';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { yellow } from '@mui/material/colors';
import Swal from 'sweetalert2';
import 'sweetalert2/src/sweetalert2.scss';
import {VASConnect} from '@estanalytical/vasconnect';

import { connect } from 'react-redux';

//menu components

import MultiSurveyConfiguration from './components/MultiSurveyConfiguration';
import MultiSurveyStyleEditor from './components/MultiSurveyStyleEditor.js';
import Configuration from './components/Configuration';
import SampleOrderRandom from './components/SampleOrderRandom';
import SampleOrderUserDefined from './components/SampleOrderUserDefined';
import ChannelAlignment from './components/ChannelAlignment';
import ReviewShareSettings from './components/ReviewShareSettings';
import VASSetup from "./components/VASSetup";
import { useLocation } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';

function TabPanel(props) {
    const { children, page, index, ...other } = props;
    // This useEffect gets the survey data model which we can use to populate the relevant fields of the
    // administer tab.
    
  return (
    <div
      role="tabpanel"
      hidden={page !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {page === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  page: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `full-width-tab-${index}`,
    'aria-controls': `full-width-tabpanel-${index}`,
  };
}

// used to submit the share and save fields for a survey
const handleReviewShareSettings = async (access_token, surveyID, design) => {
  // This function calls the design API and updates the share save settings from administer config
  // todo: need to configure a serializer so that only the values for share save could be getting updated

  design = { ...design, "survey_id": surveyID }
    let payload = { // I'm doing this because passing in the full design object (serialized) was overwriting some values in a problematic way.
      // Isolating only the variables we want to update.
        // If more variables get added to this share_save page we would need to add them here as well.
        "survey_id": surveyID,
        "internal_share": design.internal_share,
        "customer_share": design.customer_share,
        "share_list": design.share_list,
        "accessEndDate": design.accessEndDate,
       // "giv_collaborators": design.giv_collaborators,
       // "giv_customers": design.giv_customers
    }
  try {
    const response = await fetch(`${process.env.REACT_APP_API_URL}/survey/create/add_share_save`, {
      method: 'post',
      headers: {
        'content-type': 'application/json',
        'Authorization': `JWT ${access_token}`,
        'Accept': 'application/json'
      },
      body: JSON.stringify(payload)
    });
    const res = await response.json();
    console.log('json response');
    console.log(res);
    //print the error if the api call
  } catch (err) {
    console.log(err.message)
  }
}

const Administer = ({ isAuthenticated, access_token }) => {
  const navigate = useNavigate();

  // These are here to handle the change of the tab panels
  const theme = useTheme();
  // Theme for the Begin button
  const begin_button_theme = createTheme({
    palette: {
      primary: yellow,
    },
  });
  const [page, setPage] = useState(2) // What page / tab are we on?
  const [loadingDesign, setLoadingDesign] = useState(false); // Is something loading?
  const [loadingAdminConfig, setLoadingAdminConfig] = useState(false); // Is something loading?

  const [design, setDesign] = useState({});  // What is the survey design?
  const location= useLocation();
  const { surveyID } = location.state || {};  // Using a fallback in case state is not passed
  const [configInputs, setConfigInputs] = useState({}) //  These are the Administer Config model parameters
  const ConfigRef = useRef(); // Used to validate inputs in Configure

  const vas = new VASConnect({
    appId: "75f3346f-84d7-42d7-a512-c3a25755a44b",
    appName: "ATOM3",
    licenseType: "persistent",
    licenseToken: "eyJhbGciOiJFUzI1NiIsInR5cCI6IkpXVCJ9.eyJpYXQiOjE2OTIyNzc2NDAsIm5iZiI6MTY5MjI3NzY0MCwidmFzX2Nvbm5lY3QiOnsibGljZW5zZV9pZCI6ImYzZjA0YWVlLTBkZTktNDA2ZC1iZTUyLTYyNTJlODk0YmNjYSIsImFwcF9pZCI6Ijc1ZjMzNDZmLTg0ZDctNDJkNy1hNTEyLWMzYTI1NzU1YTQ0YiIsImFwcF9uYW1lIjoiQVRPTTMiLCJhcHBfcHVibGlzaGVyIjoiR2l2YXVkYW4iLCJhcHBfaW1hZ2VfdXJsIjpudWxsfX0.1QHZLnoTVjsUv9CQYzH2KC_JloEVBBiSidIMXJug1ibsSxKpA9nxuoycuyPdGvo27vlByvLOSmwu4gpcswHY7A",
    authorizeAutomatically: true
  });
   const [VASdevice, setVASdevice] = useState('')
    // This function fetches the design and adminConfig from the database on load
   useEffect(() => {
        setLoadingDesign(true)
        setLoadingAdminConfig(true)
       const fetchSurveyData = async () => {
         try {
           const data = await fetch(`${process.env.REACT_APP_API_URL}/survey/view/view_design`, {
             method: 'post',
             headers: {
               'content-type': 'application/json',
               'Authorization': `JWT ${access_token}`,
               'Accept': 'application/json'
             },
               body: JSON.stringify({
                 'design_id': surveyID
               })
           })

           const response = await data.json()
           console.log(response);


           setDesign({...response, collaborators: response.giv_collaborators.join(", ") + ', ' + response.giv_customers.join(", ") });
           setLoadingDesign(false);

           //variable_names and constant_names are both lists
         } catch (err) {
           console.log(err.message)
         }
       }
       const fetchAdministerData =  async () => {
         try {
           const data = await fetch(`${process.env.REACT_APP_API_URL}/administer/getAdminConfiguration`, {
             method: 'post',
             headers: {
               'content-type': 'application/json',
               'Authorization': `JWT ${access_token}`,
               'Accept': 'application/json'
             },
               body: JSON.stringify({
                 'design_id': surveyID
               })
           })

           const response = await data.json()
            console.log("Response")
             console.log(response)
           setConfigInputs(response)


           setLoadingAdminConfig(false);
           //variable_names and constant_names are both lists
         } catch (err) {
           console.log(err.message)
         }
       }
       fetchSurveyData();
       fetchAdministerData().then(() => {
            console.log("I am the config inputs")
            console.log(configInputs)
        });
   }, [])

   
  const handleChange = (event, newValue) => {
    setPage(newValue);
  };

  const handleChangeIndex = (index) => {
    setPage(index);
  };


  const uploadSurveyAdministerConfig = () => {
      setLoadingAdminConfig(true)
       const sendData = async () => {
           try {
               // TODO: Need to create API endpoint for this
               const data = await fetch(`${process.env.REACT_APP_API_URL}/administer/setAdminConfiguration`, {
                   method: 'post',
                   headers: {
                       'content-type': 'application/json',
                       'Authorization': `JWT ${access_token}`,
                       'Accept': 'application/json'
                   },
                   body: JSON.stringify(configInputs)
               })
               const response = await data.json()
               setLoadingAdminConfig(false)
           } catch (err) {
               console.log(err.message)
           }
       }
       sendData();
  }

  // used to set the state of the menu
  // the index is used to set the active sub-menu open and trigger handleForm submits
  const handleNext = () => {
    if(page === 2){ // Config page
      if (ConfigRef.current && ConfigRef.current.areRequiredFieldsFilled()) {
          setPage((prevValue) => prevValue + 1);
            uploadSurveyAdministerConfig()
      }
    } else if(page === 3) { // sample order. Only call if sample_order is not random.
        setPage((prevValue) => prevValue + 1);
    } else if(page === 4) { // channel alignment. check if two samples have the same value and throw an error if they do
        let assigned_channels = Object.values(configInputs.channel_alignment).map(Number)
        let valid = assigned_channels.length === new Set(assigned_channels).size;
        // Equal to true if there are no duplicates
        // Equal to false if two channels are set to the same value and we therefore have an issue
        if(valid){
            setPage((prevValue) => prevValue + 1);
            uploadSurveyAdministerConfig()
            console.log("VALID")
        }else{
            console.log("INVALID")
            alert("Two or more variables have been set to the same channel. Please check and try again")
        }



    }else if (page === 5) {
        // save the shareandsave screen that includes who to share with and email share invites
        handleReviewShareSettings(access_token, surveyID, design);
        setPage((prevValue) => prevValue + 1);

    }else if (page === 6) {

        navigate("/AdministerPanelist", {state: {surveyID, VASdevice}})

    }
  }

  const handleBack = () => {
    if (page === 2) {
      return
    }
    setPage((prevValue) => prevValue - 1);
  }

    return (
    <Box sx={{ bgcolor: 'background.paper', mx: 'auto', width: '80%' }}>
      <AppBar position="static">
        <Tabs
          value={page}
          onChange={handleChange}
          indicatorColor="secondary"
          textColor="inherit"
          variant="fullWidth"
          aria-label="full width tabs example"
        >
          <Tab label="MultiSurvey Configuration" {...a11yProps(0)} hidden disabled/>
          <Tab label="MultiSurvey Style Editor" {...a11yProps(1)} hidden disabled/>
          <Tab label="Configuration" {...a11yProps(2)} />
          <Tab label="Sample Order" {...a11yProps(3)} />
          <Tab label="Channel Alignment" {...a11yProps(4)} />
          <Tab label="Review Share Settings" {...a11yProps(5)} />
          <Tab label="VASConnect" {...a11yProps(5)} />

        </Tabs>
      </AppBar>
      {/* This is the MultiSurvey Configuration tab */}
      <TabPanel page={page} index={0} dir={theme.direction}>
        <MultiSurveyConfiguration />
      </TabPanel>
      {/* This is the MultiSurvey Editor tab */}
      <TabPanel page={page} index={1} dir={theme.direction}>
        <MultiSurveyStyleEditor />
      </TabPanel>
      {/* This is the Configuration tab */}
      <TabPanel page={page} index={2} dir={theme.direction}>
      {loadingAdminConfig ? <h1>Loading</h1> :
        <Configuration configInputs={configInputs} setConfigInputs={setConfigInputs} ref={ConfigRef}/>}
      </TabPanel>
      {/* This is the Sample Order tabs depending on if Sample Presentation Order is Random or User Defined */}
      {configInputs.sample_presentation_order_type === "random" ?
      <TabPanel page={page} index={3} dir={theme.direction} >
        <SampleOrderRandom configInputs={configInputs} setConfigInputs={setConfigInputs} />
      </TabPanel>
      : 
      <TabPanel page={page} index={3} dir={theme.direction} >
        <SampleOrderUserDefined configInputs={configInputs} setConfigInputs={setConfigInputs} />
      </TabPanel>
      }
      <TabPanel page={page} index={4} dir={theme.direction} >
        <ChannelAlignment variable_names={design.variable_names} constant_names={design.constant_names} configInputs={configInputs} setConfigInputs={setConfigInputs}  />
      </TabPanel>
      <TabPanel page={page} index={5} dir={theme.direction}>
        {loadingDesign ? <h1>Loading</h1> :

        <ReviewShareSettings  design={design} setDesign={setDesign}/>
        }
        </TabPanel>
        <TabPanel page={page} index={6} dir={theme.direction} >
            <VASSetup vas={vas} VASdevice={VASdevice} setVASdevice={setVASdevice}/>
        </TabPanel>

        {page === 2 ? 
        <div style={{ display: 'flex', justifyContent: 'end', marginBottom: '40px' }}>
          <Button variant='contained' onClick={handleBack} hidden>Back</Button>
          <Button variant='contained' onClick={handleNext}>Next</Button>
        </div>
        : page === 6 ?
        <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: '40px' }}>
            <Button variant='contained' onClick={handleBack}>Back</Button>
          <ThemeProvider theme={begin_button_theme}>
            <Button variant='contained' color="primary" onClick={handleNext}>Begin</Button>
          </ThemeProvider>
        </div>
        : 
        <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: '40px' }}>
          <Button variant='contained' onClick={handleBack}>Back</Button>
          <Button variant='contained' onClick={handleNext}>Next</Button>
        </div>
        }
    </Box>
  );
}

const mapStateToProps = state => ({
  isAuthenticated: state.auth.isAuthenticated,
  access_token: state.auth.access
});

export default connect(mapStateToProps, {})(Administer);
