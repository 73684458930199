import React, { useState, useEffect } from 'react';
import { TextField, Grid, Button, Box, Divider, InputLabel, Modal, Select, MenuItem, FormControl, Switch, FormGroup, FormControlLabel } from '@mui/material';
import { styled } from '@mui/material/styles';

const ATOMRecommendations = ({ configInputs, addPages, setAddPages, introInputs, screeningDOE }) => {

    // Set the initial value of open to false, and then open the modal box when they click to attatch image
    const [open, setOpen] = useState(false);
    const [currentRecIndex, setCurrentRecIndex] = useState(null)
    const handleOpen = () => setOpen(true);
    const handleClose = () => {
      setOpen(false);
      setCurrentRecIndex(null);
    }

    let AR = addPages.add_recommendation;
    console.log(AR)

    const [inputs, setInputs] = useState({
      drivers_of_liking: AR.drivers_of_liking || false,
      show_recommendations: AR.show_recommendations || true,
      num_products: AR.num_products || 3,
      number_of_recommendations: AR.number_of_recommendations || 3,
      recommendations: AR.recommendations || Array.from({ length: 3 }, () => ({ rec: '', variable_values: [] })),
    });

    let variable_names = configInputs.variable_names;
    let variable_highs = configInputs.variable_highs;

    const handleInputChange = (e) => {
      const { name, value } = e.target;
      let updatedValue = value;
  
      // Split the name on '-' to get the type and index
      const nameParts = name.split('-');
      const type = nameParts[0];
      
  
      // 2. Handle variableValue changes
      if (type === "variableValue") {
        const updatedRecFields = [...inputs.recommendations];
        const varIndex = parseInt(nameParts[2]);
        updatedRecFields[currentRecIndex].variable_values[varIndex] = value;
        setInputs((prevInputs) => ({
          ...prevInputs,
          recommendations: updatedRecFields,
        }));
      }         
  
      // 3. Handle number_of_recommendations changes
      if (name === "number_of_recommendations") {
          updatedValue = parseInt(value);
  
          if (Number(value) !== inputs.number_of_recommendations) {
              const diff = inputs.number_of_recommendations - Number(value);
              let updatedRecs = [...inputs.recommendations];
              
              if (diff > 0) {
                  updatedRecs = updatedRecs.slice(0, updatedRecs.length - diff);
              } else if (diff < 0) {
                  for (let i = 0; i < Math.abs(diff); i++) {
                      updatedRecs.push({ rec: '', variable_values: [] });
                  }
              }
              
              setInputs((prevInputs) => ({
                  ...prevInputs,
                  number_of_recommendations: updatedValue,
                  recommendations: updatedRecs,
              }));
          }
      }
  
      if (type !== "variableValue" && name !== "number_of_recommendations") {
          setInputs((prevInputs) => ({
              ...prevInputs,
              [name]: updatedValue,
          }));
      }
      console.log(inputs)
    }

    useEffect(() => {

      const recs = {
        drivers_of_liking: inputs.drivers_of_liking,
        show_recommendations: inputs.show_recommendations,
        number_of_recommendations: inputs.number_of_recommendations,
        recommendations: inputs.recommendations,
      }

      setAddPages( prevState  => ({ ...prevState,   add_recommendation: recs }));

    }, [inputs]);

    const handleRecChange = (e, index) => {
      const updatedRecFields = [...inputs.recommendations];
      updatedRecFields[index].rec = e.target.value;
      setInputs((prevInputs) => ({
          ...prevInputs,
          recommendations: updatedRecFields,
      }));
    };

    // Handles the switch input
    const handleSwitchChange = (e) => {
      setInputs((prevInputs) => ({
        ...prevInputs,
        [e.target.name]: e.target.checked,
      }));
    }
    
    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 400,
        bgcolor: 'background.paper',
        border: '2px solid #000',
        boxShadow: 24,
        p: 4,
    };

    const Android12Switch = styled(Switch)(({ theme }) => ({
    padding: 8,
    '& .MuiSwitch-track': {
        borderRadius: 22 / 2,
        '&:before, &:after': {
        content: '""',
        position: 'absolute',
        top: '50%',
        transform: 'translateY(-50%)',
        width: 16,
        height: 16,
        },
        '&:before': {
        backgroundImage: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="16" width="16" viewBox="0 0 24 24"><path fill="${encodeURIComponent(
            theme.palette.getContrastText(theme.palette.primary.main),
        )}" d="M21,7L9,19L3.5,13.5L4.91,12.09L9,16.17L19.59,5.59L21,7Z"/></svg>')`,
        left: 12,
        },
        '&:after': {
        backgroundImage: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="16" width="16" viewBox="0 0 24 24"><path fill="${encodeURIComponent(
            theme.palette.getContrastText(theme.palette.primary.main),
        )}" d="M19,13H5V11H19V13Z" /></svg>')`,
        right: 12,
        },
    },
    '& .MuiSwitch-thumb': {
        boxShadow: 'none',
        width: 16,
        height: 16,
        margin: 2,
    },
    }));

    console.log(screeningDOE)

    return (
        <>
        <Grid container direction="column">
            <Grid item>
                <FormControl>
                <FormGroup row>
                    <FormControlLabel
                    sx={{ p: 0.5 }}
                    label="Drivers of Liking" control={<Android12Switch checked={inputs.drivers_of_liking}
                        onChange={handleSwitchChange} name="drivers_of_liking" />}
                    />
                    <Divider orientation='vertical' sx={{ width: '10px' }} />
                    <FormControlLabel
                    sx={{ p: 0.5 }}
                    label="Show Recommendations" control={<Android12Switch checked={inputs.show_recommendations}
                        onChange={handleSwitchChange} name="show_recommendations" />}
                    />
                </FormGroup>
                </FormControl>
            </Grid>
            <Divider orientation='horizontal' sx={{ height: '15px' }} />
            <Grid item>
            <FormControl sx={{ minWidth: '50%' }}>
                  <InputLabel variant="outlined" id="num-products-label">How many products do you want to recommend to each panelist?</InputLabel>
                  <Select
                    required
                    label="How many products do you want to recommend to each panelist?"
                    labelId="num-products-label"
                    id="num_products"
                    value={inputs.num_products}
                    name="num_products"
                    onChange={handleInputChange}
                  >
                    <MenuItem value="1">1</MenuItem>
                    <MenuItem value="2">2</MenuItem>
                    <MenuItem value="3">3 (Default)</MenuItem>
                    <MenuItem value="4">4</MenuItem>
                  </Select>
                </FormControl>
            </Grid>
            <Divider orientation='horizontal' sx={{ height: '15px' }} />
            {introInputs.project_type !== "Screening" ?
            <>
            <Grid item>
              <FormControl sx={{ minWidth: 270 }}>
              <InputLabel variant="outlined" id="num-of-recs-label">Number of Recommendations</InputLabel>
                  <Select
                    required
                    label="Number of Recommendations *"
                    labelId="num-of-recs-label"
                    id="num-of-recs"
                    value={inputs.number_of_recommendations}
                    name="number_of_recommendations"
                    onChange={handleInputChange}
                  >
                    <MenuItem value="3">3</MenuItem>
                    <MenuItem value="5">5</MenuItem>
                    <MenuItem value="7">7</MenuItem>
                    <MenuItem value="9">9</MenuItem>
                  </Select>
              </FormControl>
            </Grid>
            <Grid item sx={{ marginTop: '10px' }}>
            {inputs.show_recommendations && inputs.number_of_recommendations && (
            <>
              <Divider orientation='horizontal' sx={{ height: '10px' }} />
              <Grid item sx={{ display: 'grid' }}>
                {[...Array(inputs.number_of_recommendations)].map((_, index) => (
                  <>
                  <div key={index}>
                    <TextField
                        label={`Recommendation ${index + 1}`}
                        variant="outlined"
                        name={`recommendationField[${index}]`}
                        value={inputs.recommendations[index].rec || ""}
                        onChange={(e) => handleRecChange(e, index)}
                        sx={{ width: '450px' }}
                    />
                    <Divider orientation='horizontal' sx={{ height: '10px' }} />
                    <Button variant='contained' onClick={() => {
                      setCurrentRecIndex(index);
                      handleOpen();}} 
                      sx={{ width: 'fit-content' }}>
                      Set Variable Values
                    </Button>
                    <Modal open={open} onClose={handleClose}>
                      <Box sx={style}>
                        <h3>Set Variable Value Levels</h3>
                        <Grid container>
                          <Grid item xs={6}>
                            <h4>Variables</h4>
                          </Grid>
                          <Grid item xs={6}>
                            <h4>Level</h4>
                          </Grid>
                          {variable_names.map((variable, varIndex) => (
                            <React.Fragment key={varIndex}>
                              <Grid item xs={6} sx={{ marginTop: '15px'}}>
                                <h4>{variable}</h4>
                              </Grid>
                              <Grid item xs={6} sx={{ marginTop: '15px'}}>
                                <TextField
                                  sx={{ minWidth: 110 }}
                                  label="Variable Level"
                                  required
                                  inputProps={{ min: 0 }}
                                  id={`variableLevel-${currentRecIndex}-${varIndex}`}
                                  variant="outlined"
                                  type="number"
                                  name={`variableValue-${currentRecIndex}-${varIndex}`}
                                  value={inputs.recommendations[currentRecIndex]?.variable_values[varIndex] || ""}
                                  onChange={(e) => handleInputChange(e)}
                                />
                              </Grid>
                            </React.Fragment>
                          ))}
                        </Grid>
                      </Box>
                    </Modal>
                      <Divider orientation='horizontal' sx={{ height: '15px' }} />
                  </div>
                  </>
                ))}
              </Grid>
            </>
            )}
            </Grid>
            </>
            :
            // THIS IS WHERE THE FILLED IN INPUTS GO FOR SCREENING
            <>
            <Grid item>
              <FormControl sx={{ minWidth: 270 }}>
                  <div hidden>{inputs.number_of_recommendations = screeningDOE.length}</div>
                  <TextField
                      label="Number of Recommendations"
                      id="num-of-recs"
                      variant="outlined"
                      type="number"
                      name="number_of_recommendations"
                      value={inputs.number_of_recommendations}
                      disabled
                  />
              </FormControl>
            </Grid>
            <Grid item sx={{ marginTop: '10px' }}>
            {inputs.show_recommendations && inputs.number_of_recommendations && (
            <>
              <Divider orientation='horizontal' sx={{ height: '10px' }} />
              <Grid item sx={{ display: 'grid' }}>
                {[...Array(inputs.number_of_recommendations)].map((_, index) => (
                  <>
                  <div key={index}>
                    <TextField
                        label={`Recommendation ${index + 1}`}
                        placeholder={variable_names[index]}
                        variant="outlined"
                        name={`recommendationField[${index}]`}
                        value={inputs.recommendations[index].rec || `${screeningDOE[index].sample}`}
                        onChange={(e) => handleRecChange(e, index)}
                        sx={{ width: '450px' }}
                    />
                    <Divider orientation='horizontal' sx={{ height: '10px' }} />
                    <Button variant='contained' onClick={() => {
                      setCurrentRecIndex(index);
                      handleOpen();}} 
                      sx={{ width: 'fit-content' }}>
                      Set Variable Values
                    </Button>
                    <Modal open={open} onClose={handleClose}>
                      <Box sx={style}>
                        <h3>Set Variable Value Levels</h3>
                        <Grid container>
                          <Grid item xs={6}>
                            <h4>Variables</h4>
                          </Grid>
                          <Grid item xs={6}>
                            <h4>Level</h4>
                          </Grid>
                          {variable_names.map((variable, varIndex) => (
                            <React.Fragment key={varIndex}>
                              <Grid item xs={6} sx={{ marginTop: '15px'}}>
                                <h4>{variable}</h4>
                              </Grid>
                              {console.log(screeningDOE[varIndex])}
                              <Grid item xs={6} sx={{ marginTop: '15px'}}>
                                <TextField
                                  sx={{ minWidth: 110 }}
                                  label="Variable Level"
                                  required
                                  inputProps={{ min: 0 }}
                                  id={`variableLevel-${currentRecIndex}-${varIndex}`}
                                  variant="outlined"
                                  type="number"
                                  name={`variableValue-${currentRecIndex}-${varIndex}`}
                                  // NEED TO FIGURE OUT HOW TO GET THE VARIABLE VALUES PUT IN
                                  // ALSO FILL THE screeningDOE variable as soon as ViewSamples is loaded
                                  value={inputs.recommendations[currentRecIndex]?.variable_values[varIndex] || 
                                    `${screeningDOE?.[currentRecIndex]?.[variable]}`}
                                  onChange={(e) => handleInputChange(e)}
                                  disabled
                                />
                              </Grid>
                            </React.Fragment>
                          ))}
                        </Grid>
                      </Box>
                    </Modal>
                      <Divider orientation='horizontal' sx={{ height: '15px' }} />
                  </div>
                  </>
                ))}
              </Grid>
            </>
            )}
            </Grid>
            </>
            }
        </Grid>
        </>
    )
};

export default ATOMRecommendations;