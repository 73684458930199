import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import FlipCard from '../general/FlipCard';
import array from './create-cards.json';

const cards = array.createCards;

const Design = () => {
  return (
    <div className='text-center'>
        <div className='blue_bg'>
            <div className="jumbotron">
                <h1 className="display-4 p-3">What type of survey do you want to create?</h1>
                <p className=" h4 pb-1">(This cannot be changed later)</p>
                <div className="container">
                    <div className="row">
                        <div className="col d-flex flex-column flex-md-row justify-content-around align-items-center">
                            {cards.map((card) => (
                                <FlipCard key={card.id} card={card}/>
                            ))}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
  );
};

export default Design;
