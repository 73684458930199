import * as React from 'react';
import { useState, useEffect } from 'react';
import { TextField, Grid, FormControl, FormGroup, FormControlLabel, Switch } from '@mui/material';
import { styled } from '@mui/material/styles';

const DynamicModeling = ({ configInputs, setConfigInputs }) => {
  let CI = configInputs;
  
    const [inputs, setInputs] = useState({
            dynamic_model: CI.dynamic_model || false, 
            dynamic_sample_per_it: CI.dynamic_sample_per_it || 2, 
            max_dynamic_it: CI.max_dynamic_it || 2
        })

        useEffect(() => {
        
          let dynamic_model = inputs.dynamic_model;
          let dynamic_sample_per_it = inputs.dynamic_sample_per_it; 
          let max_dynamic_it = inputs.max_dynamic_it;
         
          setConfigInputs( prevState  => ({ ...prevState,   dynamic_model: dynamic_model }));
          setConfigInputs( prevState  => ({ ...prevState,   dynamic_sample_per_it: dynamic_sample_per_it }));
          setConfigInputs( prevState  => ({ ...prevState,   max_dynamic_it: max_dynamic_it }));
  
          }, [inputs]);


    // What saves the survey setting values once there is a change in the input field
    const handleInputChange = (e) => {
      setInputs(
        {
          ...inputs,
          [e.target.name]: e.target.value
        }
      )
    };

    // Used for the switch changing
  const handleSwitchChange = (e) => {
    setInputs((prevInputs) => ({
        ...prevInputs,
        [e.target.name]: e.target.checked
      }));
    };  

    return (
        <Grid container sx={{ marginTop: '10px'}}>            
            <Grid item xs={3}>
                <FormControl>
                    <FormGroup>
                    <h5>Dynamic Modeling</h5>
                    <FormControlLabel
                        sx={{ p: 0.5 }}
                        control={<Switch 
                          checked={inputs.dynamic_model}
                          onChange={handleSwitchChange}
                          name="dynamic_model"
                        />}
                    />
                    </FormGroup>
                </FormControl>  
            </Grid>
            {inputs.dynamic_model === true ?
            <>
            <Grid item xs={3}>

              <FormControl sx={{ minWidth: 280 }}>
                  <TextField
                    required
                    className='required'
                    sx={{ width: '82%'}}
                    inputProps={{ inputMode: 'numeric', pattern: '[0-30]*', min: 1 }}
                    label="Max Samples per Batch"
                    id="dynamic_sample_per_it"
                    variant="outlined"
                    type="number"
                    name="dynamic_sample_per_it"
                    value={inputs.dynamic_sample_per_it}
                    onChange={handleInputChange}
                  />
              </FormControl>

            </Grid>
              <Grid item xs={3}>
                <TextField
                  required
                  className='required'
                  sx={{ width: '55%'}}
                  inputProps={{ inputMode: 'numeric', pattern: '[0-30]*', min: 1 }}
                  label="Max Number of Batch"
                  id="max_dynamic_it"
                  variant="outlined"
                  type="number"
                  name="max_dynamic_it"
                  value={inputs.max_dynamic_it}
                  onChange={handleInputChange}
                />
              </Grid>
              </>
              : <div></div>}
        </Grid>
    );
}


export default DynamicModeling;
