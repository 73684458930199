import React, { useState, useEffect,  forwardRef, useImperativeHandle } from 'react';
import { Grid, TextField, Autocomplete, FormControl, InputLabel, Select, MenuItem, Divider, Switch, FormLabel }from '@mui/material';
import flavorTypes from '../low_level_components/flavortypes.json';

const IntroMenu = forwardRef(({onSurveyInputsChange, introInputs, setIntroInputs}, ref ) => {
    // This function will check if all required fields are filled.

    const areRequiredFieldsFilled = () => {
        const textAreas = Array.from(document.querySelectorAll('.IntroMenuForm .required textarea'));
        const inputFields = Array.from(document.querySelectorAll('.IntroMenuForm .required input'));

        // Concatenate the arrays
        const inputs = inputFields.concat(textAreas);

        for (let input of inputs) {
            if (!input.value) {
                console.log(input.id, "IS NOT SET")
                alert(input.id + " is not set.")
                return false;
            }
        }
        return true;
    }
    useImperativeHandle(ref, () => ({
        areRequiredFieldsFilled,
    }));

    // What saves the survey setting values once there is a change in the input field
    const handleSurveyInputChange = (event) => {
        const { name, value } = event.target;
        setIntroInputs(prevState => ({ ...prevState, [name]: value }));
    };

    // used to modify flavor type from autocomplete selection
    const handleAutocompleteChange = (event, selectedOption) => {
        const value = selectedOption ? selectedOption : '';
        setIntroInputs(prevState => ({ ...prevState, flavor_type: value }));
    };

    // Used for the switch changing for atom administer option
    const handleSwitchChange = (e) => {
        setIntroInputs(prevState => ({ ...prevState, [e.target.name]: e.target.checked }));
    }

    let conditional_disable = null;
    if (introInputs.project_type === "Screening") {
        conditional_disable = true;
        introInputs.hardware = "Yes";
        introInputs.atom_administer = true;
    }

    return (
        <Grid container spacing={2} className="IntroMenuForm">
            <Grid item xs={12} className="alert alert-danger" hidden>
                error: some error message
            {/* Displaying the first error for the password,
            you can adjust this to display all errors or specific ones */}
            </Grid>
            <Grid item xs={4}>
                <TextField
                    required
                    className="required"
                    sx={{ minWidth: 300 }}
                    label="Project Name"
                    id="project_title"
                    variant="outlined"
                    name="project_title"
                    value={introInputs.project_title}
                    onChange={handleSurveyInputChange}
                />
            </Grid>
            <Grid item xs={4}>
                <TextField
                    required
                    className="required"
                    sx={{ minWidth: 300 }}
                    label="Enter BR #"
                    id="brief_number"
                    variant="outlined"
                    name="brief_number"
                    value={introInputs.brief_number}
                    onChange={handleSurveyInputChange}
                />
            </Grid>
            <Grid item xs={4}>
                <Autocomplete
                    disablePortal
                    options={flavorTypes}
                    sx={{ width: 300 }}
                    id="flavor_type"
                    name="flavor_type"
                    onChange={handleAutocompleteChange}
                    value={flavorTypes.find((option) => option === introInputs.flavor_type) || null}
                    getOptionLabel={(option) => option || ''}
                    renderInput={(params) => <TextField required className="required" {...params} label="Flavor Type" />}
                />
            </Grid>
            <Divider orientation='horizontal' sx={{ height: '20px' }} />
            {/* <Grid item justifyContent="space-between" alignItems="flex-start" style={{ display: "flex" }}> */}
            <Grid item xs={12}>
                <h5>Project Title</h5>
                <Divider orientation='horizontal' sx={{ height: '10px' }} />
                <TextField
                    required
                    className="required"
                    multiline
                    rows={1}
                    sx={{ display: 'flex', width: 'auto' }}
                    label="What is the title of your project?"
                    id="project_objective"
                    variant="outlined"
                    name="project_objective"
                    value={introInputs.project_objective}
                    onChange={handleSurveyInputChange}
                />
            </Grid>
            {/* <Divider orientation='vertical' sx={{ width: '40px' }} /> */}
            <Grid item xs={12}>
                <h5>Project Background</h5>
                <Divider orientation='horizontal' sx={{ height: '10px' }} />
                <TextField
                    required
                    className="required"
                    label="What is your project background?"
                    multiline
                    rows={4}
                    sx={{ display: 'flex', width: 'auto' }}
                    id="project_background"
                    variant="outlined"
                    name="project_background"
                    value={introInputs.project_background}
                    onChange={handleSurveyInputChange}
                />
            </Grid>
            <Divider orientation='horizontal' sx={{ height: '20px' }} />
            {/* <Grid item justifyContent="space-between" alignItems="flex-start" style={{ display: "flex" }}> */}
            <Grid item xs={4}>
                <FormControl sx={{ minWidth: 300 }}>
                    <InputLabel variant="outlined" id="project-type-label">Project Type</InputLabel>
                    <Select
                        required
                        className="required"
                        label="Project Type"
                        labelId="project-type-label"
                        id="project_type"
                        value={introInputs.project_type}
                        name="project_type"
                        onChange={handleSurveyInputChange}
                    >
                        <MenuItem value="Ingredient Replacement">Ingredient Replacement</MenuItem>
                        <MenuItem value="Product Optimization">Product Optimization</MenuItem>
                        <MenuItem value="Match Target">Match Target</MenuItem>
                        <MenuItem value="Screening">Screening</MenuItem>
                    </Select>
                </FormControl>
            </Grid>
            <Grid item xs={4}>
                <FormControl sx={{ minWidth: 300 }}>
                    <InputLabel variant="outlined" id="hardware-label">Do you plan to use VAS or Aroma Kiosk?</InputLabel>
                    <Select
                        required
                        className="required"
                        label="Do you plan to use VAS or Aroma Kiosk?"
                        labelId="hardware-label"
                        id="hardware"
                        value={introInputs.hardware}
                        name="hardware"
                        onChange={handleSurveyInputChange}
                        disabled={conditional_disable}
                    >
                        <MenuItem value="Yes">Yes</MenuItem>
                        <MenuItem value="No">No</MenuItem>
                    </Select>
                </FormControl>
            </Grid>
            <Grid item xs={4}>
                <FormControl sx={{ minWidth: 600 }}>
                    <FormLabel>Do you plan to use ATOM to administer survey?</FormLabel>
                    <Switch
                        checked={introInputs.atom_administer}
                        onChange={handleSwitchChange}
                        name="atom_administer"
                        disabled={conditional_disable}
                    />
                </FormControl>
            </Grid>
        </Grid>
    );
})

export default IntroMenu;