
import React, { useEffect, RefObject } from 'react'
import * as d3 from 'd3'
import './interactions.css'
import cloneDeep from 'lodash/cloneDeep';

const Interactions = ({ chart_data, height, interaction_container_ref }) => {

    let data = cloneDeep(chart_data)

    const ref = React.useRef(null);

    React.useEffect(() => {


        const width = interaction_container_ref.current.offsetWidth;
        //console.log(`Container width: ${width}px`);
        // draw the chart
        draw(data, width, height)

    })

    const draw = (data, width, height) => {


        //console.log('data')
        //console.log(data)
        var links = cloneDeep(data);
        var nodes = {};
        var edge_count = {};



        // compute the distinct nodes from the links.
        links.forEach(function (link) {
            link.source = nodes[link.source] || (nodes[link.source] = { name: link.source });
            link.target = nodes[link.target] || (nodes[link.target] = { name: link.target });

            // jacob added
            // used to determine how many edges are connected from a single node
            if (edge_count.hasOwnProperty(link.source.name)) {
                edge_count[link.source.name] += 1;
            } else {
                edge_count[link.source.name] = 1;
            }

            //console.log(String(link.source));
        });

        links.forEach(function (link) {
            // jacob added
            // used to determine how many edges are connected from a single node
            if (edge_count.hasOwnProperty(link.target.name)) {
                edge_count[link.target.name] += 1;
            } else {
                edge_count[link.target.name] = 1;
            }
        });

        // console.log("data")
        // console.log(data)

        // console.log("nodes")
        // console.log(nodes)

        // console.log("links")
        // console.log(links)

        // console.log("edge_count")
        // console.log(edge_count);

        //remove all prior out
        d3.select(ref.current).selectAll("*").remove();

        // create svg
        const svg = d3.select(ref.current)
            .append('svg')
            .attr('width', width)
            .attr('height', height);


        function getRadius(edge_count, term) {
            if (edge_count.hasOwnProperty(term)) {
                return edge_count[term];
            }
            else {
                return 1;
            }
        }


        let force = d3.forceSimulation()
            .nodes(d3.values(nodes))
            //.force("link", d3.forceLink(links).distance(400))
            .force('center', d3.forceCenter(width / 2, height / 2))
            .force("x", d3.forceX())
            .force("y", d3.forceY())
            .force("charge", d3.forceManyBody().strength(-300))
            .force('collision', d3.forceCollide().radius(function (d) { return getRadius(edge_count, d.name) + width*.075; }))
            .alphaTarget(1)
            .on("tick", tick);


        let pathColorScale = d3.scaleSequential(d3.interpolatePiYG)
            .domain([d3.min(data, function (d) { return d.tstat; }), 0, d3.max(data, function (d) { return d.tstat; })]);

        let pathWidthScale = d3.scaleLinear()
            .domain([d3.min(data, function (d) { return Math.abs(d.tstat); }), d3.max(data, function (d) { return Math.abs(d.tstat); })])
            .range([2, 10]);




        let path = svg.append("g")
            .selectAll("path")
            .data(links)
            .enter()
            .append("path")
            .style("stroke-width", function (d) { return pathWidthScale(Math.abs(d.tstat)); })
            .style("stroke", function (d) { 
                if (d.tstat > 0) {
                    return d3.schemeTableau10[0];
                } else {
                    return d3.schemeTableau10[1];
                }
            })
            .style("stroke-dasharray", function (d) { if (d.value == 0) { return null; } else { return "1,4"; } })
            .attr("class", function (d) { return "link " + d.type; });

        // define the nodes
        let node = svg.selectAll(".node")
            .data(force.nodes())
            .enter().append("g")
            .attr("class", "node")
            .call(d3.drag()
                .on("start", dragstarted)
                .on("drag", dragged)
                .on("end", dragended))
            .on("dblclick", doubleclicked);


        let radiusScale = d3.scaleLinear()
        .domain([d3.min(Object.values(edge_count)), d3.max(Object.values(edge_count))])
        .range([5, 25]);

        //console.log('radius scale')
        //console.log([d3.min(Object.values(edge_count)), d3.max(Object.values(edge_count))])

        let colorScale = d3.scaleLinear()
            .domain([d3.min(Object.values(edge_count)), d3.max(Object.values(edge_count))])
            .range(["#f6eff7", "#02818a"]);



        svg.selectAll(".node").append("circle")

            .attr("id", function (d) {
                return d.name;
            })
            .attr("r", function (d) {
                return radiusScale(getRadius(edge_count, d.name));
            })
            //.attr("r", 10)
            .attr("fill", function (d) { 
                
               // return colorScale(getRadius(edge_count, d.name)) 
                return "lightgrey"
            });

        node.append("text")
            .attr("text-anchor", "beginning")
            .attr("dy", function (d) { return -radiusScale(getRadius(edge_count, d.name)) })
            .attr("dx", function (d) { return 5 + radiusScale(getRadius(edge_count, d.name)) })
            //.attr("transform","translate(" +  function(d) {return radiusScale(getRadius(edge_count, d.name) ) }  + "," + function(d) {return radiusScale(getRadius(edge_count, d.name) ) }  +  ")")
            //.style("font-weight", "bold")
            .text(function (d) { return d.name });

        var legend = svg
            .append('g')
            .attr('id', 'legend')
            .attr("transform","translate(" + width*.80 + "," + 15 + ")");

        legend
            .append("circle")
            .style("fill", "lightgrey")
            .attr("r", '5px');
        
        legend
            .append("text")
            .attr("x", "30px")
            .attr('y', '1px')
            .text("Minimal Interactions");


        legend
            .append("circle")
            .style("fill", "lightgrey")
            .attr("r", '25px')
            .attr('cy', '50px');
        
        legend
            .append("text")
            .attr("x", "30px")
            .attr("y", '53px')
            .text("Maximum Interactions");


        legend
            .append("line")        
            .style("stroke", function(){  return d3.schemeTableau10[1];}) 
            .attr("x1", 0)   
            .attr("y1", 125)  
            .attr("x2", 20)  
            .attr("y2", 125)
            .style("stroke-width", "5px");  
            
        legend
            .append("text")
            .attr("x", "30px")
            .attr("y", '128px')
            .text("Positive T-Stat");
        
        legend
            .append("line")        
            .style("stroke", function(){  return d3.schemeTableau10[0];}) 
            .attr("x1", 0)   
            .attr("y1", 145)  
            .attr("x2", 20)  
            .attr("y2", 145)
            .style("stroke-width", "5px");  
            
        legend
            .append("text")
            .attr("x", "30px")
            .attr("y", '150px')
            .text("Negative T-Stat");
            
        // add the curvy lines
        function tick() {
            path.attr("d", function (d) {
                var dx = d.target.x - d.source.x,
                    dy = d.target.y - d.source.y,
                    dr = Math.sqrt(dx * dx + dy * dy);
                return "M" +
                    d.source.x + "," +
                    d.source.y + "A" +
                    dr + "," + dr + " 0 0,1 " +
                    d.target.x + "," +
                    d.target.y;
            });

            node.attr("transform", function (d) {
                return "translate(" + d.x + "," + d.y + ")";
            });


        };

        function dragstarted(d) {
            if (!d3.event.active) force.alphaTarget(0.3).restart();
            d.fx = d.x;
            d.fy = d.y;
        };

        function dragged(d) {

            d.fx = d3.event.x;
            d.fy = d3.event.y;

            d3.select(this).select("circle")
            .style("fill", "darkgrey")
        };

        function dragended(d) {

            d.fixed = true;

            if (!d3.event.active) force.alphaTarget(0);
            if (d.fixed == true) {
                d.fx = d.x;
                d.fy = d.y;
            }
            else {
                d.fx = null;
                d.fy = null;
            }
        };

        // add that on double click they return to unfixed and additionally revert color
        function doubleclicked(d) {
            console.log('registered doubleclicked()')
            d3.select(this).select("circle")
                .style("fill", 'lightgray');

            d.fx = undefined;
            d.fy = undefined;
        };

        function getRadius(edge_count, term) {

            //console.log('in getRadius()')
            //console.log(edge_count)
            //console.log(term)

            if (edge_count.hasOwnProperty(term)) {
                //console.log(edge_count[term])
                return edge_count[term];
            }
            else {
                return 1;
            }
        }
    }

    return (
        <div className="interaction_chart" ref={ref}></div>
    )
}

export default Interactions