import React, { useState, useEffect } from 'react';
import { TextField, Grid, Box, FormControl, FormGroup, FormControlLabel, Divider, Switch } from '@mui/material';
import { styled } from '@mui/material/styles';

// CheckboxesMC is used in both cases where they choose "CheckBox" or "Multiple Choice" for formats
const CheckboxesMC = ({ format, addPages, setAddPages }) => {
  let CB = addPages.primary_survey_question;
  // State setup for errors
  const [numberError, setNumberError] = useState({ status: false, message: "" });

  // initialize the state of the variables
  const [inputs, setInputs] = useState({
    question: CB.question || "",
    format: CB.format || "",
    randomize: CB.randomize || false,
    number_of_options: CB.num_options || 2,
    fields: CB.fields || [],
  });

  // Handling input change
  const handleInputChange = (e) => {
    const { name, value } = e.target;

    if (name === "number_of_options") {
        let updatedValue = parseInt(value);

        // We are removing the error validation from here

        if (value < inputs.number_of_options) {
            const diff = inputs.number_of_options - value;
            setInputs((prevInputs) => ({
                ...prevInputs,
                fields: prevInputs.fields.slice(0, prevInputs.fields.length - diff),
            }));
        }
        setInputs((prevInputs) => ({
            ...prevInputs,
            [name]: updatedValue,
        }));
    } else {
        // For other inputs
        setInputs((prevInputs) => ({
            ...prevInputs,
            [name]: value,
        }));
    }
  };

  // New handler for onBlur event
  const handleInputBlur = (e) => {
    const { name, value } = e.target;
    if (name === "number_of_options") {
        if (value < 2 || value > 16) {
            setNumberError({ status: true, message: "Value must be between 2 and 16" });
        } else {
            setNumberError({ status: false, message: "" });
        }
    }
  };

    // Handles the switch input
    const handleSwitchChange = (e) => {
        setInputs(
        {
            ...inputs,
            [e.target.name]: e.target.checked
        }
        )
    }

    // Handles the Option Fields when changed

    const handleOptionChange = (e, index) => {
      const updatedFields = [...inputs.fields];
      updatedFields[index] = e.target.value;
      setInputs((prevInputs) => ({
        ...prevInputs,
        fields: updatedFields,
      }));
    };

    useEffect(() => {
    
      const checkbox = {
        question: inputs.question,
        format: format,
        randomize: inputs.randomize,
        num_options: inputs.number_of_options,
        fields: inputs.fields,
      }
  
      setAddPages(prevState => ({ ...prevState, primary_survey_question: checkbox }));
  
    }, [inputs]);

    const Android12Switch = styled(Switch)(({ theme }) => ({
        padding: 8,
        '& .MuiSwitch-track': {
          borderRadius: 22 / 2,
          '&:before, &:after': {
            content: '""',
            position: 'absolute',
            top: '50%',
            transform: 'translateY(-50%)',
            width: 16,
            height: 16,
          },
          '&:before': {
            backgroundImage: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="16" width="16" viewBox="0 0 24 24"><path fill="${encodeURIComponent(
              theme.palette.getContrastText(theme.palette.primary.main),
            )}" d="M21,7L9,19L3.5,13.5L4.91,12.09L9,16.17L19.59,5.59L21,7Z"/></svg>')`,
            left: 12,
          },
          '&:after': {
            backgroundImage: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="16" width="16" viewBox="0 0 24 24"><path fill="${encodeURIComponent(
              theme.palette.getContrastText(theme.palette.primary.main),
            )}" d="M19,13H5V11H19V13Z" /></svg>')`,
            right: 12,
          },
        },
        '& .MuiSwitch-thumb': {
          boxShadow: 'none',
          width: 16,
          height: 16,
          margin: 2,
        },
      }));    

    const [errors, setErrors] = useState([]);
    
    useEffect(() => {
      setErrors(new Array(inputs.fields.length).fill({ status: false, message: "" }));
    }, [inputs.fields.length]);
  
    const handleBlur = (e, index) => {
      if (!e.target.value) {
        // Copy the current errors state
        const newErrors = [...errors];
  
        // Update the error message for the current field
        newErrors[index] = { status: true, message: "Required" };
  
        // Update the state
        setErrors(newErrors);
      } else {
        // Similar to the above, but sets the error to false
        const newErrors = [...errors];
        newErrors[index] = { status: false, message: "" };
        setErrors(newErrors);
      }
    };
    
    const handleNumberBlur = (e) => {
        const value = e.target.value;
        if (value < 2 || value > 16) {
            setNumberError({ status: true, message: "Value must be between 2 and 16" });
        } else {
            setNumberError({ status: false, message: "" });
        }
    };
    
    return (
        <>
          <Grid container direction="column">
              <Grid item>
                <FormControl>
                  <Divider orientation='horizontal' sx={{ height: '10px' }} />
                  <TextField
                      label="Custom Question"
                      id="question"
                      variant="outlined"
                      name="question"                    
                      inputProps={{ min: 2, maxLength: 115 }}
                      value={inputs.question}
                      onChange={handleInputChange}
                  />
                  </FormControl>
              </Grid>
              <Divider orientation='horizontal' sx={{ height: '10px' }} />
              <Grid item>
                  <Box sx={{ display: 'flex' }} justifyContent="flex-start" alignItems="center">
                  <FormControl>
                      <FormGroup>
                      <Divider orientation='horizontal' sx={{ height: '10px' }} />
                          <h5>Randomize</h5>
                          <FormControlLabel
                          sx={{ p: 0.5 }}
                          control={<Android12Switch checked={inputs.randomize}
                              onChange={handleSwitchChange} name="randomize" />}
                          />
                      </FormGroup>
                  </FormControl>
                  <Divider orientation='vertical' sx={{ width: '20px' }} />
                    <TextField
                      sx={{ minWidth: 180 }}
                      label="Number of Options"
                      id="number_of_options"
                      variant="outlined"
                      type="number"
                      name="number_of_options"
                      value={inputs.number_of_options}
                      onChange={handleInputChange}
                      onBlur={handleInputBlur} // New prop
                      error={numberError.status}
                      helperText={numberError.message}
                    />
                  </Box>
              </Grid>
              {inputs.number_of_options && numberError.status === false && (
              <>
                  <Divider orientation='horizontal' sx={{ height: '10px' }} />
                  <Grid item sx={{ display: 'grid' }}>
                  {[...Array(inputs.number_of_options)].map((_, index) => (
                      <div key={index}>
                      <TextField
                          required
                          label={`Field ${index + 1}`}
                          variant="outlined"
                          inputProps={{ min: 2 }}
                          name={`field[${index}]`}
                          value={inputs.fields[index] || ""}
                          onChange={(e) => handleOptionChange(e, index)}
                          onBlur={(e) => handleBlur(e, index)}
                          error={errors[index]?.status}
                          helperText={errors[index]?.message}
                          sx={{ width: '50%' }}
                      />
                      <Divider orientation='horizontal' sx={{ height: '10px' }} />
                      </div>
                  ))}
                  </Grid>
              </>
              )}
          </Grid>
        </>
    )
};

export default CheckboxesMC;
