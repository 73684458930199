import React, {useEffect, useState} from "react";
import { FormControl, FormControlLabel, RadioGroup, Radio } from "@mui/material";

const JAR = ({ question, questionResponseData, setQuestionResponseData, submitResponse, questionPayload }) => {

  // Input the response into "setQuestionResponseData" UseState.
  // the question object is a JSON with all the data needed to fill in the question inputs.

    // useEffect(() => {
    //     setQuestionResponseData(1)
    // }, [])

    // const [input, setInput] = useState ({
    //   JAR_response: null
    // })

    const handleChange = (event) => {
      setQuestionResponseData({
        [event.target.name]: event.target.value,
      });
      //submitResponse(questionPayload)
    };


  return (
    <div class="questionObject">
      <FormControl>
          <RadioGroup
              id="JAR_response"
              value={questionResponseData.JAR_response}
              name={question.id}
              onChange={handleChange}
              row
          >
              {question.fields.map((field, index) => (
                <FormControlLabel 
                    key={index}
                    value={`${field}`}
                    control={<Radio />}
                    label={`${field}`}
                    labelPlacement="bottom"
                />
              ))}
          </RadioGroup>
      </FormControl>
    </div>
  );
};

export default JAR;
