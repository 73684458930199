    import * as React from 'react';
    import {useState, useEffect, forwardRef, useImperativeHandle} from 'react';
    import ContinuousVariables from '../low_level_components/ContinuousVariables';
    import DynamicModeling from '../low_level_components/dynamic_modeling';
    import DesignType from '../low_level_components/DesignType';
    import ConstantVariables from '../low_level_components/constants';
    import AllVariables from '../low_level_components/AllVariables';
    import Grid from '@mui/material/Grid';
    import { TextField, Divider } from '@mui/material';
    import Calculate_NoSamples_ATOM3 from '../low_level_components/Calculate_NoSamples_ATOM3'

    // Pass in onInputChange

    // setConfigInputs, configInputs

    const Configuration = forwardRef(({ projectType, atomAdminister, hardware, introInputs, configInputs, setConfigInputs,  result, setResult, numSamples, setNumSamples }, ref) => {
        const areRequiredFieldsFilled = () => {
            const textAreas = Array.from(document.querySelectorAll('.ConfigurationForm .required textarea'));
            const inputFields = Array.from(document.querySelectorAll('.ConfigurationForm .required input'));
            // fixme
            // Concatenate the arrays
            const inputs = inputFields.concat(textAreas);

            for (let input of inputs) {
                if (!input.value) {
                    console.log(input)
                    console.log(input.name, "IS NOT SET")
                    alert(input.name + " is not set.")
                    return false;
                }
            }
            return true;
        }

        useImperativeHandle(ref, () => ({
            areRequiredFieldsFilled,
        }));

        const [messageType, setMessageType] = useState("");  // "error" or "warning"
        const [userIsEditing, setUserIsEditing] = useState(false);

        // Calculating No_Continious_Var, No_Discrete_Var, and No_Categorical_Var, if type is Continuous, Categorical, and Discrete.
        let No_Continious_Var = 0, No_Discrete_Var = 0, No_Categorical_Var = 0;
        for (let i=0; i<configInputs.variable_types.length; i++) {
            if (configInputs.variable_types[i] === 'Categorical') {
                No_Categorical_Var++;
            } else if (configInputs.variable_types[i] === 'Discrete') {
                No_Discrete_Var++;
            } else {
                No_Continious_Var++;
            } 
        }

        // Calculating Categorical and Discrete Variable Levels and checking if they are null.
        let Cat_Var_Levels = configInputs.categorical_variable_categories;
        let Discrete_Var_Levels = configInputs.discrete_variable_categories;
        Cat_Var_Levels = (No_Categorical_Var === 0) ? [] : Cat_Var_Levels;
        Discrete_Var_Levels = (No_Discrete_Var === 0) ? [] : Discrete_Var_Levels;

        // Assigning Required an checking if it is linear, interaction, purequadratic, or quadractic.
        let Required = configInputs.required_terms;
        Required = !Required ? "" : (Required === 'Main Effects' ? "linear" : (Required === 'Main + Interactions' ? "interaction" : (Required === 'Main + Quadratic' ? 'purequadratic' : (Required === 'Main + Interactions + Quadratic' ? 'quadratic' : Required))));

        // Assigning ModelType an checking if it is dsd or not.
        let ModelType = configInputs.design_type;
        ModelType = !ModelType ? "" : (ModelType === 'Definitive Screening' ? "dsd" : ModelType);

        // Create a state for the results, numSamples
        const [errorMsg, setErrorMsg] = useState("");
        const handleInputChange = (event) => {
            setUserIsEditing(true);
            setNumSamples(event.target.value);
            setConfigInputs(prevConfigInputs => ({
            ...prevConfigInputs,
            num_samples: event.target.value
            }));
        };
        
        const handleInputBlur = (event) => {
            const value = event.target.value;
            if (value < result.NoMinSamplesRequired) {
                setErrorMsg(`The minimum number of samples required is ${result.NoMinSamplesRequired}`);
                setMessageType("error");
            } else if (value < result.Recommended_No_Samples && value >= result.NoMinSamplesRequired) {
                setErrorMsg([
                    "WARNING: If you reduce the number of samples below ATOM's recommendation: " + result.Recommended_No_Samples + ",",
                    "this could impact the quality & reliability of final results."
                ]);
                setMessageType("warning");
            } else {
                setErrorMsg("");
                setMessageType("");
            }
            setUserIsEditing(false);
        };  

        useEffect(() => {
            // Calculating the result by calling the Calculate_NoSamples_ATOM3 function.
            const result = Calculate_NoSamples_ATOM3(No_Continious_Var, No_Categorical_Var, No_Discrete_Var, Cat_Var_Levels, Discrete_Var_Levels, Required, ModelType);
            console.log("Setting result!!")
            setConfigInputs(prevConfigInputs => ({
            ...prevConfigInputs,
            num_samples: result.Recommended_No_Samples
            }));
            setResult(result);
        }, [ModelType, Required]);
        
        let variable_selection = null;
        if (hardware === "No") {
            variable_selection = <AllVariables projectType={projectType} configInputs={configInputs} setConfigInputs={setConfigInputs} />
        }
        else {
            variable_selection = <ContinuousVariables projectType={projectType} configInputs={configInputs} setConfigInputs={setConfigInputs} />
        }

        // dMod is representative of Dynamic Modeling, and is only shown conditionally
        let dMod = null;
        if (projectType !== "Screening" && hardware === "Yes" && atomAdminister === true) {
            dMod = <DynamicModeling configInputs={configInputs} setConfigInputs={setConfigInputs} />
        } else {
            dMod = null;
        }

        // If not screening, then show DesignType
        let dType = null;
        if (projectType !== "Screening") {
            dType = <DesignType introInputs={introInputs} configInputs={configInputs} setConfigInputs={setConfigInputs} result={result} />
        } else {
            dType = null;
        }

        //console.log(configInputs)
        return (        
            <div className="ConfigurationForm">
                {variable_selection}
                <ConstantVariables configInputs={configInputs} setConfigInputs={setConfigInputs} />
                {dType}
                <Divider orientation='horizontal' sx={{ height: '10px' }} />
                {dMod}
                {projectType !== "Screening" ?
                <Grid container spacing={2} style={{ marginTop: '10px' }}>
                    <Grid item>
                        <TextField
                            required
                            className='required'
                            label="Number of Samples" // This sets the placeholder
                            type="number"
                            id="num_samples"
                            variant="outlined"
                            name="num_samples"
                            value={userIsEditing ? numSamples : numSamples || result.Recommended_No_Samples || ''} 
                            inputProps={{ 
                                min: result.NoMinSamplesRequired // This sets the minimum value allowed
                            }}
                            onChange={handleInputChange} // Update numSamples when TextField changes    
                            onBlur={handleInputBlur} // Add this line
                            helperText={
                                Array.isArray(errorMsg) 
                                    ? errorMsg.map((line, index) => <div key={index}>{line}</div>)
                                    : errorMsg || `Recommended number of samples: ${result.Recommended_No_Samples ?? 'Calculating...'}`
                            }
                            error={messageType === "error"}
                            FormHelperTextProps={{
                                style: {
                                    color: messageType === "warning" ? 'orange' : undefined,
                                }
                            }}      
                        />
                    </Grid>
                </Grid>
                : null}
            </div>
        );
    })

    export default Configuration;
