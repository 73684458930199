// This component contains all the processing to evaluate one aroma and return the results for that aroma to the parent component
// (Called by AdministerPanelist)

// Takes as an argument a sample, list of questions, and asks all the questions for that sample including
// Playing aromas, purge (if necessary).

// Then when it's done we change the state to "change_aromas" or something.


import React from "react";
import { useState, useEffect, useRef } from 'react';
import LoadingScreen from "./Loading";
import Question from "./Question";

const EvaluationEngine = ({key,  loadNextSample, primaryQuestion, secondaryQuestions, sample, channelalignment, SurveyDesign, Responses, setResponses, UserID, CurrentSampleMetaData }) => {

    // Primary Question - the question we show first
    // Secondary Questions - a list of questions to show next
    // Sample - the channel to play
    // channelAlignment - which variables and constants align to which VAS samples
    // TODO: how does this connect with VAS connect??
    // TODO: What to do with the responses

  const [questionIndex, setQuestionIndex] = useState(0)
  const [EvaluationEngineState, setEvaluationEngineState] = useState('initialize')
  const questions = [primaryQuestion].concat(secondaryQuestions)
  const loadNextQuestion = () => {
     if(questionIndex == secondaryQuestions.length){
         loadNextSample()
         //setState("queue_next_aroma") // maybe rename me
         // this  will basically end the evaluation process for this aroma.
         //The parent (administerPanelist) will check if additional aromas are required to play and will re-create this
         // evaluation engine for that aroma
     }else{
         setQuestionIndex(questionIndex + 1)
     }
    }

  const submitResponse = (response) => {
      // add a response to the list of responses
      console.log("Submitresponse")
      console.log(response)
      setResponses(Responses => [
        ...Responses, response
      ]);
      loadNextQuestion()
  }
  // This use Effect plays aroma
  useEffect(() => {
      const playAroma = () => {
      setEvaluationEngineState("pending_stabilization")
      // TODO call the VASconnect API here
      // When stabilized - needs to be connected with the VAS connect api here

      setEvaluationEngineState("stabilized")
      setEvaluationEngineState("show_question_UI")
    }
      playAroma()
      setQuestionIndex(0)
  }, [key])


  let content;

   switch(EvaluationEngineState) {
       case "pending_stabilization":
           content = <LoadingScreen/>;
           break;
       case "show_question_UI":
           content = <Question question={questions[questionIndex]} SurveyDesign={SurveyDesign} submitResponse={submitResponse} user_id={UserID} CurrentSampleMetaData={CurrentSampleMetaData}></Question>
           break;
   }


  return (
    <div key={key}>
        {content}
        <hr />
        <p>Debug info: Question {questionIndex + 1} / {secondaryQuestions.length + 1} </p>
    </div>
  );
};

export default EvaluationEngine;
