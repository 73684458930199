// Prompt to set up VAS connect and make full screen if necessary

import React, { useState, useEffect } from 'react';
import { TextField, Grid, Divider, Typography } from '@mui/material';

const Initialize = ({ setState }) => {

  return (
    <div>
      <h1>Initialize</h1>
      <button>Set up vasconnect</button>
        <button onClick={() => setState('introduction')}>Proceed</button>
    </div>
  );
};

export default Initialize;
