import React, {useState} from 'react';

const Screensaver = ({setState}) => {
    // This function will be called when the screen is touched
    const handleTouch = () => {
        setState('introduction');
    }

        return (
            <video
                width="100%"
                //height="100%"
                autoPlay
                muted
                loop
                onTouchStart={handleTouch}
                onClick={handleTouch}
                style={{
                    position: 'fixed',
                    top: 0,
                    left: 0,
                    minWidth: '100%',
                    height: '100%',
                    background: "black",
                }}
            >
                <source src = {`${process.env.REACT_APP_API_URL}/static/screensaver.mp4`} type="video/mp4" />
                Your browser does not support the video tag.
            </video>
        );


}

export default Screensaver;