import * as React from 'react';
import { useState, useEffect } from 'react';
import { TextField, Divider, Grid, IconButton, Button, FormControl, InputLabel, Select, MenuItem } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import AddIcon from '@mui/icons-material/Add';
import Swal from 'sweetalert2';

const ContinuousVariables = ({ configInputs, setConfigInputs, projectType }) => {

  // let type = ((projectType !== "Screening") && useHardwaare)

  function createVariable(configInputs, index) {
    return {
        variable_names: configInputs?.variable_names?.[index] || "", 
        variable_types: configInputs?.variable_types?.[index] || "Continuous", 
        variable_units: configInputs?.variable_units?.[index] || "ml/min", 
        variable_material_codes: configInputs?.variable_material_codes?.[index] || "", 
        variable_descriptions: configInputs?.variable_descriptions?.[index] || "",
        variable_lows: configInputs?.variable_lows?.[index] || null,
        variable_highs: configInputs?.variable_highs?.[index] || null,
        // variable_categories: configInput?.variable_categories?.[index] || Array(2).fill(""),
    };
   }

   const initialVariables = (configInputs.variable_names?.length || 0) === 0
      ? [createVariable({}, 0), createVariable({}, 1)] 
      : configInputs.variable_names.map((_, index) => createVariable(configInputs, index));

    const [variablesList, setVariablesList] = useState(initialVariables);


       // when variablesList is modified, we change the values of setConfigInputs to make API call in CreateAdvanced
       useEffect(() => {
            
        let all_var_names = variablesList.map(d => d.variable_names).filter(n => n);
        let all_var_types = variablesList.map(d => d.variable_types).filter(n => n);
        let all_var_variable_lows = variablesList.map(d => d.variable_lows).filter(number => number !== null);
        let all_var_variable_highs = variablesList.map(d => d.variable_highs).filter(number => number !== null);
        let all_var_units = variablesList.map(d => d.variable_units).filter(n => n);
        let all_material_codes = variablesList.map(d => d.variable_material_codes).filter(n => n);
        let all_var_descriptions = variablesList.map(d => d.variable_descriptions).filter(n => n);
        // let all_var_categories = variablesList.map(d => d.variable_categories).filter(n => n);

        setConfigInputs(prevState => ({
          ...prevState,
          variable_names: all_var_names,
          variable_types: all_var_types,
          variable_lows: all_var_variable_lows,
          variable_highs: all_var_variable_highs,

          variable_units: all_var_units,
          variable_material_codes: all_material_codes,
          variable_descriptions: all_var_descriptions,
      }));

        // JUST COMMENTED THIS OUT - DONT THINK I NEED IT
        // setConfigInputs( prevState  => ({ ...prevState,   variable_names: all_var_names }));
        // setConfigInputs( prevState  => ({ ...prevState,   variable_types: all_var_types }));
        // setConfigInputs( prevState  => ({ ...prevState,   variable_lows: all_var_variable_lows }));
        // setConfigInputs( prevState  => ({ ...prevState,   variable_highs: all_var_variable_highs }));

        // setConfigInputs( prevState  => ({ ...prevState,   variable_units: all_var_units }));
        // setConfigInputs( prevState  => ({ ...prevState,   variable_material_codes: all_material_codes }));
        // setConfigInputs( prevState  => ({ ...prevState,   variable_descriptions: all_var_descriptions }));
        // setConfigInputs( prevState  => ({ ...prevState,   variable_categories: all_var_categories }));

      }, [variablesList]);

    
      let addVariableFields = () => {
        setVariablesList([...variablesList, {
          variable_names: "", 
          variable_types: "Continuous", 
          variable_descriptions: "", 
          variable_lows: null, 
          variable_highs: null, 
          variable_units: "ml/min", 
          variable_material_codes: ""
        }])
      }
    
      let removeVariableFields = (i) => {
        let newVariables = [...variablesList];
        newVariables.splice(i, 1);
        setVariablesList(newVariables);
      }
    


      // This is what happens when the variables are changed in the variable fields
      const handleVariableChange = (e, index) => {
        let newVariables = [...variablesList];
        if (e.target.name === "variable_lows" || e.target.name === "variable_highs") {
          newVariables[index][e.target.name] = parseInt(e.target.value);
        } else {
          newVariables[index][e.target.name] = e.target.value;
        }
        setVariablesList(newVariables);
      };

    return (
        <Grid container>
            <Grid item xs={12}>
                <h5>Variables</h5>
            </Grid>
            {variablesList.map((element, index) => (
                <div key={index} style={{ display: 'flex', justifyContent: 'space-between', marginBottom: '12px' }}>
                    <Grid item xs={2}>
                    <TextField
                        required
                        className="required"
                        label="Name"
                        id="variable_names"
                        variant="outlined"
                        name="variable_names"
                        value={element.variable_names}
                        onChange={(e) => handleVariableChange(e, index)}
                    />
                    </Grid>
                    <Divider orientation='vertical' sx={{ width: '10px' }} />
                    <Grid item xs={2}>
                    <FormControl sx={{ minWidth: '95%' }}>
                    <InputLabel variant="outlined" id="var-type-label">Type</InputLabel>
                      <Select
                          label="Type"
                          required
                          className="required"
                          labelId="var-type-label"
                          id="var-type"
                          value={element.variable_types}
                          name="variable_types"
                          onChange={(e) => handleVariableChange(e, index)}
                          inputProps={{ autoComplete: 'off' }}
                      >
                          <MenuItem value="Continuous">Continuous</MenuItem>
                          <MenuItem value="Categorical" disabled>Categorical</MenuItem>
                          <MenuItem value="Discrete" disabled>Discrete</MenuItem>
                      </Select>
                    </FormControl>
                    </Grid>
                    <Grid item xs={2}>
                    <TextField
                        label="Descriptions (Optional)"
                        id="variable_descriptions"
                        variant="outlined"
                        name="variable_descriptions"
                        value={element.variable_descriptions}
                        onChange={(e) => handleVariableChange(e, index)}
                    />
                    </Grid>
                    <Divider orientation='vertical' sx={{ width: '10px' }} />
                    <Grid item xs={2}>
                    <TextField
                        label="Units (Optional)"
                        id="variable_units"
                        variant="outlined"
                        name="variable_units"
                        value={element.variable_units}
                        onChange={(e) => handleVariableChange(e, index)}
                    />
                    </Grid>
                    <Divider orientation='vertical' sx={{ width: '10px' }} />
                    <Grid item xs={2}>
                    <TextField
                        label="Material Code (Optional)"
                        id="variable_material_codes"
                        variant="outlined"
                        name="variable_material_codes"
                        value={element.variable_material_codes}
                        onChange={(e) => handleVariableChange(e, index)}
                    />
                    </Grid>
                    <Divider orientation='vertical' sx={{ width: '10px' }} />
                    <Grid item xs={2}>
                    <TextField
                        label="Low Level"
                        required
                        className="required"
                        inputProps={{ min: 0, max: 399 }}
                        error={element.variable_lows < 0 || element.variable_lows > 400 || element.variable_lows > element.variable_highs}
                        helperText={element.variable_lows < 0 ? 'Value cannot be negative' : element.variable_lows > 399 ? 'Value cannot be greater than 399' : element.variable_lows > element.variable_highs ? 'Value greater than high level' : ''}
                        sx={{ minWidth: 200 }}
                        id="variable_lows"
                        variant="outlined"
                        type="number"
                        name="variable_lows"
                        value={ projectType !== "Screening" ? element.variable_lows : 0 }
                        onChange={(e) => handleVariableChange(e, index)}
                        disabled={ projectType === "Screening" }
                    />
                    </Grid>
                    <Divider orientation='vertical' sx={{ width: '10px' }} />
                    <Grid item xs={2}>
                    <TextField
                        required
                        className="required"
                        inputProps={{ min: element.variable_lows+1, max: 400 }}
                        sx={{ minWidth: 200 }}
                        error={element.variable_highs > 400 || element.variable_lows > element.variable_highs }
                        helperText={element.variable_highs > 400 ? 'Value cannot be greater than 400' : element.variable_lows > element.variable_highs ? 'Value less than low level' : ''}
                        label="High Level"
                        id="variable_highs"
                        variant="outlined"
                        type="number"
                        name="variable_highs"
                        value={element.variable_highs}
                        onChange={(e) => handleVariableChange(e, index)}
                    />
                    </Grid>
                    <Grid item xs={1}>
                    {variablesList.length !== 2 &&
                        (<IconButton sx={{ display: 'block'}} aria-label="delete" className="remove-btn" onClick={() => removeVariableFields(index)}>
                            <DeleteIcon />
                        </IconButton>)}
                    </Grid>                    
                </div>                
            ))}
            <Grid item xs={12}>    
                <div style={{ display: 'flex', justifyContent: 'center' }}>         
                <Button sx={{ display: 'block' }} aria-label="add" variant="contained" className="add-btn" onClick={() => addVariableFields()} size="large">
                    <AddIcon/>
                </Button>      
                </div>               
            </Grid>
        </Grid>
    );
}


export default ContinuousVariables;