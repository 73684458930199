import React, { useState } from 'react';
import { Grid, Typography, IconButton, TextField, Button, Box, FormControl, FormGroup, FormControlLabel, Select, MenuItem, Divider, Switch, InputLabel } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import EditIcon from '@mui/icons-material/Edit';
import SaveIcon from '@mui/icons-material/Save';
import DeleteIcon from '@mui/icons-material/Delete';
import { styled } from '@mui/material/styles';


// Import lower-level components
import PrimarySurveyQuestion from './Add Pages/PrimarySurveyQuestion';
import InstructionsForUse from './Add Pages/InstructionsForUse';
import DemographicQuestions from './Add Pages/DemographicQuestions';
import ATOMRecommendations from './Add Pages/ATOM_Recommendations';
import SecondarySurveyQuestions from './Add Pages/SecondarySurveyQuestions';


const CustomGridItem = ({ id, title, showDelete, onDelete, configInputs, addPages, setAddPages, introInputs, screeningDOE }) => {
  
  // Set state of isEditing to false, and change to true when they click the edit button

  const [isEditing, setIsEditing] = useState(false);
  const [isPrimaryEditing, setIsPrimaryEditing] = useState(true);

  const handlePrimaryEdit = () => {    
    setIsPrimaryEditing(true);
  };

  const handlePrimarySave = () => {    
    setIsPrimaryEditing(false);
  };
  
  const handleEdit = () => {
    setIsEditing(true);
  };

  const handleSave = () => {    
    setIsEditing(false);
  };

  return (
    <Grid container direction="column" style={{ maxWidth: '63%', border: '1px solid black', padding: '10px' }}>
      <Grid item>
        <Box className="titles-and-editDelete" display="flex" justifyContent="space-between">
          <Typography variant="h6">{title}</Typography>
            <Box className="testingClassName">
              {showDelete && (
                <IconButton size="small" color="error" onClick={onDelete}>
                  <DeleteIcon />
                </IconButton>
              )}
              { title=="Primary Survey Question" ?
                <IconButton size="small" onClick={handlePrimaryEdit}>
                  <EditIcon />
                </IconButton>
                :                
                <IconButton size="small" onClick={handleEdit}>
                  <EditIcon />
                </IconButton>
              }
            </Box>
        </Box>
      </Grid>
      <Grid item>
        {isPrimaryEditing && title==="Primary Survey Question" ? (
          <>
          <PrimarySurveyQuestion addPages={addPages} setAddPages={setAddPages}/>
          <Divider orientation='horizontal' sx={{ height: '10px' }} />
          <Button onClick={handlePrimarySave} startIcon={<SaveIcon />}>
              Save
            </Button>
            </>
        ) : (
          <div></div>
        )}
        {isEditing && title==="Secondary Survey Question" ? (
          <>
            <SecondarySurveyQuestions addPages={addPages} setAddPages={setAddPages} id={id} />  
            <Divider orientation='horizontal' sx={{ height: '10px' }} />
            <Button onClick={handleSave} startIcon={<SaveIcon />}>
              Save
            </Button>
            </>
        ) : (
          <div></div>
        )}
        {isEditing && title==="Instructions for Use" ? (
          <>
            <InstructionsForUse addPages={addPages} setAddPages={setAddPages}/>
            <Divider orientation='horizontal' sx={{ height: '10px' }} />
            <Button onClick={handleSave} startIcon={<SaveIcon />}>
              Save
            </Button>
          </>
        ) : (
          <div></div>
        )}
        {isEditing && title==="Demographic Questions" ? (
          <>
            <DemographicQuestions addPages={addPages} setAddPages={setAddPages} />
            <Divider orientation='horizontal' sx={{ height: '10px' }} />
            <Button onClick={handleSave} startIcon={<SaveIcon />}>
              Save
            </Button>
          </>
        ) : (
          <div></div>
        )}
        {isEditing && title==="ATOM Recommendations" ? (
          <>
            <ATOMRecommendations configInputs={configInputs} addPages={addPages} setAddPages={setAddPages} introInputs={introInputs} screeningDOE={screeningDOE} />
            <Divider orientation='horizontal' sx={{ height: '10px' }} />
            <Button onClick={handleSave} startIcon={<SaveIcon />}>
              Save
            </Button>
          </>
        ) : (
          <div></div>
        )}
      </Grid>
    </Grid>
  );
};

export default CustomGridItem;
