import React from 'react';
import { useState } from 'react';
import cn from 'classnames';
import { useNavigate } from 'react-router-dom';
import array from './home-cards.json';
import createArray from '../survey/create-cards.json';
import basicAdvArray from '../survey/basic-adv.json';
import Design from '../survey/Design';

let cards = array.homeCards;

// Function that will flip the card over when it is hovered,
// and route to next page when clicked.
function FlipCard({ card }) {
  const [showBack] = useState(false);
  const navigate = useNavigate();
  
  // Takes the path found at that cards ID
  function getPath (comp) {
    let id = comp.id;
    let path = cards[id].path;
    return path;
  }

  // This section is how the pages are navigated.
  // Works now, but does not have backtracking functionality
  function handleClick() {
    let path = getPath(card);
    let id = card.id;
    
    if (path === "create") {
      <Design />
      cards = createArray.createCards;
    }
    navigate(card.path)
  }
  let card_details = <></>
  if(card.back != ""){
    card_details = <div style={{height:"150px", padding: "5px"}}><p className="">{card.back}</p></div>

  }
  // This is the flipping functionality
  return (
    <div className="flip-card-outer" onClick={handleClick}>
      <div
        className={cn("flip-card-inner", {
          showBack
        })}
      >
        <div className="flip-card-inner">
          <div className="card front">
            <div className="card-body d-flex justify-content-center align-items-center">
              <p className="card-text fs-1">{card.front}</p>
            </div>

              {card_details}

          </div>

        </div>
      </div>
    </div>
  );
}

export default FlipCard;
