import React, {useEffect} from "react";
import { TextField } from "@mui/material";

const Plaintext = ({ question, questionResponseData, setQuestionResponseData }) => {

  // Input the response into "setQuestionResponseData" UseState.
  // the question object is a JSON with all the data needed to fill in the question inputs.

    // useEffect(() => {
    //     setQuestionResponseData(1)
    // }, [])

    const handleChange = (event) => {
      setQuestionResponseData({
        [event.target.name]: event.target.value,
      });
    };
    console.log(questionResponseData)

  return (
    <div>
      <TextField
          id="outlined-multiline-static"
          label="Enter your response here."
          name="textfield"
          multiline
          rows={4}
          onChange={handleChange}
          sx={{ minWidth: '100%' }}
        />
    </div>
  );
};

export default Plaintext;
