import React, { useState, useEffect } from 'react';
import { TextField, Grid, Divider, Typography } from '@mui/material';
import ArrowCircleRightIcon from '@mui/icons-material/ArrowCircleRight';
import Numeric from "./subcomponents/questionmodes/Numeric";
import JAR from "./subcomponents/questionmodes/JAR";
import {Check} from "@mui/icons-material";
import CheckboxQuestion from "./subcomponents/questionmodes/Checkbox";
import MultipleChoice from "./subcomponents/questionmodes/MultipleChoice";
import Plaintext from "./subcomponents/questionmodes/Plaintext";
import SliderQuestion from "./subcomponents/questionmodes/Slider";


const Question = ({ question, SurveyDesign, submitResponse, user_id, session_id, CurrentSampleMetaData }) => {
    // content - the question object that will be rendered
    // submitResponse - call this function with the result from the input object
    // design has some UI components we will need. (style editor inputs)

    console.log("Question component rendered")

    const [questionPayload, setQuestionPayload] = useState([]) // This is what actually gets sent when the user hits submit and contains
    // meta data about the response
    const [questionResponseData, setQuestionResponseData] = useState([]) // this value gets set by the question object

    useEffect(() => { // This useEffect updates the question payload with our response.
        setQuestionPayload(
            {
             user_id: user_id,
             question_id: question.id,
             response:  questionResponseData,
             session_id: session_id,
             timestamp: Date.now(),
             sample: CurrentSampleMetaData
            })
            console.log("QUESTION PAYLOAD")
            console.log(question)
            console.log({
             user_id: user_id,
             question_id: question.id,
             response:  questionResponseData,
             session_id: session_id,
             timestamp: Date.now(),
             sample: CurrentSampleMetaData

            })

    }, [questionResponseData])

    let style = {
        question_text: SurveyDesign.question_text,
        fontFamily: '',  //FIXME - not in design
        question_bold: SurveyDesign.question_text,
        question_italic: SurveyDesign.question_italic,
        answer_text: SurveyDesign.answer_text,
        answer_description: SurveyDesign.answer_description,
        answer_description_bold: SurveyDesign.answer_description_bold,
        answer_description_italic: SurveyDesign.answer_description_italic,
        page_background: SurveyDesign.page_background

    }

    let question_object = <h1>Error - Question object not defined</h1>;
    switch(question.format){
        case 'Numeric Scale':
            question_object = <Numeric question={question} style={style} questionResponseData={questionResponseData} setQuestionResponseData={setQuestionResponseData} submitResponse={submitResponse} questionPayload={questionPayload}/>
            break;
        case "JAR Scale":
            question_object = <JAR question={question} questionResponseData={questionResponseData} setQuestionResponseData={setQuestionResponseData} submitResponse={submitResponse} questionPayload={questionPayload}/>
            break;
        case "Checkbox":
            question_object = <CheckboxQuestion question={question} questionResponseData={questionResponseData} setQuestionResponseData={setQuestionResponseData}/>
            break;
        case "Multiple Choice":
            question_object = <MultipleChoice question={question} questionResponseData={questionResponseData} setQuestionResponseData={setQuestionResponseData} submitResponse={submitResponse} questionPayload={questionPayload}/>
            break;
        case "Plain Text":
            question_object =  <Plaintext question={question} questionResponseData={questionResponseData} setQuestionResponseData={setQuestionResponseData}/>
            break;
        case "Slider":
            question_object =  <SliderQuestion question={question} questionResponseData={questionResponseData} setQuestionResponseData={setQuestionResponseData}/>
            break;
    }


  return (
    <div>
        <h1>{question.question}</h1>
        {question_object}
        {true || question.format === "Checkbox" || question.format === "Plain Text" || question.format === "Slider" ?
        <div style={{ display: 'flex', justifyContent: 'end' }}>
            <button onClick={() => submitResponse(questionPayload)}  style={{ display: 'contents' }}><ArrowCircleRightIcon fontSize='large' sx={{ color: 'blue', marginTop: '15px' }}/></button>
        </div>
        : null}
    </div>
  );
};

export default Question;
