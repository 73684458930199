import React, {useEffect} from "react";
import {Checkbox, FormControlLabel, FormGroup, FormControl, RadioGroup, Radio } from "@mui/material";

const MultipleChoice = ({ question, questionResponseData, setQuestionResponseData, submitResponse, questionPayload }) => {

  // Input the response into "setQuestionResponseData" UseState.
  // the question object is a JSON with all the data needed to fill in the question inputs.

    // useEffect(() => {
    //     setQuestionResponseData(1)
    // }, [])

  const handleChange = (event) => {
    setQuestionResponseData({
      [event.target.name]: event.target.value,
    });
    //submitResponse(questionPayload)
  };
  console.log(questionResponseData)

  return (
    <div class="questionObject">
      <FormControl>
          <RadioGroup
              id="MC_response"
              value={questionResponseData.MC_response}
              name="MC_response"
              onChange={handleChange}
              row
          >
              {question.fields.map((field, index) => (
                <FormControlLabel 
                    key={index}
                    value={`${field}`}
                    control={<Radio />}
                    label={`${field}`}
                    labelPlacement="bottom"
                />
              ))}
          </RadioGroup>
      </FormControl>
    </div>
    // <FormGroup>
    //   <FormControlLabel
    //     control={
    //       <Checkbox
    //         onChange={handleChange}
    //         name="apple"
    //         color="primary"
    //       />
    //     }
    //     label="Apple"
    //   />
    //   <FormControlLabel
    //     control={
    //       <Checkbox
    //         onChange={handleChange}
    //         name="banana"
    //         color="primary"
    //       />
    //     }
    //     label="Banana"
    //   />
    //   <FormControlLabel
    //     control={
    //       <Checkbox
    //         onChange={handleChange}
    //         name="coconut"
    //         color="primary"
    //       />
    //     }
    //     label="Coconut"
    //   />
    // </FormGroup>
  );
};

export default MultipleChoice;
