import * as React from 'react';
import {useState, useEffect, useRef, forwardRef, useImperativeHandle} from 'react';
import { Switch, FormControl, FormGroup, FormControlLabel, Box, Divider, InputLabel, MenuItem, Select, IconButton, TextField } from '@mui/material';
import { styled } from '@mui/material/styles';
import AddCircleIcon from '@mui/icons-material/AddCircle';

const Configuration = forwardRef(({configInputs, setConfigInputs }, ref) => {

    const areRequiredFieldsFilled = () => {
        // This function will check if all required fields are filled.

        const textAreas = Array.from(document.querySelectorAll('.configureAdminForm .required textarea'));
        const inputFields = Array.from(document.querySelectorAll('.configureAdminForm .required input'));

        // Concatenate the arrays
        const inputs = inputFields.concat(textAreas);

        for (let input of inputs) {
            if (!input.value) {
                console.log(input)
                console.log(input.name, "IS NOT SET")
                alert(input.name + " is not set.")
                return false;
            }
        }
        return true;
    }
    useImperativeHandle(ref, () => ({
        areRequiredFieldsFilled,
    }));

    const [inputs, setInputs] = useState({
        full_screen_mode: configInputs.full_screen_mode,
        screen_saver: configInputs.screen_saver || null,
        display_sample_code: configInputs.display_sample_code || "",
        sample_presentation_order_type: configInputs.sample_presentation_order_type || "",
        inactivity_timeout: configInputs.inactivity_timeout,
        timeout_minutes: configInputs.timeout_minutes || "",
        what_happens_timeout: configInputs.what_happens_timeout || "",
        purge_frequency: configInputs.purge_frequency || "",
        back_button: configInputs.back_button,
        quit_button: configInputs.quit_button,
        next_button: configInputs.next_button,
        progress_indicator: configInputs.progress_indicator,
        pause_button: configInputs.pause_button,
        test_flight_mode: configInputs.test_flight_mode,
        change_cartridge_panelist_reminder: configInputs.change_cartridge_panelist_reminder,
        num_panelists_per_cartridge_change_reminder: configInputs.num_panelists_per_cartridge_change_reminder
    })

     // Used for the switch changing
    const handleSwitchChange = (e) => {
        setInputs(
        {
            ...inputs,
            [e.target.name]: e.target.checked
        }
        )
    }

    const handleInputChange = (e) => {
        const { name, value } = e.target;
       
        setInputs({
        ...inputs,
        [name]: value,
        });
    };

    const fileInputRef = useRef(null); // create a reference to your file input

    const onAddIconClick = () => {
        fileInputRef.current.click(); // programmatically click the file input
    }

    const onFileChange = (event) => {
        let file = event.target.files[0]; // get the file
        if (file) { // ensure file is selected
            let reader = new FileReader();
            reader.onloadend = () => {
                // set state
                const result = reader.result;
                setInputs(prevInputs => ({...prevInputs, screen_saver: result}));
                setConfigInputs(prevConfigInputs => ({...prevConfigInputs, screen_saver: result}));
            }
            reader.readAsDataURL(file);
        }
    }


    const Android12Switch = styled(Switch)(({ theme }) => ({
        padding: 8,
        '& .MuiSwitch-track': {
          borderRadius: 22 / 2,
          '&:before, &:after': {
            content: '""',
            position: 'absolute',
            top: '50%',
            transform: 'translateY(-50%)',
            width: 16,
            height: 16,
          },
          '&:before': {
            backgroundImage: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="16" width="16" viewBox="0 0 24 24"><path fill="${encodeURIComponent(
              theme.palette.getContrastText(theme.palette.primary.main),
            )}" d="M21,7L9,19L3.5,13.5L4.91,12.09L9,16.17L19.59,5.59L21,7Z"/></svg>')`,
            left: 12,
          },
          '&:after': {
            backgroundImage: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="16" width="16" viewBox="0 0 24 24"><path fill="${encodeURIComponent(
              theme.palette.getContrastText(theme.palette.primary.main),
            )}" d="M19,13H5V11H19V13Z" /></svg>')`,
            right: 12,
          },
        },
        '& .MuiSwitch-thumb': {
          boxShadow: 'none',
          width: 16,
          height: 16,
          margin: 2,
        },
      }));

     useEffect(() => {

            let full_screen_mode = inputs.full_screen_mode;
            let screen_saver = inputs.screen_saver;
            let sample_presentation_order_type = inputs.sample_presentation_order_type;
            let display_sample_code = inputs.display_sample_code;
            let inactivity_timeout = inputs.inactivity_timeout;
            let timeout_minutes = inputs.timeout_minutes;
            let what_happens_timeout = inputs.what_happens_timeout;
            let back_button = inputs.back_button;
            let quit_button = inputs.quit_button;
            let next_button = inputs.next_button;
            let progress_indicator = inputs.progress_indicator;
            let pause_button = inputs.pause_button;
            let test_flight_mode = inputs.test_flight_mode;
            let purge_frequency = inputs.purge_frequency;
            let change_cartridge_panelist_reminder = inputs.change_cartridge_panelist_reminder;
            let num_panelists_per_cartridge_change_reminder = inputs.num_panelists_per_cartridge_change_reminder;

            setConfigInputs(prevState => ({
                ...prevState,
                full_screen_mode: full_screen_mode,
                screen_saver: screen_saver,
                sample_presentation_order_type: sample_presentation_order_type,
                display_sample_code: display_sample_code,
                inactivity_timeout: inactivity_timeout,
                timeout_minutes: timeout_minutes,
                what_happens_timeout: what_happens_timeout,
                back_button: back_button,
                quit_button: quit_button,
                next_button: next_button,
                progress_indicator: progress_indicator,
                pause_button: pause_button,
                test_flight_mode: test_flight_mode,
                purge_frequency: purge_frequency,
                change_cartridge_panelist_reminder: change_cartridge_panelist_reminder,
                num_panelists_per_cartridge_change_reminder: num_panelists_per_cartridge_change_reminder

            }));
        }, [inputs]);
        console.log(inputs)

  return (
    <>
    <Box className='configureAdminForm' sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-evenly' }}>
        <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start' }}>
            <Box sx={{ display: 'flex', flexDirection: 'row', marginBottom: '90px', alignItems: 'center' }}>
            <FormControl>
                <FormGroup>
                    <FormControlLabel
                        sx={{ p: 0.5 }}
                        control={<Android12Switch checked={inputs.full_screen_mode}
                        onChange={handleSwitchChange} name="full_screen_mode" />}
                        label="Full Screen Mode"
                        labelPlacement="top"
                    />
                </FormGroup>
            </FormControl>
            <Divider orientation='vertical' sx={{ width: '20px' }} />
            <FormControl>
                <input
                    type="file"
                    ref={fileInputRef}
                    style={{ display: "none" }} // hide the file input
                    accept="image/*" // accept only image files
                    onChange={onFileChange}
                />
                <h5>Add Screen Saver</h5>
                <IconButton aria-label="add" variant="contained" size="large" onClick={onAddIconClick}>
                    <AddCircleIcon color='primary' fontSize="large"/>
                </IconButton>
            </FormControl>
            <Divider orientation='vertical' sx={{ width: '30px' }} />
            <FormControl sx={{ minWidth: 260 }}>
                <InputLabel variant="outlined" id="presentation-order-label">Sample Presentation Order</InputLabel>
                <Select
                    required
                    className={'required'}
                    label="Sample Presentation Order"
                    labelId="presentation-order-label"
                    id="sample_presentation_order_type"
                    value={inputs.sample_presentation_order_type}
                    name="sample_presentation_order_type"
                    onChange={handleInputChange}
                >
                    <MenuItem value="random">Random</MenuItem>
                    <MenuItem value="user_defined">User Defined Order</MenuItem>
                </Select>
            </FormControl>
            </Box>
            <Box sx={{ display: 'flex', flexDirection: 'row' }}>
            <FormControl>
                <FormGroup>
                    <FormControlLabel
                        sx={{ p: 0.5 }}
                        control={<Android12Switch checked={inputs.display_sample_code}
                        onChange={handleSwitchChange} name="display_sample_code" />}
                        label="Display Sample Code"
                        labelPlacement="top"
                    />
                </FormGroup>
            </FormControl>
            <FormControl>
                <FormGroup>
                    <FormControlLabel

                        sx={{ p: 0.5 }}
                        control={<Android12Switch checked={inputs.inactivity_timeout}
                        onChange={handleSwitchChange} name="inactivity_timeout" />}
                        label="Inactivity Timeout"
                        labelPlacement="top"
                    />
                </FormGroup>
            </FormControl>
            {inputs.inactivity_timeout === true ?
            <>
            <Divider orientation='vertical' sx={{ width: '20px' }} />
            <FormControl sx={{ minWidth: 180 }}>
                <InputLabel variant="outlined" id="inactivity-timeout-label">Inactivity Timeout *</InputLabel>
                <Select
                    required
                    className={'required'}
                    label="Inactivity Timeout"
                    labelId="inactivity-timeout-label"
                    id="timeout_minutes"
                    value={inputs.timeout_minutes}
                    name="timeout_minutes"
                    onChange={handleInputChange}
                >
                    <MenuItem value="2">2 mins.</MenuItem>
                    <MenuItem value="5">5 mins.</MenuItem>
                    <MenuItem value="10">10 mins.</MenuItem>
                    <MenuItem value="20">20 mins.</MenuItem>
                </Select>
            </FormControl>
            <Divider orientation='vertical' sx={{ width: '20px' }} />
            <FormControl sx={{ minWidth: 240 }}>
                <InputLabel variant="outlined" id="at-timeout-label">What happens at timeout? *</InputLabel>
                <Select
                    required
                    className={'required'}
                    label="Sample Presentation Order"
                    labelId="at-timeout-label"
                    id="what_happens_timeout"
                    value={inputs.what_happens_timeout}
                    name="what_happens_timeout"
                    onChange={handleInputChange}
                >
                    <MenuItem value="quit">Quit survey and return to home</MenuItem>
                    <MenuItem value="pause">Pause survey and wait for user to resume</MenuItem>
                </Select>
            </FormControl>
            </>
            : <div></div>}
            <FormControl sx={{ minWidth: 180 }}>
                <InputLabel variant="outlined" id="purge-frequency-label">Purge Frequency *</InputLabel>
                <Select
                    required
                    className={'required'}
                    label="Purge Frequency"
                    labelId="purge-frequency-label"
                    id="purge_frequency"
                    value={inputs.purge_frequency}
                    name="purge_frequency"
                    onChange={handleInputChange}
                >
                    <MenuItem value="never">Never</MenuItem>
                    <MenuItem value="between_panelists">Between every panelist</MenuItem>
                    <MenuItem value="10_samples">Every 10th Sample</MenuItem>
                    <MenuItem value="6_samples">Every 6th Sample</MenuItem>
                    <MenuItem value="4_samples">Every 4th Sample</MenuItem>

                </Select>
            </FormControl>
            </Box>
            <Box sx={{ display: 'flex', flexDirection: 'row' }}>
            <FormControl>
                <FormGroup>
                    <FormControlLabel
                        sx={{ p: 0.5 }}
                        control={<Android12Switch checked={inputs.change_cartridge_panelist_reminder}
                        onChange={handleSwitchChange} name="change_cartridge_panelist_reminder" />}
                        label="Set Reminder to change cartridge after every N panelists?"
                        labelPlacement="top"
                    />
                </FormGroup>
            </FormControl>
            {inputs.change_cartridge_panelist_reminder === true ?
            <>
            <Divider orientation='vertical' sx={{ width: '20px' }} />
            <FormControl sx={{ minWidth: 180 }}>
                <TextField
                    required
                    type="number"
                    className={'required'}
                    label="Number of Panelists"
                    inputProps={{ min: 0  }}
                    error={inputs.num_panelists_per_cartridge_change_reminder < 0}
                    helperText={inputs.num_panelists_per_cartridge_change_reminder < 0 ? 'Value cannot be negative' : ''}
                    id="num_panelists_per_cartridge_change_reminder"
                    value={inputs.num_panelists_per_cartridge_change_reminder}
                    name="num_panelists_per_cartridge_change_reminder"
                    onChange={handleInputChange}
                >

                </TextField>
            </FormControl>
            </>
            : <div></div>}
            </Box>

        </Box>
            <Box sx={{display: 'flex', justifyContent: 'flex-end'}}>
            <FormControl sx={{display: 'flex'}}>
                <FormGroup row>
                    <FormControlLabel
                        sx={{ p: 0.5 }}
                        control={<Android12Switch checked={inputs.back_button}
                        onChange={handleSwitchChange} name="back_button" />}
                        label="Back Button"
                        labelPlacement="top"
                    />
                    <FormControlLabel
                        sx={{ p: 0.5 }}
                        control={<Android12Switch checked={inputs.quit_button}
                        onChange={handleSwitchChange} name="quit_button" />}
                        label="Quit Button"
                        labelPlacement="top"
                    />
                </FormGroup>
                <FormGroup row>
                    <FormControlLabel
                        sx={{ p: 0.5 }}
                        control={<Android12Switch checked={inputs.next_button}
                        onChange={handleSwitchChange} name="next_button" />}
                        label="Next Button"
                        labelPlacement="top"
                    />
                    <FormControlLabel
                        sx={{ p: 0.5 }}
                        control={<Android12Switch checked={inputs.progress_indicator}
                        onChange={handleSwitchChange} name="progress_indicator" />}
                        label="Progress Indicator"
                        labelPlacement="top"
                    />
                </FormGroup>
                <FormGroup row>
                    <FormControlLabel
                        sx={{ p: 0.5 }}
                        control={<Android12Switch checked={inputs.pause_button}
                        onChange={handleSwitchChange} name="pause_button" />}
                        label="Pause Button"
                        labelPlacement="top"
                    />
                    <FormControlLabel
                        sx={{ p: 0.5 }}
                        control={<Android12Switch checked={inputs.test_flight_mode}
                        onChange={handleSwitchChange} name="test_flight_mode" />}
                        label="Test Flight Mode"
                        labelPlacement="top"
                    />
                </FormGroup>
            </FormControl>
        </Box>
    </Box>
    </>
  );
})

export default Configuration;