import { styled } from '@mui/material/styles';
import { Grid } from '@mui/material';
import React from 'react';
import 'sweetalert2/src/sweetalert2.scss';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import IconButton from '@mui/material/IconButton';
import DoneOutlineIcon from '@mui/icons-material/DoneOutline';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import Delete from '@mui/icons-material/Delete';

const ConstraintTable = ({ constraints, handleConstraintTypeChange, handleConstraintLimitChange, removeConstraint, checkLinear }) => {

    const StyledTableCell = styled(TableCell)(({ theme }) => ({
        [`&.${tableCellClasses.head}`]: {
          backgroundColor: '#4682B4',
          color: theme.palette.common.white,
        },
        [`&.${tableCellClasses.body}`]: {
          fontSize: 14,
        },
    }));
      
    const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
        backgroundColor: theme.palette.action.hover,
    },
    // hide last border
    '&:last-child td, &:last-child th': {
        border: 0,
    },
    }));

    function handleTypeChange(event, index) {
        handleConstraintTypeChange(event, index)
    }

    function handleLimitChange(event, index){
        handleConstraintLimitChange(event, index)
    }

    const remove = (event, index) => {
        removeConstraint(event, index)
    }

    const checklinear = (event, index) => {
        checkLinear(event, index)
    }

    console.log(constraints)

    return (
    <Grid item xs={12} style={{marginTop: '40px'}}>
        <TableContainer component={Paper}>
            <Table sx={{ minWidth: 700 }} aria-label="customized table">
                <TableHead>
                <TableRow>
                    <StyledTableCell>Added Constraints</StyledTableCell>
                    <StyledTableCell >Operator</StyledTableCell>
                    <StyledTableCell >Limit</StyledTableCell>
                    <StyledTableCell >Type</StyledTableCell>
                    <StyledTableCell >Check Type</StyledTableCell>
                    <StyledTableCell >Delete</StyledTableCell>
                </TableRow>
                </TableHead>
                <TableBody>
                {constraints.map((row, index) => (
                    <StyledTableRow key={index}>
                    <StyledTableCell component="th" scope="row">{row.constraint}</StyledTableCell>
                    <StyledTableCell >                        
                    <Select
                        labelId="demo-simple-select-label"
                        id="addedConstraintTableOperator"
                        style={{height: '30px', backgroundColor: 'white', width: '100px', textAlign: 'center'}}
                        value={row.type}
                        onChange={(e) => handleTypeChange(e,index)}
                    >
                        <MenuItem value=">=">{'>'}=</MenuItem>
                        <MenuItem value=">">{'>'}</MenuItem>
                        <MenuItem value="<=">{'<'}=</MenuItem>
                        <MenuItem value="<">{'<'}</MenuItem>
                        <MenuItem value="=">{'='}</MenuItem>
                    </Select> </StyledTableCell>
                    <StyledTableCell ><input id="addedConstraintTableLimit" style={{width: '100px', textAlign: 'center'}} defaultValue={row.limit} onChange={(e) => handleLimitChange(e,index)}></input></StyledTableCell>
                    <StyledTableCell >{row.linear}</StyledTableCell>
                    <StyledTableCell style={{textAlign: 'center'}} >
                        <IconButton onClick={(e) => {checklinear(e, index)}}><DoneOutlineIcon style={{color: 'blue', fontSize: '30px'}} /></IconButton>
                    </StyledTableCell>
                    <StyledTableCell >
                        <IconButton onClick={(e) => {remove(e, index)}}><Delete style={{color: 'red', fontSize: '30px'}} /></IconButton>
                    </StyledTableCell>
                    </StyledTableRow>
                ))}
                </TableBody>
            </Table>
        </TableContainer>
    </Grid>
    )
}

export default ConstraintTable;