import * as React from 'react';
import { useState, useEffect } from 'react';
import { TextField, Divider, Grid, IconButton, Button } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import AddIcon from '@mui/icons-material/Add';

const ConstantVariables = ({ configInputs, setConfigInputs }) => {

  function createConstant(configInputs, index) {
    return {
        constant_names: configInputs?.constant_names?.[index] || "", 
        constant_amounts: configInputs?.constant_amounts?.[index] || "", 
        constant_units: configInputs?.constant_units?.[index] || "", 
        constant_materialCodes: configInputs?.constant_materialCodes?.[index] || "", 
        constant_descriptions: configInputs?.constant_descriptions?.[index] || "",
    };
   }

   const initialConstants = (configInputs.constant_names?.length || 0) === 0
      ? [createConstant({}, 0)] 
      : configInputs.constant_names.map((_, index) => createConstant(configInputs, index));

    const [constantsList, setConstantsList] = useState(initialConstants);

    useEffect(() => {
        let all_const_names = constantsList.map(d => d.constant_names).filter(n => n);
        let all_const_amounts = constantsList.map(d => d.constant_amounts).filter(n => n);
        let all_const_units = constantsList.map(d => d.constant_units).filter(n => n);
        let all_const_material_codes = constantsList.map(d => d.constant_materialCodes).filter(n => n);
        let all_const_descriptions = constantsList.map(d => d.constant_descriptions).filter(n => n);

        setConfigInputs(prevState => ({
          ...prevState,
          constant_names: all_const_names,
          constant_amounts: all_const_amounts,
          constant_units: all_const_units,
          constant_materialCodes: all_const_material_codes,
          constant_descriptions: all_const_descriptions,
      }));


    })
    
    
      let addConstantFields = () => {
        setConstantsList([...constantsList, {
          constant_names: "", constant_amounts: "", constant_units: "", constant_materialCodes: "", constant_descriptions: ""
        }])
      }
    
      let removeConstantFields = (i) => {
        let newConstants = [...constantsList];
        newConstants.splice(i, 1);
        setConstantsList(newConstants);
      }
    
      // This is what happens when the variables are changed in the variable fields
      const handleConstantChange = (e, index) => {

        let newConstants = [...constantsList];
        if (e.target.name === "constant_amounts") {
          newConstants[index][e.target.name] = parseInt(e.target.value);
        } else {
          newConstants[index][e.target.name] = e.target.value;
        }
        setConstantsList(newConstants);

      };

      // This puts the constants into the correct format to be put into the DB
      // let constant_names = [], constant_types = [], constant_amounts = [];
      // for (let i = 0; i < constantsList.length; i++) {
      //   if (constantsList[i].constant_names !== '') {
      //     constant_names.push(constantsList[i].constant_names);
      //   }
      //   if (constantsList[i].constType !== '') {
      //     constant_types.push(constantsList[i].constType);
      //   }
      //   if (constantsList[i].constAmount !== '') {
      //     constant_amounts.push(constantsList[i].constAmount);
      //   }
      // }

      //console.log(configInputs);
      return (
        <Grid container style={{ minWidth: '100%', marginBottom: '10px' }}>  
          <Grid item style={{ width: '100%', maxWidth: '100%' }}>          
            <h5>Constants</h5>
          </Grid>
          
          {constantsList.length === 0 ? (
            <Grid item xs={12}>
              <p>No constants added.</p>
            </Grid>
          ) : (
            constantsList.map((element, index) => (
              <React.Fragment key={index}>
                <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: '12px'}}>
                    <Grid item xs={3}>
                      <TextField
                        required
                        className="required"
                        label="Name"
                        id="constant_names"
                        variant="outlined"
                        name="constant_names"
                        value={element.constant_names}
                        onChange={(e) => handleConstantChange(e, index)}
                      />
                    </Grid>
                    <Divider orientation='vertical' sx={{ width: '10px' }} />
                    <Grid item xs={3}>
                      <TextField
                        required
                        label="Level"
                        inputProps={{ min: 0, max: 400 }}
                        error={element.constant_amounts > 400}
                        helperText={element.constant_amounts > 400 ? 'Value cannot be greater than 400' : ''}
                        sx={{ minWidth: 220 }}
                        className="required"
                        id="constant_amounts"
                        type="number"
                        variant="outlined"
                        name="constant_amounts"
                        value={element.constant_amounts}
                        onChange={(e) => handleConstantChange(e, index)}
                      />
                    </Grid>
                    <Divider orientation='vertical' sx={{ width: '10px' }} />
                    <Grid item xs={3}>
                      <TextField
                        label="Units (Optional)"
                        id="units"
                        variant="outlined"
                        name="constant_units"
                        value={element.constant_units}
                        onChange={(e) => handleConstantChange(e, index)}
                      />
                    </Grid>
                    <Divider orientation='vertical' sx={{ width: '10px' }} />
                    <Grid item xs={3}>
                      <TextField
                        label="Material Code (Optional)"
                        id="materialCode"
                        variant="outlined"
                        name="constant_materialCodes"
                        value={element.constant_materialCodes}
                        onChange={(e) => handleConstantChange(e, index)}
                      />
                    </Grid>
                    <Divider orientation='vertical' sx={{ width: '10px' }} />
                    <Grid item xs={3}>
                      <TextField
                        label="Description (Optional)"
                        id="description"
                        variant="outlined"
                        name="constant_descriptions"
                        value={element.constant_descriptions}
                        onChange={(e) => handleConstantChange(e, index)}
                      />
                    </Grid>
                    <Grid item style={{ width: '-20%'}}>
                      <IconButton sx={{ display: 'block'}} aria-label="delete" className="remove-btn" onClick={() => removeConstantFields(index)}>
                        <DeleteIcon />
                      </IconButton>
                    </Grid>
                </div>
    
                <Grid item xs={12}>
                  <div style={{ display: 'flex', justifyContent: 'center' }}>
                    {constantsList.length - 1 === index && (
                      <Grid item xs={12}>
                        <div style={{ display: 'flex', justifyContent: 'center' }}>
                          <Button sx={{ display: 'block' }} aria-label="add" variant="contained" className="add-btn" onClick={() => addConstantFields()} size="large">
                              <AddIcon />
                          </Button>
                        </div>
                      </Grid>
                    )}
                  </div>
                </Grid>
              </React.Fragment>
            ))
          )}

          {constantsList.length === 0 && (
            <Grid item xs={12}>
              <div style={{ display: 'flex', justifyContent: 'center' }}>
                <Button sx={{ display: 'block' }} aria-label="add" variant="contained" className="add-btn" onClick={() => addConstantFields()} size="large">
                    <AddIcon />
                </Button>
              </div>
            </Grid>
          )}
          
        </Grid>
    );    
}


export default ConstantVariables;
