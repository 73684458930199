import * as React from 'react';
import { connect } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { Box, TextField, FormGroup, FormControl, FormControlLabel, Switch, InputLabel, IconButton, Button, Drawer, Toolbar, Divider, Select, MenuItem, Grid } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import { Fragment, useState } from 'react';
import { styled } from '@mui/material/styles';
import axios from 'axios';
import Swal from 'sweetalert2';
import 'sweetalert2/src/sweetalert2.scss';
import Finalize from './Finalize';

const drawerWidth = 400;
const drawerHeight = 1050;

// Input values that the form saves to
const surveySettingInputs = {
  briefNumber: "",
  teamMemberInvite: "",
  customerInvite: "",
  title: "",
  projBackground: "",
  projObjective: "",
  atomGenSamples: true,
  atomAnalyzeData: true,
  atomBuildSurvey: true,
  reqTerms: "",
  optTerms: "",
  numSamples: 8,
  device: "",
  dynModeling: false,
  dynSamplePerIter: null,
  maxDynamicIter: null,
  desType: "",
  surveyLang: "",
};

const AdvancedForm = ({ isAuthenticated, access_token }) => {

  const [response, setResponse] = useState(null);

  const navigate = useNavigate();

  const HandleSubmit = async (e) => {

    //e.preventDefault()

    const config = {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `JWT ${access_token}`,
        'Accept': 'application/json'
      }
    };

    const data = {
        "brief_number": surveyInputs.briefNumber,
        "project_title": surveyInputs.title,
        "project_background": surveyInputs.projBackground,
        "project_objective": surveyInputs.projObjective,
        "atom_build_survey": surveyInputs.atomBuildSurvey,
        "variable_names": variable_names,
        "variable_types": variable_types,
        "variable_units": variable_units,
        "variable_descriptions": variable_descriptions,
        "variable_categories": variable_categories,
        "variable_lows": variable_lows,
        "variable_highs": variable_highs,
        "dependent_variables": dependent_variables,
        "constant_names": constant_names,
        "constant_types": constant_types,
        "constant_amounts": constant_amounts,
        "required_terms": surveyInputs.reqTerms,
        "optional_terms": surveyInputs.optTerms,
        "num_samples": surveyInputs.numSamples,
        "model_goals": model_goals,
        "hardware": surveyInputs.device,
        "dynamic_model": surveyInputs.dynModeling,
        "dynamic_sample_per_it": surveyInputs.dynSamplePerIter,
        "max_dynamic_it": surveyInputs.maxDynamicIter,
        "design_type": surveyInputs.desType,
    }

    // Show a loading message
    const swal = Swal.fire({
      title: 'Please wait...',
      text: 'Waiting on your Design of Experiment to build...',
      allowOutsideClick: false,
      backdrop: 'rgba(0, 0, 0, 0.5)',
      didOpen: () => {
        Swal.showLoading();
      }
    });

    const res = await axios.post(`${process.env.REACT_APP_API_URL}/api/create_design`, data, config);

    swal.close();

    setResponse(res.data);

    let doe = res.data.doe;
    
    navigate('/finalize', {state:{doe: doe, variable_names: variable_names, atomBuildSurvey: surveyInputs.atomBuildSurvey }})

  };


  // This is where the inputs are saved to
  const [surveyInputs, setSurveyInputs] = useState(surveySettingInputs);

  // What saves the survey setting values once there is a change in the input field
  const handleSurveySettingChange = (e) => {
    const { name, value } = e.target;
    setSurveyInputs({
      ...surveyInputs,
      [name]: name === "dynSamplePerIter" || name === "maxDynamicIter" || name === "numSamples" ? parseInt(value) : value,
    });
  };

  // This is the list of variables that the user inputs
  const [variablesList, setVariablesList] = useState([
    {
      varName: "", varType: "", description: "", low: 0, high: 1, units: "", numLevels: null, firstLevel: "", secondLevel: "", thirdLevel: "", fourthLevel: "",
      fifthLevel: "", sixthLevel: "", seventhLevel: "", eighthLevel: "", ninthLevel: "", tenthLevel: ""
    },
  ]);

  let addVariableFields = () => {
    setVariablesList([...variablesList, {
      varName: "", varType: "", description: "", low: 0, high: 1, units: "", numLevels: null, firstLevel: "", secondLevel: "", thirdLevel: "", fourthLevel: "",
      fifthLevel: "", sixthLevel: "", seventhLevel: "", eighthLevel: "", ninthLevel: "", tenthLevel: ""
    }])
  }

  let removeVariableFields = (i) => {
    let newVariables = [...variablesList];
    newVariables.splice(i, 1);
    setVariablesList(newVariables);
  }

  // This is what happens when the variables are changed in the variable fields
  const handleVariableChange = (e, index) => {
    let newVariables = [...variablesList];
    if (e.target.name === "low" || e.target.name === "high") {
      newVariables[index][e.target.name] = parseInt(e.target.value);
    } else {
      newVariables[index][e.target.name] = e.target.value;
    }
    setVariablesList(newVariables);
  };

  const [constantsList, setConstantsList] = useState([
    { constName: "", constType: "", constAmount: "" },
  ]);

  let addConstantFields = () => {
    setConstantsList([...constantsList, {
      constName: "", constType: "", constAmount: ""
    }])
  }

  let removeConstantFields = (i) => {
    let newConstants = [...constantsList];
    newConstants.splice(i, 1);
    setConstantsList(newConstants)
  }

  // This is what happens when the constants are changed in the variable fields
  const handleConstantChange = (e, index) => {
    let newConstants = [...constantsList];
    newConstants[index][e.target.name] = e.target.value;
    setConstantsList(newConstants);
  };

  const [indepsList, setIndepsList] = useState([
    { indepName: "", goal: "" },
  ]);

  let addIndepFields = () => {
    setIndepsList([...indepsList, {
      indepName: "", goal: ""
    }])
  }

  let removeIndepFields = (i) => {
    let newIndeps = [...indepsList];
    newIndeps.splice(i, 1);
    setIndepsList(newIndeps)
  }

  // This is what happens when the independent variables are changed in the variable fields
  const handleIndepChange = (e, index) => {
    let newIndeps = [...indepsList];
    newIndeps[index][e.target.name] = e.target.value;
    setIndepsList(newIndeps);
  };

  // Used for the switch changing
  const handleSwitchChange = (e) => {
    setSurveyInputs(
      {
        ...surveyInputs,
        [e.target.name]: e.target.checked
      }
    )
  }

  const Android12Switch = styled(Switch)(({ theme }) => ({
    padding: 8,
    '& .MuiSwitch-track': {
      borderRadius: 22 / 2,
      '&:before, &:after': {
        content: '""',
        position: 'absolute',
        top: '50%',
        transform: 'translateY(-50%)',
        width: 16,
        height: 16,
      },
      '&:before': {
        backgroundImage: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="16" width="16" viewBox="0 0 24 24"><path fill="${encodeURIComponent(
          theme.palette.getContrastText(theme.palette.primary.main),
        )}" d="M21,7L9,19L3.5,13.5L4.91,12.09L9,16.17L19.59,5.59L21,7Z"/></svg>')`,
        left: 12,
      },
      '&:after': {
        backgroundImage: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="16" width="16" viewBox="0 0 24 24"><path fill="${encodeURIComponent(
          theme.palette.getContrastText(theme.palette.primary.main),
        )}" d="M19,13H5V11H19V13Z" /></svg>')`,
        right: 12,
      },
    },
    '& .MuiSwitch-thumb': {
      boxShadow: 'none',
      width: 16,
      height: 16,
      margin: 2,
    },
  }));

  // This next section is how the data is formatted to be inputted into the DB...

  // This puts the dependent variables into the correct format to be inputted
  let variable_names = [], variable_categories = [], variable_types = [], variable_units = [], variable_descriptions = [], variable_lows = [], variable_highs = [];
  let hold = [];
  for (let i = 0; i < variablesList.length; i++) {
    if (variablesList[i].varName !== '') {
      variable_names.push(variablesList[i].varName)
    }
    if (variablesList[i].varType !== '') {
      variable_types.push(variablesList[i].varType);
    }
    variable_descriptions.push(variablesList[i].description);
    if (variablesList[i].varType === 'Continuous') {
        variable_units.push(variablesList[i].units);
        variable_lows.push(variablesList[i].low);
        variable_highs.push(variablesList[i].high);
    } 
    else {
        if (variablesList[i].firstLevel !== '') {
          hold.push(variablesList[i].firstLevel)
        }
        if (variablesList[i].secondLevel !== '') {
          hold.push(variablesList[i].secondLevel)
        }
        if (variablesList[i].thirdLevel !== '') {
          hold.push(variablesList[i].thirdLevel)
        }
        if (variablesList[i].fourthLevel !== '') {
          hold.push(variablesList[i].fourthLevel)
        }
        if (variablesList[i].fifthLevel !== '') {
          hold.push(variablesList[i].fifthLevel)
        }
        if (variablesList[i].sixthLevel !== '') {
          hold.push(variablesList[i].sixthLevel)
        }
        if (variablesList[i].seventhLevel !== '') {
          hold.push(variablesList[i].seventhLevel)
        }
        if (variablesList[i].eighthLevel !== '') {
          hold.push(variablesList[i].eighthLevel)
        }
        if (variablesList[i].ninthLevel !== '') {
          hold.push(variablesList[i].ninthLevel)
        }
        if (variablesList[i].tenthLevel !== '') {
          hold.push(variablesList[i].tenthLevel)
        }
          variable_categories.push(hold);
          hold = [];
      }
    }

  // This puts the constants into the correct format to be put into the DB
  let constant_names = [], constant_types = [], constant_amounts = [];
  for (let i = 0; i < constantsList.length; i++) {
    if (constantsList[i].constName !== '') {
      constant_names.push(constantsList[i].constName);
    }
    if (constantsList[i].constType !== '') {
      constant_types.push(constantsList[i].constType);
    }
    if (constantsList[i].constAmount !== '') {
      constant_amounts.push(constantsList[i].constAmount);
    }
  }

  // This puts the independent variables into the correct format to be put into the DB
  let dependent_variables = [], model_goals = [];
  for (let i=0; i < indepsList.length; i++) {
    if (indepsList[i].indepName !== '') {
      dependent_variables.push(indepsList[i].indepName)
    }
    if (indepsList[i].goal !== '') {
      model_goals.push(indepsList[i].goal);
    }
  }

  // function RenderTextFields(numLevels, element, index) {
  //   let numlevels = props.numLevels;

  //   const levels = [ "firstLevel", "secondLevel", "thirdLevel", "fourthLevel", "fifthLevel", "sixthLevel", "seventhLevel", "eighthLevel", "ninthLevel", "tenthLevel" ];
  //   const labels = [ "1st Level", "2nd Level", "3rd Level", "4th Level", "5th Level", "6th Level", "7th Level", "8th Level", "9th Level", "10th Level"]
  //   let fields = [];

  //   for (let i=0; i < numLevels; i++) {
  //     fields.push(
  //       <>
  //         <TextField
  //           required
  //           sx={{ maxWidth: 140 }}
  //           label={labels[i]}
  //           id={levels[i]}
  //           variant="outlined"
  //           name={levels[i]}
  //           value={element.levels[i]}
  //           onChange={(e) => handleVariableChange(e, index)}
  //         />
  //         <Divider orientation='vertical' sx={{ width: '10px' }} />
  //       </>
  //     );
  //   }
  //   return (
  //     <div>{fields}</div>
  //   );
  // }

  return (
    <Box sx={{ p: 2, display: 'flex' }}>
      {/* The drawer is where all of the survey settings are located */}
      <Drawer
        variant="permanent"
        containerstyle={{ height: 'calc(100%-64px)', top: 64 }}
        sx={{
          width: drawerWidth,
          flexShrink: 0,
          [`& .MuiDrawer-paper`]: { display: 'inline-table', height: drawerHeight, width: drawerWidth, boxSizing: 'border-box', position: 'absolute', zIndex: 0, top: 64, borderRight: '5px solid rgba(0, 0, 0, 0.12)' },
        }}
      >

        {/* This box holds all of the variable input fields. */}
        <Box sx={{ overflow: 'auto', position: 'absolute', top: 0, left: 30 }}>
          <Divider orientation='horizontal' sx={{ height: '10px' }} />
          <h3>Project Settings</h3>
          <Divider orientation='horizontal' sx={{ height: '10px' }} />
          <FormControl>
            <FormGroup>
              <h5>Use ATOM Generated Samples</h5>
              <FormControlLabel
                sx={{ p: 0.5 }}
                control={<Android12Switch checked={surveyInputs.atomGenSamples}
                  onChange={handleSwitchChange} name="atomGenSamples" />}
              />
              <h5>Use ATOM to Analyze Data</h5>
              <FormControlLabel
                sx={{ p: 0.5 }}
                control={<Android12Switch checked={surveyInputs.atomAnalyzeData}
                  onChange={handleSwitchChange} name="atomAnalyzeData" />}
              />
              <h5>Use ATOM to Build Survey</h5>
              <FormControlLabel
                sx={{ p: 0.5 }}
                control={<Android12Switch checked={surveyInputs.atomBuildSurvey}
                  onChange={handleSwitchChange} name="atomBuildSurvey" />}
              />
            </FormGroup>
          </FormControl>
          <Divider orientation='horizontal' sx={{ height: '10px' }} />
          <h5>Required Terms *</h5>
          <div>
            <FormControl sx={{ minWidth: 150 }}>
              <Select
                required
                id="reqTerms"
                value={surveyInputs.reqTerms}
                name="reqTerms"
                onChange={handleSurveySettingChange}
              >
                <MenuItem value="Main Effects">Main Effects</MenuItem>
                <MenuItem value="Main + Interactions">Main + Interactions</MenuItem>
                <MenuItem value="Main + Quadratic">Main + Quadratic</MenuItem>
                <MenuItem value="Main + Interactions + Quadratic">Main + Interactions + Quadratic</MenuItem>
              </Select>
            </FormControl>
          </div>
          <Divider orientation='horizontal' sx={{ height: '20px' }} />
          <h5>Optional Terms *</h5>
          <div>
            <FormControl sx={{ minWidth: 150 }}>
              <Select
                required
                id="optTerms"
                value={surveyInputs.optTerms}
                name="optTerms"
                onChange={handleSurveySettingChange}
              >
                <MenuItem value="Main Effects">Main Effects</MenuItem>
                <MenuItem value="Main + Interactions">Main + Interactions</MenuItem>
                <MenuItem value="Main + Quadratic">Main + Quadratic</MenuItem>
                <MenuItem value="Main + Interactions + Quadratic">Main + Interactions + Quadratic</MenuItem>
              </Select>
            </FormControl>
          </div>
          <Divider orientation='horizontal' sx={{ height: '20px' }} />
          <div>
            <h5>Number of Samples</h5>
            <TextField
              sx={{ maxWidth: 150 }}
              inputProps={{ min: 0 }}
              id="numSamples"
              variant="outlined"
              type="number"
              name="numSamples"
              value={surveyInputs.numSamples}
              onChange={handleSurveySettingChange}
            />
          </div>
          <Divider orientation='horizontal' sx={{ height: '20px' }} />
          <h5>Device Type *</h5>
          <div>
            <FormControl sx={{ minWidth: 150 }}>
              <Select
                required
                id="device"
                value={surveyInputs.device}
                name="device"
                onChange={handleSurveySettingChange}
              >
                <MenuItem value="VAS">VAS</MenuItem>
                <MenuItem value="AromaKiosk">Aroma Kiosk</MenuItem>
                <MenuItem value="AromaSniff">Aroma Sniff</MenuItem>
                <MenuItem value="Other">Other</MenuItem>
              </Select>
            </FormControl>
          </div>
          <Divider orientation='horizontal' sx={{ height: '20px' }} />
          <FormControl>
            <FormGroup>
              <h5>Dynamic Modeling</h5>
              <FormControlLabel
                sx={{ p: 0.5 }}
                control={<Android12Switch checked={surveyInputs.dynModeling}
                  onChange={handleSwitchChange} name="dynModeling" />}
              />
            </FormGroup>
          </FormControl>
          <Divider orientation='horizontal' sx={{ height: '10px' }} />
          {surveyInputs.dynModeling === true ?
            <Fragment>
              <div>
                <h5>Dynamic Samples per Iteration</h5>
                <TextField
                  inputProps={{ inputMode: 'numeric', pattern: '[0-30]*', min: 0 }}
                  id="dynSamplePerIter"
                  variant="outlined"
                  type="number"
                  name="dynSamplePerIter"
                  value={surveyInputs.dynSamplePerIter}
                  onChange={handleSurveySettingChange}
                />
              </div>
              <Divider orientation='horizontal' sx={{ height: '20px' }} />
              <div>
                <h5>Max Dynamic Iteration</h5>
                <TextField
                  inputProps={{ inputMode: 'numeric', pattern: '[0-30]*', min: 0 }}
                  id="maxDynamicIter"
                  variant="outlined"
                  type="number"
                  name="maxDynamicIter"
                  value={surveyInputs.maxDynamicIter}
                  onChange={handleSurveySettingChange}
                />
              </div><Divider orientation='horizontal' sx={{ height: '10px' }} />
            </Fragment>
            : <div></div>}
          <h5>Design Type *</h5>
          <div>
            <FormControl sx={{ minWidth: 150 }}>
              <Select
                required
                id="design-type"
                value={surveyInputs.desType}
                name="desType"
                onChange={handleSurveySettingChange}
              >
                <MenuItem value="Bayesian">Bayesian</MenuItem>
                <MenuItem value="DOE (D-Optimality)">DOE (D-Optimality)</MenuItem>
                <MenuItem value="Definitive Screening">Definitive Screening</MenuItem>
              </Select>
            </FormControl>
          </div>
          <Divider orientation='horizontal' sx={{ height: '20px' }} />
          <h5>Project Language</h5>
          <div>
            <FormControl sx={{ minWidth: 150 }}>
              <Select
                id="surveyLang"
                value={surveyInputs.surveyLang}
                name="surveyLang"
                onChange={handleSurveySettingChange}
              >
                <MenuItem value="English">English</MenuItem>
                <MenuItem value="Spanish">Spanish</MenuItem>
                <MenuItem value="Portugese">Portugese</MenuItem>
                <MenuItem value="French">French</MenuItem>
                <MenuItem value="German">German</MenuItem>
                <MenuItem value="Mandarin">Mandarin</MenuItem>
                <MenuItem value="Japanese">Japanese</MenuItem>
                <MenuItem value="Hindi">Hindi</MenuItem>
                <MenuItem value="Bahasa">Bahasa</MenuItem>
              </Select>
            </FormControl>
          </div>
          <Divider orientation='horizontal' sx={{ height: '30px' }} />
        </Box>
      </Drawer>
      <Toolbar />
      {/* This box holds all of the main page: the variables and the constant. */}
      <Box>
        {/* The variables and constants fields are both within the same Grid container as separate
          Grid items. The items are layed out by row, while the whole container is layed out as
          columns.  */}
        <Grid container direction="column" justifyContent="space-evenly">
          <Grid item justifyContent="space-between" alignItems="flex-start" style={{ display: "flex" }}>
                  <TextField
                    required
                    inputProps={{ min: 0 }}
                    label="Brief Number"
                    id="briefNumber"
                    variant="outlined"
                    name="briefNumber"
                    value={surveyInputs.briefNumber}
                    onChange={handleSurveySettingChange}
                  />
                  <Divider orientation='vertical' sx={{ width: '10px' }} />
                  <TextField
                    label="Team Member Invite (opt)"
                    id="teamMemberInvite"
                    variant="outlined"
                    name="teamMemberInvite"
                    value={surveyInputs.teamMemberInvite}
                    onChange={handleSurveySettingChange}
                  />
                  <Divider orientation='vertical' sx={{ width: '10px' }} />
                  <TextField
                    label="Customer Invite (opt)"
                    id="customerInvite"
                    variant="outlined"
                    name="customerInvite"
                    value={surveyInputs.customerInvite}
                    onChange={handleSurveySettingChange}
                  />
            </Grid>
            <Divider orientation='horizontal' sx={{ height: '10px' }} />
            <Grid item justifyContent="flex-start" alignItems="flex-start" style={{ display: "contents" }}>
                  <h5>Project Title</h5>
                  <Divider orientation='horizontal' sx={{ height: '10px' }} />
                  <TextField
                    required
                    label="Title"
                    id="title"
                    variant="outlined"
                    name="title"
                    value={surveyInputs.title}
                    onChange={handleSurveySettingChange}
                  />
            </Grid>
            <Divider orientation='horizontal' sx={{ height: '10px' }} />
            <Grid item justifyContent="flex-start" alignItems="flex-start" style={{ display: "contents" }}>
                  <h5>Project Background</h5>
                  <Divider orientation='horizontal' sx={{ height: '10px' }} />
                  <TextField
                    required
                    label="Background"
                    multiline
                    rows={4}
                    id="projBackground"
                    variant="outlined"
                    name="projBackground"
                    value={surveyInputs.projBackground}
                    onChange={handleSurveySettingChange}
                  />
            </Grid>
            <Divider orientation='horizontal' sx={{ height: '10px' }} />
            <Grid item justifyContent="flex-start" alignItems="flex-start" style={{ display: "contents" }}>
                  <h5>Project Objective</h5>
                  <Divider orientation='horizontal' sx={{ height: '10px' }} />
                  <TextField
                    required
                    label="Objective"
                    id="projObjective"
                    variant="outlined"
                    name="projObjective"
                    value={surveyInputs.projObjective}
                    onChange={handleSurveySettingChange}
                  />
            </Grid>
        </Grid>
        <Grid container direction="column" justifyContent="space-evenly" alignItems="flex-start">
          <Divider orientation='horizontal' sx={{ height: '10px' }} />
          {/* The variableList mapping allows for the variables to be changed one by one depending on how
          many are in the current variablesList object. */}
          <h5>Dependent Variables (what feeds the modeling)</h5>
          {/* Dividers are being used for spacing on this page */}
          <Divider orientation='horizontal' sx={{ height: '10px' }} />
          {variablesList.map((element, index) => (
            <div key={index}>
              <Grid item justifyContent="flex-start" alignItems="flex-start" style={{ display: "flex" }}>
                <TextField
                  required
                  label="Variable Name"
                  id="varName"
                  variant="outlined"
                  name="varName"
                  value={element.varName}
                  onChange={(e) => handleVariableChange(e, index)}
                />
                <Divider orientation='vertical' sx={{ width: '10px' }} />
                <FormControl sx={{ minWidth: 150 }}>
                  <InputLabel variant="outlined" id="variable-type-label">Type *</InputLabel>
                  <Select
                    required
                    label="Type *"
                    labelId="variable-type-label"
                    id="variable-type"
                    name="varType"
                    value={element.varType}
                    onChange={(e) => handleVariableChange(e, index)}
                    autoWidth
                  >
                    <MenuItem value="Continuous" id="0">Continuous</MenuItem>
                    <MenuItem value="Discrete" id="1">Discrete</MenuItem>
                    <MenuItem value="Categorical" id="2">Categorical</MenuItem>
                  </Select>
                </FormControl>
                <Divider orientation='vertical' sx={{ width: '10px' }} />
                <TextField
                  label="Description"
                  id="description"
                  variant="outlined"
                  name="description"
                  value={element.description}
                  onChange={(e) => handleVariableChange(e, index)}
                  sx={{ minWidth: 250 }}
                />
                {/* This is where the conditional input fields are called (continuous, discrete, and categorical)*/}
                <Divider orientation='vertical' sx={{ width: '10px' }} />
                {element.varType === 'Continuous' ?
                  <Fragment>
                    {element.numLevels = ""}{element.firstLevel = ""}{element.secondLevel = ""}{element.thirdLevel = ""}{element.fourthLevel = ""}
                    {element.fifthLevel = ""}{element.sixthLevel = ""}{element.seventhLevel = ""}{element.eighthLevel = ""}{element.ninthLevel = ""}{element.tenthLevel = ""}
                    {element.numLevels > 0 ? element.numLevels = 0 : null}
                    <TextField
                      sx={{ maxWidth: 140 }}
                      label="Low Level"
                      required
                      inputProps={{ min: 0 }}
                      id="low"
                      variant="outlined"
                      type="number"
                      name="low"
                      value={element.low}
                      onChange={(e) => handleVariableChange(e, index)}
                    />
                    <Divider orientation='vertical' sx={{ width: '10px' }} />
                    <TextField
                      sx={{ maxWidth: 140 }}
                      required
                      inputProps={{ min: element.low+1 }}
                      label="High Level"
                      id="high"
                      variant="outlined"
                      type="number"
                      name="high"
                      value={element.high}
                      onChange={(e) => handleVariableChange(e, index)}
                    /><Divider orientation='vertical' sx={{ width: '10px' }} />
                    <TextField
                      sx={{ maxWidth: 140 }}
                      label="Units"
                      id="units"
                      variant="outlined"
                      name="units"
                      value={element.units}
                      onChange={(e) => handleVariableChange(e, index)}
                    /></Fragment>
                  : <div></div>}
                {element.varType === 'Discrete' || element.varType === 'Categorical' ?
                  <Fragment>
                    {element.low = ""}{element.high = ""}{element.units = ""}
                    <TextField
                      sx={{ maxWidth: 140 }}
                      label="# of Levels"
                      required
                      inputProps={{ min: 2 }}
                      id="num-of-levels"
                      variant="outlined"
                      type="number"
                      name="numLevels"
                      value={element.numLevels}
                      onChange={(e) => handleVariableChange(e, index)}
                    /><Divider orientation='vertical' sx={{ width: '10px' }} /></Fragment>: <div></div>}
                    {/* <RenderTextFields numFields={element.numLevels} element={element} index={index} /> */}
                {element.numLevels === '2' ?
                    <Fragment>
                      <TextField
                        required
                        sx={{ maxWidth: 140 }}
                        label="1st Level"
                        id="firstLevel"
                        variant="outlined"
                        name="firstLevel"
                        value={element.firstLevel}
                        onChange={(e) => handleVariableChange(e, index)}
                      />
                      <Divider orientation='vertical' sx={{ width: '10px' }} />
                      <TextField
                        sx={{ maxWidth: 140 }}
                        label="2nd Level"
                        id="secondLevel"
                        variant="outlined"
                        name="secondLevel"
                        value={element.secondLevel}
                        onChange={(e) => handleVariableChange(e, index)}
                      /><Divider orientation='vertical' sx={{ width: '10px' }} /></Fragment> :
                    element.numLevels === '3' ?
                      <Fragment>
                        <TextField
                          required
                          sx={{ maxWidth: 140 }}
                          label="1st Level"
                          id="firstLevel"
                          variant="outlined"
                          name="firstLevel"
                          value={element.firstLevel}
                          onChange={(e) => handleVariableChange(e, index)}
                        /><Divider orientation='vertical' sx={{ width: '10px' }} />
                        <TextField
                          sx={{ maxWidth: 140 }}
                          label="2nd Level"
                          id="secondLevel"
                          variant="outlined"
                          name="secondLevel"
                          value={element.secondLevel}
                          onChange={(e) => handleVariableChange(e, index)}
                        /><Divider orientation='vertical' sx={{ width: '10px' }} />
                        <TextField
                          sx={{ maxWidth: 140 }}
                          label="3rd Level"
                          id="thirdLevel"
                          variant="outlined"
                          name="thirdLevel"
                          value={element.thirdLevel}
                          onChange={(e) => handleVariableChange(e, index)}
                        /><Divider orientation='vertical' sx={{ width: '10px' }} />
                      </Fragment> : element.numLevels === '4' ?
                        <Fragment>
                          <TextField
                            required
                            sx={{ maxWidth: 140 }}
                            label="1st Level"
                            id="firstLevel"
                            variant="outlined"
                            name="firstLevel"
                            value={element.firstLevel}
                            onChange={(e) => handleVariableChange(e, index)}
                          /><Divider orientation='vertical' sx={{ width: '10px' }} />
                          <TextField
                            sx={{ maxWidth: 140 }}
                            label="2nd Level"
                            id="secondLevel"
                            variant="outlined"
                            name="secondLevel"
                            value={element.secondLevel}
                            onChange={(e) => handleVariableChange(e, index)}
                          /><Divider orientation='vertical' sx={{ width: '10px' }} />
                          <TextField
                            sx={{ maxWidth: 140 }}
                            label="3rd Level"
                            id="thirdLevel"
                            variant="outlined"
                            name="thirdLevel"
                            value={element.thirdLevel}
                            onChange={(e) => handleVariableChange(e, index)}
                          /><Divider orientation='vertical' sx={{ width: '10px' }} />
                          <TextField
                            sx={{ maxWidth: 140 }}
                            label="4th Level"
                            id="fourthLevel"
                            variant="outlined"
                            name="fourthLevel"
                            value={element.fourthLevel}
                            onChange={(e) => handleVariableChange(e, index)}
                          /><Divider orientation='vertical' sx={{ width: '10px' }} />
                        </Fragment> : element.numLevels === '5' ?
                          <Fragment>
                            <TextField
                              required
                              sx={{ maxWidth: 140 }}
                              label="1st Level"
                              id="firstLevel"
                              variant="outlined"
                              name="firstLevel"
                              value={element.firstLevel}
                              onChange={(e) => handleVariableChange(e, index)}
                            /><Divider orientation='vertical' sx={{ width: '10px' }} />
                            <TextField
                              sx={{ maxWidth: 140 }}
                              label="2nd Level"
                              id="secondLevel"
                              variant="outlined"
                              name="secondLevel"
                              value={element.secondLevel}
                              onChange={(e) => handleVariableChange(e, index)}
                            /><Divider orientation='vertical' sx={{ width: '10px' }} />
                            <TextField
                              sx={{ maxWidth: 140 }}
                              label="3rd Level"
                              id="thirdLevel"
                              variant="outlined"
                              name="thirdLevel"
                              value={element.thirdLevel}
                              onChange={(e) => handleVariableChange(e, index)}
                            /><Divider orientation='vertical' sx={{ width: '10px' }} />
                            <TextField
                              sx={{ maxWidth: 140 }}
                              label="4th Level"
                              id="fourthLevel"
                              variant="outlined"
                              name="fourthLevel"
                              value={element.fourthLevel}
                              onChange={(e) => handleVariableChange(e, index)}
                            /><Divider orientation='vertical' sx={{ width: '10px' }} />
                            <TextField
                              sx={{ maxWidth: 140 }}
                              label="5th Level"
                              id="fifthLevel"
                              variant="outlined"
                              name="fifthLevel"
                              value={element.fifthLevel}
                              onChange={(e) => handleVariableChange(e, index)}
                            /><Divider orientation='vertical' sx={{ width: '10px' }} />
                          </Fragment> : element.numLevels === '6' ?
                            <Fragment>
                              <TextField
                                required
                                sx={{ maxWidth: 140 }}
                                label="1st Level"
                                id="firstLevel"
                                variant="outlined"
                                name="firstLevel"
                                value={element.firstLevel}
                                onChange={(e) => handleVariableChange(e, index)}
                              /><Divider orientation='vertical' sx={{ width: '10px' }} />
                              <TextField
                                sx={{ maxWidth: 140 }}
                                label="2nd Level"
                                id="secondLevel"
                                variant="outlined"
                                name="secondLevel"
                                value={element.secondLevel}
                                onChange={(e) => handleVariableChange(e, index)}
                              /><Divider orientation='vertical' sx={{ width: '10px' }} />
                              <TextField
                                sx={{ maxWidth: 140 }}
                                label="3rd Level"
                                id="thirdLevel"
                                variant="outlined"
                                name="thirdLevel"
                                value={element.thirdLevel}
                                onChange={(e) => handleVariableChange(e, index)}
                              /><Divider orientation='vertical' sx={{ width: '10px' }} />
                              <TextField
                                sx={{ maxWidth: 140 }}
                                label="4th Level"
                                id="fourthLevel"
                                variant="outlined"
                                name="fourthLevel"
                                value={element.fourthLevel}
                                onChange={(e) => handleVariableChange(e, index)}
                              /><Divider orientation='vertical' sx={{ width: '10px' }} />
                              <TextField
                                sx={{ maxWidth: 140 }}
                                label="5th Level"
                                id="fifthLevel"
                                variant="outlined"
                                name="fifthLevel"
                                value={element.fifthLevel}
                                onChange={(e) => handleVariableChange(e, index)}
                              /><Divider orientation='vertical' sx={{ width: '10px' }} />
                              <TextField
                                sx={{ maxWidth: 140 }}
                                label="6th Level"
                                id="sixthLevel"
                                variant="outlined"
                                name="sixthLevel"
                                value={element.sixthLevel}
                                onChange={(e) => handleVariableChange(e, index)}
                              /><Divider orientation='vertical' sx={{ width: '10px' }} />
                            </Fragment> : element.numLevels === '7' ?
                              <Fragment>
                                <TextField
                                  required
                                  sx={{ maxWidth: 140 }}
                                  label="1st Level"
                                  id="firstLevel"
                                  variant="outlined"
                                  name="firstLevel"
                                  value={element.firstLevel}
                                  onChange={(e) => handleVariableChange(e, index)}
                                /><Divider orientation='vertical' sx={{ width: '10px' }} />
                                <TextField
                                  sx={{ maxWidth: 140 }}
                                  label="2nd Level"
                                  id="secondLevel"
                                  variant="outlined"
                                  name="secondLevel"
                                  value={element.secondLevel}
                                  onChange={(e) => handleVariableChange(e, index)}
                                /><Divider orientation='vertical' sx={{ width: '10px' }} />
                                <TextField
                                  sx={{ maxWidth: 140 }}
                                  label="3rd Level"
                                  id="thirdLevel"
                                  variant="outlined"
                                  name="thirdLevel"
                                  value={element.thirdLevel}
                                  onChange={(e) => handleVariableChange(e, index)}
                                /><Divider orientation='vertical' sx={{ width: '10px' }} />
                                <TextField
                                  sx={{ maxWidth: 140 }}
                                  label="4th Level"
                                  id="fourthLevel"
                                  variant="outlined"
                                  name="fourthLevel"
                                  value={element.fourthLevel}
                                  onChange={(e) => handleVariableChange(e, index)}
                                /><Divider orientation='vertical' sx={{ width: '10px' }} />
                                <TextField
                                  sx={{ maxWidth: 140 }}
                                  label="5th Level"
                                  id="fifthLevel"
                                  variant="outlined"
                                  name="fifthLevel"
                                  value={element.fifthLevel}
                                  onChange={(e) => handleVariableChange(e, index)}
                                /><Divider orientation='vertical' sx={{ width: '10px' }} />
                                <TextField
                                  sx={{ maxWidth: 140 }}
                                  label="6th Level"
                                  id="sixthLevel"
                                  variant="outlined"
                                  name="sixthLevel"
                                  value={element.sixthLevel}
                                  onChange={(e) => handleVariableChange(e, index)}
                                /><Divider orientation='vertical' sx={{ width: '10px' }} />
                                <TextField
                                  sx={{ maxWidth: 140 }}
                                  label="7th Level"
                                  id="seventhLevel"
                                  variant="outlined"
                                  name="seventhLevel"
                                  value={element.seventhLevel}
                                  onChange={(e) => handleVariableChange(e, index)}
                                /><Divider orientation='vertical' sx={{ width: '10px' }} />
                              </Fragment> : element.numLevels === '8' ?
                                <Fragment>
                                  <TextField
                                    required
                                    sx={{ maxWidth: 140 }}
                                    label="1st Level"
                                    id="firstLevel"
                                    variant="outlined"
                                    name="firstLevel"
                                    value={element.firstLevel}
                                    onChange={(e) => handleVariableChange(e, index)}
                                  /><Divider orientation='vertical' sx={{ width: '10px' }} />
                                  <TextField
                                    sx={{ maxWidth: 140 }}
                                    label="2nd Level"
                                    id="secondLevel"
                                    variant="outlined"
                                    name="secondLevel"
                                    value={element.secondLevel}
                                    onChange={(e) => handleVariableChange(e, index)}
                                  /><Divider orientation='vertical' sx={{ width: '10px' }} />
                                  <TextField
                                    sx={{ maxWidth: 140 }}
                                    label="3rd Level"
                                    id="thirdLevel"
                                    variant="outlined"
                                    name="thirdLevel"
                                    value={element.thirdLevel}
                                    onChange={(e) => handleVariableChange(e, index)}
                                  /><Divider orientation='vertical' sx={{ width: '10px' }} />
                                  <TextField
                                    sx={{ maxWidth: 140 }}
                                    label="4th Level"
                                    id="fourthLevel"
                                    variant="outlined"
                                    name="fourthLevel"
                                    value={element.fourthLevel}
                                    onChange={(e) => handleVariableChange(e, index)}
                                  /><Divider orientation='vertical' sx={{ width: '10px' }} />
                                  <TextField
                                    sx={{ maxWidth: 140 }}
                                    label="5th Level"
                                    id="fifthLevel"
                                    variant="outlined"
                                    name="fifthLevel"
                                    value={element.fifthLevel}
                                    onChange={(e) => handleVariableChange(e, index)}
                                  /><Divider orientation='vertical' sx={{ width: '10px' }} />
                                  <TextField
                                    sx={{ maxWidth: 140 }}
                                    label="6th Level"
                                    id="sixthLevel"
                                    variant="outlined"
                                    name="sixthLevel"
                                    value={element.sixthLevel}
                                    onChange={(e) => handleVariableChange(e, index)}
                                  /><Divider orientation='vertical' sx={{ width: '10px' }} />
                                  <TextField
                                    sx={{ maxWidth: 140 }}
                                    label="7th Level"
                                    id="seventhLevel"
                                    variant="outlined"
                                    name="seventhLevel"
                                    value={element.seventhLevel}
                                    onChange={(e) => handleVariableChange(e, index)}
                                  /><Divider orientation='vertical' sx={{ width: '10px' }} />
                                  <TextField
                                    sx={{ maxWidth: 140 }}
                                    label="8th Level"
                                    id="eighthLevel"
                                    variant="outlined"
                                    name="eighthLevel"
                                    value={element.eighthLevel}
                                    onChange={(e) => handleVariableChange(e, index)}
                                  /><Divider orientation='vertical' sx={{ width: '10px' }} />
                                </Fragment> : element.numLevels === '9' ?
                                  <Fragment>
                                    <TextField
                                      required
                                      sx={{ maxWidth: 140 }}
                                      label="1st Level"
                                      id="firstLevel"
                                      variant="outlined"
                                      name="firstLevel"
                                      value={element.firstLevel}
                                      onChange={(e) => handleVariableChange(e, index)}
                                    /><Divider orientation='vertical' sx={{ width: '10px' }} />
                                    <TextField
                                      sx={{ maxWidth: 140 }}
                                      label="2nd Level"
                                      id="secondLevel"
                                      variant="outlined"
                                      name="secondLevel"
                                      value={element.secondLevel}
                                      onChange={(e) => handleVariableChange(e, index)}
                                    /><Divider orientation='vertical' sx={{ width: '10px' }} />
                                    <TextField
                                      sx={{ maxWidth: 140 }}
                                      label="3rd Level"
                                      id="thirdLevel"
                                      variant="outlined"
                                      name="thirdLevel"
                                      value={element.thirdLevel}
                                      onChange={(e) => handleVariableChange(e, index)}
                                    /><Divider orientation='vertical' sx={{ width: '10px' }} />
                                    <TextField
                                      sx={{ maxWidth: 140 }}
                                      label="4th Level"
                                      id="fourthLevel"
                                      variant="outlined"
                                      name="fourthLevel"
                                      value={element.fourthLevel}
                                      onChange={(e) => handleVariableChange(e, index)}
                                    /><Divider orientation='vertical' sx={{ width: '10px' }} />
                                    <TextField
                                      sx={{ maxWidth: 140 }}
                                      label="5th Level"
                                      id="fifthLevel"
                                      variant="outlined"
                                      name="fifthLevel"
                                      value={element.fifthLevel}
                                      onChange={(e) => handleVariableChange(e, index)}
                                    /><Divider orientation='vertical' sx={{ width: '10px' }} />
                                    <TextField
                                      sx={{ maxWidth: 140 }}
                                      label="6th Level"
                                      id="sixthLevel"
                                      variant="outlined"
                                      name="sixthLevel"
                                      value={element.sixthLevel}
                                      onChange={(e) => handleVariableChange(e, index)}
                                    /><Divider orientation='vertical' sx={{ width: '10px' }} />
                                    <TextField
                                      sx={{ maxWidth: 140 }}
                                      label="7th Level"
                                      id="seventhLevel"
                                      variant="outlined"
                                      name="seventhLevel"
                                      value={element.seventhLevel}
                                      onChange={(e) => handleVariableChange(e, index)}
                                    /><Divider orientation='vertical' sx={{ width: '10px' }} />
                                    <TextField
                                      sx={{ maxWidth: 140 }}
                                      label="8th Level"
                                      id="eighthLevel"
                                      variant="outlined"
                                      name="eighthLevel"
                                      value={element.eighthLevel}
                                      onChange={(e) => handleVariableChange(e, index)}
                                    /><Divider orientation='vertical' sx={{ width: '10px' }} />
                                    <TextField
                                      sx={{ maxWidth: 140 }}
                                      label="9th Level"
                                      id="ninthLevel"
                                      variant="outlined"
                                      name="ninthLevel"
                                      value={element.ninthLevel}
                                      onChange={(e) => handleVariableChange(e, index)}
                                    /><Divider orientation='vertical' sx={{ width: '10px' }} />
                                  </Fragment> : element.numLevels === '10' ?
                                    <Fragment>
                                      <TextField
                                        required
                                        sx={{ maxWidth: 140 }}
                                        label="1st Level"
                                        id="firstLevel"
                                        variant="outlined"
                                        name="firstLevel"
                                        value={element.firstLevel}
                                        onChange={(e) => handleVariableChange(e, index)}
                                      /><Divider orientation='vertical' sx={{ width: '10px' }} />
                                      <TextField
                                        sx={{ maxWidth: 140 }}
                                        label="2nd Level"
                                        id="secondLevel"
                                        variant="outlined"
                                        name="secondLevel"
                                        value={element.secondLevel}
                                        onChange={(e) => handleVariableChange(e, index)}
                                      /><Divider orientation='vertical' sx={{ width: '10px' }} />
                                      <TextField
                                        sx={{ maxWidth: 140 }}
                                        label="3rd Level"
                                        id="thirdLevel"
                                        variant="outlined"
                                        name="thirdLevel"
                                        value={element.thirdLevel}
                                        onChange={(e) => handleVariableChange(e, index)}
                                      /><Divider orientation='vertical' sx={{ width: '10px' }} />
                                      <TextField
                                        sx={{ maxWidth: 140 }}
                                        label="4th Level"
                                        id="fourthLevel"
                                        variant="outlined"
                                        name="fourthLevel"
                                        value={element.fourthLevel}
                                        onChange={(e) => handleVariableChange(e, index)}
                                      /><Divider orientation='vertical' sx={{ width: '10px' }} />
                                      <TextField
                                        sx={{ maxWidth: 140 }}
                                        label="5th Level"
                                        id="fifthLevel"
                                        variant="outlined"
                                        name="fifthLevel"
                                        value={element.fifthLevel}
                                        onChange={(e) => handleVariableChange(e, index)}
                                      /><Divider orientation='vertical' sx={{ width: '10px' }} />
                                      <TextField
                                        sx={{ maxWidth: 140 }}
                                        label="6th Level"
                                        id="sixthLevel"
                                        variant="outlined"
                                        name="sixthLevel"
                                        value={element.sixthLevel}
                                        onChange={(e) => handleVariableChange(e, index)}
                                      /><Divider orientation='vertical' sx={{ width: '10px' }} />
                                      <TextField
                                        sx={{ maxWidth: 140 }}
                                        label="7th Level"
                                        id="seventhLevel"
                                        variant="outlined"
                                        name="seventhLevel"
                                        value={element.seventhLevel}
                                        onChange={(e) => handleVariableChange(e, index)}
                                      /><Divider orientation='vertical' sx={{ width: '10px' }} />
                                      <TextField
                                        sx={{ maxWidth: 140 }}
                                        label="8th Level"
                                        id="eighthLevel"
                                        variant="outlined"
                                        name="eighthLevel"
                                        value={element.eighthLevel}
                                        onChange={(e) => handleVariableChange(e, index)}
                                      /><Divider orientation='vertical' sx={{ width: '10px' }} />
                                      <TextField
                                        sx={{ maxWidth: 140 }}
                                        label="9th Level"
                                        id="ninthLevel"
                                        variant="outlined"
                                        name="ninthLevel"
                                        value={element.ninthLevel}
                                        onChange={(e) => handleVariableChange(e, index)}
                                      /><Divider orientation='vertical' sx={{ width: '10px' }} />
                                      <TextField
                                        sx={{ maxWidth: 140 }}
                                        label="10th Level"
                                        id="tenthLevel"
                                        variant="outlined"
                                        name="tenthLevel"
                                        value={element.tenthLevel}
                                        onChange={(e) => handleVariableChange(e, index)}
                                      /><Divider orientation='vertical' sx={{ width: '10px' }} />
                                    </Fragment>
                                    : <div></div>}
                {variablesList.length !== 1 &&
                  (<IconButton sx={{ display: 'block'}} aria-label="delete" className="remove-btn" onClick={() => removeVariableFields(index)}>
                    <DeleteIcon />
                  </IconButton>)}
              </Grid>
              <Divider orientation='horizontal' sx={{ height: '10px' }} />
              <Grid item justifyContent="start" alignItems="flex-start" style={{ display: "flex" }}>
                {variablesList.length - 1 === index &&
                  (<Button variant="contained" className="add-btn" onClick={() => addVariableFields()} size="large">
                    Add Variable
                  </Button>)}
              </Grid>
              <Divider orientation='horizontal' sx={{ height: '10px' }} />
            </div>
          ))}
          {/* These are the input fields for the constant input page */}
          <h5>Constants</h5>
          <Divider orientation='horizontal' sx={{ height: '10px' }} />
          {constantsList.map((element, index) => (
            <div key={index}>
              <Grid item justifyContent="flex-start" alignItems="flex-start" style={{ display: "flex" }}>
                <TextField
                  label="Constant Name"
                  id="constName"
                  variant="outlined"
                  name="constName"
                  value={element.constName}
                  onChange={(e) => handleConstantChange(e, index)}
                />
                <Divider orientation='vertical' sx={{ width: '10px' }} />
                <FormControl sx={{ minWidth: 150 }}>
                  <InputLabel variant="outlined" id="constant-type-label">Constant Type</InputLabel>
                  <Select
                    label="Constant Type"
                    labelId="constant-type-label"
                    id="constant-type"
                    value={element.constType}
                    name="constType"
                    onChange={(e) => handleConstantChange(e, index)}
                    autoWidth
                  >
                    <MenuItem value="Numeric" id="0">Numeric</MenuItem>
                    <MenuItem value="Discrete" id="1">Discrete</MenuItem>
                    <MenuItem value="Categorical" id="2">Categorical</MenuItem>
                  </Select>
                </FormControl>
                <Divider orientation='vertical' sx={{ width: '10px' }} />
                <TextField
                  inputProps={{ min: 0 }}
                  label="Constant Amount"
                  id="constAmount"
                  variant="outlined"
                  type="number"
                  name="constAmount"
                  value={element.constAmount}
                  onChange={(e) => handleConstantChange(e, index)}
                />
                {constantsList.length !== 1 &&
                  (<IconButton sx={{ display: 'block'}} aria-label="delete" className="remove-btn" onClick={() => removeConstantFields(index)}>
                    <DeleteIcon />
                  </IconButton>)}
              </Grid>
              <Divider orientation='horizontal' sx={{ height: '10px' }} />
              <Grid item justifyContent="start" alignItems="flex-start" style={{ display: "flex" }}>
                {constantsList.length - 1 === index &&
                  (<Button variant="contained" className="add-btn" onClick={() => addConstantFields()} size="large">
                    Add Constant
                  </Button>)}
              </Grid>
            </div>
          ))}
          <Divider orientation='horizontal' sx={{ height: '10px' }} />
          {/* These are the input fields for the independent vairable input page */}
          <h5>Independent Variables (what are you trying to accomplish?)</h5>
          <Divider orientation='horizontal' sx={{ height: '10px' }} />
          {indepsList.map((element, index) => (
            <div key={index}>
              <Grid item justifyContent="flex-start" alignItems="flex-start" style={{ display: "flex" }}>
                <TextField
                  required
                  label="Variable Name"
                  id="indepName"
                  variant="outlined"
                  name="indepName"
                  value={element.indepName}
                  onChange={(e) => handleIndepChange(e, index)}
                />
                <Divider orientation='vertical' sx={{ width: '10px' }} />
                <FormControl sx={{ minWidth: 160 }}>
                  <InputLabel variant="outlined" id="goal-label">Goal *</InputLabel>
                  <Select
                    required
                    label="Goal *"
                    labelId="goal-label"
                    id="goal"
                    value={element.goal}
                    name="goal"
                    onChange={(e) => handleIndepChange(e, index)}
                    autoWidth
                  >
                    <MenuItem value="Minimize" id="0">Minimize</MenuItem>
                    <MenuItem value="Target" id="1">Target</MenuItem>
                    <MenuItem value="Maximize" id="2">Maximize</MenuItem>
                  </Select>
                </FormControl>
                {indepsList.length !== 1 &&
                  (<IconButton sx={{ display: 'block'}} aria-label="delete" className="remove-btn" onClick={() => removeIndepFields(index)}>
                    <DeleteIcon />
                  </IconButton>)}
              </Grid>
              <Divider orientation='horizontal' sx={{ height: '10px' }} />
              <Grid item justifyContent="start" alignItems="flex-start" style={{ display: "flex" }}>
                {indepsList.length - 1 === index &&
                  (<Button variant="contained" className="add-btn" onClick={() => addIndepFields()} size="large">
                    Add Variable
                  </Button>)}
              </Grid>
            </div>
          ))}
          <Divider orientation='horizontal' sx={{ height: '10px' }} />
          <Grid item justifyContent="start" alignItems="flex-start" style={{ display: "flex" }}>
            <Button type="submit" variant="contained" className="submit-btn" onClick={(e) => HandleSubmit(e)} size="large">
              Submit
            </Button>
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
}

const mapStateToProps = state => ({
  isAuthenticated: state.auth.isAuthenticated,
  access_token: state.auth.access
});

export default connect(mapStateToProps, {})(AdvancedForm);