import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom'
import PrivateRoute from './containers/accounts/PrivateRoute'
import Home from './containers/general/Home'
import Login from './containers/accounts/Login'
import Signup from './containers/accounts/Signup'
import Activate from './containers/accounts/Activate'
import ResetPassword from './containers/accounts/ResetPassword'
import ResetPasswordConfirm from './containers/accounts/ResetPasswordConfirm'

import Dashboard from './containers/general/Dashboard'
import LearnMore from './containers/general/LearnMore'
import Search from './containers/general/Search'

import Design from './containers/survey/Design'
import ValidateEmail from './containers/accounts/ValidateEmail.js'
import SurveyList from './containers/survey/administer/SurveyList'
import Administer from './containers/survey/administer/Administer'
import Analyze from './containers/analyze/Analyze'
import ProjectDetails from './containers/analyze/ProjectDetails'
import MySurveys from './containers/general/MySurveys'
import Replacement from './containers/survey/Replacement'
import Optimization from './containers/survey/Optimization'
import Matching from './containers/survey/Matching'
import FlavorDirections from './containers/survey/FlavorDirections'
import BasicCarousel from './containers/survey/BasicCarousel'
import AdvancedForm from './containers/survey/AdvancedForm'
import Finalize from './containers/survey/Finalize';
import Create from './containers/survey/Create';
import CreateAdvanced from './containers/survey/create/advanced/CreateAdvanced';
import CreateBasic from './containers/survey/create/basic/CreateBasic';
import CreateExisting from './containers/survey/create/existing/CreateExisting';
import { Provider } from 'react-redux';
import store from './store';
import Layout from './hocs/Layout'

import AnalyzeMilk from './containers/analyze/AnalyzeMilk';
import AnalyzeKCl from './containers/analyze/AnalyzeKCl';
import ISDPrime from './containers/analyze/Integrated_Solutions/ISDPrime';
import Interactions from './containers/analyze/charts/Interactions';
import ISColor from './containers/analyze/Integrated_Solutions/ISColor';
import ISPhysicalStability from './containers/analyze/Integrated_Solutions/ISPhyicalStability';
import AdministerPanelist from "./containers/survey/administer/AdministerPanelist";


const App = () => (

    <Provider store={store}> 
        <Router>
            <Routes>
                {/* Routes without layout */}
                <Route path="/administerPanelist" element={<AdministerPanelist/>}/>

                {/* Routes with layout */}
                <Route path="*" element={<Layout>
                    <Routes>
                        {/* user auth routes PUBLIC */}
                        <Route path="/" element={<PrivateRoute><Dashboard /></ PrivateRoute>} />
                        <Route path="/unauth" element={<Home />} />
                        <Route path="/login" element={<Login />} />
                        <Route path="/signup" element={<Signup />} />
                        <Route path="/validate-email" element={<ValidateEmail />} />
                        <Route path="/reset-password" element={<ResetPassword />} />
                        <Route path="/password/reset/confirm/:uid/:token" element={<ResetPasswordConfirm />} />
                        <Route path="/activate/:uid/:token" element={<Activate />} />
                        <Route path="/advanced" element={<AdvancedForm />} />
                        <Route path="/learn-more" element={<LearnMore />} />
                        <Route path="/finalize" element={<Finalize />} />
                        <Route path="/administer" element={<Administer />} />
                        {/* <Route path="/survey/create/advanced" element={<Create />} /> */}
                        {/* <Route path="/survey/create/advanced" element={<CreateAdvanced />} /> */}

                        {/* design routes PRIVATE */}
                        <Route path="/survey/advanced" element={<PrivateRoute><AdvancedForm /></PrivateRoute>} />
                        <Route path="/survey" element={<PrivateRoute><Design /></PrivateRoute>} />
                        <Route path="/survey_list" element={<PrivateRoute><SurveyList /></PrivateRoute>} />

                        {/* analyze routes PRIVATE */}
                        <Route path="/analyze/details" element={<PrivateRoute><ProjectDetails /></PrivateRoute>} />

                        {/* <Route path="/administer" element={<PrivateRoute><Administer /></PrivateRoute>} /> */}
                        <Route path="/analyze/analyze" element={<PrivateRoute><Analyze /></PrivateRoute>} />
                        <Route path="/survey/replacement" element={<PrivateRoute><Replacement /></PrivateRoute>} />
                        <Route path="/survey/optimization" element={<PrivateRoute><Optimization /></PrivateRoute>} />
                        <Route path="/survey/matching" element={<PrivateRoute><Matching /></PrivateRoute>} />

                        <Route path="/survey/flavor-directions" element={<PrivateRoute><FlavorDirections /></PrivateRoute>} />
                        {/* <Route path="/survey/finalize" element={<PrivateRoute><Finalize /></PrivateRoute>} /> */}
                        <Route path="/survey/create" element={<PrivateRoute><Design /></PrivateRoute>} />
                        <Route path="/survey/create/advanced" element={<PrivateRoute><CreateAdvanced /></PrivateRoute>} />
                        <Route path="/survey/create/basic" element={<PrivateRoute><CreateBasic /></PrivateRoute>} />
                        <Route path="/survey/create/open_existing" element={<PrivateRoute><CreateExisting/></PrivateRoute>} />


                        <Route path="/survey/replacement/basic" element={<PrivateRoute><BasicCarousel /></PrivateRoute>} />
                        <Route path="/survey/replacement/advanced" element={<PrivateRoute><AdvancedForm /></PrivateRoute>} />
                        <Route path="/survey/optimization/basic" element={<PrivateRoute><BasicCarousel /></PrivateRoute>} />
                        <Route path="/survey/optimization/advanced" element={<PrivateRoute><AdvancedForm /></PrivateRoute>} />
                        <Route path="/survey/matching/basic" element={<PrivateRoute><BasicCarousel /></PrivateRoute>} />
                        <Route path="/survey/matching/advanced" element={<PrivateRoute><AdvancedForm /></PrivateRoute>} />
                        <Route path="/survey/flavor-directions/basic" element={<PrivateRoute><BasicCarousel /></PrivateRoute>} />
                        <Route path="/survey/flavor-directions/advanced" element={<PrivateRoute><AdvancedForm /></PrivateRoute>} />


                        {/* general routes available after user login PRIVATE */}
                        <Route path="/dashboard" element={<PrivateRoute><Dashboard /></PrivateRoute>} />
                        <Route path="/my_surveys" element={<PrivateRoute><MySurveys /></PrivateRoute>} />
                        <Route path="/search" element={<PrivateRoute><Search /></PrivateRoute>} />

                        {/* analyze routes PRIVATE for analyze testing*/}
                        <Route path="/milk" element={<AnalyzeMilk />} />
                        <Route path="/kcl" element={<AnalyzeKCl />} />
                        <Route path="/is_dprime" element={<ISDPrime />} />
                        <Route path="/is_color" element={<ISColor />} />
                        <Route path="/is_physical_stability" element={<ISPhysicalStability />} />
                </Routes>
            </Layout> } />
            </Routes>
        </Router>
    </Provider> 
)

export default App;