import React, { useState } from 'react';
import { Link, Navigate } from 'react-router-dom';
import { connect } from 'react-redux';
import { signup } from '../../actions/auth';

// TODO: If there's an error
const Signup = ({ signup, isAuthenticated, errors }) => {
    const [accountCreated, setAccountCreated] = useState(false);
    const [formData, setFormData] = useState({
        name: '',
        email: '',
        password: '',
        re_password: ''
    });

    const { name, email, password, re_password } = formData;

    const onChange = e => setFormData({ ...formData, [e.target.name]: e.target.value })

    const onSubmit = async e => {
        e.preventDefault();

        if (password === re_password) {
            try {
                let email_lower = email.toLowerCase();
                await signup(name, email_lower, password, re_password);

                // Check if there are any errors
                if (!errors) {
                    setAccountCreated(true);
                }
            } catch (err) {
                console.error("Error during signup:", err);
                // Handle any additional errors if necessary
            }
        }
    };
    
    if (isAuthenticated) {
        return <Navigate to='/' replace={true} />
    }

    if (accountCreated) {
        return <Navigate to='/validate-email' replace={true} />
    }
    console.log(errors)

    return (
        <div className='container mt-5'>
            <h1>Sign Up</h1>
            <p>Create your Account</p>


            {errors ? (
                <div className="alert alert-danger">
                    {errors.password ? ( errors.password[0] ) : null}
                    {errors.email ? ( errors.email[0] ) : null}

                    {/* Displaying the first error for the password,
                        you can adjust this to display all errors or specific ones */}
                </div>
            ) : null}


            <form onSubmit={e => onSubmit(e)}>
                <div className='form-group pb-3'>
                <label for="nameField" class="form-label">Full Name</label>
                    <input 
                        id='nameField'
                        className='form-control'
                        type='text'
                        name='name'
                        value={name}
                        onChange={e => onChange(e)}
                        placeholder="Name*"
                        required
                    />
                </div>
                <div className='form-group pb-3'>
                    <label for="emailInput" class="form-label">Email address</label>
                    <input 
                        id='emailInput'
                        className='form-control'
                        type='email'
                        name='email'
                        value={email}
                        onChange={e => onChange(e)}
                        placeholder="Email*"
                        required
                    />
                </div>
                <div className='form-group pb-3'>
                    <label for="passwordInput" class="form-label">Password</label>
                    <input 
                        id='passwordInput'
                        className='form-control'
                        type='password'
                        name='password'
                        value={password}
                        onChange={e => onChange(e)}
                        minLength='8'
                        placeholder="Password*"
                        required
                    />
                </div>
                <div className='form-group pb-3'>
                    <label for="confirmPasswordInput" class="form-label">Confirm Password</label>
                    <input 
                        id='confirmPasswordInput'
                        className='form-control'
                        type='password'
                        name='re_password'
                        value={re_password}
                        onChange={e => onChange(e)}
                        minLength='8'
                        placeholder="Confirm Password*"
                        required
                    />
                </div>

                <button className='btn btn-primary' type='submit'>Register</button>
            </form>
            <p className='mt-3'>
                Already have an account? <Link to='/login'>Sign In</Link>
            </p>
        </div>
    );
};

const mapStateToProps = state => ({
    isAuthenticated: state.isAuthenticated,
    errors: state.auth.errors
});

export default connect(mapStateToProps, { signup })(Signup);