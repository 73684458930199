import React, { useState, useEffect } from 'react';
import { Grid, TextField, FormControl, InputLabel, Select, MenuItem, Divider } from '@mui/material';

const JARScale = ({ format, addPages, setAddPages, id }) => {
    let SSQIndex = id-2;
    let JS = addPages && addPages.secondary_survey_question && addPages.secondary_survey_question[SSQIndex];

    // Initialize the variable states
    const [inputs, setInputs] = useState({
        question: JS ? JS.question : "",
        format: JS ? JS.format : "",
        scale: JS ? JS.scale : "",
        fields: JS ? JS.fields : [],
    });

    // What saves the input fields
    const handleInputChange = (e) => {
        const { name, value } = e.target;
        let updatedValue = value;

        if (name === "scale") {
            updatedValue = parseInt(value)

            if (name === "scale" && value < inputs.scale) {
                const diff = inputs.scale - value;
                setInputs((prevInputs) => ({
                    ...prevInputs,
                    fields: prevInputs.fields.slice(0, prevInputs.fields.length - diff),
                }));
            }
        }

        setInputs((prevInputs) => ({
            ...prevInputs,
            [name]: updatedValue,
          }));
          console.log(addPages.secondary_survey_question)
    };

    // Handles the Fields when input is changed

    const handleFieldChange = (e, index) => {
        const updatedFields = [...inputs.fields];
        updatedFields[index] = e.target.value;
        setInputs((prevInputs) => ({
          ...prevInputs,
          fields: updatedFields,
        }));
    };

    useEffect(() => {
    
        const JAR = {
            question: inputs.question,
            format: format,
            scale: inputs.scale,
            fields: inputs.fields,
        }
    
        setAddPages ( prevState  => {
            const updatedSecondarySurveyQuestion = prevState.secondary_survey_question.map((item, index) =>
            index === SSQIndex ? JAR : item
          );
      
          return {
            ...prevState,
            secondary_survey_question: updatedSecondarySurveyQuestion,
          };
        });    
      }, [inputs]);    

    return (
        <>
        <Grid container direction="column">
            <Grid item>
                <FormControl>
                <Divider orientation='horizontal' sx={{ height: '10px' }} />
                <TextField
                    sx={{ minWidth: '315px' }}
                    label="Custom Question"
                    id="question"
                    variant="outlined"
                    name="question"
                    value={inputs.question}
                    onChange={handleInputChange}
                />
                </FormControl>
            </Grid>
            <Divider orientation='horizontal' sx={{ height: '10px' }} />
            <Grid item>
            <FormControl sx={{ minWidth: 150 }}>
            <InputLabel variant="outlined" id="scale-label">Scale</InputLabel>
                <Select
                  required
                  label="Scale *"
                  labelId="scale-label"
                  id="scale"
                  value={inputs.scale}
                  name="scale"
                  onChange={handleInputChange}
                >
                  <MenuItem value="3">3</MenuItem>
                  <MenuItem value="5">5</MenuItem>
                  <MenuItem value="7">7</MenuItem>
                  <MenuItem value="9">9</MenuItem>
                </Select>
              </FormControl>
            </Grid>
            {inputs.scale && (
                <>
                    <Divider orientation='horizontal' sx={{ height: '10px' }} />
                    <Grid item sx={{ display: 'grid' }}>
                    {[...Array(inputs.scale)].map((_, index) => (
                        <div key={index}>
                        <TextField
                            label={`Field ${index + 1} (Optional)`}
                            variant="outlined"
                            name={`field[${index}]`}
                            value={inputs.fields[index] || ""}
                            onChange={(e) => handleFieldChange(e, index)}
                            sx={{ width: '450px' }}
                        />
                        <Divider orientation='horizontal' sx={{ height: '10px' }} />
                        </div>
                    ))}
                    </Grid>
                </>
                )}
        </Grid>
        </>
    )
};

export default JARScale;