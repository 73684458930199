import React, { useState, useEffect } from 'react';
import { TextField, Grid, Divider, Typography } from '@mui/material';

const ChannelAlignment = ({ variable_names, constant_names, configInputs, setConfigInputs }) => {
  // let variable_names = ["variable1", "variable2", "variable3", "var4"];
  // let constant_names = ["constant 1", "constant 2", "constant 3"];

  const [varState, setVarState] = useState(
    variable_names.reduce((acc, variable, index) => {
      return { ...acc, [variable]: { name: variable, channel: configInputs.channel_alignment[variable].toString() } };
    }, {})
  );

  const handleVarChange = (variable, fieldType) => (event) => {

    let channel_alignment = configInputs.channel_alignment;

    const fieldValue = event.target.value;
    channel_alignment[variable] = fieldValue
    setConfigInputs({...configInputs, channel_alignment: channel_alignment}) // update config inputs with the new channel alignment value
    // TODO: add in some kind of assertion so two configs aren't the same value.
    setVarState((prevState) => ({
      ...prevState,
      [variable]: { ...prevState[variable], [fieldType]: fieldValue },
    }));
  };

  const [constState, setConstState] = useState(
    constant_names.reduce((acc, constant, index) => {
      return { ...acc, [constant]: { name: constant, channel: configInputs.channel_alignment[constant].toString() } };
    }, {})
  );

  const handleConstChange = (constant, fieldType) => (event) => {
    let channel_alignment = configInputs.channel_alignment;

    const fieldValue = event.target.value;
    channel_alignment[constant] = fieldValue
    setConfigInputs({...configInputs, channel_alignment: channel_alignment})

    setConstState((prevState) => ({
      ...prevState,
      [constant]: { ...prevState[constant], [fieldType]: fieldValue },
    }));
  };

  const [errorState, setErrorState] = useState({});

  useEffect(() => {
    const channelValues = {...varState, ...constState};
    const duplicateChannelValues = Object.values(channelValues).reduce((acc, {channel}, index, arr) => {
      return arr.filter(({channel: val}) => val === channel).length > 1 ? [...acc, channel] : acc;
    }, []);

    setErrorState(Object.keys(channelValues).reduce((acc, key) => {
      return {...acc, [key]: {error: duplicateChannelValues.includes(channelValues[key].channel), helperText: duplicateChannelValues.includes(channelValues[key].channel) ? "Channel #'s cannot be the same value." : ""}}
    }, {}));
  }, [varState, constState]);

  return (
    <div>
      <Grid container spacing={5}>
        <Grid item xs={3}>
          <Typography variant="h6">Variables</Typography>
        </Grid>
        <Grid item xs={3}>
          <Typography variant="h6">MiniVAS/VAS-Air Channel</Typography>
        </Grid>
      </Grid>
      {variable_names.map((variable, index) => (
        <Grid container spacing={5} key={variable} sx={{ marginBottom: '20px'}}>
          <Grid item xs={3}>
            <TextField
              fullWidth
              inputProps={{
                readOnly: true,
              }}
              name="name"
              value={varState[variable].name}
              onChange={handleVarChange(variable, 'name')}
              
            />
          </Grid>
          <Grid item xs={2}>
            <TextField
              fullWidth
              error={errorState[variable]?.error}
              helperText={errorState[variable]?.helperText}
              type="number"
              defaultValue={`${index}`}
              name="channel"
              value={varState[variable].channel}
              onChange={handleVarChange(variable, 'channel')}
            />
          </Grid>
        </Grid>
      ))}

      <Divider orientation='horizontal' sx={{ height: '20px', color: 'red' }} />

      <Grid container spacing={5}>
        <Grid item xs={3}>
          <Typography variant="h6">Constants</Typography>
        </Grid>
        <Grid item xs={3}>
          <Typography variant="h6">MiniVAS/VAS-Air Channel</Typography>
        </Grid>
      </Grid>
      {constant_names.map((constant, index) => (
        <Grid container spacing={5} key={constant} sx={{ marginBottom: '20px'}}>
          <Grid item xs={3}>
            <TextField
              fullWidth
              inputProps={{
                readOnly: true,
              }}
              name="name"
              value={constState[constant].name}
              onChange={handleConstChange(constant, 'name')}
              
            />
          </Grid>
          <Grid item xs={2}>
            <TextField
              fullWidth
              error={errorState[constant]?.error}
              helperText={errorState[constant]?.helperText}
              type="number"
              defaultValue={`${index}`}
              name="channel"
              value={constState[constant].channel}
              onChange={handleConstChange(constant, 'channel')}
            />
          </Grid>
        </Grid>
      ))}
    </div>
  );
};

export default ChannelAlignment;
