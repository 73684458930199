import React, { useState, useEffect } from 'react';
import { Grid, TextField, Box, FormControl, FormGroup, FormControlLabel, Select, MenuItem, Divider, Switch, InputLabel } from '@mui/material';

const PlainText = ({ format, addPages, setAddPages, id }) => {
  let SSQIndex = id-2;
  let NS = addPages && addPages.secondary_survey_question && addPages.secondary_survey_question[SSQIndex];

  // Set input states
  const [inputs, setInputs] = useState({
    question: NS ? NS.question : "",
    format: format,
  });

  // Handle the inputs as they are received
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    let updatedValue = value;
  
    setInputs((prevInputs) => ({
      ...prevInputs,
      [name]: updatedValue,
    }));

  };

  useEffect(() => {
    const plaintext = {
      question: inputs.question,
      format: format,
    }
    
    setAddPages(prevState => {
      const updatedSecondarySurveyQuestion = prevState.secondary_survey_question.map((item, index) =>
        index === SSQIndex ? plaintext : item
      );
  
      return {
        ...prevState,
        secondary_survey_question: updatedSecondarySurveyQuestion,
      };
    });
  }, [inputs]);
  

  return (
    <>
      <Grid container direction="column">
        <Grid item>
          <FormControl>
            <Divider orientation='horizontal' sx={{ height: '10px' }} />
            <TextField
                sx={{ minWidth: '315px' }}
                label="Custom Question"
                id="question"
                variant="outlined"
                name="question"
                value={inputs.question}
              onChange={handleInputChange}
            />
          </FormControl>
        </Grid>
      </Grid>
    </>
  );
};

export default PlainText;
