import { styled } from '@mui/material/styles';
import { Grid } from '@mui/material';
import React from 'react';
import 'sweetalert2/src/sweetalert2.scss';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import IconButton from '@mui/material/IconButton';
import DoneOutlineIcon from '@mui/icons-material/DoneOutline';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import Delete from '@mui/icons-material/Delete';

const PerformAnalysisTable = ({ analysis, handleWeightageChange, removeQuestion }) => {

    const StyledTableCell = styled(TableCell)(({ theme }) => ({
        [`&.${tableCellClasses.head}`]: {
          backgroundColor: '#4682B4',
          color: theme.palette.common.white,
        },
        [`&.${tableCellClasses.body}`]: {
          fontSize: 14,
        },
    }));
      
    const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
        backgroundColor: theme.palette.action.hover,
    },
    // hide last border
    '&:last-child td, &:last-child th': {
        border: 0,
    },
    }));

    const changeWeightage = (event, index) => {
        console.log(event.target.value)
        handleWeightageChange(event, index)
    }

    const remove = (event, index) => {
        removeQuestion(event, index)
    }

    return (
    <Grid item xs={12} style={{marginTop: '0px'}}>
        <TableContainer component={Paper}>
            <Table sx={{ minWidth: 700 }} aria-label="customized table">
                <TableHead>
                <TableRow>
                    <StyledTableCell>Output Criteria</StyledTableCell>
                    <StyledTableCell >Weightage</StyledTableCell>
                    <StyledTableCell >Maximize/Minimize/Match</StyledTableCell>
                    <StyledTableCell >Delete</StyledTableCell>
                </TableRow>
                </TableHead>
                <TableBody>
                {analysis.map((row, index) => (
                    <StyledTableRow key={index}>
                    <StyledTableCell component="th" scope="row">{row.question}</StyledTableCell>
                    <StyledTableCell ><input id="weightage" style={{width: '100px', textAlign: 'center'}} defaultValue={row.weightage} onBlur={(e) => changeWeightage(e,index)}></input></StyledTableCell>
                    <StyledTableCell >{row.mmm}</StyledTableCell>
                    <StyledTableCell >
                        <IconButton onClick={(e) => {remove(e, index)}}><Delete style={{color: 'red', fontSize: '30px'}} /></IconButton>
                    </StyledTableCell>
                    </StyledTableRow>
                ))}
                </TableBody>
            </Table>
        </TableContainer>
    </Grid>
    )
}

export default PerformAnalysisTable;