import * as React from 'react';
import { useState, useEffect } from 'react';
import { Grid, FormControl, Select, InputLabel, MenuItem, Divider } from '@mui/material';
import Swal from 'sweetalert2';

const DesignType = ({ introInputs, configInputs, setConfigInputs, result }) => {

    const [inputs, setInputs] = useState({
        design_type: configInputs.design_type || "", 
        required_terms: configInputs.required_terms || "", 
        optional_terms: configInputs.optional_terms || ""
    })

    useEffect(() => {
        
        let design_type = inputs.design_type;
        let required_terms = inputs.required_terms; 
        let optional_terms = inputs.optional_terms;
       
        setConfigInputs( prevState  => ({ ...prevState,   design_type: design_type }));
        setConfigInputs( prevState  => ({ ...prevState,   required_terms: required_terms }));
        setConfigInputs( prevState  => ({ ...prevState,   optional_terms: optional_terms }));

    }, [inputs]);


    // What saves the survey setting values once there is a change in the input field
    
    const handleInputChange = (e) => {
        const { name, value } = e.target;
        let updatedInputs = {...inputs, [name]: value};
    
        // if a user select DOE (D-Optimality), set the optional terms equal to required terms
        if (value === "DOE (D-Optimality") {
            updatedInputs.optional_terms = updatedInputs.required_terms;
        }
    
        // if a user select "Main + Interactions" or "Main + Quadratic", set the optional_terms to "Main + Interactions + Quadratic"
        if (name === "required_terms" && (value === "Main + Interactions" || value === "Main + Quadratic")) {
            updatedInputs.optional_terms = "Main + Interactions + Quadratic";
        }
    
        setInputs(updatedInputs);
    };    
    // let conditional_dsd = true;
    // let categories = configInputs.variable_categories;
    // categories.forEach((category) => {
    //     if (introInputs.hardware === "VAS") {
    //         conditional_dsd = false;
    //         break;
    //     }
    //     else {
    //         if (category.length === 2) {
    //         conditional_dsd = false;
    //         }
    //         else if (category.length !== 2 && inputs.design_type === "Definitive Screening") {
    //             conditional_dsd = true;
    //             inputs.design_type = "Bayesian";
    //             Swal.fire({
    //                 icon: 'warning',
    //                 title: 'Oops...',
    //                 text: 'Your design type has been dafaulted to Bayesian because you must only have a total of 2 categorical and/or discrete variable levels to use the Definitive Screening design type.',
    //                 allowOutsideClick: false,
    //                 backdrop: 'rgba(0, 0, 0, 0.5)',
    //             });
    //         }
    //     }
    // })
    let conditional_dsd = false;

    return (
        <Grid container spacing={2} style={{ marginTop: '10px' }}>
            <Grid item xs={12}>
                <h5>Design Type</h5>
            </Grid>
            <Grid item>
                <FormControl sx={{ minWidth: 150 }}>
                    <InputLabel variant="outlined" id="design-type-label">Design Type</InputLabel>
                    <Select
                        label="Design Type \*"
                        className="required"
                        required
                        labelId="design-type-label"
                        id="design-type"
                        value={inputs.design_type}
                        name="design_type"
                        onChange={handleInputChange}
                    >
                        <MenuItem value="Bayesian">Bayesian</MenuItem>
                        <MenuItem value="DOE (D-Optimality)">DOE (D-Optimality)</MenuItem>
                        <MenuItem value="Definitive Screening" disabled={conditional_dsd}>Definitive Screening</MenuItem>
                    </Select>
                </FormControl>
            </Grid>
            {inputs.design_type === "Bayesian" ?
            <>
                <Grid item>
                    <FormControl sx={{ minWidth: 280 }}>
                        <InputLabel variant="outlined" id="required-terms-label">Required Terms</InputLabel>
                        <Select
                            required
                            className="required"
                            label="Required Terms *"
                            labelId="required-terms-label"
                            id="required-terms"
                            value={inputs.required_terms}
                            name="required_terms"
                            onChange={handleInputChange}
                            defaultValue={inputs.required_terms ? inputs.required_terms : inputs.required_terms="Main Effects"}
                        >
                            <MenuItem value="Main Effects">Main Effects</MenuItem>
                            <MenuItem value="Main + Interactions">Main + Interactions</MenuItem>
                            <MenuItem value="Main + Quadratic">Main + Quadratic</MenuItem>
                            <MenuItem value="Main + Interactions + Quadratic">Main + Interactions + Quadratic</MenuItem>
                            <MenuItem value="Custom">Custom</MenuItem>
                        </Select>
                    </FormControl>
                    </Grid>
                    {inputs.required_terms === "Custom" ?
                    <>
                    <div hidden>{inputs.optional_terms = "Custom"}</div>
                        <Grid item>
                            <FormControl sx={{ minWidth: 280 }}>
                                <InputLabel variant="outlined" id="optional-terms-label">Optional Terms</InputLabel>
                                <Select
                                    required
                                    className="required"
                                    label="Optional Terms *"
                                    labelId="optional-terms-label"
                                    id="optional-terms"
                                    value={inputs.optional_terms}
                                    name="optional_terms"
                                    onChange={handleInputChange}
                                >
                                    <MenuItem value="Main Effects" disabled>Main Effects</MenuItem>
                                    <MenuItem value="Main + Interactions" disabled>Main + Interactions</MenuItem>
                                    <MenuItem value="Main + Quadratic" disabled>Main + Quadratic</MenuItem>
                                    <MenuItem value="Main + Interactions + Quadratic" disabled>Main + Interactions + Quadratic</MenuItem>
                                    <MenuItem value="Custom">Custom</MenuItem>
                                </Select>
                            </FormControl>
                        </Grid>
                    </>
                    : inputs.required_terms === "Main + Interactions + Quadratic" ?
                    <>
                    <div hidden>{inputs.optional_terms = "Main + Interactions + Quadratic"}</div>
                        <Grid item>
                            <FormControl sx={{ minWidth: 280 }}>
                                <InputLabel variant="outlined" id="optional-terms-label">Optional Terms</InputLabel>
                                <Select
                                    required
                                    className="required"
                                    label="Optional Terms *"
                                    labelId="optional-terms-label"
                                    id="optional-terms"
                                    value={inputs.optional_terms}
                                    name="optional_terms"
                                    onChange={handleInputChange}
                                >
                                    <MenuItem value="Main Effects" disabled>Main Effects</MenuItem>
                                    <MenuItem value="Main + Interactions" disabled>Main + Interactions</MenuItem>
                                    <MenuItem value="Main + Quadratic" disabled>Main + Quadratic</MenuItem>
                                    <MenuItem value="Main + Interactions + Quadratic" defaultValue>Main + Interactions + Quadratic</MenuItem>
                                    <MenuItem value="Custom" disabled>Custom</MenuItem>
                                </Select>
                            </FormControl>
                        </Grid>
                        </>
                    : inputs.required_terms === "Main + Interactions" ?
                    <>
                        <Grid item>
                            <FormControl sx={{ minWidth: 280 }}>
                                <InputLabel variant="outlined" id="optional-terms-label">Optional Terms</InputLabel>
                                <Select
                                    required
                                    className="required"
                                    label="Optional Terms *"
                                    labelId="optional-terms-label"
                                    id="optional-terms"
                                    value={inputs.optional_terms}
                                    name="optional_terms"
                                    onChange={handleInputChange}
                                >
                                    <MenuItem value="Main Effects" disabled>Main Effects</MenuItem>
                                    <MenuItem value="Main + Interactions" defaultValue>Main + Interactions</MenuItem>
                                    <MenuItem value="Main + Quadratic" disabled>Main + Quadratic</MenuItem>
                                    <MenuItem value="Main + Interactions + Quadratic">Main + Interactions + Quadratic</MenuItem>
                                    <MenuItem value="Custom" disabled>Custom</MenuItem>
                                </Select>
                            </FormControl>
                        </Grid>
                    </>
                    : inputs.required_terms === "Main + Quadratic" ?
                    <>
                        <Grid item>
                            <FormControl sx={{ minWidth: 280 }}>
                                <InputLabel variant="outlined" id="optional-terms-label">Optional Terms</InputLabel>
                                <Select
                                    required
                                    className="required"
                                    label="Optional Terms *"
                                    labelId="optional-terms-label"
                                    id="optional-terms"
                                    value={inputs.optional_terms}
                                    name="optional_terms"
                                    onChange={handleInputChange}
                                >
                                    <MenuItem value="Main Effects" disabled>Main Effects</MenuItem>
                                    <MenuItem value="Main + Interactions" disabled>Main + Interactions</MenuItem>
                                    <MenuItem value="Main + Quadratic" defaultValue>Main + Quadratic</MenuItem>
                                    <MenuItem value="Main + Interactions + Quadratic">Main + Interactions + Quadratic</MenuItem>
                                    <MenuItem value="Custom" disabled>Custom</MenuItem>
                                </Select>
                            </FormControl>
                        </Grid>
                    </>
                    :
                    <>
                        <Grid item>
                            <FormControl sx={{ minWidth: 280 }}>
                                <InputLabel variant="outlined" id="optional-terms-label">Optional Terms</InputLabel>
                                <Select
                                    required
                                    className="required"
                                    label="Optional Terms *"
                                    labelId="optional-terms-label"
                                    id="optional-terms"
                                    value={inputs.optional_terms}
                                    name="optional_terms"
                                    onChange={handleInputChange}
                                    defaultValue={inputs.optional_terms ? inputs.optional_terms : inputs.optional_terms="Main + Interactions + Quadratic"}
                                >
                                    <MenuItem value="Main Effects">Main Effects</MenuItem>
                                    <MenuItem value="Main + Interactions">Main + Interactions</MenuItem>
                                    <MenuItem value="Main + Quadratic">Main + Quadratic</MenuItem>
                                    <MenuItem value="Main + Interactions + Quadratic">Main + Interactions + Quadratic</MenuItem>
                                    <MenuItem value="Custom" disabled>Custom</MenuItem>
                                </Select>
                            </FormControl>
                        </Grid>
                    </> }
                </>
            : inputs.design_type === "DOE (D-Optimality)" ?
                <>
                {inputs.optional_terms = null}
                    <Grid item xs={2.25}>
                        <FormControl sx={{ minWidth: 280 }}>
                            <InputLabel variant="outlined" id="required-terms-label">Required Terms</InputLabel>
                            <Select
                                required
                                className="required"
                                label="Required Terms *"
                                labelId="required-terms-label"
                                id="required-terms"
                                value={inputs.required_terms}
                                name="required_terms"
                                onChange={handleInputChange}
                            >
                                <MenuItem value="Main Effects">Main Effects</MenuItem>
                                <MenuItem value="Main + Interactions">Main + Interactions</MenuItem>
                                <MenuItem value="Main + Quadratic">Main + Quadratic</MenuItem>
                                <MenuItem value="Main + Interactions + Quadratic">Main + Interactions + Quadratic</MenuItem>
                                <MenuItem value="Custom">Custom</MenuItem>
                            </Select>
                        </FormControl>
                    </Grid>
                </>
            : inputs.design_type === "Definitive Screening" ?
                <>
                <Grid item>
                    <FormControl sx={{ minWidth: 280 }} disabled>
                        <InputLabel variant="outlined" id="required-terms-label">Required Terms</InputLabel>
                        <Select
                            required
                            className="required"
                            label="Required Terms *"
                            labelId="required-terms-label"
                            id="required-terms"
                            value={inputs.required_terms = "Main Effects"}
                            name="required_terms"
                            onChange={handleInputChange}
                        >
                            <MenuItem value="Main Effects" defaultChecked>Main Effects</MenuItem>
                            <MenuItem value="Main + Interactions">Main + Interactions</MenuItem>
                            <MenuItem value="Main + Quadratic">Main + Quadratic</MenuItem>
                            <MenuItem value="Main + Interactions + Quadratic">Main + Interactions + Quadratic</MenuItem>
                            <MenuItem value="Custom">Custom</MenuItem>
                        </Select>
                    </FormControl>
                </Grid>
                <Grid item>
                    <FormControl sx={{ minWidth: 280 }} disabled>
                        <InputLabel variant="outlined" id="optional-terms-label">Optional Terms</InputLabel>
                        <Select
                            required
                            className="required"
                            label="Optional Terms *"
                            labelId="optional-terms-label"
                            id="optional-terms"
                            value={inputs.optional_terms = "Main + Interactions + Quadratic"}
                            name="optional_terms"
                            onChange={handleInputChange}
                        >
                            <MenuItem value="Main Effects">Main Effects</MenuItem>
                            <MenuItem value="Main + Interactions">Main + Interactions</MenuItem>
                            <MenuItem value="Main + Quadratic">Main + Quadratic</MenuItem>
                            <MenuItem value="Main + Interactions + Quadratic" defaultChecked>Main + Interactions + Quadratic</MenuItem>
                            <MenuItem value="Custom">Custom</MenuItem>
                        </Select>
                    </FormControl>
                </Grid>
                </>
            : <div></div>}
        </Grid>
    );
}


export default DesignType;
