import React, { useState, useCallback, useEffect } from 'react';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import * as XLSX from 'xlsx';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import { FormControl, IconButton, InputLabel } from '@mui/material';

// TODO: Get this data from the server
const panelists = Array(100).fill("User 1");
const samples = Array.from({length: 10}, (_, i) => `Sample ${i+1}`);
const order = Array.from({length: 10}, (_, i) => `${i+1}`);

const initialData = panelists.map((p, index) => ({
  id: `item-${index}`,
  panelist: p,
  sample: samples[index],
  order: order[index],
}));

const SampleOrderUserDefined = ({configInputs, setConfigInputs}) => {
  const [sampleOrder, setSampleOrder] = useState([]);
  const [data, setData] = useState(initialData);

  const onDragEnd = useCallback((result) => {
    if (!result.destination) {
      return;
    }

    const newData = Array.from(data);
    const [removed] = newData.splice(result.source.index, 1);
    newData.splice(result.destination.index, 0, removed);

    setData(newData);
  }, [data]);

  // Export template file for the user
  const exportTemplateFile = () => {
    // Create a new workbook and a new worksheet
    const wb = XLSX.utils.book_new();
    const ws = XLSX.utils.aoa_to_sheet([["Panelist", "Sample", "Order"]]);

    // Append the worksheet to the workbook
    XLSX.utils.book_append_sheet(wb, ws, "Sheet1");

    // Write and save the file
    XLSX.writeFile(wb, "template.xlsx");
  };
  
  // Import excel file to fill in the sample order
  const importExcelFile = (event) => {
    const file = event.target.files[0];
    const reader = new FileReader();

    reader.onload = (e) => {
        const data = e.target.result;
        const workbook = XLSX.read(data, { type: 'binary' });

        const wsname = workbook.SheetNames[0];
        const ws = workbook.Sheets[wsname];

        const jsonData = XLSX.utils.sheet_to_json(ws);

        setData(jsonData.map((item, index) => ({
            id: `item-${index}`,
            ...item
        })));

        // Also, update the sample_order based on the jsonData
        setSampleOrder(jsonData.map((item, index) => ({
            id: (index + 1).toString(), 
            panelist: item.Panelist, 
            sample: item.Sample,
            order: parseInt(item.Order, 10)
        })));
    };

    if (file) reader.readAsBinaryString(file);
  };

  console.log(sampleOrder)

  

  return (
    <div style={{ display: 'flex', justifyContent: 'space-evenly', alignItems: 'flex-start' }}>

      <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
        <h5>You Selected - User Defined Sample Presentation Order</h5>
        <h5>Import a Sample File below with correct formatting (example template given below).</h5>
        <FormControl style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }} >
          <h4>Example Template File</h4>
          <IconButton component="span" onClick={exportTemplateFile}>
            <AddCircleIcon color='primary' fontSize="large"/>
          </IconButton>
          <h4>Import Excel File</h4>
          <input
            type="file"
            accept=".xlsx"
            style={{ display: "none" }}
            id="import-excel"
            onChange={importExcelFile}
          />
          <label htmlFor="import-excel">
            <IconButton component="span">
              <AddCircleIcon color='primary' fontSize="large"/>
            </IconButton>
          </label>
        </FormControl>
      </div>

      <DragDropContext onDragEnd={onDragEnd}>
        <Droppable droppableId="droppable">
          {(provided) => (
            <table ref={provided.innerRef} {...provided.droppableProps} style={{ border: '1px solid black', borderCollapse: 'collapse', height: "400px", overflowY: 'scroll', display: 'block'  }}>
              <thead>
                <tr>
                  <th style={{ border: '1px solid black', padding: '10px' }}>Panelist</th>
                  <th style={{ border: '1px solid black', padding: '10px' }}>Sample</th>
                  <th style={{ border: '1px solid black', padding: '10px' }}>Order</th>
                </tr>
              </thead>
              <tbody>
              {data.map((item, index) => (
                <Draggable key={item.id} draggableId={item.id} index={index}>
                  {(provided) => (
                    <tr
                      ref={provided.innerRef}
                      {...provided.draggableProps}
                      {...provided.dragHandleProps}
                    >
                      <td style={{ border: '1px solid black', padding: '10px' }}>{item.Panelist}</td>
                      <td style={{ border: '1px solid black', padding: '10px' }}>{item.Sample}</td>
                      <td style={{ border: '1px solid black', padding: '10px' }}>{item.Order}</td>
                    </tr>
                  )}
                </Draggable>
              ))}
              </tbody>
            </table>
          )}
        </Droppable>
      </DragDropContext>
    </div>
  );
}

export default SampleOrderUserDefined;
