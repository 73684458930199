// Administer panelist UI

// fetches all the data to administer and uploads data when each panelist takes a survey

import {connect} from "react-redux";
import * as React from 'react';
import { useState, useEffect, useRef } from 'react';
import { useLocation } from 'react-router-dom';
import { Switch, FormControl, FormGroup, FormControlLabel, Box, Divider, InputLabel, MenuItem, Select, IconButton } from '@mui/material';
import Initialize from "./administerPanelistComponents/Initialize";
import Introduction from "./administerPanelistComponents/Introduction";
import Recommendation from "./administerPanelistComponents/Recommendation";
import Question from "./administerPanelistComponents/Question";
import ParentTemplate from "./administerPanelistComponents/ParentTemplate"
import Design from "../Design";
import LoadingScreen from "./administerPanelistComponents/Loading";
import Screensaver from "./administerPanelistComponents/Screensaver";
import EvaluationEngine from "./administerPanelistComponents/EvaluationEngine";
// import SampleOrder from "./components/SampleOrder";
import {VASConnect} from '@estanalytical/vasconnect';
import VASIntegrationSystem from "./administerPanelistComponents/VASIntegrationSystem";
import Stabilizing from "./administerPanelistComponents/Stabilizing";
import { v4 as uuidv4 } from 'uuid';
import Demographics from "./administerPanelistComponents/Demographics";



const AdministerPanelist = ({ isAuthenticated, access_token }) => {

    const [AdminConfig, setAdminConfig] = useState({}) // Specific Administration Configuration
    const [SurveyDesign, setSurveyDesign] = useState({}) // All the survey design data

    const [CurrentParticipantCount, setCurrentParticipantCount] = useState(1)
    const [CurrentSampleIndex, setCurrentSampleIndex] = useState(0) // Which sample is the participant on (in their specific sample order).
    const [Responses, setResponses] = useState([]) // List of responses from this particular user. Gets reset on the intro page.\
    const [DemographicResponses, setDemographicResponses] = useState([])
    const [State, setState] = useState('screensaver') // What phase of the survey is our participant in ?  Options are screensaver,  introduction, dispense_aroma, loading, questions, reccomendation, restart, stabilizing


    // Loading states. The loading variable is just an "or" statement on all of them set by useeffect.
    const [Loading, setLoading] = useState(false) // is anything currently loading?
    const [LoadingVAS, setLoadingVAS] = useState(false)
    const [LoadingAdminConfig, setLoadingAdminConfig] = useState(false)
    const [LoadingDesign, setLoadingDesign] = useState(false)

    const location= useLocation();
    const { surveyID, VASdevice } = location.state || {};  // Using a fallback in case state is not passed
    const [isFullscreen, setIsFullscreen] = useState(false);

    const [IsVASconnected, setIsVASconnected] = useState(false)
    const [IsVASstable, setIsVASstable] = useState(false)
    const [DesiredVASflow, setDesiredVASflow] = useState([0, 0, 0, 0, 0]) // set this to the vector of flows you want and VAS will automatically adjust to it. Use the stability use effect to validate that you are stable.
    const [DesiredVASFanSpeed, setDesiredVASFanSpeed] = useState(8) // Can be used to purge as well.
    const [UserID, setUserID] = useState(1)
    const [CurrentSampleMetaData, SetCurrentSampleMetaData] = useState({}) // This is the sample that is currently running. It gets sent to the question object so that we can add the sample to the response data.


    const [SessionID, _] = useState(uuidv4())
    // Watch for fullscreenchange
    React.useEffect(() => {
        console.log("VAS device in administerpanelist")
        console.log(VASdevice)
      function onFullscreenChange() {
        setIsFullscreen(Boolean(document.fullscreenElement));
      }

      document.addEventListener('fullscreenchange', onFullscreenChange);

      return () => document.removeEventListener('fullscreenchange', onFullscreenChange);
    }, []);
    const uploadResponse = () => {

    }
    const ResetSurveyForNewPanelist = () => {
        setCurrentSampleIndex(0)
        setCurrentParticipantCount(CurrentParticipantCount + 1)
    }

    const loadNextSample = () => {
        console.log("Loadnextsample")
        console.log(CurrentSampleIndex)
        console.log(Object.keys(SurveyDesign.doe_matrix).length)
        setState('loading')

            // Check if theres another aroma to evaluate, or check if we gotta run dynamic modeling.
            // Set the desired flow rates
            // Check if we need to purge.


        if(CurrentSampleIndex ===  Object.keys(SurveyDesign.doe_matrix).length ){
            setState('recommendation')
            setCurrentSampleIndex(0)
        }else{
            setCurrentSampleIndex(CurrentSampleIndex + 1)

            //console.log(`CurrentParticipantCount ${CurrentParticipantCount}`)
            //console.log(`ADMIN CONFIG ${AdminConfig}`)
            //console.log(AdminConfig)
            let my_user_order = AdminConfig.user_sample_order[`participant_${CurrentParticipantCount}`]
            let next_sample_id = my_user_order[CurrentSampleIndex]
            // Next_sample is the actual sample from the DOE we need to evaluate
            let sample_flows = SurveyDesign.doe_matrix[`sample_${next_sample_id - 1}`]
            SetCurrentSampleMetaData({ [`sample_${next_sample_id - 1}`]: sample_flows})

            // We still need to add the constants from the design because they're not technically in the DOE matrix.
            for ( let i = 0; i < SurveyDesign.constant_names.length; i++){
                sample_flows[SurveyDesign.constant_names[i]] = SurveyDesign.constant_amounts[i]
            }
            console.log(sample_flows)

            let flows = new Array(30).fill(0);
            // Lastly we need to use the channel alignment to map these flows to an 30x0 array then send that to the VAS.
            let channel_alignment = AdminConfig.channel_alignment
            console.log(`Channel alignment ${JSON.stringify(channel_alignment)}`)
            for(let i in sample_flows){
                flows[channel_alignment[i] - 1] = sample_flows[i]

            }
            setDesiredVASflow(flows)
            console.log(flows)
            setIsVASstable(false)

            setState("stabilizing")

            //setState("evaluate_aroma")

        }
    }

    useEffect(() => {
      setLoading(LoadingVAS || LoadingDesign || LoadingAdminConfig || LoadingDesign )
    }, [LoadingVAS, LoadingDesign, LoadingAdminConfig, LoadingDesign])

    const upload_results = async (results, demographics) => {
        // First upload demographic responses
        let demographics_payload = {
            'results': demographics,
            'user_id': UserID,
            'survey_id': surveyID,
            'session_id': SessionID
        }
        try {
            const response = await fetch(`${process.env.REACT_APP_API_URL}/administer/upload_demographics`, {
              method: 'post',
              headers: {
                'content-type': 'application/json',
                'Authorization': `JWT ${access_token}`,
                'Accept': 'application/json'
              },
              body: JSON.stringify(demographics_payload)
            });
            const res = await response.json();
            //print the error if the api call
          } catch (err) {
            console.log(err.message)
      }

        // then upload survey results
        let payload = {
            'results': results,
            'user_id': UserID,
            'survey_id': surveyID,
            'session_id': SessionID

        }
        try {
            const response = await fetch(`${process.env.REACT_APP_API_URL}/administer/upload_user_results`, {
              method: 'post',
              headers: {
                'content-type': 'application/json',
                'Authorization': `JWT ${access_token}`,
                'Accept': 'application/json'
              },
              body: JSON.stringify(payload)
            });
            const res = await response.json();
            //print the error if the api call
          } catch (err) {
            console.log(err.message)
      }

    }
    // This useEffect sets AdminConfig and SurveyDesign by fetching the values from the API
    useEffect(() => {
       setLoadingDesign(true)
       setLoadingAdminConfig(true)
       const fetchSurveyData = async () => {
         try {
           const data = await fetch(`${process.env.REACT_APP_API_URL}/survey/view/view_design`, {
             method: 'post',
             headers: {
               'content-type': 'application/json',
               'Authorization': `JWT ${access_token}`,
               'Accept': 'application/json'
             },
               body: JSON.stringify({
                 'design_id': surveyID
               })
           })

           const response = await data.json()
           console.log(response);



           setSurveyDesign(response);
           setLoadingDesign(false);

           //variable_names and constant_names are both lists
         } catch (err) {
           console.log(err.message)
         }
       }
       const fetchAdministerData =  async () => {
         try {
           const data = await fetch(`${process.env.REACT_APP_API_URL}/administer/getAdminConfiguration`, {
             method: 'post',
             headers: {
               'content-type': 'application/json',
               'Authorization': `JWT ${access_token}`,
               'Accept': 'application/json'
             },
               body: JSON.stringify({
                 'design_id': surveyID
               })
           })
           const response = await data.json()
           setLoadingAdminConfig(false);
           setAdminConfig(response)
           //variable_names and constant_names are both lists
         } catch (err) {
           console.log(err.message)
         }
       }
       fetchSurveyData();
       fetchAdministerData();
   }, [])
    useEffect(() => {
        switch(State){
            case 'recommendation':
                setDesiredVASflow(new Array(15).fill(0))
                break
            case "survey_end":
                // Upload results
                upload_results(Responses, DemographicResponses).then(r => {
                    // clear queue of results
                    setResponses([])
                    setState("screensaver")
                })
                break
            case "stabilizing":
                // Check if stable. If stable, change to evaluate_aroma. Otherwise continue
                if(IsVASstable){
                    setState('evaluate_aroma')
                }
                console.log("STABILIZING")
                break;




        }
    }, [State, IsVASstable])

    let content;

    switch(State) {
        case "loading":
            content = <LoadingScreen />;
            break;
        case "demographics":
            content = <Demographics setState={setState} DemographicResponse={DemographicResponses} setDemographicResponse={setDemographicResponses} SurveyDesign ={SurveyDesign} loadNextSample={loadNextSample}/>
            break;
        case "screensaver":
            content = <Screensaver setState={setState} />;
            break;

        case "initialize":
            content = <Initialize setState={setState} />;
            break;
        case "introduction":
            content = <Introduction setState={setState} SurveyDesign={SurveyDesign} loadNextSample={loadNextSample} />;
            break;
        case "evaluate_aroma":
            content = <><EvaluationEngine key={CurrentSampleIndex}
                                        loadNextSample={loadNextSample}
                                        primaryQuestion={SurveyDesign.primary_survey_question}
                                        secondaryQuestions={SurveyDesign.secondary_survey_question}
                                        sample={CurrentSampleIndex}
                                        channelalignment={SurveyDesign.channel_alignment}
                                        SurveyDesign={SurveyDesign}
                                        Responses={Responses}
                                        UserID = {CurrentParticipantCount}
                                        setResponses={setResponses}
                                        CurrentSampleMetaData={CurrentSampleMetaData}/>

            <p>Debug info: Sample {CurrentSampleIndex} / { Object.keys(SurveyDesign.doe_matrix).length } </p>
            <p>Debug info: Panelist {CurrentParticipantCount}</p>
                <p>Session : {SessionID}</p>
                <p>Responses:</p>
                    <div style={{maxHeight: "400px", width: "500px", overflowY: "scroll"}}>
                        {Responses.map((item, index) => (
                            <pre key={index}>
                                {JSON.stringify(item, null, 2)}
                            </pre>
                        ))}

                    </div>
            </>

            break;
        case "recommendation":
            content = <Recommendation setState={setState} />;
            break;
        case "stabilizing":
            content = <Stabilizing />
            break
        default:
            content = null; // Or some default content if necessary
            break;
    }

    if (Loading || !content) {
        content = <LoadingScreen />;
    }else if(!IsVASconnected){
        content = <><LoadingScreen /><br /><h4>Waiting for VAS....</h4></>;
    }

    return <VASIntegrationSystem LoadingVAS={LoadingVAS}
                                 setLoadingVAS={setLoadingVAS}
                                 IsVASconnected={IsVASconnected}
                                 setIsVASconnected={setIsVASconnected}
                                 IsVASstable={IsVASstable}
                                 setIsVASstable={setIsVASstable} DesiredVASFlow={DesiredVASflow}>
         {/* This component handles all the VAS connect initialization stuff.
                                                we pass use States to it so the info can be routed to other components in the app. */}
                <ParentTemplate SurveyDesign={SurveyDesign}>
                    {content}
                </ParentTemplate>
            </VASIntegrationSystem>
}


const mapStateToProps = state => ({
  isAuthenticated: state.auth.isAuthenticated,
  access_token: state.auth.access
});

export default connect(mapStateToProps, {})(AdministerPanelist);