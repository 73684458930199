import React, { useState, useEffect } from 'react';
import { TextField, Grid, Divider, Typography } from '@mui/material';
import LoadingIcon from "./subcomponents/loadingIcon"
const LoadingScreen = ({  }) => {

  return (
    <div>
      <LoadingIcon></LoadingIcon>
    </div>
  );
};

export default LoadingScreen;
