import React, { useState, useEffect } from 'react';
import { Grid, TextField, Box, FormControl, FormGroup, FormControlLabel, Select, MenuItem, Divider, Switch, InputLabel } from '@mui/material';
import { styled } from '@mui/material/styles';

const InstructionsForUse = ({ addPages, setAddPages }) => {
  let IFU = addPages.instructions;

  // Set input states
  const [inputs, setInputs] = useState({
    title: IFU.title || "Instruction for Use",
    description: IFU.description || "",
  });

  // Handle the inputs as they are received
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    let updatedValue = value;
    setInputs((prevInputs) => ({
      ...prevInputs,
      [name]: updatedValue,
    }));
  };

  useEffect(() => {
    
    const IFU = {
        title: inputs.title,
        description: inputs.description
    }

    let instructions = IFU;

    setAddPages( prevState  => ({ ...prevState,   instructions: instructions }));

  }, [inputs]);

  return (
    <Grid container direction="column">
        <Grid item>
            <h5>Title</h5>
            <TextField
                inputProps={{style: {fontWeight: "normal"}}}
                sx={{ minWidth: '100%', '& .MuiInputBase-input': { fontWeight: 'normal' } }}
                label="Title"
                id="title"
                variant="outlined"
                name="title"
                value={inputs.title}
                onChange={handleInputChange}
            />
          </Grid>
          <Divider orientation='horizontal' sx={{ height: '10px' }} />
          <Grid item>
            <h5>Project Background</h5>
            <Divider orientation='horizontal' sx={{ height: '10px' }} />
            <TextField
                sx={{ minWidth: '100%' }}
                label="Description"
                multiline
                rows={4}
                id="description"
                variant="outlined"
                name="description"
                value={inputs.description}
                onChange={handleInputChange}
            />
        </Grid>
    </Grid>
  );
};

export default InstructionsForUse;
