import React, { useState, useEffect } from 'react';
import { Grid, TextField, Box, FormControl, FormGroup, FormControlLabel, Select, MenuItem, Divider, Switch, InputLabel } from '@mui/material';

const NumericScale = ({ format, addPages, setAddPages }) => {
  let NS = addPages.primary_survey_question;

  // Set input states
  const [inputs, setInputs] = useState({
    question: NS.question || "",
    format: format,
    show_rating_label: NS.show_rating_label || true,
    minimum: NS.minimum || null,
    maximum: NS.maximum || null,
    step: NS.step || null,
    shape: NS.shape || "",
    fields: NS.fields || [],
});

  // Handle the inputs as they are received
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    let updatedValue = value;

    if(name === "step" && (inputs.step === null || inputs.step === [])){
      // Set the fields to an empty list of strings equal to the length of step
      setInputs((prevInputs) => ({
          ...prevInputs,
          fields: Array.from(' '.repeat(value)),
      }));

    }
    if (name === "minimum" || name === "maximum" || name === "step") {
      updatedValue = parseInt(value);
  
      // Handle the change in increase or decrease of number of fields
      if (name === "step" && value < inputs.step) {
        const diff = inputs.step - value;
        setInputs((prevInputs) => ({
          ...prevInputs,
          fields: prevInputs.fields.slice(0, prevInputs.fields.length - diff),
        }));
      }
    }
  
    setInputs((prevInputs) => ({
      ...prevInputs,
      [name]: updatedValue,
    }));
    console.log(addPages.primary_survey_question)
  };
  
  // Handles the switch inputs
  const handleSwitchChange = (e) => {
    setInputs((prevInputs) => ({
      ...prevInputs,
      [e.target.name]: e.target.checked,
    }));
  };

  // Take in the changes made to the rating label fields
  const handleRatingLabelChange = (e, index) => {
    const updatedRatingLabelFields = [...inputs.fields];
    updatedRatingLabelFields[index] = e.target.value || " ";
    setInputs((prevInputs) => ({
      ...prevInputs,
      fields: updatedRatingLabelFields,
    }));
    // TODO: if any of them are null, the field should be " ". Otherwise we have null values.

  };

  useEffect(() => {
    const numeric = {
      question: inputs.question,
      format: format,
      show_rating_label: inputs.show_rating_label,
      minimum: inputs.minimum,
      maximum: inputs.maximum,
      step: inputs.step,
      shape: inputs.shape,
      fields: inputs.fields,
    }
    
    setAddPages(prevState => ({ ...prevState, primary_survey_question: numeric }));
  }, [inputs]);
  

  return (
    <>
      <Grid container direction="column">
            <Grid item>
            <FormControl>
                <Divider orientation='horizontal' sx={{ height: '10px' }} />
                <TextField
                    sx={{ minWidth: '315px' }}
                    label="Custom Question"
                    id="question"
                    variant="outlined"
                    name="question"
                    value={inputs.question}
                    onChange={handleInputChange}
                />
                </FormControl>
            </Grid>
            <Divider orientation='horizontal' sx={{ height: '10px' }} />
        <Grid item xs={12}>
            <FormControl>
              <FormGroup>
                <h6>Show Rating Labels</h6>
                <FormControlLabel
                  sx={{ p: 0.5 }}
                  control={
                    <Switch
                      checked={inputs.show_rating_label}
                      onChange={handleSwitchChange}
                      name="show_rating_label"
                    />
                  }
                />
              </FormGroup>
            </FormControl>
        </Grid>
        <Grid item>
          <Box sx={{ display: 'flex' }} justifyContent="flex-start">
            <TextField
              sx={{ width: 120 }}
              label="Minimum"
              required
              className="required"
              inputProps={{ min: 0 }}
              variant="outlined"
              type="number"
              name="minimum"
              value={inputs.minimum || ""}
              onChange={handleInputChange}
            />
            <Divider orientation='vertical' sx={{ width: '10px' }} />
            <TextField
              sx={{ width: 120 }}
              label="Maximum"
              required
              className="required"
              inputProps={{ min: inputs.min + 1 }}
              variant="outlined"
              type="number"
              name="maximum"
              value={inputs.maximum || ""}
              onChange={handleInputChange}
            />
            <Divider orientation='vertical' sx={{ width: '10px' }} />
            <TextField
              sx={{ width: 110 }}
              inputProps={{ min: 2, max: 10 }}
              label="Step"
              variant="outlined"
              type="number"
              name="step"
              value={inputs.step || ""}
              onChange={handleInputChange}
            />
            <Divider orientation='vertical' sx={{ width: '10px' }} />
            <FormControl sx={{ minWidth: '10%' }}>
              <InputLabel variant="outlined" id="shape-label">Shape</InputLabel>
              <Select
                required
                label="Shape *"
                labelId="shape-label"
                id="shape"
                value={inputs.shape}
                name="shape"
                onChange={handleInputChange}
              >
                <MenuItem value="Star">Star</MenuItem>
                <MenuItem value="Triangle">Triangle</MenuItem>
                <MenuItem value="Circle">Circle</MenuItem>
                <MenuItem value="Numeral">Numeral</MenuItem>
              </Select>
            </FormControl>
          </Box>
        </Grid>
        {inputs.show_rating_label && inputs.step && (
          <>
            <Divider orientation='horizontal' sx={{ height: '10px' }} />
            <Grid item sx={{ display: 'grid' }}>
              {[...Array(inputs.step)].map((_, index) => (
                <div key={index}>
                  <TextField
                    label={`${inputs.shape} ${index + 1} (Optional)`}
                    variant="outlined"
                    name={`fields[${index}]`}
                    value={inputs.fields[index] || ""}
                    onChange={(e) => handleRatingLabelChange(e, index)}
                    sx={{ width: '450px' }}
                  />
                  <Divider orientation='horizontal' sx={{ height: '10px' }} />
                </div>
              ))}
            </Grid>
          </>
        )}
      </Grid>
    </>
  );
};

export default NumericScale;
