import React, {useEffect} from "react";
import {Slider} from "@mui/material";

const SliderQuestion = ({ question, questionResponseData, setQuestionResponseData }) => {

  // Input the response into "setQuestionResponseData" UseState.
  // the question object is a JSON with all the data needed to fill in the question inputs.

    // useEffect(() => {
    //     setQuestionResponseData(1)
    // }, [])

    const handleChange = (event) => {
      setQuestionResponseData({
        [event.target.name]: event.target.value,
      });
    };
    console.log(questionResponseData)


  return (
    <div>
          <Slider
              aria-label="slider"
              max={question.maximum}
              min={question.minimum}
              step={question.step || 1}
              name="slider"
              marks
              onChange={handleChange}
          />
    </div>
  );
};

export default SliderQuestion;
