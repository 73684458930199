import * as React from 'react';
import { useState, useRef, useEffect, Fragment } from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';
import { Box, Button, Container, Grid, Drawer, Checkbox, Toolbar, Divider, Tooltip, Radio, Slider, RadioGroup, FormControlLabel, FormControl, FormLabel, FormGroup, Accordion, AccordionSummary, AccordionDetails, Typography, List, ListItem, ListItemButton, ListItemText, Switch, createTheme, ThemeProvider, Slide, TextField } from '@mui/material';
import LinearProgress from '@mui/material/LinearProgress';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { makeStyles, styled } from '@mui/material/styles';
import imageCompression from 'browser-image-compression';
import defaultImages from '../low_level_components/defaultImages';
import { SketchPicker } from 'react-color';
import ColorLensIcon from '@mui/icons-material/ColorLens';
import BrightBerryBackground from '../styleEditorImages/Bright-Berry-Background.png';
import BrightBerryPrimary from '../styleEditorImages/Bright-Berry-Primary.jpg';
import BrightBerryRecommended from '../styleEditorImages/Bright-Berry-Recommendations.jpg'
import CitrusBackground from '../styleEditorImages/citrus-gradient-bg.png';
import CitrusPrimary from '../styleEditorImages/Citrus-Primary.jpg';
import CitrusRecommended from '../styleEditorImages/Citrus-Recommendations.jpg';
import BlackBackground from '../styleEditorImages/dark-gradient-bg.png';
import BlackPrimary from '../styleEditorImages/black-primary.jpg';
import LightBackground from '../styleEditorImages/light-gradient-bg.png';
import LightPrimary from '../styleEditorImages/light-primary.png';
import LuxeBackground from '../styleEditorImages/luxe-bg.jpg';
import LuxePrimary from '../styleEditorImages/Luxe-Primary.jpg';
import LuxeRecommended from '../styleEditorImages/Luxe-Recommendations.jpg';
import TropicalBackground from '../styleEditorImages/tropical-bg.jpg';
import TropicalPrimary from '../styleEditorImages/Tropical-Primary.jpg';
import TropicalRecommended from '../styleEditorImages/Tropical-Recommendations.jpg';
import StarIcon from '@mui/icons-material/Star';
import StarBorderIcon from '@mui/icons-material/StarBorder';
import CircleIcon from '@mui/icons-material/Circle';
import CircleOutlinedIcon from '@mui/icons-material/CircleOutlined';
import ChangeHistoryRoundedIcon from '@mui/icons-material/ChangeHistoryRounded';
import { add, ceil } from 'mathjs';
import ArrowForwardIosRoundedIcon from '@mui/icons-material/ArrowForwardIosRounded';
import ArrowBackIosNewRoundedIcon from '@mui/icons-material/ArrowBackIosNewRounded';
import HomeIcon from '@mui/icons-material/Home';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';

const drawerWidth = "27.5rem";
//const drawerWidth = "24rem"; // Change the value to 24 rem to fix dead space.
const drawerHeight = "34.375rem";

const StyleEditor = ({ setStyleEditor, addPages }) => {

    // Initializing with Bright Berry as default theme.
    const [inputs, setInputs] = useState({
        survey_theme:               "Bright Berry",
        page_background:            "#FFE6F0",
        page_title:                 "#EA2D52",
        description_text:           "#328A27",
        question_text:              "#328A27",
        answer_text:                "#E21B39",
        answer_description:         "#328A27",
        error_text:                 "#C1272D",
        progress_indicator:         "#EF7D9D",
        buttons:                    "#EF7D98",
        word_cloud:                 "#BA0F1C",
        recommendation_name:        "#FFE6F0",
        recommendation_title:       "#BA0F1C",
        page_title_bold:            true,
        page_title_italic:          true,
        description_text_bold:      false, 
        description_text_italic:    true,
        question_bold:              true,
        question_italic:            true,
        answer_bold:                true,
        answer_italic:              true,
        answer_description_bold:    true,
        answer_description_italic:  true,
        error_bold:                 true,
        error_italic:               true,
        recommendation_name_bold:   true,
        recommendation_name_italic: true,
        header_bold:                true,
        header_italic:              true,
        survey_background:          defaultImages.BrightBerryBackground,
        survey_overlay:             defaultImages.BrightBerryPrimary,
        survey_recommendation:      defaultImages.BrightBerryRecommended
    });

    // Declaring all the theme properties.
    const themeStyles = {
        "Bright Berry": {
            survey_theme:               "Bright Berry",
            page_background:            "#FFE6F0",
            page_title:                 "#EA2D52",
            description_text:           "#328A27",
            question_text:              "#328A27",
            answer_text:                "#E21B39",
            answer_description:         "#328A27",
            error_text:                 "#C1272D",
            progress_indicator:         "#EF7D9D",
            buttons:                    "#EF7D98",
            word_cloud:                 "#BA0F1C",
            recommendation_name:        "#FFE6F0",
            recommendation_title:       "#BA0F1C",
            page_title_bold:            false,
            page_title_italic:          false,
            description_text_bold:      false, 
            description_text_italic:    false,
            question_bold:              false,
            question_italic:            false,
            answer_bold:                true,
            answer_italic:              false,
            answer_description_bold:    false,
            answer_description_italic:  false,
            error_bold:                 true,
            error_italic:               false,
            recommendation_name_bold:   false,
            recommendation_name_italic: false,
            header_bold:                false,
            header_italic:              false,
            survey_background:          defaultImages.BrightBerryBackground,
            survey_overlay:             defaultImages.BrightBerryPrimary,
            survey_recommendation:      defaultImages.BrightBerryRecommended
        },
        "Citrus": {
            survey_theme:               "Citrus",
            page_background:            "#FFFBEB",
            page_title:                 "#F15A24",
            description_text:           "#476500",
            question_text:              "#476500",
            answer_text:                "#F76400",
            answer_description:         "#476500",
            error_text:                 "#C1272D",
            progress_indicator:         "#F9751C",
            buttons:                    "#FBB03B",
            word_cloud:                 "#328A27",
            recommendation_name:        "#FFFBEB",
            recommendation_title:       "#FD8201",
            page_title_bold:            false,
            page_title_italic:          false,
            description_text_bold:      false, 
            description_text_italic:    false,
            question_bold:              false,
            question_italic:            false,
            answer_bold:                true,
            answer_italic:              false,
            answer_description_bold:    false,
            answer_description_italic:  false,
            error_bold:                 false,
            error_italic:               false,
            recommendation_name_bold:   false,
            recommendation_name_italic: false,
            header_bold:                false,
            header_italic:              false,
            survey_background:          defaultImages.CitrusBackground,
            survey_overlay:             defaultImages.CitrusPrimary,
            survey_recommendation:      defaultImages.CitrusRecommended
        },
        "Black Givaudan": {
            survey_theme:               "Black Givaudan",
            page_background:            "#000000",
            page_title:                 "#30B3AD",
            description_text:           "#CCCCCC",
            question_text:              "#E6E6E6",
            answer_text:                "#30B3AD",
            answer_description:         "#AFD9CA",
            error_text:                 "#C1272D",
            progress_indicator:         "#30B3AD",
            buttons:                    "#A6A6A6",
            word_cloud:                 "#A6A6A6",
            recommendation_name:        "#FFFFFF",
            recommendation_title:       "#A6A6A6",
            page_title_bold:            false,
            page_title_italic:          false,
            description_text_bold:      false, 
            description_text_italic:    false,
            question_bold:              false,
            question_italic:            false,
            answer_bold:                false,
            answer_italic:              false,
            answer_description_bold:    false,
            answer_description_italic:  false,
            error_bold:                 true,
            error_italic:               false,
            recommendation_name_bold:   false,
            recommendation_name_italic: false,
            header_bold:                false,
            header_italic:              false,
            survey_background:          defaultImages.BlackBackground,
            survey_overlay:             defaultImages.BlackPrimary,
            survey_recommendation:      null
        },
        "Light": {
            survey_theme:               "Light",
            page_background:            "#FFFFFF",
            page_title:                 "#666666",
            description_text:           "#525252",
            question_text:              "#525252",
            answer_text:                "#1A1A1A",
            answer_description:         "#525252",
            error_text:                 "#C1272D",
            progress_indicator:         "#5D71FB",
            buttons:                    "#666666",
            word_cloud:                 "#5D71FB",
            recommendation_name:        "#FFFFFF",
            recommendation_title:       "#605EFF",
            page_title_bold:            false,
            page_title_italic:          false,
            description_text_bold:      false, 
            description_text_italic:    false,
            question_bold:              false,
            question_italic:            false,
            answer_bold:                true,
            answer_italic:              false,
            answer_description_bold:    false,
            answer_description_italic:  false,
            error_bold:                 false,
            error_italic:               false,
            recommendation_name_bold:   false,
            recommendation_name_italic: false,
            header_bold:                false,
            header_italic:              false,
            survey_background:          defaultImages.LightBackground,
            survey_overlay:             defaultImages.LightPrimary,
            survey_recommendation:      null
        },
        "Luxe": {
            survey_theme:               "Luxe",
            page_background:            "#180F0E",
            page_title:                 "#E89B46",
            description_text:           "#E2BDAA",
            question_text:              "#E89B46",
            answer_text:                "#AD6551",
            answer_description:         "#E2BDAA",
            error_text:                 "#C1272D",
            progress_indicator:         "#7F6155",
            buttons:                    "#7F6155",
            word_cloud:                 "#E89B46",
            recommendation_name:        "#FFFFFF",
            recommendation_title:       "#C64F26",
            page_title_bold:            false,
            page_title_italic:          false,
            description_text_bold:      false, 
            description_text_italic:    false,
            question_bold:              false,
            question_italic:            false,
            answer_bold:                false,
            answer_italic:              false,
            answer_description_bold:    false,
            answer_description_italic:  false,
            error_bold:                 false,
            error_italic:               false,
            recommendation_name_bold:   false,
            recommendation_name_italic: false,
            header_bold:                false,
            header_italic:              false,
            survey_background:          defaultImages.LuxeBackground,
            survey_overlay:             defaultImages.LuxePrimary,
            survey_recommendation:      defaultImages.LuxeRecommended
        },
        "Tropical": {
            survey_theme:               "Tropical",
            page_background:            "#FFFFFF",
            page_title:                 "#666666",
            description_text:           "#525252",
            question_text:              "#525252",
            answer_text:                "#1A1A1A",
            answer_description:         "#525252",
            error_text:                 "#C1272D",
            progress_indicator:         "#5D71FB",
            buttons:                    "#666666",
            word_cloud:                 "#5D71FB",
            recommendation_name:        "#FFFFFF",
            recommendation_title:       "#605EFF",
            page_title_bold:            false,
            page_title_italic:          false,
            description_text_bold:      false, 
            description_text_italic:    false,
            question_bold:              true,
            question_italic:            false,
            answer_bold:                true,
            answer_italic:              false,
            answer_description_bold:    false,
            answer_description_italic:  false,
            error_bold:                 false,
            error_italic:               false,
            recommendation_name_bold:   false,
            recommendation_name_italic: false,
            header_bold:                false,
            header_italic:              false,
            survey_background:          defaultImages.TropicalBackground,
            survey_overlay:             defaultImages.TropicalPrimary,
            survey_recommendation:      defaultImages.TropicalRecommended
        },
    };
    
    // Changing the properties as per the theme selected.
    useEffect(() => {
    if (inputs.survey_theme in themeStyles) {
        setInputs((prevInputs) => ({
        ...prevInputs, // This spreads the old state
        ...themeStyles[inputs.survey_theme], // This overwrites the styles with the new theme
        }));
    }
    }, [inputs.survey_theme]);

    // Declaring the middleLayer, i.e. the background of the themes.
    const [middleLayerColor, setMiddleLayerColor] = useState("#ffe6f0"); // default to Bright Berry's background color
    
    // Initializing themeBackgroundColors for all the themes
    const themeBackgroundColors = {
        "Bright Berry": "#ffe6f0",
        "Citrus": "#fffbeb", // Update this to Citrus's background color
        "Black Givaudan": "#000000", // Update this to Black Givaudan's background color
        "Light": "#ffffff", // Update this to Light's background color
        "Luxe": "#180f0e", // Update this to Luxe's background color
        "Tropical": "#FFFFFF", // Update this to Tropical's background color
    };

    // define your font families
    const fontFamilies = {
        "Bright Berry": "Proxima Nova",
        "Citrus": "Lobster",
        "Black Givaudan": "fira-sans",
        "Light": "Metrophobic",
        "Luxe": "Montserrat",
        "Tropical": "Comfortaa",
    };    
    
    // Changing the background color as per the theme selected.
    useEffect(() => {
        if(inputs.survey_theme in themeBackgroundColors){
            setMiddleLayerColor(themeBackgroundColors[inputs.survey_theme]);
        }
    }, [inputs.survey_theme]);

    // define a state for current fontFamily
    const [fontFamily, setFontFamily] = useState("Proxima Nova");

    // Whenever the theme changes, also change the font family
    useEffect(() => {
        setFontFamily(fontFamilies[inputs.survey_theme]);
    }, [inputs.survey_theme]);

    const style_editor = inputs;
    setStyleEditor(inputs)

    const handleInputChange = (event) => {
        const { name, value } = event.target;
        if (name === "survey_theme") {
            let themeBackground;
            switch (value) {
                case 'Bright Berry':
                    themeBackground = '#f6edf0';
                    break;
                case 'Citrus':
                    themeBackground = '#fffbeb';
                    break;
                case 'Black Givaudan':
                    themeBackground = '#000000';
                    break;
                case 'Light':
                    themeBackground = '#ffffff';
                    break;
                case 'Luxe':
                    themeBackground = '#180f0e';
                    break;
                case 'Tropical':
                    themeBackground = '#FFFFFF';
                    break;
                default:
                    themeBackground = '#f6edf0';
                    break;
            }
            setMiddleLayerColor(themeBackground);
            setInputs((prevInputs) => ({
                ...prevInputs,
                [name]: value,
                page_background: themeBackground,
            }));
        } else {
            setInputs((prevInputs) => ({
                ...prevInputs,
                [name]: value,
            }));
        }
    };    

    const Android12Switch = styled(Switch)(({ theme }) => ({
        padding: 8,
        '& .MuiSwitch-track': {
        borderRadius: 22 / 2,
        '&:before, &:after': {
            content: '""',
            position: 'absolute',
            top: '50%',
            transform: 'translateY(-50%)',
            width: 16,
            height: 16,
        },
        '&:before': {
            backgroundImage: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="16" width="16" viewBox="0 0 24 24"><path fill="${encodeURIComponent(
            theme.palette.getContrastText(theme.palette.primary.main),
            )}" d="M21,7L9,19L3.5,13.5L4.91,12.09L9,16.17L19.59,5.59L21,7Z"/></svg>')`,
            left: 12,
        },
        '&:after': {
            backgroundImage: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="16" width="16" viewBox="0 0 24 24"><path fill="${encodeURIComponent(
            theme.palette.getContrastText(theme.palette.primary.main),
            )}" d="M19,13H5V11H19V13Z" /></svg>')`,
            right: 12,
        },
        },
        '& .MuiSwitch-thumb': {
        boxShadow: 'none',
        width: 16,
        height: 16,
        margin: 2,
        },
    }));

    // Used for the switch changing
    const handleSwitchChange = (e) => {
        setInputs(
        {
            ...inputs,
            [e.target.name]: e.target.checked
        }
        )
    }

    // These next few lines are used for the color choices.
    const [showColorPickers, setShowColorPickers] = useState({
        page_background: false,
        page_title: false,
        description_text: false,
        question_text: false,
        answer_text: false,
        answer_description: false,
        error_text: false,
        progress_indicator: false,
        buttons: false,
        word_cloud: false,
        recommendation_name: false,
        recommendation_title: false
    });

    const pickersRef = useRef([]);

    const handleColorButtonClick = (fieldName) => {
        setShowColorPickers((prevShowColorPickers) => ({
        ...prevShowColorPickers,
        [fieldName]: true,
        }));
    };

    const handleClosePicker = (fieldName) => {
        setShowColorPickers((prevShowColorPickers) => ({
        ...prevShowColorPickers,
        [fieldName]: false,
        }));
    };

    const handleColorChange = (color, fieldName) => {
        if(fieldName === 'page_background') {
            setMiddleLayerColor(color.hex);
        }
        setInputs((prevInputs) => ({
            ...prevInputs,
            [fieldName]: color.hex,
        }));
    };

    const handleClickOutside = (event) => {
        if (pickersRef.current && pickersRef.current.length > 0) {
            for (let i = 0; i < pickersRef.current.length; i++) {
                if (pickersRef.current[i] && !pickersRef.current[i].contains(event.target)) {
                handleClosePicker(pickersRef.current[i].getAttribute('data-field-name'));
                }
            }
        }
    };

    useEffect(() => {
        document.addEventListener('mousedown', handleClickOutside);
            return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    const fieldsWithPicker = Object.keys(showColorPickers);

    // These are used for the styling of the Survey Themes
    const containerStyle = {
        position: 'relative',
        width: '90%',
        height: '90%',
        overflow: 'hidden' // to ensure the overlay image doesn't extend beyond the container
    };

    const baseImageStyle = {
        display: 'block', // to ensure the image takes up the entire width of the container
        width: '200%',
        height: '100%'
    };

    const middleLayerStyle = (backgroundColor) => ({
        position: 'absolute',
        bottom: '5%',
        left: '13%',
        width: '75%',
        height: '90%',
        objectFit: 'contain',
        backgroundColor: backgroundColor,      
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        zIndex: 1,
        borderTopLeftRadius: '20px',
        borderTopRightRadius: '20px',
        borderBottomLeftRadius: '20px',
        borderBottomRightRadius: '20px',
        boxShadow: '8px 8px 10px 0px rgba(0, 0, 0, 0.25)',
    });
    
    const questionStyle = {
        position: "absolute",
        display: 'flex',
        top: '10%',
        alignItems: 'center',
        justifyContent: 'center',
        color: inputs.question_text, // color value 'bodyText'
        fontFamily: fontFamily, // font family 'Font'
        fontWeight: inputs.question_bold ? 'bold' : 'normal', // add 'bold' if true, else 'normal'
        fontSize: '20px', // font size 'fontSize'
        fontStyle: inputs.question_italic ? 'italic' : 'normal', // add 'italic' if true, else 'normal'
        // For min/max font size, CSS does not directly support this
        // Consider using JavaScript logic to dynamically adjust based on conditions
    };
    
    const mcqQuestionStyle = {
        position: "absolute",
        display: 'flex',
        top: '10%',
        alignItems: 'center',
        justifyContent: 'center',
        color: inputs.question_text, // color value 'bodyText'
        fontFamily: fontFamily, // font family 'Font'
        fontWeight: inputs.question_bold ? 'bold' : 'normal', // add 'bold' if true, else 'normal'
        fontSize: '20px', // font size 'fontSize'
        fontStyle: inputs.question_italic ? 'italic' : 'normal', // add 'italic' if true, else 'normal'
        // For min/max font size, CSS does not directly support this
        // Consider using JavaScript logic to dynamically adjust based on conditions
    };

    const primaryQuestionStyle = {
        position: "absolute",
        display: 'flex',
        top: '20%',
        alignItems: 'center',
        justifyContent: 'center',
        color: inputs.question_text, // color value 'bodyText'
        fontFamily: fontFamily, // font family 'Font'
        fontWeight: inputs.question_bold ? 'bold' : 'normal', // add 'bold' if true, else 'normal'
        fontSize: '20px', // font size 'fontSize'
        fontStyle: inputs.question_italic ? 'italic' : 'normal', // add 'italic' if true, else 'normal'
        // For min/max font size, CSS does not directly support this
        // Consider using JavaScript logic to dynamically adjust based on conditions
    };

    const numericSecondaryQuestionStyle = {
        position: "absolute",
        display: 'flex',
        top: '20%',
        alignItems: 'center',
        justifyContent: 'center',
        color: inputs.question_text, // color value 'bodyText'
        fontFamily: fontFamily, // font family 'Font'
        fontWeight: inputs.question_bold ? 'bold' : 'normal', // add 'bold' if true, else 'normal'
        fontSize: '20px', // font size 'fontSize'
        fontStyle: inputs.question_italic ? 'italic' : 'normal', // add 'italic' if true, else 'normal'
        // For min/max font size, CSS does not directly support this
        // Consider using JavaScript logic to dynamically adjust based on conditions
    };

    const JARsecondaryQuestionStyle = {
        position: "absolute",
        display: 'flex',
        top: '20%',
        alignItems: 'center',
        justifyContent: 'center',
        color: inputs.question_text, // color value 'bodyText'
        fontFamily: fontFamily, // font family 'Font'
        fontWeight: inputs.question_bold ? 'bold' : 'normal', // add 'bold' if true, else 'normal'
        fontSize: '20px', // font size 'fontSize'
        fontStyle: inputs.question_italic ? 'italic' : 'normal', // add 'italic' if true, else 'normal'
        // For min/max font size, CSS does not directly support this
        // Consider using JavaScript logic to dynamically adjust based on conditions
    };

    const SliderQuestionStyle = {
        position: "absolute",
        top: '20%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        color: inputs.question_text, // color value 'bodyText'
        fontFamily: fontFamily, // font family 'Font'
        fontWeight: inputs.question_bold ? 'bold' : 'normal', // add 'bold' if true, else 'normal'
        fontSize: '20px', // font size 'fontSize'
        fontStyle: inputs.question_italic ? 'italic' : 'normal', // add 'italic' if true, else 'normal'
        // For min/max font size, CSS does not directly support this
        // Consider using JavaScript logic to dynamically adjust based on conditions
    };
    
    const calculateLeft = (str) => {
        if (str.length <= 16) return '35%';
        if (str.length <= 24) return `${35 - ((str.length - 16) * (35 - 30) / (24 - 16))}%`;
        // Add more conditions here if needed
    };
    
    const CheckboxSecondaryQuestionStyle = (question) => ({
        position: "absolute",
        display: 'flex',
        top: '20%',
        left: '5%', // adjusted to give more space on the left
        alignItems: 'center',
        justifyContent: 'center',
        color: inputs.question_text,
        fontFamily: fontFamily,
        fontWeight: inputs.question_bold ? 'bold' : 'normal',
        fontSize: '20px',
        fontStyle: inputs.question_italic ? 'italic' : 'normal',
        width: '90%', // limit the width to 90% of the parent's width
        wordWrap: 'break-word', // break words that are too long
        textAlign: 'center' // center long texts
    });
    
    const raceQuestionStyle = {
        position: "absolute",
        display: 'flex',
        top: '15%',
        alignItems: 'center',
        justifyContent: 'center',
        color: inputs.question_text, // color value 'bodyText'
        fontFamily: fontFamily, // font family 'Font'
        fontWeight: inputs.question_bold ? 'bold' : 'normal', // add 'bold' if true, else 'normal'
        fontSize: '20px', // font size 'fontSize'
        fontStyle: inputs.question_italic ? 'italic' : 'normal', // add 'italic' if true, else 'normal'
        // For min/max font size, CSS does not directly support this
        // Consider using JavaScript logic to dynamically adjust based on conditions
    };

    const ProgressBarStyle = {
        position: 'absolute', 
        display: 'flex', 
        justifyContent: 'space-between', 
        alignItems: 'center', 
        top: '5%',
        width:'50%', 
        height: '10px',
        borderRadius: '25px'
    };

    const CheckBoxProgressBarStyle = {
        position: 'absolute', 
        display: 'flex', 
        justifyContent: 'space-between', 
        alignItems: 'center', 
        top: '5%',
        left: '25%',
        width:'50%', 
        height: '10px',
        borderRadius: '25px'
    };

    const shapeDivStyle = {
        color: inputs.answer_text, // errorText color
        position: "absolute",
        top: '35%',
        fontFamily: fontFamily,
        fontWeight: "bold",
        fontSize: 35,
        fontStyle: "normal",
        display: "flex",
        justifyContent: "space-around", // change to space-around to distribute items evenly
        alignItems: "center",
        margin: 0,
        padding: 0,
        width: "100%", // add this to fill the parent width
        flexDirection: "row", // to make sure items are aligned horizontally
        gap: '5px' // This will add space between child elements
    };

    // Create styled Slider component with custom styles for mark label
    const CustomSlider = styled(Slider)({
        '& .MuiSlider-markLabel': {
            top: '80%',
            color: inputs.answer_description, // color value 'bodyText'
            fontFamily: fontFamily, // font family 'Font'
            fontWeight: inputs.answer_description_bold ? 'bold' : 'normal', // add 'bold' if true, else 'normal'
            fontSize: '70%', // font size 'fontSize'
            fontStyle: inputs.answer_description_italic ? 'italic' : 'normal', // add 'italic' if true, else 'normal'
            // For min/max font size, CSS does not directly support this
            // Consider using JavaScript logic to dynamically adjust based on conditions   
        },
    });

    const JARshapeDivStyle = {
        color: inputs.answer_text, // errorText color
        position: "absolute",
        top: '40%',
        fontFamily: fontFamily,
        fontWeight: "bold",
        fontSize: 35,
        fontStyle: "normal",
        display: "flex",
        justifyContent: "space-around", // change to space-around to distribute items evenly
        alignItems: "center",
        margin: 0,
        padding: 0,
        width: "100%", // add this to fill the parent width
        flexDirection: "row", // to make sure items are aligned horizontally
        gap: '5px' // This will add space between child elements
    };

    const SliderShapeDivStyle = {
        color: inputs.answer_text, // errorText color
        position: "absolute",
        top: '35%',
        display: "flex",
        justifyContent: "center", // center items horizontally
        alignItems: "center",
        width: "100%", // add this to fill the parent width
        flexDirection: "row", // to make sure items are aligned horizontally
        gap: '5px' // This will add space between child elements
    };

    const containerWithButtonsStyle = {
        ...containerStyle,
        position: 'relative', // Set the position to 'relative' to establish a new context for absolutely positioned child elements
        overflow: 'hidden',
    };

    const parentStyle = {
        display: 'flex', // to arrange child elements in a row
        justifyContent: 'space-between', // to distribute items evenly in the horizontal direction
        alignItems: 'center', // to align items vertically in the middle
    };

    const numericQuestionParentStyle = {
        display: 'flex',
        flexDirection: 'column', // to stack child elements vertically
        justifyContent: 'center', // to center items on the cross-axis (vertical, because of 'column')
        alignItems: 'center', // to center items on the main axis (horizontal, because of 'column')
        height: '100%', // to fill the parent height, necessary for centering vertically
    };

    const JARQuestionParentStyle = {
        display: 'flex',
        flexDirection: 'column', // to stack child elements vertically
        justifyContent: 'center', // to center items on the cross-axis (vertical, because of 'column')
        alignItems: 'center', // to center items on the main axis (horizontal, because of 'column')
        height: '100%', // to fill the parent height, necessary for centering vertically
    };

    const SliderQuestionParentStyle = {
        display: 'flex',
        flexDirection: 'column', // to stack child elements vertically
        justifyContent: 'center', // to center items on the cross-axis (vertical, because of 'column')
        alignItems: 'center', // to center items on the main axis (horizontal, because of 'column')
        height: '100%', // to fill the parent height, necessary for centering vertically
        width: '90%', // to ensure child elements are contained within parent
    };

    const CheckboxQuestionParentStyle = {
        display: 'flex',
        flexDirection: 'column', 
        justifyContent: 'center', 
        alignItems: 'center',
        height: '100%', 
        padding: '2em', // added padding to prevent child elements from touching the edges
        boxSizing: 'border-box', // include padding in total width and height
    };

    const containerRecommendationStyle = { 
        display: 'flex', 
        flexDirection: 'column', // Ensure the parent container is in column orientation
        width: '100%', 
        height: '100%',
    };
    
    const column60Style = {
        width: '60%',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'flex-start', // Added to ensure the children start from the top
    };
    
    const rowStyle1 = {
        height: '70%',
        width: '60%',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        overflow: 'auto', 
    };
    
    const rowStyle2 = {
        height: '30%',
        display: 'flex', 
        justifyContent: 'center',  // This will center the divs
        alignItems: 'center', 
        flexDirection: 'row',  // This will make the divs align horizontally
        overflow: 'auto', 
    };    

    const columnStyle1 = {
        width: '50%', // Now 50% of remaining 60% (30% of total width)
        height: '100%', 
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center', 
        paddingLeft: '8%',
    };
    
    const columnStyle2 = {
        width: '50%', // Now 50% of remaining 60% (30% of total width)
        height: '100%', 
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        overflow: 'auto', // to handle potential overflow
    };    
    
    const questionStyleRight = {
        ...questionStyle,
        fontSize: '90%',
        paddingRight: '1%',
        alignItems: 'center',
        wordBreak: 'break-word',
        overflow: 'auto', // change this to 'auto'
    };
    
    const questionStyleLeft = {
        ...questionStyle,
        fontSize: '90%',
        paddingRight: '1%',
        wordBreak: 'break-word',
        overflow: 'auto', // change this to 'auto'
    };
    
    const questionStyleBottom = {
        ...questionStyle,
        top: '56%',
        textAlign: 'center', // This will center the text within the p tag
        fontSize: '90%', 
    };
    
    const smellInstructionStyle = {
        ...questionStyle,
        fontSize: '50%',
        marginTop: '3.5%'
    };
    
    const buttonStyle = {
        cursor: "pointer", // to change the cursor to a hand when hovering
        color: "#1976d2", // or whichever color you prefer
        fontSize: "3em", // or adjust the size as you prefer
    };

    const hiddenButtonStyle = {
        ...buttonStyle,
        visibility: 'hidden', // hide the button but maintain its space
    };

    const surveyCompleteStyle = {
        position: 'absolute',     // absolute positioning
        top: '35%',               // halfway down the parent
        transform: 'translate(0%, -50%)', // translate to center in the parent
        display: 'flex',
        justifyContent: 'center', 
        alignItems: 'center',     
        textAlign: 'center',      // center text alignment
        color: inputs.description_text,            // text color, adjust as needed
        fontSize: '1.2em',        // text size, adjust as needed
        fontFamily: fontFamily, // font family 'Font'
        fontWeight: inputs.description_text_bold ? 'bold' : 'normal', // add 'bold' if true, else 'normal'
        fontStyle: inputs.description_text_italic ? 'italic' : 'normal', // add 'italic' if true, else 'normal'
    };
    
    const HomeIconStyle = {
        position: 'absolute',
        top: '-20px', // Adjust these values as needed
        right: '10px',
        color: inputs.buttons, // Or whichever color you prefer
        fontSize: "3em", // Adjust the size as you prefer
    };
    
    const progressBackIconStyle = {
        position: 'absolute', 
        display: 'flex', 
        justifyContent: 'space-between', 
        alignItems: 'center', 
        top: '3%',
        right: '77.5%',
        color: inputs.buttons, // Or whichever color you prefer
    };   

    const progressForwardIconStyle = {
        position: 'absolute', 
        display: 'flex', 
        justifyContent: 'space-between', 
        alignItems: 'center', 
        top: '3%',
        right: '18.5%',
        color: inputs.buttons, // Or whichever color you prefer
    };    

    const progressCancelIconStyle = {
        position: 'absolute', 
        display: 'flex', 
        justifyContent: 'space-between', 
        alignItems: 'center', 
        top: '3%',
        right: '2%',
        color: inputs.buttons, // Or whichever color you prefer
    };    
    
    const pageTitleStyle = {  
        position: "absolute",
        display: 'flex',
        top: '20%',
        alignItems: 'center',
        justifyContent: 'center',
        color: inputs.page_title, // color value 'bodyText'
        fontFamily: fontFamily, // font family 'Font'
        fontWeight: inputs.page_title_bold ? 'bold' : 'normal', // add 'bold' if true, else 'normal'
        fontSize: '25px', // font size 'fontSize'
        fontStyle: inputs.page_title_italic ? 'italic' : 'normal', // add 'italic' if true, else 'normal'
        // For min/max font size, CSS does not directly support this
        // Consider using JavaScript logic to dynamically adjust based on conditions          
    };

    const descriptionStyle = {
        position: "absolute",
        display: 'flex',
        top: '35%',
        left: 0,
        right: 0,
        alignItems: 'center',
        justifyContent: 'center',
        color: inputs.description_text, // color value 'bodyText'
        fontFamily: fontFamily, // font family 'Font'
        fontWeight: inputs.description_text_bold ? 'bold' : 'normal', // add 'bold' if true, else 'normal'
        fontSize: '18.75px', // font size 'fontSize'
        fontStyle: inputs.description_text_italic ? 'italic' : 'normal', // add 'italic' if true, else 'normal'
        width: '100%',
        padding: '0 20px', // providing a padding for text not to touch borders
        boxSizing: 'border-box', // to make sure padding is included in width
        textAlign: 'center', // to center text within the div
        whiteSpace: 'pre-wrap', // for line breaks
        overflowWrap: 'break-word' // for long words to break to the next line
    };
        
    const radioGroupStyle = {
        position: "absolute",
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center', // Or 'start' or 'end'
        alignItems: 'center',
        top: '20%',
        width: '100%',
        paddingLeft: '2em',
        paddingRight: '2em',
        boxSizing: 'border-box',
    };
        
    const mcqRadioGroupStyle = {
        position: "absolute",
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center', // Or 'start' or 'end'
        alignItems: 'center',
        top: '20%',
        left: '0%',
        width: '100%',
        paddingLeft: '2em',
        paddingRight: '2em',
        boxSizing: 'border-box',
    };

    const formControlLabelStyle = {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        marginBottom: '0',
    };

    const StyledRadio = styled((props) => <Radio {...props} />)(({ theme }) => ({
        color: inputs.answer_text, // this will set the default color
        '&.Mui-checked': {
            color: inputs.answer_text, // this will set the color when the radio button is checked
        },
    }));
    
    const StyledFormControlLabel = styled((props) => <FormControlLabel {...props} />)(({ theme }) => ({
        '& .MuiFormControlLabel-label': {
            marginTop: '-10px',
            color: inputs.answer_description, // color value 'bodyText'
            fontFamily: fontFamily, // font family 'Font'
            fontWeight: inputs.answer_description_bold ? 'bold' : 'normal', // add 'bold' if true, else 'normal'
            fontSize: '13px', // font size 'fontSize'
            fontStyle: inputs.answer_description_italic ? 'italic' : 'normal', // add 'italic' if true, else 'normal'
        },
        position: 'relative',
        '&:after': {
            content: '""',
            position: 'absolute',
            top: '33%',
            left: '50%', // Start from the center of the element
            width: 'calc((18vw - 100% - 10px) / 2)', // Half of the remaining space in the viewport minus some margin
            height: '1px',
            backgroundColor: inputs.answer_text,
            zIndex: -1
        },
        '&:first-child:after': {
            left: '100%', // For the first button, start from the right edge of the element
        },
        '&:last-child:after': {
            content: 'none', // Removes the line after the last radio button
        },
    }));

    const StyledRadioContainer = styled('div')(({ theme }) => ({
        display: 'flex',
        alignItems: 'center',
        position: 'relative',
    
        '&:not(:last-child)::after': {
        },
    }));

    const GenderStyledFormControlLabel = styled(StyledFormControlLabel)({
        marginRight: '1em', // adjust this value as needed
    });

    const StyledCheckboxControlLabel = styled((props) => <FormControlLabel {...props} />)(({ theme }) => ({
        '& .MuiFormControlLabel-label': {
            color: inputs.answer_description, // color value 'bodyText'
            fontFamily: fontFamily, // font family 'Font'
            fontWeight: inputs.answer_description_bold ? 'bold' : 'normal', // add 'bold' if true, else 'normal'
            fontSize: inputs.survey_theme === "Tropical" ? '10px' : ((inputs.survey_theme === "Black Givaudan" || inputs.survey_theme === "Light") ? '11px': '12px'), // font size 'fontSize'
            fontStyle: inputs.answer_description_italic ? 'italic' : 'normal', // add 'italic' if true, else 'normal'
        }
    }));
    
    const StyledCheckboxContainer = styled('div')(({ theme }) => ({
        display: 'flex',
        alignItems: 'center',
        position: 'relative',
    
        '&:not(:last-child)::after': {
        },
    }));

    const checkBoxGroupStyle = {
        position: "absolute",
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
        top: '30%',
        width: '100%',
        paddingLeft: '2em',
        paddingRight: '1em',
        boxSizing: 'border-box',
    };

    const customQuestioncheckBoxGroupStyle = {
        position: "absolute",
        display: 'flex',
        flexDirection: 'row',
        flexWrap: 'wrap', // This enables the checkboxes to wrap to the next line
        justifyContent: 'space-between',
        alignItems: 'center',
        top: '30%',
        width: '100%',
        paddingLeft: '2em',
        paddingRight: '1em',
        boxSizing: 'border-box',
    };
    
    const SecondaryStyledCheckboxContainer = styled('div')({
        display: 'flex',
        alignItems: 'center',
        position: 'relative',
        width: '25%', // This will ensure 4 checkboxes fit in a single row.
        boxSizing: 'border-box',
        padding: '0.5em', // You might need to adjust the padding to suit your needs.

        '&:not(:last-child)::after': {
        },
    });

    const SecondaryStyledCheckboxControlLabel = styled((props) => <FormControlLabel {...props} />)(({ theme }) => ({
        '& .MuiFormControlLabel-label': {
            color: inputs.answer_description, // color value 'bodyText'
            fontFamily: fontFamily, // font family 'Font'
            fontWeight: inputs.answer_description_bold ? 'bold' : 'normal', // add 'bold' if true, else 'normal'
            fontSize: inputs.survey_theme === "Tropical" ? '10px' : ((inputs.survey_theme === "Black Givaudan" || inputs.survey_theme === "Light") ? '11px': '12px'), // font size 'fontSize'
            fontStyle: inputs.answer_description_italic ? 'italic' : 'normal', // add 'italic' if true, else 'normal'
        }
    }));

    let instructionsTitle = addPages.instructions.title;
    let instructionsDescription = addPages.instructions.description;
    let custom_question = addPages.demographic_questions.question;
    let custom_steps = addPages.demographic_questions.number_of_options;
    let custom_rating_labels = addPages.demographic_questions ? addPages.demographic_questions.fields : [];
    let custom_question_shape = 'Star';
    let custom_question_show_rating_labels = true;
    const [currentSecondaryIndex, setCurrentSecondaryIndex] = useState(0);
    let recommendations = addPages.add_recommendation && addPages.add_recommendation.show_recommendations 
                        ? addPages.add_recommendation.recommendations : [];

    function randomSplitArray(inputArray) {
        // Create a copy of the input array
        let copyArray = [...inputArray];
    
        // Randomly select 6 items from copyArray
        let selectedItems = [];
        while(selectedItems.length < Math.min(6, copyArray.length)) {
            let randIndex = Math.floor(Math.random() * copyArray.length);
            selectedItems.push(copyArray[randIndex]);
            copyArray.splice(randIndex, 1);
        }
    
        let arr1 = [];
        let arr2 = [...selectedItems];
    
        // Split randomly selected items into two arrays
        while(arr2.length > Math.ceil(selectedItems.length / 2)) {
            let randIndex = Math.floor(Math.random() * arr2.length);
            arr1.push(arr2[randIndex]);
            arr2.splice(randIndex, 1);
        }
    
        // If selectedItems has only one element, ensure both arrays have that element
        if (selectedItems.length === 1) {
            arr1 = arr2 = selectedItems;
        }
        // If selectedItems has two elements, ensure each array has one element
        if (selectedItems.length === 2) {
            arr1 = [selectedItems[0]];
            arr2 = [selectedItems[1]];
        }
    
        return [arr1, arr2];
    }
    
    function randomSelectProduct(inputArray) {
        if (inputArray && inputArray.length > 0) {
            return inputArray[Math.floor(Math.random() * inputArray.length)];
        } else {
            return null;
        }
    }
    
    let product1 = randomSelectProduct(recommendations);
    let product2 = randomSelectProduct(recommendations);
    let product3 = randomSelectProduct(recommendations);
    
    function adjustBrightness(hex, percent){
        hex = hex.substring(1);
        let [r, g, b] = [parseInt(hex.slice(0,2), 16), parseInt(hex.slice(2,4), 16), parseInt(hex.slice(4,6), 16)];
    
        let amount = Math.floor(255 * percent / 100);
        r = r + amount < 255 ? r + amount : 255;
        g = g + amount < 255 ? g + amount : 255;
        b = b + amount < 255 ? b + amount : 255;
    
        let newColor = "#" + (r | 1 << 8).toString(16).slice(1) + (g | 1 << 8).toString(16).slice(1) + (b | 1 << 8).toString(16).slice(1);
        return newColor;
    }

    // Add the PrimarySurvey component
    const PrimarySurvey = ({ question }) => {
        return (
            <QuestionPreview question={question} />
        );
    };
    
    let backgroundColor1 = adjustBrightness(inputs.page_background, -33);  // 20% lighter
    let backgroundColor2 = adjustBrightness(inputs.page_background, -31);  // 20% darker
    let backgroundColor3 = adjustBrightness(inputs.page_background, -33);  // 40% lighter

    const [recommendations1, recommendations2] = randomSplitArray(recommendations);
    console.log(recommendations)
    console.log(recommendations1)
    console.log(recommendations2)
                        
    const checkSecondarySurveyExists = (addPages) => {
        let results = [];
        
        if (addPages.secondary_survey_question) {
            for (let i = 0; i < addPages.secondary_survey_question.length; i++) {
                if (addPages.secondary_survey_question[i].format) {
                    if (addPages.secondary_survey_question[i].question) {
                        // If either question or format is empty, push false
                        if (addPages.secondary_survey_question[i].question === '' || addPages.secondary_survey_question[i].format === '') {
                            results.push(false);
                        } else {
                            // If both question and format are present, push true
                            results.push(true);
                        }
                    } else if (addPages.secondary_survey_question[i].format === 'Plain Text') {
                        // If question is empty, push false
                        if (addPages.secondary_survey_question[i].question === '') {
                            results.push(false);
                        } else {
                            // If question is present, push true
                            results.push(true);
                        }
                    } else { 
                        // If the format is not 'Plain Text' and there is no question, push false
                        results.push(false); 
                    }
                } else { 
                    // If there is no format, push false
                    results.push(false); 
                }
            }
        }
    
        // If at least one result is true, return true; otherwise, return false
        return results.some(value => value === true);
    };    
      
    const checkInstructionsExists = (addPages) => {
        if (addPages.instructions.title) {
            return addPages.instructions.title != '';
        }
        return false;
    };
      
    const checkDemographicQuestionsExists = (addPages) => {
        if (addPages.demographic_questions) {
            return ((
                addPages.demographic_questions.age_range == true ||
                addPages.demographic_questions.gender == true ||
                addPages.demographic_questions.race == true ||
                addPages.demographic_questions.question)
            );
        }
        return false;
    };
      
    const checkRecommendationsExists = (addPages) => {
        if(addPages.add_recommendation) {
            if (addPages.add_recommendation.drivers_of_liking == true) {
                return true;
            } else if (addPages.add_recommendation.show_recommendations == true) {
                return true;
            }
        }        
        return false;
    };
    
    let secondaryExists = checkSecondarySurveyExists(addPages);
    let instructionsExists = checkInstructionsExists(addPages);
    let demographicExists = checkDemographicQuestionsExists(addPages);
    let recommendationsExists = checkRecommendationsExists(addPages);

    // inside your component function
    const [currentStep, setCurrentStep] = useState(1);
    const [currentPage, setCurrentPage] = useState('');
    const [subheading, setSubheading] = useState('');
    const [currentPageType, setCurrentPageType] = useState('');
    
    let stepsOrder = [];
    if (secondaryExists) stepsOrder.push('Secondary Question Preview');
    let demographics = [];
    if (demographicExists) {
        if (addPages.demographic_questions.age_range) demographics.push('Age Range');
        if (addPages.demographic_questions.gender) demographics.push('Gender');
        if (addPages.demographic_questions.race) demographics.push('Race');
        if (addPages.demographic_questions.question) demographics.push('Custom');
    }
    stepsOrder = stepsOrder.concat(demographics.map(demographic => `Demographics Questions Preview - ${demographic}`));
    if (instructionsExists) stepsOrder.push('Instructions For Use Preview');
    if (recommendationsExists) stepsOrder.push('Recommendations Preview');
    
    // Calculate total steps
    let totalStep = 2;
    if (secondaryExists) totalStep++;
    if (instructionsExists) totalStep++;
    if (demographicExists) {
        if (addPages.demographic_questions.age_range) totalStep++;
        if (addPages.demographic_questions.gender) totalStep++;
        if (addPages.demographic_questions.race) totalStep++;
        if (addPages.demographic_questions.question) totalStep++;
    }
    if (recommendationsExists) totalStep++;

    if (secondaryExists) console.log('secondaryExists');
    if (instructionsExists) console.log('instructionsExists');
    if (demographicExists) {
        console.log('demographicExists');
        if (addPages.demographic_questions.age_range) console.log('age_rangeExists');
        if (addPages.demographic_questions.gender) console.log('genderExists');
        if (addPages.demographic_questions.race) console.log('raceExists');
        if (addPages.demographic_questions.question) console.log('customExists');
    }
    if (demographicExists) console.log('demographicExists');
    if (recommendationsExists) console.log('recommendationsExists');

    const handleNext = () => {
        if (currentPageType === 'Multiple Choice') {
            setCurrentPageType(''); 
        }
        
        if (currentPage === 'Secondary Question Preview' && currentSecondaryIndex < secondary_questions.length - 1) {
            setCurrentSecondaryIndex(prevIndex => prevIndex + 1);
        } else if (currentStep < totalStep) {
            setCurrentStep(prevStep => prevStep + 1);
        }
    };
    
    const handlePrev = () => {
        if (currentPageType === 'Multiple Choice') {
            setCurrentPageType(''); 
        }
    
        if (currentPage === 'Secondary Question Preview' && currentSecondaryIndex > 0) {
            setCurrentSecondaryIndex(prevIndex => prevIndex - 1);
        } else if (currentStep > 0) {
            setCurrentStep(prevStep => prevStep - 1);
        }
    };    

    useEffect(() => {
        if (currentPage !== 'Secondary Question Preview') {
            setCurrentSecondaryIndex(0);
        }
    }, [currentPage]);

    useEffect(() => {
        if (currentStep === 0) {
            setCurrentPage('Survey Complete Preview');
            setSubheading('');
        } else if (currentStep === 1) {
            setCurrentPage('Primary Question Preview');
            setSubheading('');
        } else if (currentStep >= 2 && currentStep < totalStep && (currentStep - 2) < stepsOrder.length) {
            const pageData = stepsOrder[currentStep - 2].split(' - ');
            setCurrentPage(pageData[0]);
            setSubheading(pageData[1] ? pageData[1] : '');
        } else if (currentStep === totalStep) {
            setCurrentPage('Loading Screen Preview');
            setSubheading('');
        }
    }, [currentStep, secondaryExists, demographicExists, instructionsExists, recommendationsExists, totalStep]);
    
    const ShapeComponent = ({ shapeMax, shapeMin, shape, steps, index }) => {

        // Calculate the dynamic font size
        const fontSize = Math.min(2, 10 / steps) + "em";
        let marginRightSpace = 0;

        if (steps > 5){
            marginRightSpace = Math.max(-50, (-100*(steps))) + "px";
        } else {
            marginRightSpace = (-50 * (5-steps)) + "px";
        }
        
        const style = {
            fontSize,
            filter: index !== steps - 1? 'drop-shadow(0px 3px 3px rgba(0,0,0,0.3))' : '', // Add drop shadow using a filter
            marginTop: '-20px',
            marginRight: index !== steps - 1 ? marginRightSpace : '0px', // Add a right margin to all but the last item
        };

        if (steps === 1) {
            switch (shape) {
                case "Star": return <StarIcon style={style} />;
                case "Circle": return <CircleIcon style={style} />;
                case "Triangle": return <span class="material-symbols-outlined" style={style}> change_history </span>;
                case "Numeral": return <div style={{
                                            ...style,
                                            fontSize: Math.min(20, 150 / steps) + "px",
                                            border: "1px solid",
                                            borderRadius: "50%",
                                            width: "fit-content",
                                            padding: "5px"}}> 
                                            {Number(shapeMin + (index) * ((shapeMax - shapeMin) / (steps - 1))).toFixed(2).toString()}
                                        </div>;
                default: return null;
            }
        } else if (index === steps - 1) {
            switch (shape) {
                case "Star": return <StarBorderIcon style={style} />;
                case "Circle": return <CircleOutlinedIcon style={style} />;
                case "Triangle": return <ChangeHistoryRoundedIcon style={style} />;
                case "Numeral": return <div style={{
                                                ...style,
                                                fontSize: Math.min(20, 150 / steps) + "px",
                                                border: "1px solid",
                                                borderRadius: "50%",
                                                width: "fit-content",
                                                padding: "5px",
                                                color: inputs.page_background,
                                                backgroundColor: inputs.answer_text,
                                            }}
                                        >
                                            {Number(shapeMin + (index) * ((shapeMax - shapeMin) / (steps - 1))).toFixed(2).toString()}
                                        </div>;
                default: return null;
            }
        } else {
            switch (shape) {
                case "Star": return <StarIcon style={style} />;
                case "Circle": return <CircleIcon style={style} />;
                case "Triangle": return <span class="material-symbols-outlined" style={style}> change_history </span>;
                case "Numeral": return <div style={{
                                            ...style,
                                            fontSize: Math.min(20, 150 / steps) + "px",
                                            border: "1px solid",
                                            borderRadius: "50%",
                                            width: "fit-content",
                                            padding: "5px"}}> 
                                            {Number(shapeMin + (index) * ((shapeMax - shapeMin) / (steps - 1))).toFixed(2).toString()} 
                                        </div>;
                default: return null;
            }
        }
    };

    const wrapperStyle = {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        margin: '5px', // This will create a 10px space between each div (5px on each side)
    };

    const ShapeAndLabel = ({ shapeMax, shapeMin, shape, steps, index, showRatingLabels, ratingLabels }) => {
        // Calculate the dynamic font size
        const fontSize = Math.min(2, 10 / steps) + "em";
        let marginRightSpace = 0;

        if ( steps == 1) {
            marginRightSpace = (-50 * (5-steps)) + "px";         
        } else if ( steps == 2 || steps == 3 ) {
            marginRightSpace = Math.max(-70, (-100*(steps))) + "px";            
        } else if (steps == 4) {
            marginRightSpace = Math.max(-50, (-100*(steps))) + "px";
        } else if (steps == 5) {
            marginRightSpace ="0px";
        } else if (steps == 6 || steps == 7) {
            marginRightSpace = Math.max(-50, (-100*(steps))) + "px";
        } else if (steps == 8 || steps == 9) {
            marginRightSpace = Math.max(-40, (-100*(steps))) + "px";
        } else if (steps == 10) {
            marginRightSpace = Math.max(-30, (-100*(steps))) + "px";
        }
    
        const shapeStyle = {
            fontSize,
            filter: index !== steps - 1? 'drop-shadow(0px 3px 3px rgba(0,0,0,0.3))' : '', // Add drop shadow using a filter
            marginTop: '-10px', // reduced margin top
            marginRight: index !== steps - 1 ? marginRightSpace : '0px', // Add a right margin to all but the last item
        };
    
        const labelStyle = {
            color: inputs.answer_description,
            display: showRatingLabels ? "block" : "none", 
            fontSize: '0.5em',
            fontWeight: inputs.answer_description_bold ? 'bold' : 'normal', // add 'bold' if true, else 'normal'
            fontStyle: inputs.answer_description_italic ? 'italic' : 'normal', // add 'italic' if true, else 'normal'
            marginTop: '-30px',
            marginBottom: steps > 6 ? ((5 - steps) + 'px') : '5px', // Reduced bottom margin
            textAlign: 'center',
            marginRight: index !== steps - 1 ? marginRightSpace : '0px', // Add a right margin to all but the last item
        };
    
        return (
            <div>
                {showRatingLabels &&
                    <div style={labelStyle}>
                        {ratingLabels[index]}
                    </div>
                }
                <ShapeComponent shapeMax={shapeMax} shapeMin={shapeMin} shape={shape} steps={steps} index={index} style={shapeStyle}/>
            </div>
        );
    };

    const [checked, setChecked] = React.useState({
        female: true,
        male: true,
        other: true,
        'Under 12': true,
        '12-17': true,
        '18-24': true,
        '25-34': true,
        '35-44': true,
        '45-54': true,
        '55-64': true,
        '65-74': true,
        '75 or older': true,
        'Prefer to not answer': true,
        'African American': false,
        'Asian American': false,
        'Caucasian/White': false,
        'Hispanic/Latino': false,
        'Native American': false,
        'Pacific Islander': false,
        'Other': true,
        'Prefer not to answer': false,
    });
    
    // Use this helper function to handle changes to any radio button.
    const handleCheckChange = (option, event) => {
        setChecked({ ...checked, [option]: event.target.checked });
    };
    
    const averageColor = (hex1, hex2) => {
        const hexToRgb = (hex) => {
            let c;
            if(/^#([A-Fa-f0-9]{3}){1,2}$/.test(hex)){
                c = hex.substring(1).split('');
                if(c.length === 3){
                    c = [c[0], c[0], c[1], c[1], c[2], c[2]];
                }
                c = '0x'+c.join('');
                return [(c>>16)&255, (c>>8)&255, c&255];
            }
            throw new Error('Bad Hex');
        }
    
        const rgbToHex = (r, g, b) => {
            return "#" + ((1 << 24) + (r << 16) + (g << 8) + b).toString(16).slice(1).toUpperCase();
        }
    
        const rgb1 = hexToRgb(hex1);
        const rgb2 = hexToRgb(hex2);
    
        const avgRgb = [
            Math.round((rgb1[0] + rgb2[0]) / 2),
            Math.round((rgb1[1] + rgb2[1]) / 2),
            Math.round((rgb1[2] + rgb2[2]) / 2)
        ]
    
        return rgbToHex(avgRgb[0], avgRgb[1], avgRgb[2]);
    };

    const theme = createTheme({
        palette: {
            customColor: { main: inputs.progress_indicator, },
        },
        components: {
          MuiSlider: {
            styleOverrides: {
              thumb: {
                '&:hover, &.Mui-focusVisible, &.Mui-active': {
                  boxShadow: `0 0 0 12px ${averageColor(inputs.answer_text, inputs.page_background)}66`, // Append '66' for 40% opacity
                },
              },
            },
          },
          MuiLinearProgress: {
            styleOverrides: {
              bar: {
                backgroundColor: 'customColor',
              },
            },
          },
        },
    });
    
    // Assuming secondary_survey_question is an array of objects, with each object representing a question
    let secondary_questions = addPages.secondary_survey_question ? addPages.secondary_survey_question : [];
    
    const NumericScaleQuestion = ({ question }) => (
        <div style={numericQuestionParentStyle}>
            <div style={numericSecondaryQuestionStyle}>
                <p>{`${question.question}`}</p>
            </div>
            <div style={shapeDivStyle}>
                {Array(question.step).fill().map((_, i) => 
                    <ShapeAndLabel 
                        shapeMax={question.maximum}
                        shapeMin={question.minimum}
                        shape={question.shape} 
                        steps={question.step}
                        index={i}
                        showRatingLabels={question.show_rating_label}
                        ratingLabels={question.fields}
                        key={i} 
                    />
                )}
            </div>
        </div>
    );  

    // Similarly, create components for other question formats...
    const JARScaleQuestion = ({ question }) => (
        <div style={JARQuestionParentStyle}>
            <div style={JARsecondaryQuestionStyle}>
                <p>{`${question.question}`}</p>
            </div>
            <div style={JARshapeDivStyle}>
                {console.log(question)}
                {Array(question.scale).fill().map((_, i) => 
                    <ShapeAndLabel 
                        shapeMax={question.scale}
                        shapeMin={1}
                        shape={'Star'} 
                        steps={question.scale}
                        index={i}
                        showRatingLabels={question.fields? true : false}
                        ratingLabels={question.fields}
                        key={i} 
                    />
                )}
            </div>            
        </div>
    );

    const CheckboxQuestion = ({ question }) => (
        <div style={CheckboxQuestionParentStyle}>
            <div style={mcqQuestionStyle}>
                <p>{`${question.question}`}</p>
            </div>
            <RadioGroup
                row
                aria-labelledby="demo-row-radio-buttons-group-label"
                name="row-radio-buttons-group"
                style={mcqRadioGroupStyle}
            >
                {question.fields.map(range => (
                    <StyledRadioContainer key={range} >
                        <StyledFormControlLabel 
                            control={
                                <StyledRadio 
                                    checked={checked[range]} 
                                    onChange={e => handleCheckChange(range, e)}
                                />
                            }
                            label={range}
                            labelPlacement="bottom"
                        />
                    </StyledRadioContainer>
                ))}
            </RadioGroup>
        </div>        
    );

    const MultipleChoiceQuestion = ({ question }) => {
        const [checked, setChecked] = React.useState({}); // Define your checked state
    
        return (
            <div style={CheckboxQuestionParentStyle}>                      
                <ArrowBackIcon style={progressBackIconStyle}/>                          
                <ThemeProvider theme={theme}>
                    <LinearProgress style={CheckBoxProgressBarStyle} color="customColor" variant="determinate" value={40} />
                </ThemeProvider> 
                <ArrowForwardIcon style={progressForwardIconStyle}/>  
                <CancelOutlinedIcon style={progressCancelIconStyle}/>                                     
                <div style={CheckboxSecondaryQuestionStyle(question)}>
                    <p>{`${question.question}`}</p>
                </div>
                <FormGroup style={customQuestioncheckBoxGroupStyle}>
                    {question.fields.map((field, index) => ( // Use index for unique key
                        <SecondaryStyledCheckboxContainer key={index} >
                            <SecondaryStyledCheckboxControlLabel 
                                control={<Checkbox checked={checked[field] || false} onChange={() => setChecked({...checked, [field]: !checked[field]})}/>} 
                                label={field} 
                            />
                        </SecondaryStyledCheckboxContainer>
                    ))}
                </FormGroup>
            </div>
        );
    };
    
    const isCurrentQuestionMCQ = () => {
        return currentPageType === 'Multiple Choice';
    };

    const SliderQuestion = ({ question }) => {
        const { question: questionText, format, show_rating_label, minimum, maximum, step, fields } = question;
        
        // derive marks from fields array
        const marks = Array(step).fill().map((_, i) => {
            let value = minimum + i * ((maximum - minimum) / (step - 1));
            value = Math.round((value + Number.EPSILON) * 100) / 100; // round to two decimal places at max
            const label = fields && fields[i] ? fields[i] : `${value}`;
            return { value, label };
        });
    
        return (
            <div style={SliderQuestionParentStyle}>
                <div style={SliderQuestionStyle}>
                    <p>{`${questionText}`}</p>
                </div>
                <div style={SliderShapeDivStyle}>
                    <ThemeProvider theme={theme}>
                        <CustomSlider
                            max={maximum}
                            min={minimum}
                            step={(maximum - minimum) / (step - 1)}
                            marks={marks}
                            style={{ width: '90%', color: inputs.answer_text }}
                            valueLabelDisplay="auto"
                        />
                    </ThemeProvider>
                </div>  
            </div>
        );
    };

    const PlainTextQuestion = ({ question }) => (
        <div style={numericQuestionParentStyle}>
            <div style={numericSecondaryQuestionStyle}>
                <p>{`${question.question}`}</p>
            </div>
            <TextField sx={{ marginTop:'-20%', minWidth: '315px' }} disabled />
        </div>
    );
    
    const QuestionPreview = ({ question, setCurrentPageType }) => {
        let questionElement;        
        
        switch(question.format) {
            case 'Numeric Scale':
                questionElement = <NumericScaleQuestion question={question} />;
                break;
            case 'JAR Scale':
                questionElement = <JARScaleQuestion question={question} />;
                break;
            case 'Checkbox':
                questionElement = <CheckboxQuestion question={question} />;
                break;
            case 'Multiple Choice':
                questionElement = <MultipleChoiceQuestion question={question} />;
                break;
            case 'Slider':
                questionElement = <SliderQuestion question={question} />;
                break;
            case 'Plain Text':
                questionElement = <PlainTextQuestion question={question} />;
                break;
        }
        return questionElement;
    };
        
    const SecondarySurvey = ({ secondary_questions, currentSecondaryIndex, setCurrentPageType }) => {
        return (
            <div>
                <QuestionPreview question={secondary_questions[currentSecondaryIndex]} setCurrentPageType={setCurrentPageType} />
            </div>
        );
    };

    useEffect(() => {
        if(secondary_questions.format !== 'Multiple Choice' || currentPage !== 'Secondary Question Preview') {
            setCurrentPageType('');
        }
    }, [secondary_questions.format, currentPage]); // React will re-run this effect whenever `question.format` or `currentPage` changes    
    
    const base64Cache = {};

    // States for background image
    const [selectedBgImage, setSelectedBgImage] = useState(themeStyles[inputs.survey_theme].survey_background);
    const [bgImageUploaded, setBgImageUploaded] = useState(false);

    // States for overlay image
    const [selectedOverlayImage, setSelectedOverlayImage] = useState(themeStyles[inputs.survey_theme].survey_overlay);
    const [overlayImageUploaded, setOverlayImageUploaded] = useState(false);

    // States for recommendation image
    const [selectedRecommendImage, setSelectedRecommendImage] = useState(themeStyles[inputs.survey_theme].survey_recommendation);
    const [recommendImageUploaded, setRecommendImageUploaded] = useState(false);

    const [currentTheme, setCurrentTheme] = useState(inputs.survey_theme);
    const [inputKey, setInputKey] = useState(Date.now());
    const [bgIconColor, setBgIconColor] = React.useState('lightgray');
    const [overlayIconColor, setOverlayIconColor] = React.useState('lightgray');
    const [recommendIconColor, setRecommendIconColor] = React.useState('lightgray');

    const [fileName1, setFileName1] = useState("");
    const [fileName2, setFileName2] = useState("");
    const [fileName3, setFileName3] = useState("");

    const getImageDimensions = async (url) => {
        return new Promise((resolve, reject) => {
            let img = new Image();
            img.onload = () => resolve({ height: img.height, width: img.width });
            img.onerror = reject;
            img.src = url;
        });
    };
    
    const handleBgImageChange = async (event) => {
        if (event.target.files && event.target.files[0]) {
            const file = event.target.files[0];
        
            // If we have this file cached, use the cache
            if (base64Cache[file.name]) {
                setInputs(prevInputs => ({
                    ...prevInputs,
                    survey_background: base64Cache[file.name]
                }));
                setSelectedBgImage(URL.createObjectURL(new Blob([base64Cache[file.name]])));
                setBgImageUploaded(true);
            } else {
                const options = {
                    maxSizeMB: 1, // max file size
                    maxWidthOrHeight: 1920, // max width and height
                    useWebWorker: true
                };
        
                try {
                    const compressedFile = await imageCompression(file, options);
                    const reader = new FileReader();
                    const defaultImageDimensions = await getImageDimensions(themeStyles[inputs.survey_theme].survey_background);
                    
                    reader.onloadend = async () => {
                        let rescaledImage = await resizeImage(reader.result, defaultImageDimensions.width, defaultImageDimensions.height);
                        setSelectedBgImage(URL.createObjectURL(rescaledImage));
                        setInputs(prevInputs => ({  
                        ...prevInputs,
                        survey_background: reader.result
                        }));
                        setBgImageUploaded(true);
                        
                        // Cache the result
                        base64Cache[file.name] = reader.result;
                    };
            
                    reader.readAsDataURL(compressedFile);
                    setFileName1(compressedFile ? compressedFile.name : "");
                } catch (error) {
                    console.error('Image compression failed:', error);
                }
            }
        }
    };         
          
    const handleOverlayImageChange = async (event) => {
        if (event.target.files && event.target.files[0]) {
            const file = event.target.files[0];
        
            // If we have this file cached, use the cache
            if (base64Cache[file.name]) {
                setInputs(prevInputs => ({
                    ...prevInputs,
                    survey_overlay: base64Cache[file.name]
                }));
                setSelectedOverlayImage(URL.createObjectURL(new Blob([base64Cache[file.name]])));
                setOverlayImageUploaded(true);
            } else {
                const options = {
                    maxSizeMB: 1, // max file size
                    maxWidthOrHeight: 1920, // max width and height
                    useWebWorker: true
                };
        
                try {
                    const compressedFile = await imageCompression(file, options);
                    const reader = new FileReader();
                    const defaultOverlayImageDimensions = await getImageDimensions(themeStyles[inputs.survey_theme].survey_overlay);
                    
                    reader.onloadend = async () => {
                        let resizedImage = await resizeImage(reader.result, defaultOverlayImageDimensions.width, defaultOverlayImageDimensions.height);
                        setSelectedOverlayImage(URL.createObjectURL(resizedImage));
                        setInputs(prevInputs => ({  
                            ...prevInputs,
                            survey_overlay: reader.result
                        }));
                        setOverlayImageUploaded(true);
                        
                        // Cache the result
                        base64Cache[file.name] = reader.result;
                    };
            
                    reader.readAsDataURL(compressedFile);
                    setFileName2(compressedFile ? compressedFile.name : "");
                } catch (error) {
                    console.error('Image compression failed:', error);
                }
            }
        }
      };
      
    const handleRecommendImageChange = async (event) => {
        if (event.target.files && event.target.files[0]) {
            const file = event.target.files[0];
        
            // If we have this file cached, use the cache
            if (base64Cache[file.name]) {
                setInputs(prevInputs => ({
                    ...prevInputs,
                    survey_recommendation: base64Cache[file.name]
                }));
                setSelectedRecommendImage(URL.createObjectURL(new Blob([base64Cache[file.name]])));
                setRecommendImageUploaded(true);
            } else {
                const options = {
                    maxSizeMB: 1, // max file size
                    maxWidthOrHeight: 1920, // max width and height
                    useWebWorker: true
                };
        
                try {
                    const compressedFile = await imageCompression(file, options);
                    const reader = new FileReader();
                    const defaultRecommendImageDimensions = await getImageDimensions(themeStyles[inputs.survey_theme].survey_recommendation);
                    
                    reader.onloadend = async () => {
                        let resizedImage = await resizeImage(reader.result, defaultRecommendImageDimensions.width, defaultRecommendImageDimensions.height);
                        setSelectedRecommendImage(URL.createObjectURL(resizedImage));
                        setInputs(prevInputs => ({  
                            ...prevInputs,
                            survey_recommendation: reader.result
                        }));
                        setRecommendImageUploaded(true);
                        
                        // Cache the result
                        base64Cache[file.name] = reader.result;
                    };
            
                    reader.readAsDataURL(compressedFile);
                    setFileName3(compressedFile ? compressedFile.name : "");
                } catch (error) {
                    console.error('Image compression failed:', error);
                }
            }
        }
    };          

    const shortenFileName = (name, length = 15) => {
      return name.length > length ? name.substr(0, length - 3) + '...' + name.substr(name.length - 3, 3) : name;
    };   

    const handleResetBgImage = () => {
        setSelectedBgImage(themeStyles[currentTheme].survey_background);
        setInputKey(Date.now());
        setBgImageUploaded(false);
        setFileName1('No files chosen');  // reset filename
    };
    
    const handleResetOverlayImage = () => {
        setSelectedOverlayImage(themeStyles[currentTheme].survey_overlay);
        setInputKey(Date.now());
        setOverlayImageUploaded(false);
        setFileName2('No files chosen');  // reset filename
    };
    
    const handleResetRecommendImage = () => {
        setSelectedRecommendImage(themeStyles[currentTheme].survey_recommendation);
        setInputKey(Date.now());
        setRecommendImageUploaded(false);
        setFileName3('No files chosen');  // reset filename
    };    

    useEffect(() => {
        if (inputs.survey_theme !== currentTheme) {
            setSelectedBgImage(themeStyles[inputs.survey_theme].survey_background);
            setCurrentTheme(inputs.survey_theme);
            setInputKey(Date.now());
            setBgImageUploaded(false);
        }
    }, [inputs.survey_theme]);

    const resizeImage = (base64Str, maxWidth, maxHeight) => {
        return new Promise((resolve, reject) => {
            let img = new Image();
            img.src = base64Str;
            img.onload = () => {
                let canvas = document.createElement('canvas');
                canvas.width = maxWidth;
                canvas.height = maxHeight;
                let ctx = canvas.getContext('2d');
                ctx.drawImage(img, 0, 0, canvas.width, canvas.height);
                canvas.toBlob(resolve, 'image/jpeg', 0.6);
            };
            img.onerror = reject;
        });
    };

    const backgroundImageStyle = {
        width: '50%',
        height: '100%', // 1/2 height of the container, so it covers 1/2 of the image below
        objectFit: 'cover',
    };
    
    const overlayImageStyle = {
        position: 'absolute', // this will take the image out of the normal document flow
        bottom: 0, // place it at the bottom of the parent container
        width: '100%',
        height: '45%',
        zIndex: 2,
        borderBottomLeftRadius: '20px',
        borderBottomRightRadius: '20px',
        objectFit: 'cover',
    };
    
    const overlayRecommendedImageStyle = {
        position: 'absolute', // this will take the image out of the normal document flow
        right: 0,  // place it at the right of the parent container
        width: '40%',
        height: '100%',
        zIndex: 2,
        borderTopRightRadius: '20px',
        borderBottomRightRadius: '20px',
        objectFit: 'cover',
    };
    
    return (
        <Box sx={{ display: 'flex' }}>
            {/* The drawer is where all of the survey settings are located */}
            <Drawer variant="permanent" 
                    containerstyle={{ height: 'calc(100%-64px)', width: 'auto', top: 64 }}
                    sx={{ width: drawerWidth, flexShrink: 0, [`& .MuiDrawer-paper`]: { width: drawerWidth, height: drawerHeight, boxSizing: 'border-box', position: 'relative', zIndex: 'tootip' }}}>
                {/* This box holds all of the variable input fields. */}
                <Box sx={{ overflow: 'auto', position: 'absolute', top: 0, left: 30 }}>
                    <Box display="flex" alignItems="end">
                    <FormControl>
                        <FormLabel id="survey-themes-label"><b>Survey Themes</b></FormLabel>
                        <Divider orientation='horizontal' sx={{ height: '10px' }} />
                        <RadioGroup
                            column
                            aria-labelledby="survey-themes-label"
                            name="survey_theme"
                            value={inputs.survey_theme}
                            onChange={handleInputChange}
                        >
                            <FormControlLabel value="Bright Berry" control={<Radio />} label="Bright Berry" />
                            <FormControlLabel value="Citrus" control={<Radio />} label="Citrus" />
                            <FormControlLabel value="Black Givaudan" control={<Radio />} label="Black Givaudan" />
                            <FormControlLabel value="Light" control={<Radio />} label="Light" />
                            <FormControlLabel value="Luxe" control={<Radio />} label="Luxe" />
                            <FormControlLabel value="Tropical" control={<Radio />} label="Tropical" />
                        </RadioGroup>
                    </FormControl>
                    </Box>
                    
                    <Divider orientation='horizontal' sx={{ height: '20px' }} />

                    <Box display="flex" alignItems="end">
                        <FormControl>
                            <FormLabel id="customization-options-label"><b>Customization Options</b></FormLabel>
                            <Divider orientation='horizontal' sx={{ height: '10px' }} />

                            <Accordion>
                                <AccordionSummary
                                expandIcon={<ExpandMoreIcon />}
                                aria-controls="panel1a-content"
                                id="panel1a-header"
                                >
                                <Typography>Colors</Typography>
                                </AccordionSummary>

                                <AccordionDetails>
                                <List>
                                    {fieldsWithPicker.map((fieldName) => (
                                        <ListItem key={fieldName} disablePadding>
                                            <ListItemButton onClick={() => handleColorButtonClick(fieldName)}>
                                                <ListItemText primary={fieldName.split('_').map((word) => word.charAt(0).toUpperCase() + word.slice(1)).join(' ')} value={inputs[fieldName]}/>
                                                <ColorLensIcon style={{color: inputs[fieldName]}} />
                                            </ListItemButton>
                                            {showColorPickers[fieldName] && (
                                                <div ref={(el) => (pickersRef.current[fieldName] = el)} data-field-name={fieldName}>
                                                    <SketchPicker 
                                                        color={
                                                            fieldName === 'page_background' ? middleLayerColor : inputs[fieldName]
                                                        } 
                                                        onChange={(color) => handleColorChange(color, fieldName)} 
                                                        onClose={() => handleClosePicker(fieldName)} 
                                                    />
                                                    <center><button onClick={() => handleClosePicker(fieldName)}>Close</button></center>
                                                </div>
                                            )}
                                        </ListItem>
                                    ))}
                                </List>
                                </AccordionDetails>
                            </Accordion>

                            <Accordion>
                                <AccordionSummary
                                expandIcon={<ExpandMoreIcon />}
                                aria-controls="panel1a-content"
                                id="panel1a-header"
                                >
                                    <Typography>Typography</Typography>
                                </AccordionSummary>
                                <AccordionDetails>
                                <Accordion>
                                    <AccordionSummary
                                    expandIcon={<ExpandMoreIcon />}
                                    aria-controls="panel1a-content"
                                    id="panel1a-header"
                                    >
                                        <Typography>Page Title</Typography>
                                    </AccordionSummary>
                                    <AccordionDetails>
                                    <FormControl>
                                        <FormGroup>
                                        <FormControlLabel
                                            sx={{ p: 0.5 }}
                                            label="Bold"
                                            labelPlacement="start"
                                            control={<Android12Switch checked={inputs.page_title_bold}
                                            onChange={handleSwitchChange} name="page_title_bold"/>}
                                        />
                                        </FormGroup>
                                    </FormControl>
                                    <FormControl>
                                        <FormGroup>
                                        <FormControlLabel
                                            sx={{ p: 0.5 }}
                                            label="Italic"
                                            labelPlacement="start"
                                            control={<Android12Switch checked={inputs.page_title_italic}
                                            onChange={handleSwitchChange} name="page_title_italic"/>}
                                        />
                                        </FormGroup>
                                    </FormControl>
                                    </AccordionDetails>
                                </Accordion>
                                <Accordion>
                                    <AccordionSummary
                                    expandIcon={<ExpandMoreIcon />}
                                    aria-controls="panel1a-content"
                                    id="panel1a-header"
                                    >
                                    <Typography>Description Text</Typography>
                                    </AccordionSummary>
                                    <AccordionDetails>
                                    <FormControl>
                                        <FormGroup>
                                        <FormControlLabel
                                            sx={{ p: 0.5 }}
                                            label="Bold"
                                            labelPlacement="start"
                                            control={<Android12Switch checked={inputs.description_text_bold}
                                            onChange={handleSwitchChange} name="description_text_bold"/>}
                                        />
                                        </FormGroup>
                                    </FormControl>
                                    <FormControl>
                                        <FormGroup>
                                        <FormControlLabel
                                            sx={{ p: 0.5 }}
                                            label="Italic"
                                            labelPlacement="start"
                                            control={<Android12Switch checked={inputs.description_text_italic}
                                            onChange={handleSwitchChange} name="description_text_italic"/>}
                                        />
                                        </FormGroup>
                                    </FormControl>
                                    </AccordionDetails>
                                </Accordion>
                                <Accordion>
                                    <AccordionSummary
                                    expandIcon={<ExpandMoreIcon />}
                                    aria-controls="panel1a-content"
                                    id="panel1a-header"
                                    >
                                    <Typography>Question Text</Typography>
                                    </AccordionSummary>
                                    <AccordionDetails>
                                    <FormControl>
                                        <FormGroup>
                                        <FormControlLabel
                                            sx={{ p: 0.5 }}
                                            label="Bold"
                                            labelPlacement="start"
                                            control={<Android12Switch checked={inputs.question_bold}
                                            onChange={handleSwitchChange} name="question_bold"/>}
                                        />
                                        </FormGroup>
                                    </FormControl>
                                    <FormControl>
                                        <FormGroup>
                                        <FormControlLabel
                                            sx={{ p: 0.5 }}
                                            label="Italic"
                                            labelPlacement="start"
                                            control={<Android12Switch checked={inputs.question_italic}
                                            onChange={handleSwitchChange} name="question_italic"/>}
                                        />
                                        </FormGroup>
                                    </FormControl>
                                    </AccordionDetails>
                                </Accordion>
                                <Accordion>
                                    <AccordionSummary
                                    expandIcon={<ExpandMoreIcon />}
                                    aria-controls="panel1a-content"
                                    id="panel1a-header"
                                    >
                                    <Typography>Answer Text</Typography>
                                    </AccordionSummary>
                                    <AccordionDetails>
                                    <FormControl>
                                        <FormGroup>
                                        <FormControlLabel
                                            sx={{ p: 0.5 }}
                                            label="Bold"
                                            labelPlacement="start"
                                            control={<Android12Switch checked={inputs.answer_bold}
                                            onChange={handleSwitchChange} name="answer_bold"/>}
                                        />
                                        </FormGroup>
                                    </FormControl>
                                    <FormControl>
                                        <FormGroup>
                                        <FormControlLabel
                                            sx={{ p: 0.5 }}
                                            label="Italic"
                                            labelPlacement="start"
                                            control={<Android12Switch checked={inputs.answer_italic}
                                            onChange={handleSwitchChange} name="answer_italic"/>}
                                        />
                                        </FormGroup>
                                    </FormControl>
                                    </AccordionDetails>
                                </Accordion>
                                <Accordion>
                                    <AccordionSummary
                                    expandIcon={<ExpandMoreIcon />}
                                    aria-controls="panel1a-content"
                                    id="panel1a-header"
                                    >
                                    <Typography>Answer Description Text</Typography>
                                    </AccordionSummary>
                                    <AccordionDetails>
                                    <FormControl>
                                        <FormGroup>
                                        <FormControlLabel
                                            sx={{ p: 0.5 }}
                                            label="Bold"
                                            labelPlacement="start"
                                            control={<Android12Switch checked={inputs.answer_description_bold}
                                            onChange={handleSwitchChange} name="answer_description_bold"/>}
                                        />
                                        </FormGroup>
                                    </FormControl>
                                    <FormControl>
                                        <FormGroup>
                                        <FormControlLabel
                                            sx={{ p: 0.5 }}
                                            label="Italic"
                                            labelPlacement="start"
                                            control={<Android12Switch checked={inputs.answer_description_italic}
                                            onChange={handleSwitchChange} name="answer_description_italic"/>}
                                        />
                                        </FormGroup>
                                    </FormControl>
                                    </AccordionDetails>
                                </Accordion>
                                <Accordion>
                                    <AccordionSummary
                                    expandIcon={<ExpandMoreIcon />}
                                    aria-controls="panel1a-content"
                                    id="panel1a-header"
                                    >
                                    <Typography>Error Text</Typography>
                                    </AccordionSummary>
                                    <AccordionDetails>
                                    <FormControl>
                                        <FormGroup>
                                        <FormControlLabel
                                            sx={{ p: 0.5 }}
                                            label="Bold"
                                            labelPlacement="start"
                                            control={<Android12Switch checked={inputs.error_bold}
                                            onChange={handleSwitchChange} name="error_bold"/>}
                                        />
                                        </FormGroup>
                                    </FormControl>
                                    <FormControl>
                                        <FormGroup>
                                        <FormControlLabel
                                            sx={{ p: 0.5 }}
                                            label="Italic"
                                            labelPlacement="start"
                                            control={<Android12Switch checked={inputs.error_italic}
                                            onChange={handleSwitchChange} name="error_italic"/>}
                                        />
                                        </FormGroup>
                                    </FormControl>
                                    </AccordionDetails>
                                </Accordion>
                                <Accordion>
                                    <AccordionSummary
                                    expandIcon={<ExpandMoreIcon />}
                                    aria-controls="panel1a-content"
                                    id="panel1a-header"
                                    >
                                    <Typography>Recommendation Name</Typography>
                                    </AccordionSummary>
                                    <AccordionDetails>
                                    <FormControl>
                                        <FormGroup>
                                        <FormControlLabel
                                            sx={{ p: 0.5 }}
                                            label="Bold"
                                            labelPlacement="start"
                                            control={<Android12Switch checked={inputs.recommendation_name_bold}
                                            onChange={handleSwitchChange} name="recommendation_name_bold"/>}
                                        />
                                        </FormGroup>
                                    </FormControl>
                                    <FormControl>
                                        <FormGroup>
                                        <FormControlLabel
                                            sx={{ p: 0.5 }}
                                            label="Italic"
                                            labelPlacement="start"
                                            control={<Android12Switch checked={inputs.recommendation_name_italic}
                                            onChange={handleSwitchChange} name="recommendation_name_italic"/>}
                                        />
                                        </FormGroup>
                                    </FormControl>
                                    </AccordionDetails>
                                </Accordion>
                                <Accordion>
                                    <AccordionSummary
                                    expandIcon={<ExpandMoreIcon />}
                                    aria-controls="panel1a-content"
                                    id="panel1a-header"
                                    >
                                    <Typography>Recommendation Header</Typography>
                                    </AccordionSummary>
                                    <AccordionDetails>
                                    <FormControl>
                                        <FormGroup>
                                        <FormControlLabel
                                            sx={{ p: 0.5 }}
                                            label="Bold"
                                            labelPlacement="start"
                                            control={<Android12Switch checked={inputs.header_bold}
                                            onChange={handleSwitchChange} name="header_bold"/>}
                                        />
                                        </FormGroup>
                                    </FormControl>
                                    <FormControl>
                                        <FormGroup>
                                        <FormControlLabel
                                            sx={{ p: 0.5 }}
                                            label="Italic"
                                            labelPlacement="start"
                                            control={<Android12Switch checked={inputs.header_italic}
                                            onChange={handleSwitchChange} name="header_italic"/>}
                                        />
                                        </FormGroup>
                                    </FormControl>
                                    </AccordionDetails>
                                </Accordion>
                                </AccordionDetails>
                            </Accordion>

                            <Accordion>
                                <AccordionSummary
                                    expandIcon={<ExpandMoreIcon />}
                                    aria-controls="panel1a-content"
                                    id="panel1a-header"
                                >
                                    <Typography>Images</Typography>
                                </AccordionSummary>
                                <AccordionDetails>
                                    {/* Background Image */}
                                    <Accordion>
                                        <AccordionSummary
                                            expandIcon={<ExpandMoreIcon />}
                                            aria-controls="panel1a-content"
                                            id="panel1a-header"
                                        >
                                            <Typography>Background Image</Typography>
                                        </AccordionSummary>
                                        <AccordionDetails style={{ display: 'flex', alignItems: 'center' }}>
                                            <label htmlFor="survey_background">
                                                <Button 
                                                    component="span" 
                                                    variant="outlined"
                                                    sx={{
                                                        borderColor: 'transparent',
                                                        '&:hover': {
                                                            backgroundColor: 'lightblue',
                                                        },
                                                    }}
                                                >
                                                    {fileName1 ? shortenFileName(fileName1) : 'No files chosen'}
                                                </Button>
                                            </label>
                                            <input 
                                                type="file" 
                                                accept=".png,.jpg,.jpeg,.bmp" 
                                                id="survey_background" 
                                                name="survey_background" 
                                                onChange={handleBgImageChange}
                                                key={inputKey}
                                                style={{ display: 'none' }}
                                            />
                                            <div 
                                                style={{ left:'4%', width: '12%', height: '7%', position: 'relative', display: 'inline-block' }}
                                                onMouseEnter={() => setBgIconColor('#1976d2')}
                                                onMouseLeave={() => setBgIconColor('#909DF9')}
                                            >
                                                <img 
                                                    style={{ width: '100%', height: '100%', border: '.01px solid black' }}
                                                    src={selectedBgImage}
                                                    alt='current background'
                                                />
                                                {bgImageUploaded && (
                                                    <DeleteForeverIcon
                                                        onClick={handleResetBgImage}
                                                        style={{
                                                            position: 'absolute',
                                                            top: '6%',
                                                            left: '110%',
                                                            color: bgIconColor,
                                                            borderColor: bgIconColor,
                                                            cursor: 'pointer',
                                                        }}
                                                    />
                                                )}
                                            </div>
                                        </AccordionDetails>  
                                    </Accordion>
                                    {/* Overlay Image */}
                                    <Accordion>
                                        <AccordionSummary
                                            expandIcon={<ExpandMoreIcon />}
                                            aria-controls="panel1a-content"
                                            id="panel1a-header"
                                        >
                                            <Typography>Overlay Image</Typography>
                                        </AccordionSummary>
                                        <AccordionDetails style={{ display: 'flex', alignItems: 'center' }}>
                                            <label htmlFor="survey_overlay">
                                                <Button 
                                                    component="span" 
                                                    variant="outlined"
                                                    sx={{
                                                        borderColor: 'transparent',
                                                        '&:hover': {
                                                            backgroundColor: 'lightblue',
                                                        },
                                                    }}
                                                >
                                                    {fileName2 ? shortenFileName(fileName2) : 'No files chosen'}
                                                </Button>
                                            </label>
                                            <input 
                                                type="file" 
                                                accept=".png,.jpg,.jpeg,.bmp" 
                                                id="survey_overlay" 
                                                name="survey_overlay" 
                                                onChange={handleOverlayImageChange}
                                                key={inputKey}
                                                style={{ display: 'none' }}
                                            />
                                            <div 
                                                style={{ left:'4%', width: '12%', height: '5%', position: 'relative', display: 'inline-block' }}
                                                onMouseEnter={() => setOverlayIconColor('#1976d2')}
                                                onMouseLeave={() => setOverlayIconColor('#909DF9')}
                                            >
                                                <img 
                                                    style={{width: '100%', height: '100%', top: '20%', border: '.01px solid black'}}
                                                    src={overlayImageUploaded ? selectedOverlayImage : themeStyles[currentTheme].survey_overlay}
                                                    alt='current overlay image'
                                                />
                                                {overlayImageUploaded && (
                                                    <DeleteForeverIcon
                                                        onClick={handleResetOverlayImage}
                                                        style={{
                                                            position: 'absolute',
                                                            top: '50%',
                                                            left: '140%',
                                                            transform: 'translate(-50%, -50%)',
                                                            color: overlayIconColor,
                                                            borderColor: overlayIconColor,
                                                            cursor: 'pointer',
                                                        }}
                                                    />
                                                )}
                                            </div>
                                        </AccordionDetails>  
                                    </Accordion>
                                    {/* Recommendation Image */}
                                    <Accordion>
                                        <AccordionSummary
                                            expandIcon={<ExpandMoreIcon />}
                                            aria-controls="panel1a-content"
                                            id="panel1a-header"
                                        >
                                            <Typography>Recommendation Image</Typography>
                                        </AccordionSummary>
                                        <AccordionDetails style={{ display: 'flex', alignItems: 'center' }}>
                                            <label htmlFor="survey_recommendation">
                                                <Button 
                                                    component="span" 
                                                    variant="outlined"
                                                    sx={{
                                                        borderColor: 'transparent',
                                                        '&:hover': {
                                                            backgroundColor: 'lightblue',
                                                        },
                                                    }}
                                                >
                                                    {fileName3 ? shortenFileName(fileName3) : 'No files chosen'}
                                                </Button>
                                            </label>
                                            <input 
                                                type="file" 
                                                accept=".png,.jpg,.jpeg,.bmp" 
                                                id="survey_recommendation" 
                                                name="survey_recommendation" 
                                                onChange={handleRecommendImageChange}
                                                key={inputKey}
                                                style={{ display: 'none' }}
                                            />
                                            <div 
                                                style={{ left:'4%', width: '9%', height: '12%', position: 'relative', display: 'inline-block', border: '.01px solid black' }}
                                                onMouseEnter={() => setRecommendIconColor('#1976d2')}
                                                onMouseLeave={() => setRecommendIconColor('#909DF9')}
                                            >
                                                <img 
                                                    style={{width: '100%', height: '100%'}}
                                                    src={recommendImageUploaded ? selectedRecommendImage : themeStyles[currentTheme].survey_recommendation}
                                                    alt='current recommendation'
                                                />
                                                {recommendImageUploaded && (
                                                    <DeleteForeverIcon
                                                        onClick={handleResetRecommendImage}
                                                        style={{
                                                            position: 'absolute',
                                                            top: '20%',
                                                            left: '120%',
                                                            color: recommendIconColor,
                                                            borderColor: recommendIconColor,
                                                            cursor: 'pointer',
                                                        }}
                                                    />
                                                )}
                                            </div>
                                        </AccordionDetails>  
                                    </Accordion>
                                </AccordionDetails>
                            </Accordion>
                        </FormControl>
                    </Box>
                <Divider orientation='horizontal' sx={{ height: '20px' }} />
                </Box>
            </Drawer>
            <Toolbar />
            <Box sx={{ height: '34.375rem', width: '56.5rem', overflow: 'hidden' }}>   
            
            <div style={{ justifyContent: 'center', width: 'auto', overflow: 'hidden', textAlign: 'center' }}>
                <h1 style={{ display: 'inline-block' }}>
                    <span>{currentPage}</span>
                    {subheading && <><span> - {subheading}</span></>}
                </h1>
            </div>
                                
            {inputs.survey_theme === "Bright Berry" ?
                <div style={parentStyle}>
                    <ArrowBackIosNewRoundedIcon style={currentStep > 0 ? buttonStyle : hiddenButtonStyle} onClick={handlePrev} />
                    <div style={containerWithButtonsStyle}> 
                        <div style={baseImageStyle}>
                        <img style={backgroundImageStyle} src={selectedBgImage || themeStyles[currentTheme].survey_background} alt={`${currentTheme} Background`} />     
                            <div style={middleLayerStyle(middleLayerColor)}>
                                {currentPage === 'Survey Complete Preview' && 
                                    <>
                                        <div style={HomeIconStyle}>
                                            <HomeIcon />
                                        </div>
                                        <div style={surveyCompleteStyle}>
                                            <p>Survey Complete. Thank you for your participation.</p>
                                        </div>
                                    </>
                                }
                                {currentPage === 'Primary Question Preview' &&
                                    <PrimarySurvey
                                        question={addPages.primary_survey_question}
                                    />
                                }
                                {currentPage === 'Secondary Question Preview' && 
                                    <SecondarySurvey
                                        secondary_questions={secondary_questions} 
                                        currentSecondaryIndex={currentSecondaryIndex}
                                        setCurrentPageType={setCurrentPageType}
                                    />
                                }
                                {currentPage === 'Instructions For Use Preview' && 
                                    <>
                                        <div style={questionStyle}>
                                            <p>{instructionsTitle}</p>
                                        </div>    
                                        <div style={{...descriptionStyle, top: '20%',}}>
                                            <p>{instructionsDescription}</p>
                                        </div>                                        
                                    </>
                                }
                                {subheading === 'Age Range' && 
                                    <>
                                        <div style={questionStyle}>
                                            <p>What is your age range?</p>
                                        </div>
                                        <RadioGroup
                                            row
                                            aria-labelledby="demo-row-radio-buttons-group-label"
                                            name="row-radio-buttons-group"
                                            style={radioGroupStyle}
                                        >
                                            {['Under 12', '12-17', '18-24', '25-34', '35-44', '45-54', '55-64', '65-74', '75 or older', 'Prefer to not answer'].map(range => (
                                                <StyledRadioContainer key={range} >
                                                    <StyledFormControlLabel 
                                                        control={
                                                            <StyledRadio 
                                                                checked={checked[range]} 
                                                                onChange={e => handleCheckChange(range, e)}
                                                            />
                                                        }
                                                        label={range}
                                                        labelPlacement="bottom"
                                                    />
                                                </StyledRadioContainer>
                                            ))}
                                        </RadioGroup>
                                    </>
                                }
                                {subheading === 'Gender' && 
                                    <>
                                        <div style={questionStyle}>
                                            <p>What is your gender?</p>
                                        </div>
                                        <RadioGroup
                                        row
                                        aria-labelledby="demo-row-radio-buttons-group-label"
                                        name="row-radio-buttons-group"
                                        style={radioGroupStyle}
                                        >
                                            {['female', 'male', 'other'].map(gender => (
                                                <StyledRadioContainer key={gender}>
                                                    <GenderStyledFormControlLabel
                                                        control={
                                                            <StyledRadio
                                                                checked={checked[gender]}
                                                                onChange={e => handleCheckChange(gender, e)}
                                                            />
                                                        }
                                                        label={gender}
                                                        labelPlacement="bottom"
                                                    />
                                                </StyledRadioContainer>
                                            ))}
                                        </RadioGroup>
                                    </>
                                }
                                {subheading === 'Race' && 
                                    <>                           
                                        <ArrowBackIcon style={progressBackIconStyle}/>                          
                                        {/* Change the color as needed. */}
                                        <ThemeProvider theme={theme}>
                                            <LinearProgress style={ProgressBarStyle} color="customColor" variant="determinate" value={60} />
                                        </ThemeProvider> 
                                        <ArrowForwardIcon style={progressForwardIconStyle}/>  
                                        <CancelOutlinedIcon style={progressCancelIconStyle}/>                                     
                                        <div style={raceQuestionStyle}>
                                            <p>With which racial group(s) do you most closely identify?<br/>
                                            <center>Check all that apply</center></p>
                                        </div>
                                        <FormGroup style={checkBoxGroupStyle}>
                                            {['African American', 'Asian American', 'Caucasian/White', 'Hispanic/Latino', 
                                              'Native American', 'Pacific Islander', 'Other', 'Prefer not to answer'].map(range => (
                                                <StyledCheckboxContainer key={range} >
                                                    <StyledCheckboxControlLabel control={<Checkbox checked={checked[range]}/>} label={range} />
                                                </StyledCheckboxContainer>
                                            ))}
                                        </FormGroup>
                                    </>
                                }
                                {subheading === 'Custom' && 
                                    <>
                                        <div style={questionStyle}>
                                            <p>{custom_question}</p>
                                        </div>
                                        <div style={{...shapeDivStyle, top: '25%',}}>
                                            {Array(custom_steps).fill().map((_, i) => 
                                                <ShapeAndLabel 
                                                    shape={custom_question_shape} 
                                                    steps={custom_steps} 
                                                    index={i}
                                                    showRatingLabels={custom_question_show_rating_labels}
                                                    ratingLabels={custom_rating_labels}
                                                    key={i} 
                                                />
                                            )}
                                        </div>
                                    </>
                                }
                                {currentPage === 'Recommendations Preview' && 
                                    <>
                                        <div style={HomeIconStyle}>
                                            <HomeIcon />
                                        </div>
                                        <div style={containerRecommendationStyle}>
                                            <div style={column60Style}>
                                                <div style={rowStyle1}>
                                                    <div style={columnStyle1}>
                                                        <div>
                                                            <p style={questionStyleLeft}>
                                                                What you liked
                                                            </p>
                                                            <p style={smellInstructionStyle}>
                                                                Click on the word to smell
                                                            </p>
                                                        </div>
                                                        {addPages.add_recommendation && addPages.add_recommendation.drivers_of_liking &&
                                                            recommendations1.map((rec, index) => (
                                                                <p key={index} 
                                                                style={{
                                                                    ...questionStyleLeft, 
                                                                    marginLeft: '1%',
                                                                    top: `${20 + (10 * index)}%`,
                                                                    color: inputs.recommendation_title, // color value 'bodyText'
                                                                    fontFamily: fontFamily, // font family 'Font'
                                                                    fontWeight: inputs.recommendation_name_bold ? 'bold' : 'normal', // add 'bold' if true, else 'normal'
                                                                    fontStyle: inputs.recommendation_name_italic ? 'italic' : 'normal', // add 'italic' if true, else 'normal'
                                                                }}>
                                                                    {rec.rec}
                                                                </p>
                                                            ))
                                                        }
                                                    </div>
                                                    <div style={columnStyle2}>
                                                        <div>
                                                            <p style={questionStyleRight}>
                                                                What you didn't like
                                                            </p>
                                                        </div>
                                                        {addPages.add_recommendation && addPages.add_recommendation.drivers_of_liking &&
                                                            recommendations2.map((rec, index) => (
                                                            <p key={index} 
                                                            style={{
                                                                        ...questionStyleLeft, 
                                                                        top: `${20 + (10 * index)}%`,
                                                                        paddingLeft: '25%',
                                                                        paddingRight: '0%',
                                                                        color: inputs.recommendation_title, // color value 'bodyText'
                                                                        fontFamily: fontFamily, // font family 'Font'
                                                                        fontWeight: inputs.recommendation_name_bold ? 'bold' : 'normal', // add 'bold' if true, else 'normal'
                                                                        fontStyle: inputs.recommendation_name_italic ? 'italic' : 'normal', // add 'italic' if true, else 'normal'
                                                                    }}
                                                            >
                                                                {rec.rec}
                                                            </p>
                                                        ))}
                                                    </div>
                                                </div>
                                                <div style={{
                                                    ...rowStyle2, 
                                                }}>
                                                    <p style={{...questionStyleBottom, marginLeft: '4%', marginRight: '4%', marginBottom: '4%', fontSize: '70%'}}>
                                                        Based on your responses, we think you might like these 3 products (click on the images to smell them)
                                                    </p>
                                                    <div style={{
                                                        ...questionStyleBottom,
                                                        backgroundColor: inputs.recommendation_title,
                                                        width: '15%',
                                                        height: '30%',
                                                        top: '66%',
                                                        marginRight: '35%',
                                                        display: 'flex',
                                                        flexDirection: 'column',
                                                        borderRadius: '6%',
                                                    }}>
                                                        {product1 && (
                                                            <p style={{
                                                                height: '10%',
                                                                color: inputs.recommendation_name,
                                                                fontFamily: fontFamily, // font family 'Font'
                                                                fontWeight: inputs.recommendation_name_bold ? 'bold' : 'normal', // add 'bold' if true, else 'normal'
                                                                fontStyle: inputs.recommendation_name_italic ? 'italic' : 'normal', // add 'italic' if true, else 'normal'
                                                                textAlign: 'center',
                                                                fontSize: '70%',
                                                                paddingTop: '5%',
                                                            }}>
                                                                {product1.rec}
                                                            </p>
                                                        )}
                                                        <div style={{
                                                            height: '70%',  
                                                            backgroundImage: `url(${BrightBerryRecommended})`, 
                                                            backgroundSize: 'contain',
                                                            backgroundRepeat: 'no-repeat',
                                                            backgroundPosition: 'center',
                                                        }}>
                                                            {/* insert an image or any other elements here */}
                                                        </div>
                                                    </div>
                                                    <div style={{
                                                        ...questionStyleBottom,
                                                        backgroundColor: inputs.recommendation_title,
                                                        width: '15%',
                                                        height: '30%',
                                                        top: '66%',
                                                        display: 'flex',
                                                        flexDirection: 'column',
                                                        borderRadius: '6%',
                                                    }}>
                                                        {product2 && (
                                                            <p style={{
                                                                height: '10%',
                                                                color: inputs.recommendation_name,
                                                                fontFamily: fontFamily, // font family 'Font'
                                                                fontWeight: inputs.recommendation_name_bold ? 'bold' : 'normal', // add 'bold' if true, else 'normal'
                                                                fontStyle: inputs.recommendation_name_italic ? 'italic' : 'normal', // add 'italic' if true, else 'normal'
                                                                textAlign: 'center',
                                                                fontSize: '70%',
                                                                paddingTop: '5%',
                                                            }}>
                                                                {product2.rec}
                                                            </p>
                                                        )}
                                                        <div style={{
                                                            height: '70%', 
                                                            backgroundImage: `url(${BrightBerryRecommended})`, 
                                                            backgroundSize: 'contain',
                                                            backgroundRepeat: 'no-repeat',
                                                            backgroundPosition: 'center',
                                                        }}>
                                                            {/* insert an image or any other elements here */}
                                                        </div>
                                                    </div>
                                                    <div style={{
                                                        ...questionStyleBottom,
                                                        backgroundColor: inputs.recommendation_title,
                                                        width: '15%',
                                                        height: '30%',
                                                        top: '66%',
                                                        marginLeft: '35%',
                                                        display: 'flex',
                                                        flexDirection: 'column',
                                                        borderRadius: '6%',
                                                    }}>
                                                        {product3 && (
                                                            <p style={{
                                                                height: '10%',
                                                                color: inputs.recommendation_name,
                                                                fontFamily: fontFamily, // font family 'Font'
                                                                fontWeight: inputs.recommendation_name_bold ? 'bold' : 'normal', // add 'bold' if true, else 'normal'
                                                                fontStyle: inputs.recommendation_name_italic ? 'italic' : 'normal', // add 'italic' if true, else 'normal'
                                                                textAlign: 'center',
                                                                fontSize: '70%',
                                                                paddingTop: '5%',
                                                            }}>
                                                                {product3.rec}
                                                            </p>
                                                        )}
                                                        <div style={{
                                                            height: '70%', 
                                                            backgroundImage: `url(${BrightBerryRecommended})`, 
                                                            backgroundSize: 'contain',
                                                            backgroundRepeat: 'no-repeat',
                                                            backgroundPosition: 'center',
                                                        }}>
                                                            {/* insert an image or any other elements here */}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div> 
                                            <img style={overlayRecommendedImageStyle} src={selectedRecommendImage || BrightBerryRecommended} alt="Bright Berry Primary" />
                                        </div>
                                    </>
                                }
                                {currentPage === 'Loading Screen Preview' && 
                                    <>
                                        <div style={pageTitleStyle}>
                                            <p>Please Wait ...</p>
                                        </div>
                                        <div style={descriptionStyle}>
                                            <p>Please wait while the next aroma is being prepared</p>
                                        </div>
                                    </>
                                }                           
                                { (currentPage != 'Recommendations Preview' && !isCurrentQuestionMCQ()) &&
                                    <img style={overlayImageStyle} src={selectedOverlayImage || BrightBerryPrimary} alt="Bright Berry Primary" />
                                }
                            </div>
                        </div>                        
                    </div>
                    <ArrowForwardIosRoundedIcon style={currentStep < totalStep ? buttonStyle : hiddenButtonStyle} onClick={handleNext} />
                </div>
            : inputs.survey_theme === "Citrus" ?
                <div style={parentStyle}>
                    <ArrowBackIosNewRoundedIcon style={currentStep > 0 ? buttonStyle : hiddenButtonStyle} onClick={handlePrev} />
                    <div style={containerWithButtonsStyle}> 
                        <div style={baseImageStyle}>
                        <img style={backgroundImageStyle} src={selectedBgImage || themeStyles[currentTheme].survey_background} alt={`${currentTheme} Background`} />  
                            <div style={middleLayerStyle(middleLayerColor)}>
                                {currentPage === 'Survey Complete Preview' && 
                                    <>
                                        <div style={HomeIconStyle}>
                                            <HomeIcon />
                                        </div>
                                        <div style={surveyCompleteStyle}>
                                            <p>Survey Complete. Thank you for your participation.</p>
                                        </div>
                                    </>
                                }
                                {currentPage === 'Primary Question Preview' &&
                                    <PrimarySurvey
                                        question={addPages.primary_survey_question}
                                    />
                                }
                                {currentPage === 'Secondary Question Preview' && 
                                    <SecondarySurvey
                                        secondary_questions={secondary_questions} 
                                        currentSecondaryIndex={currentSecondaryIndex}
                                        setCurrentPageType={setCurrentPageType}
                                    />
                                }
                                {currentPage === 'Instructions For Use Preview' && 
                                    <>
                                        <div style={questionStyle}>
                                            <p>{instructionsTitle}</p>
                                        </div>    
                                        <div style={{...descriptionStyle, top: '20%',}}>
                                            <p>{instructionsDescription}</p>
                                        </div>                                        
                                    </>
                                }
                                {subheading === 'Age Range' && 
                                    <>
                                        <div style={questionStyle}>
                                            <p>What is your age range?</p>
                                        </div>
                                        <RadioGroup
                                            row
                                            aria-labelledby="demo-row-radio-buttons-group-label"
                                            name="row-radio-buttons-group"
                                            style={radioGroupStyle}
                                        >
                                            {['Under 12', '12-17', '18-24', '25-34', '35-44', '45-54', '55-64', '65-74', '75 or older', 'Prefer to not answer'].map(range => (
                                                <StyledRadioContainer key={range} >
                                                    <StyledFormControlLabel 
                                                        control={
                                                            <StyledRadio 
                                                                checked={checked[range]} 
                                                                onChange={e => handleCheckChange(range, e)}
                                                            />
                                                        }
                                                        label={range}
                                                        labelPlacement="bottom"
                                                    />
                                                </StyledRadioContainer>
                                            ))}
                                        </RadioGroup>
                                    </>
                                }
                                {subheading === 'Gender' && 
                                    <>
                                        <div style={questionStyle}>
                                            <p>What is your gender?</p>
                                        </div>
                                        <RadioGroup
                                        row
                                        aria-labelledby="demo-row-radio-buttons-group-label"
                                        name="row-radio-buttons-group"
                                        style={radioGroupStyle}
                                        >
                                            {['female', 'male', 'other'].map(gender => (
                                                <StyledRadioContainer key={gender}>
                                                    <GenderStyledFormControlLabel
                                                        control={
                                                            <StyledRadio
                                                                checked={checked[gender]}
                                                                onChange={e => handleCheckChange(gender, e)}
                                                            />
                                                        }
                                                        label={gender}
                                                        labelPlacement="bottom"
                                                    />
                                                </StyledRadioContainer>
                                            ))}
                                        </RadioGroup>
                                    </>
                                }
                                {subheading === 'Race' && 
                                    <>                           
                                        <ArrowBackIcon style={progressBackIconStyle}/>                          
                                        {/* Change the color as needed. */}
                                        <ThemeProvider theme={theme}>
                                            <LinearProgress style={ProgressBarStyle} color="customColor" variant="determinate" value={60} />
                                        </ThemeProvider> 
                                        <ArrowForwardIcon style={progressForwardIconStyle}/>  
                                        <CancelOutlinedIcon style={progressCancelIconStyle}/>                                     
                                        <div style={raceQuestionStyle}>
                                            <p>With which racial group(s) do you most closely identify?<br/>
                                            <center>Check all that apply</center></p>
                                        </div>
                                        <FormGroup style={checkBoxGroupStyle}>
                                            {['African American', 'Asian American', 'Caucasian/White', 'Hispanic/Latino', 
                                              'Native American', 'Pacific Islander', 'Other', 'Prefer not to answer'].map(range => (
                                                <StyledCheckboxContainer key={range} >
                                                    <StyledCheckboxControlLabel control={<Checkbox checked={checked[range]}/>} label={range} />
                                                </StyledCheckboxContainer>
                                            ))}
                                        </FormGroup>
                                    </>
                                }
                                {subheading === 'Custom' && 
                                    <>
                                        <div style={questionStyle}>
                                            <p>{custom_question}</p>
                                        </div>
                                        <div style={{...shapeDivStyle, top: '25%',}}>
                                            {Array(custom_steps).fill().map((_, i) => 
                                                <ShapeAndLabel 
                                                    shape={custom_question_shape} 
                                                    steps={custom_steps} 
                                                    index={i}
                                                    showRatingLabels={custom_question_show_rating_labels}
                                                    ratingLabels={custom_rating_labels}
                                                    key={i} 
                                                />
                                            )}
                                        </div>
                                    </>
                                }
                                {currentPage === 'Recommendations Preview' && 
                                    <>
                                        <div style={containerRecommendationStyle}>
                                            <div style={column60Style}>
                                                <div style={rowStyle1}>
                                                    <div style={columnStyle1}>
                                                        <div>
                                                            <p style={questionStyleLeft}>
                                                                What you liked
                                                            </p>
                                                            <p style={smellInstructionStyle}>
                                                                Click on the word to smell
                                                            </p>
                                                        </div>
                                                        {recommendations1.map((rec, index) => (
                                                            <p key={index} 
                                                            style={{
                                                                        ...questionStyleLeft, 
                                                                        marginLeft: '1%',
                                                                        top: `${20 + (10 * index)}%`,
                                                                        color: inputs.recommendation_title, // color value 'bodyText'
                                                                        fontFamily: fontFamily, // font family 'Font'
                                                                        fontWeight: inputs.recommendation_name_bold ? 'bold' : 'normal', // add 'bold' if true, else 'normal'
                                                                        fontStyle: inputs.recommendation_name_italic ? 'italic' : 'normal', // add 'italic' if true, else 'normal'
                                                                    }}
                                                            >
                                                                {rec.rec}
                                                            </p>
                                                        ))}
                                                    </div>
                                                    <div style={columnStyle2}>
                                                        <div>
                                                            <p style={questionStyleRight}>
                                                                What you didn't like
                                                            </p>
                                                        </div>
                                                        {recommendations2.map((rec, index) => (
                                                            <p key={index} 
                                                            style={{
                                                                        ...questionStyleLeft, 
                                                                        top: `${20 + (10 * index)}%`,
                                                                        paddingLeft: '25%',
                                                                        paddingRight: '0%',
                                                                        color: inputs.recommendation_title, // color value 'bodyText'
                                                                        fontFamily: fontFamily, // font family 'Font'
                                                                        fontWeight: inputs.recommendation_name_bold ? 'bold' : 'normal', // add 'bold' if true, else 'normal'
                                                                        fontStyle: inputs.recommendation_name_italic ? 'italic' : 'normal', // add 'italic' if true, else 'normal'
                                                                    }}
                                                            >
                                                                {rec.rec}
                                                            </p>
                                                        ))}
                                                    </div>
                                                </div>
                                                <div style={{
                                                    ...rowStyle2, 
                                                }}>
                                                    <p style={{...questionStyleBottom, marginLeft: '4%', marginRight: '4%', marginBottom: '4%', fontSize: '70%'}}>
                                                        Based on your responses, we think you might like these 3 products (click on the images to smell them)
                                                    </p>
                                                    <div style={{
                                                        ...questionStyleBottom,
                                                        backgroundColor: inputs.recommendation_title,
                                                        width: '15%',
                                                        height: '30%',
                                                        top: '66%',
                                                        marginRight: '35%',
                                                        display: 'flex',
                                                        flexDirection: 'column',
                                                        borderRadius: '6%',
                                                    }}>
                                                        {product1 && (
                                                            <p style={{
                                                                height: '10%',
                                                                color: inputs.recommendation_name,
                                                                fontFamily: fontFamily, // font family 'Font'
                                                                fontWeight: inputs.recommendation_name_bold ? 'bold' : 'normal', // add 'bold' if true, else 'normal'
                                                                fontStyle: inputs.recommendation_name_italic ? 'italic' : 'normal', // add 'italic' if true, else 'normal'
                                                                textAlign: 'center',
                                                                fontSize: '70%',
                                                                paddingTop: '5%',
                                                            }}>
                                                                {product1.rec}
                                                            </p>
                                                        )}
                                                        <div style={{
                                                            height: '70%',  
                                                            backgroundImage: `url(${BrightBerryRecommended})`, 
                                                            backgroundSize: 'contain',
                                                            backgroundRepeat: 'no-repeat',
                                                            backgroundPosition: 'center',
                                                        }}>
                                                            {/* insert an image or any other elements here */}
                                                        </div>
                                                    </div>
                                                    <div style={{
                                                        ...questionStyleBottom,
                                                        backgroundColor: inputs.recommendation_title,
                                                        width: '15%',
                                                        height: '30%',
                                                        top: '66%',
                                                        display: 'flex',
                                                        flexDirection: 'column',
                                                        borderRadius: '6%',
                                                    }}>
                                                        {product2 && (
                                                            <p style={{
                                                                height: '10%',
                                                                color: inputs.recommendation_name,
                                                                fontFamily: fontFamily, // font family 'Font'
                                                                fontWeight: inputs.recommendation_name_bold ? 'bold' : 'normal', // add 'bold' if true, else 'normal'
                                                                fontStyle: inputs.recommendation_name_italic ? 'italic' : 'normal', // add 'italic' if true, else 'normal'
                                                                textAlign: 'center',
                                                                fontSize: '70%',
                                                                paddingTop: '5%',
                                                            }}>
                                                                {product2.rec}
                                                            </p>
                                                        )}
                                                        <div style={{
                                                            height: '70%', 
                                                            backgroundImage: `url(${BrightBerryRecommended})`, 
                                                            backgroundSize: 'contain',
                                                            backgroundRepeat: 'no-repeat',
                                                            backgroundPosition: 'center',
                                                        }}>
                                                            {/* insert an image or any other elements here */}
                                                        </div>
                                                    </div>
                                                    <div style={{
                                                        ...questionStyleBottom,
                                                        backgroundColor: inputs.recommendation_title,
                                                        width: '15%',
                                                        height: '30%',
                                                        top: '66%',
                                                        marginLeft: '35%',
                                                        display: 'flex',
                                                        flexDirection: 'column',
                                                        borderRadius: '6%',
                                                    }}>
                                                        {product3 && (
                                                            <p style={{
                                                                height: '10%',
                                                                color: inputs.recommendation_name,
                                                                fontFamily: fontFamily, // font family 'Font'
                                                                fontWeight: inputs.recommendation_name_bold ? 'bold' : 'normal', // add 'bold' if true, else 'normal'
                                                                fontStyle: inputs.recommendation_name_italic ? 'italic' : 'normal', // add 'italic' if true, else 'normal'
                                                                textAlign: 'center',
                                                                fontSize: '70%',
                                                                paddingTop: '5%',
                                                            }}>
                                                                {product3.rec}
                                                            </p>
                                                        )}
                                                        <div style={{
                                                            height: '70%', 
                                                            backgroundImage: `url(${BrightBerryRecommended})`, 
                                                            backgroundSize: 'contain',
                                                            backgroundRepeat: 'no-repeat',
                                                            backgroundPosition: 'center',
                                                        }}>
                                                            {/* insert an image or any other elements here */}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <img style={overlayRecommendedImageStyle} src={selectedRecommendImage || CitrusRecommended} alt="Citrus Primary" />
                                        </div>
                                    </>
                                }
                                {currentPage === 'Loading Screen Preview' && 
                                    <>
                                        <div style={pageTitleStyle}>
                                            <p>Please Wait ...</p>
                                        </div>
                                        <div style={descriptionStyle}>
                                            <p>Please wait while the next aroma is being prepared</p>
                                        </div>
                                    </>
                                }                            
                                { (currentPage != 'Recommendations Preview' && !isCurrentQuestionMCQ()) &&
                                    <img style={overlayImageStyle} src={selectedOverlayImage || CitrusPrimary} alt="Citrus Primary" />
                                }                             
                            </div>
                        </div>               
                    </div>
                    <ArrowForwardIosRoundedIcon style={currentStep < totalStep ? buttonStyle : hiddenButtonStyle} onClick={handleNext} />
                </div>
            : inputs.survey_theme === "Black Givaudan" ?
                <div style={parentStyle}>
                    <ArrowBackIosNewRoundedIcon style={currentStep > 0 ? buttonStyle : hiddenButtonStyle} onClick={handlePrev} />
                        <div style={containerWithButtonsStyle}>
                            <div style={baseImageStyle}>
                            <img style={backgroundImageStyle} src={selectedBgImage || themeStyles[currentTheme].survey_background} alt={`${currentTheme} Background`} />     
                                <div style={middleLayerStyle(middleLayerColor)}>
                                {currentPage === 'Survey Complete Preview' && 
                                    <>
                                        <div style={HomeIconStyle}>
                                            <HomeIcon />
                                        </div>
                                        <div style={surveyCompleteStyle}>
                                            <p>Survey Complete. Thank you for your participation.</p>
                                        </div>
                                    </>
                                }
                                {currentPage === 'Primary Question Preview' &&
                                    <PrimarySurvey
                                        question={addPages.primary_survey_question}
                                    />
                                }
                                {currentPage === 'Secondary Question Preview' && 
                                    <SecondarySurvey
                                        secondary_questions={secondary_questions} 
                                        currentSecondaryIndex={currentSecondaryIndex}
                                        setCurrentPageType={setCurrentPageType}
                                    />
                                }
                                {currentPage === 'Instructions For Use Preview' && 
                                    <>
                                        <div style={questionStyle}>
                                            <p>{instructionsTitle}</p>
                                        </div>    
                                        <div style={{...descriptionStyle, top: '20%',}}>
                                            <p>{instructionsDescription}</p>
                                        </div>                                        
                                    </>
                                }
                                {subheading === 'Age Range' && 
                                    <>
                                        <div style={questionStyle}>
                                            <p>What is your age range?</p>
                                        </div>
                                        <RadioGroup
                                            row
                                            aria-labelledby="demo-row-radio-buttons-group-label"
                                            name="row-radio-buttons-group"
                                            style={radioGroupStyle}
                                        >
                                            {['Under 12', '12-17', '18-24', '25-34', '35-44', '45-54', '55-64', '65-74', '75 or older', 'Prefer to not answer'].map(range => (
                                                <StyledRadioContainer key={range} >
                                                    <StyledFormControlLabel 
                                                        control={
                                                            <StyledRadio 
                                                                checked={checked[range]} 
                                                                onChange={e => handleCheckChange(range, e)}
                                                            />
                                                        }
                                                        label={range}
                                                        labelPlacement="bottom"
                                                    />
                                                </StyledRadioContainer>
                                            ))}
                                        </RadioGroup>
                                    </>
                                }
                                {subheading === 'Gender' && 
                                    <>
                                        <div style={questionStyle}>
                                            <p>What is your gender?</p>
                                        </div>
                                        <RadioGroup
                                        row
                                        aria-labelledby="demo-row-radio-buttons-group-label"
                                        name="row-radio-buttons-group"
                                        style={radioGroupStyle}
                                        >
                                            {['female', 'male', 'other'].map(gender => (
                                                <StyledRadioContainer key={gender}>
                                                    <GenderStyledFormControlLabel
                                                        control={
                                                            <StyledRadio
                                                                checked={checked[gender]}
                                                                onChange={e => handleCheckChange(gender, e)}
                                                            />
                                                        }
                                                        label={gender}
                                                        labelPlacement="bottom"
                                                    />
                                                </StyledRadioContainer>
                                            ))}
                                        </RadioGroup>
                                    </>
                                }
                                {subheading === 'Race' && 
                                    <>                           
                                        <ArrowBackIcon style={progressBackIconStyle}/>                          
                                        {/* Change the color as needed. */}
                                        <ThemeProvider theme={theme}>
                                            <LinearProgress style={ProgressBarStyle} color="customColor" variant="determinate" value={60} />
                                        </ThemeProvider> 
                                        <ArrowForwardIcon style={progressForwardIconStyle}/>  
                                        <CancelOutlinedIcon style={progressCancelIconStyle}/>                                     
                                        <div style={raceQuestionStyle}>
                                            <p>With which racial group(s) do you most closely identify?<br/>
                                            <center>Check all that apply</center></p>
                                        </div>
                                        <FormGroup style={checkBoxGroupStyle}>
                                            {['African American', 'Asian American', 'Caucasian/White', 'Hispanic/Latino', 
                                              'Native American', 'Pacific Islander', 'Other', 'Prefer not to answer'].map(range => (
                                                <StyledCheckboxContainer key={range} >
                                                    <StyledCheckboxControlLabel control={<Checkbox checked={checked[range]}/>} label={range} />
                                                </StyledCheckboxContainer>
                                            ))}
                                        </FormGroup>
                                    </>
                                }
                                {subheading === 'Custom' && 
                                    <>
                                        <div style={questionStyle}>
                                            <p>{custom_question}</p>
                                        </div>
                                        <div style={{...shapeDivStyle, top: '25%',}}>
                                            {Array(custom_steps).fill().map((_, i) => 
                                                <ShapeAndLabel 
                                                    shape={custom_question_shape} 
                                                    steps={custom_steps} 
                                                    index={i}
                                                    showRatingLabels={custom_question_show_rating_labels}
                                                    ratingLabels={custom_rating_labels}
                                                    key={i} 
                                                />
                                            )}
                                        </div>
                                    </>
                                }
                                {currentPage === 'Recommendations Preview' && 
                                    <>
                                        <div style={containerRecommendationStyle}>
                                            <div style={column60Style}>
                                                <div style={rowStyle1}>
                                                    <div style={columnStyle1}>
                                                        <div>
                                                            <p style={questionStyleLeft}>
                                                                What you liked
                                                            </p>
                                                            <p style={smellInstructionStyle}>
                                                                Click on the word to smell
                                                            </p>
                                                        </div>
                                                        {recommendations1.map((rec, index) => (
                                                            <p key={index} 
                                                            style={{
                                                                        ...questionStyleLeft, 
                                                                        marginLeft: '1%',
                                                                        top: `${20 + (10 * index)}%`,
                                                                        color: inputs.recommendation_title, // color value 'bodyText'
                                                                        fontFamily: fontFamily, // font family 'Font'
                                                                        fontWeight: inputs.recommendation_name_bold ? 'bold' : 'normal', // add 'bold' if true, else 'normal'
                                                                        fontStyle: inputs.recommendation_name_italic ? 'italic' : 'normal', // add 'italic' if true, else 'normal'
                                                                    }}
                                                            >
                                                                {rec.rec}
                                                            </p>
                                                        ))}
                                                    </div>
                                                    <div style={columnStyle2}>
                                                        <div>
                                                            <p style={questionStyleRight}>
                                                                What you didn't like
                                                            </p>
                                                        </div>
                                                        {recommendations2.map((rec, index) => (
                                                            <p key={index} 
                                                            style={{
                                                                        ...questionStyleLeft, 
                                                                        top: `${20 + (10 * index)}%`,
                                                                        paddingLeft: '25%',
                                                                        paddingRight: '0%',
                                                                        color: inputs.recommendation_title, // color value 'bodyText'
                                                                        fontFamily: fontFamily, // font family 'Font'
                                                                        fontWeight: inputs.recommendation_name_bold ? 'bold' : 'normal', // add 'bold' if true, else 'normal'
                                                                        fontStyle: inputs.recommendation_name_italic ? 'italic' : 'normal', // add 'italic' if true, else 'normal'
                                                                    }}
                                                            >
                                                                {rec.rec}
                                                            </p>
                                                        ))}
                                                    </div>
                                                </div>
                                                <div style={{
                                                    ...rowStyle2, 
                                                }}>
                                                    <p style={{...questionStyleBottom, marginLeft: '4%', marginRight: '4%', marginBottom: '4%', fontSize: '70%'}}>
                                                        Based on your responses, we think you might like these 3 products (click on the images to smell them)
                                                    </p>
                                                    <div style={{
                                                        ...questionStyleBottom,
                                                        backgroundColor: inputs.recommendation_title,
                                                        width: '15%',
                                                        height: '30%',
                                                        top: '66%',
                                                        marginRight: '35%',
                                                        display: 'flex',
                                                        flexDirection: 'column',
                                                        borderRadius: '6%',
                                                    }}>
                                                        {product1 && (
                                                            <p style={{
                                                                height: '10%',
                                                                color: inputs.recommendation_name,
                                                                fontFamily: fontFamily, // font family 'Font'
                                                                fontWeight: inputs.recommendation_name_bold ? 'bold' : 'normal', // add 'bold' if true, else 'normal'
                                                                fontStyle: inputs.recommendation_name_italic ? 'italic' : 'normal', // add 'italic' if true, else 'normal'
                                                                textAlign: 'center',
                                                                fontSize: '70%',
                                                                paddingTop: '5%',
                                                            }}>
                                                                {product1.rec}
                                                            </p>
                                                        )}
                                                        <div style={{
                                                            height: '70%',  
                                                            backgroundImage: `url(${BrightBerryRecommended})`, 
                                                            backgroundSize: 'contain',
                                                            backgroundRepeat: 'no-repeat',
                                                            backgroundPosition: 'center',
                                                        }}>
                                                            {/* insert an image or any other elements here */}
                                                        </div>
                                                    </div>
                                                    <div style={{
                                                        ...questionStyleBottom,
                                                        backgroundColor: inputs.recommendation_title,
                                                        width: '15%',
                                                        height: '30%',
                                                        top: '66%',
                                                        display: 'flex',
                                                        flexDirection: 'column',
                                                        borderRadius: '6%',
                                                    }}>
                                                        {product2 && (
                                                            <p style={{
                                                                height: '10%',
                                                                color: inputs.recommendation_name,
                                                                fontFamily: fontFamily, // font family 'Font'
                                                                fontWeight: inputs.recommendation_name_bold ? 'bold' : 'normal', // add 'bold' if true, else 'normal'
                                                                fontStyle: inputs.recommendation_name_italic ? 'italic' : 'normal', // add 'italic' if true, else 'normal'
                                                                textAlign: 'center',
                                                                fontSize: '70%',
                                                                paddingTop: '5%',
                                                            }}>
                                                                {product2.rec}
                                                            </p>
                                                        )}
                                                        <div style={{
                                                            height: '70%', 
                                                            backgroundImage: `url(${BrightBerryRecommended})`, 
                                                            backgroundSize: 'contain',
                                                            backgroundRepeat: 'no-repeat',
                                                            backgroundPosition: 'center',
                                                        }}>
                                                            {/* insert an image or any other elements here */}
                                                        </div>
                                                    </div>
                                                    <div style={{
                                                        ...questionStyleBottom,
                                                        backgroundColor: inputs.recommendation_title,
                                                        width: '15%',
                                                        height: '30%',
                                                        top: '66%',
                                                        marginLeft: '35%',
                                                        display: 'flex',
                                                        flexDirection: 'column',
                                                        borderRadius: '6%',
                                                    }}>
                                                        {product3 && (
                                                            <p style={{
                                                                height: '10%',
                                                                color: inputs.recommendation_name,
                                                                fontFamily: fontFamily, // font family 'Font'
                                                                fontWeight: inputs.recommendation_name_bold ? 'bold' : 'normal', // add 'bold' if true, else 'normal'
                                                                fontStyle: inputs.recommendation_name_italic ? 'italic' : 'normal', // add 'italic' if true, else 'normal'
                                                                textAlign: 'center',
                                                                fontSize: '70%',
                                                                paddingTop: '5%',
                                                            }}>
                                                                {product3.rec}
                                                            </p>
                                                        )}
                                                        <div style={{
                                                            height: '70%', 
                                                            backgroundImage: `url(${BrightBerryRecommended})`, 
                                                            backgroundSize: 'contain',
                                                            backgroundRepeat: 'no-repeat',
                                                            backgroundPosition: 'center',
                                                        }}>
                                                            {/* insert an image or any other elements here */}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <img style={overlayRecommendedImageStyle} src={selectedRecommendImage} alt="Black Primary" />
                                        </div>
                                    </>
                                }
                                {currentPage === 'Loading Screen Preview' && 
                                    <>
                                        <div style={pageTitleStyle}>
                                            <p>Please Wait ...</p>
                                        </div>
                                        <div style={descriptionStyle}>
                                            <p>Please wait while the next aroma is being prepared</p>
                                        </div>
                                    </>
                                }                            
                                { (currentPage != 'Recommendations Preview' && !isCurrentQuestionMCQ()) &&
                                    <img style={overlayImageStyle} src={selectedOverlayImage || BlackPrimary} alt="Black Givaudan Primary" />
                                }    
                                </div>
                            </div>                         
                    </div>
                    <ArrowForwardIosRoundedIcon style={currentStep < totalStep ? buttonStyle : hiddenButtonStyle} onClick={handleNext} />
                </div>
            : inputs.survey_theme === "Light" ?
                <div style={parentStyle}>
                    <ArrowBackIosNewRoundedIcon style={currentStep > 0 ? buttonStyle : hiddenButtonStyle} onClick={handlePrev} />
                        <div style={containerWithButtonsStyle}>
                            <div style={baseImageStyle}>
                            <img style={backgroundImageStyle} src={selectedBgImage || themeStyles[currentTheme].survey_background} alt={`${currentTheme} Background`} />     
                                <div style={middleLayerStyle(middleLayerColor)}>
                                {currentPage === 'Survey Complete Preview' && 
                                    <>
                                        <div style={HomeIconStyle}>
                                            <HomeIcon />
                                        </div>
                                        <div style={surveyCompleteStyle}>
                                            <p>Survey Complete. Thank you for your participation.</p>
                                        </div>
                                    </>
                                }
                                {currentPage === 'Primary Question Preview' &&
                                    <PrimarySurvey
                                        question={addPages.primary_survey_question}
                                    />
                                }
                                {currentPage === 'Secondary Question Preview' && 
                                    <SecondarySurvey
                                        secondary_questions={secondary_questions} 
                                        currentSecondaryIndex={currentSecondaryIndex}
                                        setCurrentPageType={setCurrentPageType}
                                    />
                                }
                                {currentPage === 'Instructions For Use Preview' && 
                                    <>
                                        <div style={questionStyle}>
                                            <p>{instructionsTitle}</p>
                                        </div>    
                                        <div style={{...descriptionStyle, top: '20%',}}>
                                            <p>{instructionsDescription}</p>
                                        </div>                                        
                                    </>
                                }
                                {subheading === 'Age Range' && 
                                    <>
                                        <div style={questionStyle}>
                                            <p>What is your age range?</p>
                                        </div>
                                        <RadioGroup
                                            row
                                            aria-labelledby="demo-row-radio-buttons-group-label"
                                            name="row-radio-buttons-group"
                                            style={radioGroupStyle}
                                        >
                                            {['Under 12', '12-17', '18-24', '25-34', '35-44', '45-54', '55-64', '65-74', '75 or older', 'Prefer to not answer'].map(range => (
                                                <StyledRadioContainer key={range} >
                                                    <StyledFormControlLabel 
                                                        control={
                                                            <StyledRadio 
                                                                checked={checked[range]} 
                                                                onChange={e => handleCheckChange(range, e)}
                                                            />
                                                        }
                                                        label={range}
                                                        labelPlacement="bottom"
                                                    />
                                                </StyledRadioContainer>
                                            ))}
                                        </RadioGroup>
                                    </>
                                }
                                {subheading === 'Gender' && 
                                    <>
                                        <div style={questionStyle}>
                                            <p>What is your gender?</p>
                                        </div>
                                        <RadioGroup
                                        row
                                        aria-labelledby="demo-row-radio-buttons-group-label"
                                        name="row-radio-buttons-group"
                                        style={radioGroupStyle}
                                        >
                                            {['female', 'male', 'other'].map(gender => (
                                                <StyledRadioContainer key={gender}>
                                                    <GenderStyledFormControlLabel
                                                        control={
                                                            <StyledRadio
                                                                checked={checked[gender]}
                                                                onChange={e => handleCheckChange(gender, e)}
                                                            />
                                                        }
                                                        label={gender}
                                                        labelPlacement="bottom"
                                                    />
                                                </StyledRadioContainer>
                                            ))}
                                        </RadioGroup>
                                    </>
                                }
                                {subheading === 'Race' && 
                                    <>                           
                                        <ArrowBackIcon style={progressBackIconStyle}/>                          
                                        {/* Change the color as needed. */}
                                        <ThemeProvider theme={theme}>
                                            <LinearProgress style={ProgressBarStyle} color="customColor" variant="determinate" value={60} />
                                        </ThemeProvider> 
                                        <ArrowForwardIcon style={progressForwardIconStyle}/>  
                                        <CancelOutlinedIcon style={progressCancelIconStyle}/>                                     
                                        <div style={raceQuestionStyle}>
                                            <p>With which racial group(s) do you most closely identify?<br/>
                                            <center>Check all that apply</center></p>
                                        </div>
                                        <FormGroup style={checkBoxGroupStyle}>
                                            {['African American', 'Asian American', 'Caucasian/White', 'Hispanic/Latino', 
                                              'Native American', 'Pacific Islander', 'Other', 'Prefer not to answer'].map(range => (
                                                <StyledCheckboxContainer key={range} >
                                                    <StyledCheckboxControlLabel control={<Checkbox checked={checked[range]}/>} label={range} />
                                                </StyledCheckboxContainer>
                                            ))}
                                        </FormGroup>
                                    </>
                                }
                                {subheading === 'Custom' && 
                                    <>
                                        <div style={questionStyle}>
                                            <p>{custom_question}</p>
                                        </div>
                                        <div style={{...shapeDivStyle, top: '25%',}}>
                                            {Array(custom_steps).fill().map((_, i) => 
                                                <ShapeAndLabel 
                                                    shape={custom_question_shape} 
                                                    steps={custom_steps} 
                                                    index={i}
                                                    showRatingLabels={custom_question_show_rating_labels}
                                                    ratingLabels={custom_rating_labels}
                                                    key={i} 
                                                />
                                            )}
                                        </div>
                                    </>
                                }
                                {currentPage === 'Recommendations Preview' && 
                                    <>
                                        <div style={containerRecommendationStyle}>
                                            <div style={column60Style}>
                                                <div style={rowStyle1}>
                                                    <div style={columnStyle1}>
                                                        <div>
                                                            <p style={questionStyleLeft}>
                                                                What you liked
                                                            </p>
                                                            <p style={smellInstructionStyle}>
                                                                Click on the word to smell
                                                            </p>
                                                        </div>
                                                        {recommendations1.map((rec, index) => (
                                                            <p key={index} 
                                                            style={{
                                                                        ...questionStyleLeft, 
                                                                        marginLeft: '1%',
                                                                        top: `${20 + (10 * index)}%`,
                                                                        color: inputs.recommendation_title, // color value 'bodyText'
                                                                        fontFamily: fontFamily, // font family 'Font'
                                                                        fontWeight: inputs.recommendation_name_bold ? 'bold' : 'normal', // add 'bold' if true, else 'normal'
                                                                        fontStyle: inputs.recommendation_name_italic ? 'italic' : 'normal', // add 'italic' if true, else 'normal'
                                                                    }}
                                                            >
                                                                {rec.rec}
                                                            </p>
                                                        ))}
                                                    </div>
                                                    <div style={columnStyle2}>
                                                        <div>
                                                            <p style={questionStyleRight}>
                                                                What you didn't like
                                                            </p>
                                                        </div>
                                                        {recommendations2.map((rec, index) => (
                                                            <p key={index} 
                                                            style={{
                                                                        ...questionStyleLeft, 
                                                                        top: `${20 + (10 * index)}%`,
                                                                        paddingLeft: '25%',
                                                                        paddingRight: '0%',
                                                                        color: inputs.recommendation_title, // color value 'bodyText'
                                                                        fontFamily: fontFamily, // font family 'Font'
                                                                        fontWeight: inputs.recommendation_name_bold ? 'bold' : 'normal', // add 'bold' if true, else 'normal'
                                                                        fontStyle: inputs.recommendation_name_italic ? 'italic' : 'normal', // add 'italic' if true, else 'normal'
                                                                    }}
                                                            >
                                                                {rec.rec}
                                                            </p>
                                                        ))}
                                                    </div>
                                                </div>
                                                <div style={{
                                                    ...rowStyle2, 
                                                }}>
                                                    <p style={{...questionStyleBottom, marginLeft: '4%', marginRight: '4%', marginBottom: '4%', fontSize: '70%'}}>
                                                        Based on your responses, we think you might like these 3 products (click on the images to smell them)
                                                    </p>
                                                    <div style={{
                                                        ...questionStyleBottom,
                                                        backgroundColor: inputs.recommendation_title,
                                                        width: '15%',
                                                        height: '30%',
                                                        top: '66%',
                                                        marginRight: '35%',
                                                        display: 'flex',
                                                        flexDirection: 'column',
                                                        borderRadius: '6%',
                                                    }}>
                                                        {product1 && (
                                                            <p style={{
                                                                height: '10%',
                                                                color: inputs.recommendation_name,
                                                                fontFamily: fontFamily, // font family 'Font'
                                                                fontWeight: inputs.recommendation_name_bold ? 'bold' : 'normal', // add 'bold' if true, else 'normal'
                                                                fontStyle: inputs.recommendation_name_italic ? 'italic' : 'normal', // add 'italic' if true, else 'normal'
                                                                textAlign: 'center',
                                                                fontSize: '70%',
                                                                paddingTop: '5%',
                                                            }}>
                                                                {product1.rec}
                                                            </p>
                                                        )}
                                                        <div style={{
                                                            height: '70%',  
                                                            backgroundImage: `url(${BrightBerryRecommended})`, 
                                                            backgroundSize: 'contain',
                                                            backgroundRepeat: 'no-repeat',
                                                            backgroundPosition: 'center',
                                                        }}>
                                                            {/* insert an image or any other elements here */}
                                                        </div>
                                                    </div>
                                                    <div style={{
                                                        ...questionStyleBottom,
                                                        backgroundColor: inputs.recommendation_title,
                                                        width: '15%',
                                                        height: '30%',
                                                        top: '66%',
                                                        display: 'flex',
                                                        flexDirection: 'column',
                                                        borderRadius: '6%',
                                                    }}>
                                                        {product2 && (
                                                            <p style={{
                                                                height: '10%',
                                                                color: inputs.recommendation_name,
                                                                fontFamily: fontFamily, // font family 'Font'
                                                                fontWeight: inputs.recommendation_name_bold ? 'bold' : 'normal', // add 'bold' if true, else 'normal'
                                                                fontStyle: inputs.recommendation_name_italic ? 'italic' : 'normal', // add 'italic' if true, else 'normal'
                                                                textAlign: 'center',
                                                                fontSize: '70%',
                                                                paddingTop: '5%',
                                                            }}>
                                                                {product2.rec}
                                                            </p>
                                                        )}
                                                        <div style={{
                                                            height: '70%', 
                                                            backgroundImage: `url(${BrightBerryRecommended})`, 
                                                            backgroundSize: 'contain',
                                                            backgroundRepeat: 'no-repeat',
                                                            backgroundPosition: 'center',
                                                        }}>
                                                            {/* insert an image or any other elements here */}
                                                        </div>
                                                    </div>
                                                    <div style={{
                                                        ...questionStyleBottom,
                                                        backgroundColor: inputs.recommendation_title,
                                                        width: '15%',
                                                        height: '30%',
                                                        top: '66%',
                                                        marginLeft: '35%',
                                                        display: 'flex',
                                                        flexDirection: 'column',
                                                        borderRadius: '6%',
                                                    }}>
                                                        {product3 && (
                                                            <p style={{
                                                                height: '10%',
                                                                color: inputs.recommendation_name,
                                                                fontFamily: fontFamily, // font family 'Font'
                                                                fontWeight: inputs.recommendation_name_bold ? 'bold' : 'normal', // add 'bold' if true, else 'normal'
                                                                fontStyle: inputs.recommendation_name_italic ? 'italic' : 'normal', // add 'italic' if true, else 'normal'
                                                                textAlign: 'center',
                                                                fontSize: '70%',
                                                                paddingTop: '5%',
                                                            }}>
                                                                {product3.rec}
                                                            </p>
                                                        )}
                                                        <div style={{
                                                            height: '70%', 
                                                            backgroundImage: `url(${BrightBerryRecommended})`, 
                                                            backgroundSize: 'contain',
                                                            backgroundRepeat: 'no-repeat',
                                                            backgroundPosition: 'center',
                                                        }}>
                                                            {/* insert an image or any other elements here */}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div> 
                                            <img style={overlayRecommendedImageStyle} src={selectedRecommendImage || LightPrimary} alt="Light Primary" />
                                        </div>
                                    </>
                                }
                                {currentPage === 'Loading Screen Preview' && 
                                    <>
                                        <div style={pageTitleStyle}>
                                            <p>Please Wait ...</p>
                                        </div>
                                        <div style={descriptionStyle}>
                                            <p>Please wait while the next aroma is being prepared</p>
                                        </div>
                                    </>
                                }                            
                                { (currentPage != 'Recommendations Preview' && !isCurrentQuestionMCQ()) &&
                                    <img style={overlayImageStyle} src={selectedOverlayImage || LightPrimary} alt="Light Primary" />
                                }  
                                </div>
                            </div>                                    
                    </div>
                    <ArrowForwardIosRoundedIcon style={currentStep < totalStep ? buttonStyle : hiddenButtonStyle} onClick={handleNext} />
                </div>
            : inputs.survey_theme === "Luxe" ?
                <div style={parentStyle}>
                    <ArrowBackIosNewRoundedIcon style={currentStep > 0 ? buttonStyle : hiddenButtonStyle} onClick={handlePrev} />
                    <div style={containerWithButtonsStyle}>
                            <div style={baseImageStyle}>
                            <img style={backgroundImageStyle} src={selectedBgImage || themeStyles[currentTheme].survey_background} alt={`${currentTheme} Background`} />     
                                <div style={middleLayerStyle(middleLayerColor)}>
                                {currentPage === 'Survey Complete Preview' && 
                                    <>
                                        <div style={HomeIconStyle}>
                                            <HomeIcon />
                                        </div>
                                        <div style={surveyCompleteStyle}>
                                            <p>Survey Complete. Thank you for your participation.</p>
                                        </div>
                                    </>
                                }
                                {currentPage === 'Primary Question Preview' &&
                                    <PrimarySurvey
                                        question={addPages.primary_survey_question}
                                    />
                                }
                                {currentPage === 'Secondary Question Preview' && 
                                    <SecondarySurvey
                                        secondary_questions={secondary_questions} 
                                        currentSecondaryIndex={currentSecondaryIndex}
                                        setCurrentPageType={setCurrentPageType}
                                    />
                                }
                                {currentPage === 'Instructions For Use Preview' && 
                                    <>
                                        <div style={questionStyle}>
                                            <p>{instructionsTitle}</p>
                                        </div>    
                                        <div style={{...descriptionStyle, top: '20%',}}>
                                            <p>{instructionsDescription}</p>
                                        </div>                                        
                                    </>
                                }
                                {subheading === 'Age Range' && 
                                    <>
                                        <div style={questionStyle}>
                                            <p>What is your age range?</p>
                                        </div>
                                        <RadioGroup
                                            row
                                            aria-labelledby="demo-row-radio-buttons-group-label"
                                            name="row-radio-buttons-group"
                                            style={radioGroupStyle}
                                        >
                                            {['Under 12', '12-17', '18-24', '25-34', '35-44', '45-54', '55-64', '65-74', '75 or older', 'Prefer to not answer'].map(range => (
                                                <StyledRadioContainer key={range} >
                                                    <StyledFormControlLabel 
                                                        control={
                                                            <StyledRadio 
                                                                checked={checked[range]} 
                                                                onChange={e => handleCheckChange(range, e)}
                                                            />
                                                        }
                                                        label={range}
                                                        labelPlacement="bottom"
                                                    />
                                                </StyledRadioContainer>
                                            ))}
                                        </RadioGroup>
                                    </>
                                }
                                {subheading === 'Gender' && 
                                    <>
                                        <div style={questionStyle}>
                                            <p>What is your gender?</p>
                                        </div>
                                        <RadioGroup
                                        row
                                        aria-labelledby="demo-row-radio-buttons-group-label"
                                        name="row-radio-buttons-group"
                                        style={radioGroupStyle}
                                        >
                                            {['female', 'male', 'other'].map(gender => (
                                                <StyledRadioContainer key={gender}>
                                                    <GenderStyledFormControlLabel
                                                        control={
                                                            <StyledRadio
                                                                checked={checked[gender]}
                                                                onChange={e => handleCheckChange(gender, e)}
                                                            />
                                                        }
                                                        label={gender}
                                                        labelPlacement="bottom"
                                                    />
                                                </StyledRadioContainer>
                                            ))}
                                        </RadioGroup>
                                    </>
                                }
                                {subheading === 'Race' && 
                                    <>                           
                                        <ArrowBackIcon style={progressBackIconStyle}/>                          
                                        {/* Change the color as needed. */}
                                        <ThemeProvider theme={theme}>
                                            <LinearProgress style={ProgressBarStyle} color="customColor" variant="determinate" value={60} />
                                        </ThemeProvider> 
                                        <ArrowForwardIcon style={progressForwardIconStyle}/>  
                                        <CancelOutlinedIcon style={progressCancelIconStyle}/>                                     
                                        <div style={raceQuestionStyle}>
                                            <p>With which racial group(s) do you most closely identify?<br/>
                                            <center>Check all that apply</center></p>
                                        </div>
                                        <FormGroup style={checkBoxGroupStyle}>
                                            {['African American', 'Asian American', 'Caucasian/White', 'Hispanic/Latino', 
                                              'Native American', 'Pacific Islander', 'Other', 'Prefer not to answer'].map(range => (
                                                <StyledCheckboxContainer key={range} >
                                                    <StyledCheckboxControlLabel control={<Checkbox checked={checked[range]}/>} label={range} />
                                                </StyledCheckboxContainer>
                                            ))}
                                        </FormGroup>
                                    </>
                                }
                                {subheading === 'Custom' && 
                                    <>
                                        <div style={questionStyle}>
                                            <p>{custom_question}</p>
                                        </div>
                                        <div style={{...shapeDivStyle, top: '25%',}}>
                                            {Array(custom_steps).fill().map((_, i) => 
                                                <ShapeAndLabel 
                                                    shape={custom_question_shape} 
                                                    steps={custom_steps} 
                                                    index={i}
                                                    showRatingLabels={custom_question_show_rating_labels}
                                                    ratingLabels={custom_rating_labels}
                                                    key={i} 
                                                />
                                            )}
                                        </div>
                                    </>
                                }
                                {currentPage === 'Recommendations Preview' && 
                                    <>
                                        <div style={containerRecommendationStyle}>
                                            <div style={column60Style}>
                                                <div style={rowStyle1}>
                                                    <div style={columnStyle1}>
                                                        <div>
                                                            <p style={questionStyleLeft}>
                                                                What you liked
                                                            </p>
                                                            <p style={smellInstructionStyle}>
                                                                Click on the word to smell
                                                            </p>
                                                        </div>
                                                        {recommendations1.map((rec, index) => (
                                                            <p key={index} 
                                                            style={{
                                                                        ...questionStyleLeft, 
                                                                        marginLeft: '1%',
                                                                        top: `${20 + (10 * index)}%`,
                                                                        color: inputs.recommendation_title, // color value 'bodyText'
                                                                        fontFamily: fontFamily, // font family 'Font'
                                                                        fontWeight: inputs.recommendation_name_bold ? 'bold' : 'normal', // add 'bold' if true, else 'normal'
                                                                        fontStyle: inputs.recommendation_name_italic ? 'italic' : 'normal', // add 'italic' if true, else 'normal'
                                                                    }}
                                                            >
                                                                {rec.rec}
                                                            </p>
                                                        ))}
                                                    </div>
                                                    <div style={columnStyle2}>
                                                        <div>
                                                            <p style={questionStyleRight}>
                                                                What you didn't like
                                                            </p>
                                                        </div>
                                                        {recommendations2.map((rec, index) => (
                                                            <p key={index} 
                                                            style={{
                                                                        ...questionStyleLeft, 
                                                                        top: `${20 + (10 * index)}%`,
                                                                        paddingLeft: '25%',
                                                                        paddingRight: '0%',
                                                                        color: inputs.recommendation_title, // color value 'bodyText'
                                                                        fontFamily: fontFamily, // font family 'Font'
                                                                        fontWeight: inputs.recommendation_name_bold ? 'bold' : 'normal', // add 'bold' if true, else 'normal'
                                                                        fontStyle: inputs.recommendation_name_italic ? 'italic' : 'normal', // add 'italic' if true, else 'normal'
                                                                    }}
                                                            >
                                                                {rec.rec}
                                                            </p>
                                                        ))}
                                                    </div>
                                                </div>
                                                <div style={{
                                                    ...rowStyle2, 
                                                }}>
                                                    <p style={{...questionStyleBottom, marginLeft: '4%', marginRight: '4%', marginBottom: '4%', fontSize: '65%'}}>
                                                        Based on your responses, we think you might like these 3 products (click on the images to smell them)
                                                    </p>
                                                    <div style={{
                                                        ...questionStyleBottom,
                                                        backgroundColor: inputs.recommendation_title,
                                                        width: '15%',
                                                        height: '30%',
                                                        top: '66%',
                                                        marginRight: '35%',
                                                        display: 'flex',
                                                        flexDirection: 'column',
                                                        borderRadius: '6%',
                                                    }}>
                                                        {product1 && (
                                                            <p style={{
                                                                height: '10%',
                                                                color: inputs.recommendation_name,
                                                                fontFamily: fontFamily, // font family 'Font'
                                                                fontWeight: inputs.recommendation_name_bold ? 'bold' : 'normal', // add 'bold' if true, else 'normal'
                                                                fontStyle: inputs.recommendation_name_italic ? 'italic' : 'normal', // add 'italic' if true, else 'normal'
                                                                textAlign: 'center',
                                                                fontSize: '70%',
                                                                paddingTop: '5%',
                                                            }}>
                                                                {product1.rec}
                                                            </p>
                                                        )}
                                                        <div style={{
                                                            height: '70%',  
                                                            backgroundImage: `url(${BrightBerryRecommended})`, 
                                                            backgroundSize: 'contain',
                                                            backgroundRepeat: 'no-repeat',
                                                            backgroundPosition: 'center',
                                                        }}>
                                                            {/* insert an image or any other elements here */}
                                                        </div>
                                                    </div>
                                                    <div style={{
                                                        ...questionStyleBottom,
                                                        backgroundColor: inputs.recommendation_title,
                                                        width: '15%',
                                                        height: '30%',
                                                        top: '66%',
                                                        display: 'flex',
                                                        flexDirection: 'column',
                                                        borderRadius: '6%',
                                                    }}>
                                                        {product2 && (
                                                            <p style={{
                                                                height: '10%',
                                                                color: inputs.recommendation_name,
                                                                fontFamily: fontFamily, // font family 'Font'
                                                                fontWeight: inputs.recommendation_name_bold ? 'bold' : 'normal', // add 'bold' if true, else 'normal'
                                                                fontStyle: inputs.recommendation_name_italic ? 'italic' : 'normal', // add 'italic' if true, else 'normal'
                                                                textAlign: 'center',
                                                                fontSize: '70%',
                                                                paddingTop: '5%',
                                                            }}>
                                                                {product2.rec}
                                                            </p>
                                                        )}
                                                        <div style={{
                                                            height: '70%', 
                                                            backgroundImage: `url(${BrightBerryRecommended})`, 
                                                            backgroundSize: 'contain',
                                                            backgroundRepeat: 'no-repeat',
                                                            backgroundPosition: 'center',
                                                        }}>
                                                            {/* insert an image or any other elements here */}
                                                        </div>
                                                    </div>
                                                    <div style={{
                                                        ...questionStyleBottom,
                                                        backgroundColor: inputs.recommendation_title,
                                                        width: '15%',
                                                        height: '30%',
                                                        top: '66%',
                                                        marginLeft: '35%',
                                                        display: 'flex',
                                                        flexDirection: 'column',
                                                        borderRadius: '6%',
                                                    }}>
                                                        {product3 && (
                                                            <p style={{
                                                                height: '10%',
                                                                color: inputs.recommendation_name,
                                                                fontFamily: fontFamily, // font family 'Font'
                                                                fontWeight: inputs.recommendation_name_bold ? 'bold' : 'normal', // add 'bold' if true, else 'normal'
                                                                fontStyle: inputs.recommendation_name_italic ? 'italic' : 'normal', // add 'italic' if true, else 'normal'
                                                                textAlign: 'center',
                                                                fontSize: '70%',
                                                                paddingTop: '5%',
                                                            }}>
                                                                {product3.rec}
                                                            </p>
                                                        )}
                                                        <div style={{
                                                            height: '70%', 
                                                            backgroundImage: `url(${BrightBerryRecommended})`, 
                                                            backgroundSize: 'contain',
                                                            backgroundRepeat: 'no-repeat',
                                                            backgroundPosition: 'center',
                                                        }}>
                                                            {/* insert an image or any other elements here */}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <img style={overlayRecommendedImageStyle} src={selectedRecommendImage || LuxeRecommended} alt="Luxe Primary" />
                                        </div>
                                    </>
                                }
                                {currentPage === 'Loading Screen Preview' && 
                                    <>
                                        <div style={pageTitleStyle}>
                                            <p>Please Wait ...</p>
                                        </div>
                                        <div style={descriptionStyle}>
                                            <p>Please wait while the next aroma is being prepared</p>
                                        </div>
                                    </>
                                }                            
                                { (currentPage != 'Recommendations Preview' && !isCurrentQuestionMCQ()) &&
                                    <img style={overlayImageStyle} src={selectedOverlayImage || LuxePrimary} alt="Luxe Primary" />
                                }  
                                </div>
                            </div>                                   
                    </div>
                    <ArrowForwardIosRoundedIcon style={currentStep < totalStep ? buttonStyle : hiddenButtonStyle} onClick={handleNext} />
                </div>
            : inputs.survey_theme === "Tropical" ?
                <div style={parentStyle}>
                    <ArrowBackIosNewRoundedIcon style={currentStep > 0 ? buttonStyle : hiddenButtonStyle} onClick={handlePrev} />
                    <div style={containerWithButtonsStyle}>
                        <div style={baseImageStyle}>
                        <img style={backgroundImageStyle} src={selectedBgImage || themeStyles[currentTheme].survey_background} alt={`${currentTheme} Background`} />     
                            <div style={middleLayerStyle(middleLayerColor)}>
                            {currentPage === 'Survey Complete Preview' && 
                                <>
                                    <div style={HomeIconStyle}>
                                        <HomeIcon />
                                    </div>
                                    <div style={surveyCompleteStyle}>
                                        <p>Survey Complete. Thank you for your participation.</p>
                                    </div>
                                </>
                            }
                            {currentPage === 'Primary Question Preview' &&
                                <PrimarySurvey
                                    question={addPages.primary_survey_question}
                                />
                            }
                            {currentPage === 'Secondary Question Preview' && 
                                <SecondarySurvey
                                    secondary_questions={secondary_questions} 
                                    currentSecondaryIndex={currentSecondaryIndex}
                                    setCurrentPageType={setCurrentPageType}
                                />
                            }
                            {currentPage === 'Instructions For Use Preview' && 
                                <>
                                    <div style={questionStyle}>
                                        <p>{instructionsTitle}</p>
                                    </div>    
                                    <div style={{...descriptionStyle, top: '20%',}}>
                                        <p>{instructionsDescription}</p>
                                    </div>                                        
                                </>
                            }
                            {subheading === 'Age Range' && 
                                <>
                                    <div style={questionStyle}>
                                        <p>What is your age range?</p>
                                    </div>
                                    <RadioGroup
                                        row
                                        aria-labelledby="demo-row-radio-buttons-group-label"
                                        name="row-radio-buttons-group"
                                        style={radioGroupStyle}
                                    >
                                        {['Under 12', '12-17', '18-24', '25-34', '35-44', '45-54', '55-64', '65-74', '75 or older', 'Prefer to not answer'].map(range => (
                                            <StyledRadioContainer key={range} >
                                                <StyledFormControlLabel 
                                                    control={
                                                        <StyledRadio 
                                                            checked={checked[range]} 
                                                            onChange={e => handleCheckChange(range, e)}
                                                        />
                                                    }
                                                    label={range}
                                                    labelPlacement="bottom"
                                                />
                                            </StyledRadioContainer>
                                        ))}
                                    </RadioGroup>
                                </>
                            }
                            {subheading === 'Gender' && 
                                <>
                                    <div style={questionStyle}>
                                        <p>What is your gender?</p>
                                    </div>
                                    <RadioGroup
                                    row
                                    aria-labelledby="demo-row-radio-buttons-group-label"
                                    name="row-radio-buttons-group"
                                    style={radioGroupStyle}
                                    >
                                        {['female', 'male', 'other'].map(gender => (
                                            <StyledRadioContainer key={gender}>
                                                <GenderStyledFormControlLabel
                                                    control={
                                                        <StyledRadio
                                                            checked={checked[gender]}
                                                            onChange={e => handleCheckChange(gender, e)}
                                                        />
                                                    }
                                                    label={gender}
                                                    labelPlacement="bottom"
                                                />
                                            </StyledRadioContainer>
                                        ))}
                                    </RadioGroup>
                                </>
                            }
                            {subheading === 'Race' && 
                                <>                           
                                    <ArrowBackIcon style={progressBackIconStyle}/>                          
                                    {/* Change the color as needed. */}
                                    <ThemeProvider theme={theme}>
                                        <LinearProgress style={ProgressBarStyle} color="customColor" variant="determinate" value={60} />
                                    </ThemeProvider> 
                                    <ArrowForwardIcon style={progressForwardIconStyle}/>  
                                    <CancelOutlinedIcon style={progressCancelIconStyle}/>                                     
                                    <div style={raceQuestionStyle}>
                                        <p>With which racial group(s) do you most closely identify?<br/>
                                        <center>Check all that apply</center></p>
                                    </div>
                                    <FormGroup style={checkBoxGroupStyle}>
                                        {['African American', 'Asian American', 'Caucasian/White', 'Hispanic/Latino', 
                                            'Native American', 'Pacific Islander', 'Other', 'Prefer not to answer'].map(range => (
                                            <StyledCheckboxContainer key={range} >
                                                <StyledCheckboxControlLabel control={<Checkbox checked={checked[range]}/>} label={range} />
                                            </StyledCheckboxContainer>
                                        ))}
                                    </FormGroup>
                                </>
                            }
                            {subheading === 'Custom' && 
                                <>
                                    <div style={questionStyle}>
                                        <p>{custom_question}</p>
                                    </div>
                                    <div style={{...shapeDivStyle, top: '25%',}}>
                                        {Array(custom_steps).fill().map((_, i) => 
                                            <ShapeAndLabel 
                                                shape={custom_question_shape} 
                                                steps={custom_steps} 
                                                index={i}
                                                showRatingLabels={custom_question_show_rating_labels}
                                                ratingLabels={custom_rating_labels}
                                                key={i} 
                                            />
                                        )}
                                    </div>
                                </>
                            }
                            {currentPage === 'Recommendations Preview' && 
                                <>
                                    <div style={containerRecommendationStyle}>
                                        <div style={column60Style}>
                                            <div style={rowStyle1}>
                                                <div style={columnStyle1}>
                                                    <div>
                                                        <p style={questionStyleLeft}>
                                                            What you liked
                                                        </p>
                                                        <p style={smellInstructionStyle}>
                                                            Click on the word to smell
                                                        </p>
                                                    </div>
                                                    {recommendations1.map((rec, index) => (
                                                        <p key={index} 
                                                        style={{
                                                                    ...questionStyleLeft, 
                                                                    marginLeft: '1%',
                                                                    top: `${20 + (10 * index)}%`,
                                                                    color: inputs.recommendation_title, // color value 'bodyText'
                                                                    fontFamily: fontFamily, // font family 'Font'
                                                                    fontWeight: inputs.recommendation_name_bold ? 'bold' : 'normal', // add 'bold' if true, else 'normal'
                                                                    fontStyle: inputs.recommendation_name_italic ? 'italic' : 'normal', // add 'italic' if true, else 'normal'
                                                                }}
                                                        >
                                                            {rec.rec}
                                                        </p>
                                                    ))}
                                                </div>
                                                <div style={columnStyle2}>
                                                    <div>
                                                        <p style={questionStyleRight}>
                                                            What you didn't like
                                                        </p>
                                                    </div>
                                                    {recommendations2.map((rec, index) => (
                                                        <p key={index} 
                                                        style={{
                                                                    ...questionStyleLeft, 
                                                                    top: `${20 + (10 * index)}%`,
                                                                    paddingLeft: '25%',
                                                                    paddingRight: '0%',
                                                                    color: inputs.recommendation_title, // color value 'bodyText'
                                                                    fontFamily: fontFamily, // font family 'Font'
                                                                    fontWeight: inputs.recommendation_name_bold ? 'bold' : 'normal', // add 'bold' if true, else 'normal'
                                                                    fontStyle: inputs.recommendation_name_italic ? 'italic' : 'normal', // add 'italic' if true, else 'normal'
                                                                }}
                                                        >
                                                            {rec.rec}
                                                        </p>
                                                    ))}
                                                </div>
                                            </div>
                                            <div style={{
                                                ...rowStyle2, 
                                            }}>
                                                <p style={{...questionStyleBottom, marginLeft: '4%', marginRight: '4%', marginBottom: '4%', fontSize: '62.5%'}}>
                                                    Based on your responses, we think you might like these 3 products (click on the images to smell them)
                                                </p>
                                                <div style={{
                                                    ...questionStyleBottom,
                                                    backgroundColor: inputs.recommendation_title,
                                                    width: '15%',
                                                    height: '30%',
                                                    top: '66%',
                                                    marginRight: '35%',
                                                    display: 'flex',
                                                    flexDirection: 'column',
                                                    borderRadius: '6%',
                                                }}>
                                                    {product1 && (
                                                        <p style={{
                                                            height: '10%',
                                                            color: inputs.recommendation_name,
                                                            fontFamily: fontFamily, // font family 'Font'
                                                            fontWeight: inputs.recommendation_name_bold ? 'bold' : 'normal', // add 'bold' if true, else 'normal'
                                                            fontStyle: inputs.recommendation_name_italic ? 'italic' : 'normal', // add 'italic' if true, else 'normal'
                                                            textAlign: 'center',
                                                            fontSize: '70%',
                                                            paddingTop: '5%',
                                                        }}>
                                                            {product1.rec}
                                                        </p>
                                                    )}
                                                    <div style={{
                                                        height: '70%',  
                                                        backgroundImage: `url(${BrightBerryRecommended})`, 
                                                        backgroundSize: 'contain',
                                                        backgroundRepeat: 'no-repeat',
                                                        backgroundPosition: 'center',
                                                    }}>
                                                        {/* insert an image or any other elements here */}
                                                    </div>
                                                </div>
                                                <div style={{
                                                    ...questionStyleBottom,
                                                    backgroundColor: inputs.recommendation_title,
                                                    width: '15%',
                                                    height: '30%',
                                                    top: '66%',
                                                    display: 'flex',
                                                    flexDirection: 'column',
                                                    borderRadius: '6%',
                                                }}>
                                                    {product2 && (
                                                        <p style={{
                                                            height: '10%',
                                                            color: inputs.recommendation_name,
                                                            fontFamily: fontFamily, // font family 'Font'
                                                            fontWeight: inputs.recommendation_name_bold ? 'bold' : 'normal', // add 'bold' if true, else 'normal'
                                                            fontStyle: inputs.recommendation_name_italic ? 'italic' : 'normal', // add 'italic' if true, else 'normal'
                                                            textAlign: 'center',
                                                            fontSize: '70%',
                                                            paddingTop: '5%',
                                                        }}>
                                                            {product2.rec}
                                                        </p>
                                                    )}
                                                    <div style={{
                                                        height: '70%', 
                                                        backgroundImage: `url(${BrightBerryRecommended})`, 
                                                        backgroundSize: 'contain',
                                                        backgroundRepeat: 'no-repeat',
                                                        backgroundPosition: 'center',
                                                    }}>
                                                        {/* insert an image or any other elements here */}
                                                    </div>
                                                </div>
                                                <div style={{
                                                    ...questionStyleBottom,
                                                    backgroundColor: inputs.recommendation_title,
                                                    width: '15%',
                                                    height: '30%',
                                                    top: '66%',
                                                    marginLeft: '35%',
                                                    display: 'flex',
                                                    flexDirection: 'column',
                                                    borderRadius: '6%',
                                                }}>
                                                    {product3 && (
                                                        <p style={{
                                                            height: '10%',
                                                            color: inputs.recommendation_name,
                                                            fontFamily: fontFamily, // font family 'Font'
                                                            fontWeight: inputs.recommendation_name_bold ? 'bold' : 'normal', // add 'bold' if true, else 'normal'
                                                            fontStyle: inputs.recommendation_name_italic ? 'italic' : 'normal', // add 'italic' if true, else 'normal'
                                                            textAlign: 'center',
                                                            fontSize: '70%',
                                                            paddingTop: '5%',
                                                        }}>
                                                            {product3.rec}
                                                        </p>
                                                    )}
                                                    <div style={{
                                                        height: '70%', 
                                                        backgroundImage: `url(${BrightBerryRecommended})`, 
                                                        backgroundSize: 'contain',
                                                        backgroundRepeat: 'no-repeat',
                                                        backgroundPosition: 'center',
                                                    }}>
                                                        {/* insert an image or any other elements here */}
                                                    </div>
                                                </div>
                                            </div>
                                        </div> 
                                        <img style={overlayRecommendedImageStyle} src={selectedRecommendImage || TropicalRecommended} alt="Tropical Primary" />
                                    </div>
                                </>
                            }
                            {currentPage === 'Loading Screen Preview' && 
                                <>
                                    <div style={pageTitleStyle}>
                                        <p>Please Wait ...</p>
                                    </div>
                                    <div style={descriptionStyle}>
                                        <p>Please wait while the next aroma is being prepared</p>
                                    </div>
                                </>
                            }                            
                            { (currentPage != 'Recommendations Preview' && !isCurrentQuestionMCQ()) &&
                                <img style={overlayImageStyle} src={selectedOverlayImage || TropicalPrimary} alt="Tropical Primary" />
                            }  
                            </div>
                        </div>                                   
                    </div>
                    <ArrowForwardIosRoundedIcon style={currentStep < totalStep ? buttonStyle : hiddenButtonStyle} onClick={handleNext} />
                </div>
            : <div></div>}                
            </Box>
        </Box>
    )
}

export default StyleEditor;
