import React, { useEffect, useState } from 'react';
// File inputs
import NumericScale from './Secondary Survey Question/NumericScale';
import CheckboxesMC from './Secondary Survey Question/Checkboxes';
import JARScale from './Secondary Survey Question/JAR_scale';
import { Grid, Select, FormControl, MenuItem, Divider, InputLabel } from '@mui/material';
import PlainText from './Secondary Survey Question/PlainText';

const SecondarySurveyQuestions = ({ addPages, setAddPages, id }) => {
  let SSQIndex = id-2;
  let SSQ = addPages && addPages.secondary_survey_question && addPages.secondary_survey_question[SSQIndex];

  const [inputs, setInputs] = useState({
    format: SSQ ? SSQ.format : ""
  });


  // Handle the inputs as they are received
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setInputs(prevState => ({ ...prevState, [name]: value }));
  };

  return (
    <>
        <Grid container direction="row" alignItems="end">
            <Grid item>
                <FormControl>
                <InputLabel variant="outlined" id="format-label">Format</InputLabel>
                    <Select
                    sx={{ width: 160 }}
                    label="Format *"
                    labelId="format-label"
                    id="format"
                    value={inputs.format}
                    name="format"
                    onChange={handleInputChange}
                    >
                    <MenuItem value="Numeric Scale">Numeric Scale</MenuItem>
                    <MenuItem value="JAR Scale">JAR Scale</MenuItem>
                    <MenuItem value="Checkbox">Checkbox</MenuItem>
                    <MenuItem value="Multiple Choice">Multiple Choice</MenuItem>
                    <MenuItem value="Slider">Slider</MenuItem>
                    <MenuItem value="Plain Text">Plain Text</MenuItem>
                    </Select>
                </FormControl>
            </Grid>
        </Grid>
        <Divider orientation='horizontal' sx={{ height: '10px' }} />
            {inputs.format === "Numeric Scale" || inputs.format === "Slider" ? 
            <NumericScale format={inputs.format} addPages={addPages} setAddPages={setAddPages} id={id} /> : <div></div>
            }{inputs.format === "JAR Scale" ?
            <JARScale format={inputs.format} addPages={addPages} setAddPages={setAddPages} id={id} /> : <div></div>
            }{inputs.format === "Checkbox" || inputs.format === "Multiple Choice" ? 
            <CheckboxesMC format={inputs.format} addPages={addPages} setAddPages={setAddPages} id={id} /> : <div></div>
            }{inputs.format === "Plain Text" ?
            <PlainText format={inputs.format} setAddPages={setAddPages} id={id}/>
            : <div></div>}
            <Divider orientation='horizontal' sx={{ height: '10px' }} />        
    </>
  );
};

export default SecondarySurveyQuestions;
