// The parent template holds the primary style for the survey, all children are rendered as a subcomponent of this
import {Children, useEffect, useState} from 'react';
import ArrowBackIosNewRoundedIcon from "@mui/icons-material/ArrowBackIosNewRounded";
import * as React from "react";
import defaultImages from "../../create/advanced/low_level_components/defaultImages.json";
import "./AdministerPanelistStylesheet.css"
import BrightBerryPrimary from "../../create/advanced/styleEditorImages/Bright-Berry-Primary.jpg";
const ParentTemplate = ({ SurveyDesign, children}) => {

        // Declaring all the theme properties.
    const themeStyles = {
        "Bright Berry": {
            survey_theme:               "Bright Berry",
            page_background:            "#FFE6F0",
            page_title:                 "#EA2D52",
            description_text:           "#328A27",
            question_text:              "#328A27",
            answer_text:                "#E21B39",
            answer_description:         "#328A27",
            error_text:                 "#C1272D",
            progress_indicator:         "#EF7D9D",
            buttons:                    "#EF7D98",
            word_cloud:                 "#BA0F1C",
            recommendation_name:        "#FFE6F0",
            recommendation_title:       "#BA0F1C",
            page_title_bold:            false,
            page_title_italic:          false,
            description_text_bold:      false,
            description_text_italic:    false,
            question_bold:              false,
            question_italic:            false,
            answer_bold:                true,
            answer_italic:              false,
            answer_description_bold:    false,
            answer_description_italic:  false,
            error_bold:                 true,
            error_italic:               false,
            recommendation_name_bold:   false,
            recommendation_name_italic: false,
            header_bold:                false,
            header_italic:              false,
            survey_background:          defaultImages.BrightBerryBackground,
            survey_overlay:             defaultImages.BrightBerryPrimary,
            survey_recommendation:      defaultImages.BrightBerryRecommended
        },
        "Citrus": {
            survey_theme:               "Citrus",
            page_background:            "#FFFBEB",
            page_title:                 "#F15A24",
            description_text:           "#476500",
            question_text:              "#476500",
            answer_text:                "#F76400",
            answer_description:         "#476500",
            error_text:                 "#C1272D",
            progress_indicator:         "#F9751C",
            buttons:                    "#FBB03B",
            word_cloud:                 "#328A27",
            recommendation_name:        "#FFFBEB",
            recommendation_title:       "#FD8201",
            page_title_bold:            false,
            page_title_italic:          false,
            description_text_bold:      false,
            description_text_italic:    false,
            question_bold:              false,
            question_italic:            false,
            answer_bold:                true,
            answer_italic:              false,
            answer_description_bold:    false,
            answer_description_italic:  false,
            error_bold:                 false,
            error_italic:               false,
            recommendation_name_bold:   false,
            recommendation_name_italic: false,
            header_bold:                false,
            header_italic:              false,
            survey_background:          defaultImages.CitrusBackground,
            survey_overlay:             defaultImages.CitrusPrimary,
            survey_recommendation:      defaultImages.CitrusRecommended
        },
        "Black Givaudan": {
            survey_theme:               "Black Givaudan",
            page_background:            "#000000",
            page_title:                 "#30B3AD",
            description_text:           "#CCCCCC",
            question_text:              "#E6E6E6",
            answer_text:                "#30B3AD",
            answer_description:         "#AFD9CA",
            error_text:                 "#C1272D",
            progress_indicator:         "#30B3AD",
            buttons:                    "#A6A6A6",
            word_cloud:                 "#A6A6A6",
            recommendation_name:        "#FFFFFF",
            recommendation_title:       "#A6A6A6",
            page_title_bold:            false,
            page_title_italic:          false,
            description_text_bold:      false,
            description_text_italic:    false,
            question_bold:              false,
            question_italic:            false,
            answer_bold:                false,
            answer_italic:              false,
            answer_description_bold:    false,
            answer_description_italic:  false,
            error_bold:                 true,
            error_italic:               false,
            recommendation_name_bold:   false,
            recommendation_name_italic: false,
            header_bold:                false,
            header_italic:              false,
            survey_background:          defaultImages.BlackBackground,
            survey_overlay:             defaultImages.BlackPrimary,
            survey_recommendation:      null
        },
        "Light": {
            survey_theme:               "Light",
            page_background:            "#FFFFFF",
            page_title:                 "#666666",
            description_text:           "#525252",
            question_text:              "#525252",
            answer_text:                "#1A1A1A",
            answer_description:         "#525252",
            error_text:                 "#C1272D",
            progress_indicator:         "#5D71FB",
            buttons:                    "#666666",
            word_cloud:                 "#5D71FB",
            recommendation_name:        "#FFFFFF",
            recommendation_title:       "#605EFF",
            page_title_bold:            false,
            page_title_italic:          false,
            description_text_bold:      false,
            description_text_italic:    false,
            question_bold:              false,
            question_italic:            false,
            answer_bold:                true,
            answer_italic:              false,
            answer_description_bold:    false,
            answer_description_italic:  false,
            error_bold:                 false,
            error_italic:               false,
            recommendation_name_bold:   false,
            recommendation_name_italic: false,
            header_bold:                false,
            header_italic:              false,
            survey_background:          defaultImages.LightBackground,
            survey_overlay:             defaultImages.LightPrimary,
            survey_recommendation:      null
        },
        "Luxe": {
            survey_theme:               "Luxe",
            page_background:            "#180F0E",
            page_title:                 "#E89B46",
            description_text:           "#E2BDAA",
            question_text:              "#E89B46",
            answer_text:                "#AD6551",
            answer_description:         "#E2BDAA",
            error_text:                 "#C1272D",
            progress_indicator:         "#7F6155",
            buttons:                    "#7F6155",
            word_cloud:                 "#E89B46",
            recommendation_name:        "#FFFFFF",
            recommendation_title:       "#C64F26",
            page_title_bold:            false,
            page_title_italic:          false,
            description_text_bold:      false,
            description_text_italic:    false,
            question_bold:              false,
            question_italic:            false,
            answer_bold:                false,
            answer_italic:              false,
            answer_description_bold:    false,
            answer_description_italic:  false,
            error_bold:                 false,
            error_italic:               false,
            recommendation_name_bold:   false,
            recommendation_name_italic: false,
            header_bold:                false,
            header_italic:              false,
            survey_background:          defaultImages.LuxeBackground,
            survey_overlay:             defaultImages.LuxePrimary,
            survey_recommendation:      defaultImages.LuxeRecommended
        },
        "Tropical": {
            survey_theme:               "Tropical",
            page_background:            "#FFFFFF",
            page_title:                 "#666666",
            description_text:           "#525252",
            question_text:              "#525252",
            answer_text:                "#1A1A1A",
            answer_description:         "#525252",
            error_text:                 "#C1272D",
            progress_indicator:         "#5D71FB",
            buttons:                    "#666666",
            word_cloud:                 "#5D71FB",
            recommendation_name:        "#FFFFFF",
            recommendation_title:       "#605EFF",
            page_title_bold:            false,
            page_title_italic:          false,
            description_text_bold:      false,
            description_text_italic:    false,
            question_bold:              true,
            question_italic:            false,
            answer_bold:                true,
            answer_italic:              false,
            answer_description_bold:    false,
            answer_description_italic:  false,
            error_bold:                 false,
            error_italic:               false,
            recommendation_name_bold:   false,
            recommendation_name_italic: false,
            header_bold:                false,
            header_italic:              false,
            survey_background:          defaultImages.TropicalBackground,
            survey_overlay:             defaultImages.TropicalPrimary,
            survey_recommendation:      defaultImages.TropicalRecommended
        },
    };

    // Render parent CSS based on survey design parameters
    const backgroundImageStyle = {
        width: '100%',
        height: '100%', // 1/2 height of the container, so it covers 1/2 of the image below
        objectFit: 'cover',
    };
    const parentStyle = {
        display: 'flex', // to arrange child elements in a row
        height: "100%",
        justifyContent: 'space-between', // to distribute items evenly in the horizontal direction
        alignItems: 'center', // to align items vertically in the middle
    };

    const overlayImageStyle = {
        position: 'absolute', // this will take the image out of the normal document flow
        bottom: 0, // place it at the bottom of the parent container
        width: '100%',
        height: '45%',
        zIndex: 2,
        borderBottomLeftRadius: '20px',
        borderBottomRightRadius: '20px',
        objectFit: 'cover',
    };

    const overlayRecommendedImageStyle = {
        position: 'absolute', // this will take the image out of the normal document flow
        right: 0,  // place it at the right of the parent container
        width: '40%',
        height: '100%',
        zIndex: 2,
        borderTopRightRadius: '20px',
        borderBottomRightRadius: '20px',
        objectFit: 'cover',
    };

    const containerStyle = {
        position: 'relative',
        width: '100%',
        height: '100%',
        overflow: 'hidden' // to ensure the overlay image doesn't extend beyond the container
    };

    const containerWithButtonsStyle = {
        ...containerStyle,
        position: 'relative', // Set the position to 'relative' to establish a new context for absolutely positioned child elements
        overflow: 'hidden',
    };

    const baseImageStyle = {
        display: 'block', // to ensure the image takes up the entire width of the container
        width: '200%',
        height: '100%'
    };

    const middleLayerStyle = (backgroundColor) => ({
        position: 'absolute',
        bottom: '5%',
        left: '13%',
        width: '75%',
        height: '90%',
        objectFit: 'contain',
        backgroundColor: backgroundColor,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        zIndex: 1,
        borderTopLeftRadius: '20px',
        borderTopRightRadius: '20px',
        borderBottomLeftRadius: '20px',
        borderBottomRightRadius: '20px',
        boxShadow: '8px 8px 10px 0px rgba(0, 0, 0, 0.25)',
    });


    const [selectedBgImage, setSelectedBgImage] = useState(themeStyles["Bright Berry"].survey_background);

    const [currentTheme, setCurrentTheme] = useState("Tropical");

    // Declaring the middleLayer, i.e. the background of the themes.
    const [middleLayerColor, setMiddleLayerColor] = useState("#ffe6f0"); // default to Bright Berry's background color
    //const [selectedOverlayImage, setSelectedOverlayImage] = useState(themeStyles[currentTheme].survey_overlay);


    return (
    <div style={parentStyle}>


        <div style={containerWithButtonsStyle}>
            <div style={baseImageStyle}>
            <img style={backgroundImageStyle} src={selectedBgImage} alt={`${currentTheme} Background`} />
                <div style={middleLayerStyle(middleLayerColor)}>
                    <div>{children}</div>
                    {/* <img hidden style={overlayImageStyle} src={selectedOverlayImage || BrightBerryPrimary} alt="Bright Berry Primary" />  */}
                 </div>
            </div>
        </div>
    </div>
    )





}
export default ParentTemplate
