import * as React from 'react';
import PropTypes from 'prop-types';
import { useTheme } from '@mui/material/styles';
import { AppBar, Tabs, Tab, Typography, Box, TextField, Button, Drawer, Toolbar, Divider, Grid, Switch, Select } from '@mui/material';
import { useState } from 'react';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import DeleteIcon from '@mui/icons-material/Delete';
import CustomGridItem from './create/advanced/low_level_components/CreateGridItem';

const drawerWidth = 360;



function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `full-width-tab-${index}`,
    'aria-controls': `full-width-tabpanel-${index}`,
  };
}

const Create = () => {

  const [itemInputs, setItemInputs] = useState({});
  
  const handleInputChange = (id, inputName, value) => {
    setItemInputs((prevItemInputs) => ({
      ...prevItemInputs,
      [id]: {
        ...prevItemInputs[id],
        [inputName]: value
      }
    }));
  };

  const theme = useTheme();
  const [value, setValue] = useState(1);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleChangeIndex = (index) => {
    setValue(index);
  };

  const [gridItems, setGridItems] = useState ([
    {
      id: 1,
      title: "Primary Survey Question",
      defaultText: "How much do you like this aroma?",
      showDelete: false,
    },
    {
      id: 2,
      title: "Instructions for Use",
      defaultText: "Instructions for Use",
      showDelete: true,
    },
    {
      id: 3,
      title: "Demographic Questions",
      defaultText: "Demographic Questions",
      showDelete: true,
    },
    {
      id: 4,
      title: "ATOM Recommendations",
      defaultText: "Strawberry, banana, kiwi",
      showDelete: true,
    }
  ]);

  const handleAddPrimaryQuestion = (id) => {

  }

  const handleAddSecondaryQuestion = () => {
    setGridItems((prevGridItems) => {
      let newGridItems = [...prevGridItems];
  
      // Find the index of the last secondary question
      let lastSecondaryQuestionIndex = 0;
      for (let i = 0; i < newGridItems.length; i++) {
        if (newGridItems[i].id === 1) {
          lastSecondaryQuestionIndex = i;
          break;
        }
      }
  
      // Increment id values of the following items
      for (let i = lastSecondaryQuestionIndex + 1; i < newGridItems.length; i++) {
        newGridItems[i].id += 1;
      }
  
      // Insert a new secondary question at the correct position
      const newSecondaryQuestion = {
        id: 2,
        title: "Secondary Survey Question",
        defaultText: "Secondary Survey Question",
        showDelete: true,
      };
      newGridItems.splice(lastSecondaryQuestionIndex + 1, 0, newSecondaryQuestion);
  
      return newGridItems;
    });
  };

  const handleAddInstructions = (id) => {

  }

  const handleAddDemographicsQuestion = (id) => {

  }

  const handleAddRecommendation = (id) => {

  }
  
  const handleDeleteItem = (id) => {
    setGridItems(gridItems.filter((item) => item.id !== id));
  };

  return (
    <Box sx={{ bgcolor: 'background.paper', width: 'auto' }}>
      <AppBar position="static">
        <Tabs
          value={value}
          onChange={handleChange}
          indicatorColor="secondary"
          textColor="inherit"
          variant="fullWidth"
          aria-label="full width tabs example"
        >
          <Tab label="Configuration" {...a11yProps(0)} />
          <Tab label="Add Pages" {...a11yProps(1)} />
          <Tab label="Style Editor" {...a11yProps(2)} />
        </Tabs>
      </AppBar>
        {/* This is the configuration page layout */}
        <TabPanel value={value} index={0} dir={theme.direction}>
          Item One
        </TabPanel>
        {/* This is the Add Pages page layout */}
        <TabPanel value={value} index={1} dir={theme.direction}>
        <Box sx={{ p: 2, display: 'flex' }}>
          {/* The drawer is where all of the survey settings are located */}
          <Drawer
            variant="permanent"
            containerstyle={{ height: 'calc(100%-64px)', top: 64 }}
            sx={{
              width: drawerWidth,
              flexShrink: 0,
              [`& .MuiDrawer-paper`]: { width: drawerWidth, boxSizing: 'border-box', position: 'absolute', top: 114, zIndex: 'tootip' },
            }}
          >
            {/* This box holds all of the variable input fields. */}
            <Box sx={{ overflow: 'auto', position: 'absolute', top: 0, left: 30 }}>
            {/* onClick={handleAddCustomSample} */}
            <Divider orientation='horizontal' sx={{ height: '15px' }} />
            <h5><b>Survey Pages</b></h5>
                <Divider orientation='horizontal' sx={{ height: '10px' }} />
                    <Box display="flex" alignItems="end" justifyContent="space-between">
                        <span><h7>Add Primary Survey Question</h7></span>
                        <Button color="primary" size="large" onClick={"hello world"} disabled>
                            <AddCircleIcon />
                        </Button>
                    </Box>
                <Divider orientation='horizontal' sx={{ height: '20px' }} />
                {/* onClick={handleAddDummySample} */}
                <Box display="flex" alignItems="end" justifyContent="space-between">
                    <span><h7>Add Secondary Survey Question</h7></span>
                    <Button color="primary" size="large" onClick={handleAddSecondaryQuestion}>
                        <AddCircleIcon />
                    </Button>
                </Box>
              <Divider orientation='horizontal' sx={{ height: '20px' }} />
              <Box display="flex" alignItems="end" justifyContent="space-between">
                    <span><h7>Add Instructions for Use</h7></span>
                    <Button color="primary" size="large" onClick={"hello world"} disabled>
                        <AddCircleIcon />
                    </Button>
                </Box>
              <Divider orientation='horizontal' sx={{ height: '20px' }} />
              <Box display="flex" alignItems="end" justifyContent="space-between">
                    <span><h7>Add Demographics Survey Question</h7></span>
                    <Button color="primary" size="large" onClick={"hello world"} disabled>
                        <AddCircleIcon />
                    </Button>
                </Box>
              <Divider orientation='horizontal' sx={{ height: '20px' }} />
              <Box display="flex" alignItems="end" justifyContent="space-between">
                    <span><h7>Add Recommendation</h7></span>
                    <Button color="primary" size="large" onClick={"hello world"} disabled>
                        <AddCircleIcon />
                    </Button>
                </Box>
              <Divider orientation='horizontal' sx={{ height: '20px' }} />
            </Box>
          </Drawer>
          {/* <Toolbar /> */}
          <Box>
            <h3>Survey Pages</h3>
            <Divider orientation='horizontal' sx={{ height: '10px' }} />
            {gridItems.map((item) => (
              <Grid container direction="column" justifyContent="space-evenly" sx={{ width: "350%" }} key={item.id}>
                <CustomGridItem
                  id = {item.id}
                  title={item.title}
                  defaultText={item.defaultText}
                  showDelete={item.showDelete}
                  inputs={itemInputs[item.id] || {}}
                  onInputChange={handleInputChange}
                  onDelete={() => handleDeleteItem(item.id)}
                />
                <Divider orientation='horizontal' sx={{ height: '10px' }} />
              </Grid>
            ))}
          </Box>
        </Box>
        </TabPanel>
        {/* This is the style editor page layout */}
        <TabPanel value={value} index={2} dir={theme.direction}>
          Item Three
        </TabPanel>
    </Box>
  );
}

export default Create;