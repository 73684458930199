import * as React from 'react';
import { useState, useRef, useEffect, Fragment } from 'react';
const VASSetup = ({ vas, VASdevice, setVASdevice }) => {

    const [VASReady, setVASReady] = useState(false)
    const [APIStatus, setAPIStatus] = useState('initializing')

    vas.onDeviceConnected((e) => {
        console.log(`Device connected: id=${e.device.id}`);
        setVASdevice(e.device)
        vas.updateDevice(e.device.id, {
        setpoints: [0],
        fanSpeed: 8,
        });
        setVASReady(true)
    })


    vas.onWelcome((e) => {
      const devices = e.connectedDevices;

      // Zero all the setpoints and set an initial fan speed.
      devices.forEach((device) => {
          setAPIStatus(true)
        vas.updateDevice(device.id, {
          setpoints: [0],
          fanSpeed: 8,
        });
      })
})
    const testVAS = () => {
        vas.updateDevice(VASdevice.id, {
            setpoints: [100, 200, 300], fanSpeed: 8
        })
    }
    const zeroVAS = () => {
        vas.updateDevice(VASdevice.id, {
            setpoints: [0, 0, 0], fanSpeed: 8
        })
    }
    const setFullScreen = () => {
        if (!document.fullscreenElement) {
            if (document.documentElement.requestFullscreen) {
                document.documentElement.requestFullscreen();
            } else if (document.documentElement.mozRequestFullScreen) {
                document.documentElement.mozRequestFullScreen();
            } else if (document.documentElement.webkitRequestFullscreen) {
                document.documentElement.webkitRequestFullscreen();
            } else if (document.documentElement.msRequestFullscreen) {
                document.documentElement.msRequestFullscreen();
            }
        } else {
            if (document.exitFullscreen) {
                document.exitFullscreen();
            } else if (document.mozCancelFullScreen) {
                document.mozCancelFullScreen();
            } else if (document.webkitExitFullscreen) {
                document.webkitExitFullscreen();
            } else if (document.msExitFullscreen) {
                document.msExitFullscreen();
            }
        }
    }
    vas.onConnectionStatusChange((status, oldStatus) => {
        setAPIStatus(status)
          switch (status) {
            case "error": // An error has occurred when trying to connect to VASConnect
              break;
            case "disconnected": // VASConnect is not running.
              break;
            case "unauthorized": // VASConnect is running, but this app is not authorized to use it.
              break;
            case "authorizing": // VASConnect is showing the user the authorization dialog.
              break;
            case "authorized": // VASConnect is ready to use, but the websocket connection is not active.
              break;
            case "websocket": // VASConnect is ready, and the websocket connection is active. Success!
              break;
          }
        })

    if(VASReady){
        return(<><h1>VAS is ready.</h1>
                  <h4>Device ID: {VASdevice.id}</h4>
                  <button onClick={() => testVAS()}>Test by setting flows</button>
                  <button onClick={() => zeroVAS()}>Zero flows</button>
                  <button onClick={() => setFullScreen()}>Go Full Screen</button>
        </>)
    }else{
        return <><h1>VAS is not ready.</h1>
                    <h4>Status: {APIStatus}</h4></>
    }
}


export default VASSetup;