import React from "react";
import { useState, useEffect, useRef } from 'react';
import ArrowCircleRightIcon from "@mui/icons-material/ArrowCircleRight";
import {FormControl, FormControlLabel, Radio, RadioGroup} from "@mui/material";

const AgeRange = ({loadNextQuestion, SetQuestionResponse}) => {
    const [selectedValue, setSelectedValue] = useState('');

const handleChange = (event) => {
    const newValue = event.target.value;
    const response = {
        question_type: 'age',
        value: newValue
    };
    loadNextQuestion(response);
};
    let options = ['0-18', '18-24', '25-34', '35-44', "45-54", "55-64", "65 or over", "Prefer not to answer"]
    return <>
        <h1>What is your age?</h1>
        <div className="questionObject">
            <FormControl>
                    <RadioGroup
                        id="MC_response"
                        value={false}
                        name="MC_response"
                        onChange={handleChange}
                        row
                    >
                    {options.map((field, index) => (
                        <FormControlLabel
                            key={index}
                            value={`${field}`}
                            control={<Radio/>}
                            label={`${field}`}
                            labelPlacement="bottom"
                        />
                    ))}
                </RadioGroup>
            </FormControl>
        </div>
        </>
}

const Gender = ({loadNextQuestion, SetQuestionResponse}) => {
    const [selectedValue, setSelectedValue] = useState('');

const handleChange = (event) => {
    const newValue = event.target.value;
    const response = {
        question_type: 'gender',
        value: newValue
    };
    loadNextQuestion(response);
};
    let options = ['Male', 'Female', 'Other', 'Prefer not to answer']
    return <>
        <h1>What is your gender?</h1>
        <div className="questionObject">
            <FormControl>
                    <RadioGroup
                        id="MC_response"
                        value={false}
                        name="MC_response"
                        onChange={handleChange}
                        row
                    >
                    {options.map((field, index) => (
                        <FormControlLabel
                            key={index}
                            value={`${field}`}
                            control={<Radio/>}
                            label={`${field}`}
                            labelPlacement="bottom"
                        />
                    ))}
                </RadioGroup>
            </FormControl>
        </div>
        </>
}

const Race = ({loadNextQuestion, SetQuestionResponse}) => {
    const [selectedValue, setSelectedValue] = useState('');

const handleChange = (event) => {
    const newValue = event.target.value;
    const response = {
        question_type: 'race',
        value: newValue
    };
    loadNextQuestion(response);
};

    let options = ['Hispanic / Latino', 'American Indian / Alaska Native', 'Asian', "Black or African American", "Native Hawaiian / Pacific Islander", "White",  'Prefer not to answer']
    return <>
        <h1>What is your race?</h1>
        <div className="questionObject">
            <FormControl>
                    <RadioGroup
                        id="MC_response"
                        value={false}
                        name="MC_response"
                        onChange={handleChange}
                        row
                    >
                    {options.map((field, index) => (
                        <FormControlLabel
                            key={index}
                            value={`${field}`}
                            control={<Radio/>}
                            label={`${field}`}
                            labelPlacement="bottom"
                        />
                    ))}
                </RadioGroup>
            </FormControl>
        </div>
        </>
}


const Demographics = ({setState, setDemographicResponse, DemographicResponse, SurveyDesign, loadNextSample}) => {


    const [order, setOrder] = useState([])
    const [currentQuestion, setCurrentQuestion] = useState(-1) // Goes from 0 to the number of demo qs to ask
    const [content, setContent] = useState(<h1>Error</h1>)
    const [QuestionResponse, SetQuestionResponse] = useState(null) // This is the response for a single question. passes in as a prop

    useEffect(() => {
        console.log(SurveyDesign)

        let demographic_questions = SurveyDesign.demographic_questions
        // Set the order of questions to iterate through based on the input of demographic questions
        // If there are no questions to run, run loadNextSample since there are no demographic questions to collect.
        console.log(demographic_questions)
        if(demographic_questions.age_range){
            order.push("age_range")
        }
        if(demographic_questions.gender){
            order.push("gender")
        }
        if(demographic_questions.race){
            order.push("race")
        }
        if(order.length < 1){
            loadNextSample()
        }
        setCurrentQuestion(0)
        console.log(order)
    }, [])



    // on complete run loadNextSample()

    useEffect(() => {

        switch(order[currentQuestion]){
        case 'age_range':
            setContent(<AgeRange loadNextQuestion={loadNextQuestion} SetQuestionResponse={SetQuestionResponse} />)
            break;
            case "gender":
            setContent(<Gender loadNextQuestion={loadNextQuestion} SetQuestionResponse={SetQuestionResponse} />)
            break;
        case "race":
            setContent(<Race loadNextQuestion={loadNextQuestion} SetQuestionResponse={SetQuestionResponse} />)
            break;
        default:
            setContent(<h1>Error</h1>)
            break;
    }
    console.log(order[currentQuestion])
    }, [currentQuestion])


const loadNextQuestion = (response) => {
    // Set the demographic response here directly
        setDemographicResponse(prev => [...prev, response]);

        // Check if you've reached the end of the questions
        if(order.length - 1 === currentQuestion) {
            loadNextSample();
        } else {
            setCurrentQuestion(currentQuestion + 1);
        }
    };




    return content


 }

 export default Demographics;
