import { Navigate } from 'react-router-dom';
import { useSelector, connect } from 'react-redux';
import { Route } from 'react-router-dom'
//import history from '_helpers';


const PrivateRoute = ({ children, isAuthenticated }, ...rest) => {  
    if (isAuthenticated === null) {
        // App is still determining authentication status. 
        // Ideally show a loader or some placeholder here.
        return <div>Loading...</div>;
    }

    if (!isAuthenticated) {
        return <Navigate to="/unauth" />;
    }

    return children;
}

const mapStateToProps = state => ({
    isAuthenticated: state.auth.isAuthenticated
});

export default connect(mapStateToProps, {})(PrivateRoute);

