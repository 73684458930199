import * as React from 'react';
import { useState, useEffect } from 'react';
import { Grid, TextField, Select, FormControl, FormGroup, FormControlLabel, InputLabel, MenuItem, Alert, Switch, Button } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import { styled } from '@mui/material/styles';

function ReviewShareSettings({ design, setDesign }) {

  const [inputs, setInputs] = useState({
      allowGivViewing: design.internal_share,
      allowCustomerViewing: design.customer_share,
      collaborators: design.giv_collaborators.map(collaborator => collaborator).join(', ') + ", " + design.giv_customers.map(collaborator => collaborator).join(', ') , // This is a hack for now to get the comma separated list but in the future we need a more robust solution
      accessEndDate: design.accessEndDate.substring(0, 10), // yyyy-mm-dd
  });

  const [error, setError] = useState({status: false, message: ""});

  const handleInputChange = (event) => {
      const {name, value} = event.target;
      setInputs(inputs => ({...inputs, [name]: value}));
  };

  const handleSwitchChange = (e) => {
      setInputs({
          ...inputs,
          [e.target.name]: e.target.checked
      });
  };

  useEffect(() => {
      // when inputs changes, modify shareSave
      let internal_share = inputs.allowGivViewing;
      let customer_share = inputs.allowCustomerViewing;
      setDesign(prevState => ({ ...prevState, internal_share: internal_share, customer_share: customer_share }));
      
      if (inputs.collaborators && inputs.collaborators.includes(",")) {
          let share_list = inputs.collaborators.split(",");
          setDesign(prevState => ({ ...prevState, share_list: share_list }));
      }
      
      // Email validation
      if(inputs.allowCustomerViewing || !inputs.collaborators) {
          setError({status: false, message: ""});
      } else {
          const emails = inputs.collaborators.split(',').map(email => email.trim());
          const allValid = emails.every(email => /[\w-]+(\.[\w-]+)*@givaudan.com$/.test(email));
          if(!allValid) {
              setError({status: true, message: "Only emails ending with @givaudan.com are allowed"});
          } else {
              setError({status: false, message: ""});
          }
      }
  }, [inputs]); // listen for changes in inputs object

  // The styling for the switch
  const Android12Switch = styled(Switch)(({ theme }) => ({
      padding: 8,
      '& .MuiSwitch-track': {
      borderRadius: 22 / 2,
      '&:before, &:after': {
          content: '""',
          position: 'absolute',
          top: '50%',
          transform: 'translateY(-50%)',
          width: 16,
          height: 16,
      },
      '&:before': {
          backgroundImage: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="16" width="16" viewBox="0 0 24 24"><path fill="${encodeURIComponent(
          theme.palette.getContrastText(theme.palette.primary.main),
          )}" d="M21,7L9,19L3.5,13.5L4.91,12.09L9,16.17L19.59,5.59L21,7Z"/></svg>')`,
          left: 12,
      },
      '&:after': {
          backgroundImage: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="16" width="16" viewBox="0 0 24 24"><path fill="${encodeURIComponent(
          theme.palette.getContrastText(theme.palette.primary.main),
          )}" d="M19,13H5V11H19V13Z" /></svg>')`,
          right: 12,
      },
      },
      '& .MuiSwitch-thumb': {
      boxShadow: 'none',
      width: 16,
      height: 16,
      margin: 2,
      },
  }));

  const getDateWeekAhead = () => {
      const date = new Date();
      date.setDate(date.getDate() + 7);
      return date.toISOString().split('T')[0];
  };

  const [date, setDate] = useState(inputs.accessEndDate);
  const [dateError, setDateError] = useState("");

  const handleDateChange = (e) => {
      const selectedDate = e.target.value ? new Date(e.target.value) : null;
      const currentDate = new Date();
      currentDate.setHours(0, 0, 0, 0);
      const maxDate = new Date();
      maxDate.setDate(currentDate.getDate() + 30);
  
      if (!selectedDate || (selectedDate >= currentDate && selectedDate <= maxDate)) {
          setDate(e.target.value);
          setInputs({...inputs, accessEndDate: e.target.value});
          setDesign(prevState => ({ ...prevState, accessEndDate: e.target.value }));
          setDateError("");
      } else {
          if(selectedDate < currentDate){
              setDateError("Date cannot be before " + (currentDate.getMonth()+1) + "/" + (currentDate.getDate()+1) + "/" + currentDate.getFullYear() + ".");
          } else if(selectedDate > maxDate){
              setDateError("Date cannot be after " + (maxDate.getMonth()+1) + "/" + maxDate.getDate() + "/" + maxDate.getFullYear() + ".");
          }
          setInputs({...inputs});
      }
  };   

  return (
    <Grid container spacing={2}>
        <Grid item xs={8}>
            <h5>Export Samples & Output Template to Excel File</h5>
        </Grid>
        <Grid item xs={4}>
            <Button variant="contained" className="add-btn" size="large">
                <AddIcon />
            </Button>
        </Grid>
        <Grid item xs={8}>
            <h5>Allow everyone in Givaudan to view this survey?</h5>
        </Grid>
        <Grid item xs={4}>
            <FormControl>
                <FormGroup>
                <FormControlLabel
                    sx={{ p: 0.5 }}
                    control={<Android12Switch checked={inputs.allowGivViewing}
                    onChange={handleSwitchChange} name="allowGivViewing" defaultChecked />}
                />
                </FormGroup>
            </FormControl>
        </Grid>
        <Grid item xs={8}>
            <h5>Allow Givaudan authorized customers to view this survey?</h5>
        </Grid>
        <Grid item xs={4}>
            <FormControl>
                <FormGroup>
                <FormControlLabel
                    sx={{ p: 0.5 }}
                    control={<Android12Switch checked={inputs.allowCustomerViewing}
                    onChange={handleSwitchChange} name="allowCustomerViewing" />}
                />
                </FormGroup>
            </FormControl>
        </Grid>
        <Grid item xs={8}>
            <TextField
                required
                sx={{ minWidth: 600 }}
                label="Add Collaborators or customers by email separated by comma (,) "
                id="collaborators"
                variant="outlined"
                name="collaborators"
                value={inputs.collaborators}
                onChange={handleInputChange}
                error={error.status}
                helperText={error.message}
            />
        </Grid>
        <Grid item xs={8}>
            <h5>Until what date do you want external customers to be able to access this project ?<br />(cannot be more than 30 days from today) EST TIME</h5>
        </Grid>
        <Grid item>
            <input type="date" value={date} onChange={handleDateChange} />
            {dateError && <Alert severity="error">{dateError}</Alert>}
        </Grid>
    </Grid>
  );
};

export default ReviewShareSettings;
