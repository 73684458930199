// This component handles most of the boilerplate for validating the VAS is connected.
// It also shows a nice cute charm in the upper corner if desired so we know that the VAS is operating.
// It can show a status message for what's going on too.


import React, {useEffect, useRef, useState} from 'react';
import {VASConnect} from "@estanalytical/vasconnect";

let vas = new VASConnect({
    appId: "75f3346f-84d7-42d7-a512-c3a25755a44b",
    appName: "ATOM3",
    licenseType: "persistent",
    licenseToken: "eyJhbGciOiJFUzI1NiIsInR5cCI6IkpXVCJ9.eyJpYXQiOjE2OTIyNzc2NDAsIm5iZiI6MTY5MjI3NzY0MCwidmFzX2Nvbm5lY3QiOnsibGljZW5zZV9pZCI6ImYzZjA0YWVlLTBkZTktNDA2ZC1iZTUyLTYyNTJlODk0YmNjYSIsImFwcF9pZCI6Ijc1ZjMzNDZmLTg0ZDctNDJkNy1hNTEyLWMzYTI1NzU1YTQ0YiIsImFwcF9uYW1lIjoiQVRPTTMiLCJhcHBfcHVibGlzaGVyIjoiR2l2YXVkYW4iLCJhcHBfaW1hZ2VfdXJsIjpudWxsfX0.1QHZLnoTVjsUv9CQYzH2KC_JloEVBBiSidIMXJug1ibsSxKpA9nxuoycuyPdGvo27vlByvLOSmwu4gpcswHY7A",
    authorizeAutomatically: true
  })

const VASIntegrationSystem = ({children,
                                  LoadingVAS,
                                  setLoadingVAS,
                                 // VASdevice,
                                  IsVASconnected,
                                  setIsVASconnected,
                                  setIsVASstable,
                                  IsVASstable,
                                  DesiredVASFlow}) => {

    // children - objects to appear within the VAS integration system
    // LoadingVAS - state (setLoadingVAS) - we are waiting on the vas to connect, or stabilize possible?
    // VASdevice - vas device object returned by vas connect.
    // isVASconnected / setIsVASconnected - usestate
    // isVasStable / setIsVASstable - usestate

    const [InternalVASIntegrationSystemState, SetInternalVASIntegrationState] = useState('initialize')
    const [color, setColor] = useState("black")
    const [VASdevice, setVASdevice] = useState('')


    vas.onDeviceUpdated((e) => {
        // Check stabilized
          if (e.causedByThisApp) {
            return;
            }
        let current_stabilization_value = 0;
        let cont = true
        let threshold = 2
        for(let i = 0; i < DesiredVASFlow.length; i++){
            current_stabilization_value += Math.abs(DesiredVASFlow[i] - e.device.flows[i])
          if(Math.abs(DesiredVASFlow[i] - e.device.flows[i]) > threshold){ // 1 is the threshold
            cont = false; // If we have overridden the flow rate,
          }
        }
        setIsVASstable(cont)


    })

    useEffect(() => {
        if(IsVASconnected) {
            console.log(`Desired VAS flow changed. ${DesiredVASFlow} `)
            vas.updateDevice(VASdevice.id, {
                setpoints: DesiredVASFlow,
                fanSpeed: 10,
            });
        }
    }, [DesiredVASFlow])
   vas.onDeviceDisconnected((e) => {
        alert("VASconnect alert: device was disconnected.")
    })

    vas.onDeviceConnected((e) => {
            console.log(`Device connected: id=${e.device.id}`);
            console.log(`my internal cached devices ${VASdevice}`)
            vas.updateDevice(e.device.id, {
            setpoints: [0],
            fanSpeed: 8,
            });
            setVASdevice(e.device)
            SetInternalVASIntegrationState(`connected to ${e.device.id}`)
            setIsVASconnected(true)
            setColor('white')
        })

    vas.onWelcome((e) => {
        const devices = e.connectedDevices;

        // Zero all the setpoints and set an initial fan speed.
            devices.forEach((device) => {
            SetInternalVASIntegrationState(`connected to ${device.id}`)

            setIsVASconnected(true)
                setColor('white')
                setVASdevice(device)
            vas.updateDevice(device.id, {
              setpoints: [0],
              fanSpeed: 8,
            });
        })
    })

    vas.onConnectionStatusChange((status, oldStatus) => {
        SetInternalVASIntegrationState(status)
          switch (status) {
            case "error": // An error has occurred when trying to connect to VASConnect
                setColor('red')
              break;
            case "disconnected": // VASConnect is not running.
                setColor('red')

              break;
            case "unauthorized": // VASConnect is running, but this app is not authorized to use it.
                setColor('pink')

              break;
            case "authorizing": // VASConnect is showing the user the authorization dialog.
                setColor('blue')

              break;
            case "authorized": // VASConnect is ready to use, but the websocket connection is not active.
                setColor('yellow')

              break;
            case "websocket": // VASConnect is ready, and the websocket connection is active. Success!
                setColor('green')
              break;
          }
        })


    let style = {
        height: "10px",
        width: "10px",
        borderRadius: "10px",
        background: color,
        zIndex:100,
        position: 'absolute',
        right: '5px',
        top: '5px'
    }
    let statusMessageStyle = {
        position: 'absolute',
        zIndex: 100,
        background: 'white',
        right: "25px"
    }
    return <>
        <div style={statusMessageStyle}> VAS.is status: {InternalVASIntegrationSystemState}</div>
        <div style={style}></div>
        {children}
    </>
}

export default VASIntegrationSystem