import { useTheme, styled } from '@mui/material/styles';
import { AppBar, Tab, Tabs, Typography, Box, Button, Grid } from '@mui/material';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import React, {useEffect, useRef, useState, CSSProperties} from 'react';
import 'sweetalert2/src/sweetalert2.scss';
import Paper from '@mui/material/Paper';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import CheckBox from '@mui/material/Checkbox';
import { connect } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import DriveFolderUploadIcon from '@mui/icons-material/DriveFolderUpload';
import InfoIcon from '@mui/icons-material/Info';
import SummarizeIcon from '@mui/icons-material/Summarize';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import RadarIcon from '@mui/icons-material/Radar';
import InsightsIcon from '@mui/icons-material/Insights';
import BarChartIcon from '@mui/icons-material/BarChart';
import PreviewIcon from '@mui/icons-material/Preview';
import ProjectBackground from './ProjectBackground';
import DolChart from './DolChart';
import OptumChart from './OptumChart';
import ConstraintTable from './ConstraintTable';
import PerformAnalysisTable from './PerformAnalysisTable';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import IconButton from '@mui/material/IconButton';
import { useCSVReader } from 'react-papaparse';
import axios from 'axios';
import Swal from 'sweetalert2';
import AnalyzeKCl from './AnalyzeKCl'


const ProjectDetails = ({ isAuthenticated, access_token }) => {

    const config = {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `JWT ${access_token}`,
          'Accept': 'application/json'
        }
      };
    const { CSVReader } = useCSVReader();
    const styles = {
        csvReader: {
          display: 'flex',
          flexDirection: 'row',
          marginBottom: 10,
        },
        acceptedFile: {
          border: '1px solid #ccc',
          height: 45,
          lineHeight: 2.5,
          paddingLeft: 10,
          width: '80%',
        },
        progressBarBackgroundColor: {
          backgroundColor: 'green',
        },
      };
    const location= useLocation();
    const { surveyID } = location.state || {};  // Using a fallback in case state is not passed
    const [SurveyDesign, setSurveyDesign] = useState({}) // All the survey design data
    const [analysisCriterias, setAnalysisCriterias] = useState({}) 
    const [value, setValue] = React.useState('100000');
    const [valueVertical, setValueVertical] = React.useState('1');
    const [pbExpanded, setPbExpanded] = useState(true);
    const [udExpanded, setUdExpanded] = useState(false);
    const [acExpanded, setAcExpanded] = useState(false);
    const [paExpanded, setPaExpanded] = useState(false);
    const [dolExpanded, setDolExpanded] = useState(false);
    const [sumExpanded, setSumExpanded] = useState(true);
    const [optExpanded, setOptExpanded] = useState(false);
    const [viewExpanded, setViewExpanded] = useState(false);
    const [showUploaded, setShowUploaded] = useState(false);
    const [uploadedData, setUploadedData] = useState([]);
    const [fullSurveyResults, setFullSurveyResults] = useState([]);
    const [rowVariable, setRowVariable] = useState([]);
    const [rowCategory, setRowCategory] = useState([]);
    const [constraintDisabled, setConstraintDisabled] = useState(true);
    const [uploadDisabled, setUploadDisabled] = useState(false);
    const [performAnalysisDisabled, setPerformAnalysisDisabled] = useState(true);
    const [constraintOptions, setConstraintOptions] = useState([]);
    const [areConstraints, setAreConstraints] = useState(false); 
    const [constraintsFromExcel, setConstraintsFromExcel] = useState(false); 
    const [modelName, setModelName] = useState(""); 
    const [currentCriteria, setCurrentCriteria] = useState(""); 
    const [currentDol, setCurrentDol] = useState("");
    const [currentOptimum, setCurrentOptimum] = useState("");
    const [firstTableData, setFirstTableData] = useState({})
    const [fullAnalysisData, setFullAnalysisData] = useState({})
    let constraintDropDown = []
    let addedConstraints = []
    const [constraintTable, setConstraintTable] = useState([]);
    let constraintObject = {
        constraint: '',
        limit: '',
        type: ''
    }
    const [constraintLabel,setConstraintLabel] = useState()
    const [analysisTable, setAnalysisTable] = useState([]);
    let analysisObject = {
        question: '',
        weightage: '',
        mmm: ''
    }
    const [analysisLabel,setAnalysisLabel] = useState()
    const [mmmLabel,setMmmlabel] = useState()
    const [vertTabs, setVertTabs] = useState([])

    const doldata = [
        {
            name: 'Green Coffee Beans',
            LikingScore: -0.534,
        },
        {
            name: 'Rosemary',
            LikingScore: 2.755,
        },
        {
            name: 'Lemon Balm',
            LikingScore: -1.915,
        },
        {
            name: 'Vega Brite Black Carrot',
            LikingScore: 0.784,
        },
        {
            name: 'Anthocyanin Extract',
            LikingScore: -0.464,
        },
        {
            name: 'Turmeric Extract',
            LikingScore: 1.656,
        },
        {
            name: 'Storage ColdBox',
            LikingScore: -0.092,
        },
        {
            name: 'Storage Hotbox',
            LikingScore: 1.051,
        },
        {
            name: 'Flavour Type - Apple',
            LikingScore: -0.092,
        },
        {
            name: 'Flavour Type - Lemon',
            LikingScore: -0.588,
        },
      ];

    const optumdata = [
        {
            variable: 'Green Coffee Bean',
            AtomBest1: 0.01,
            AtomBest2: 0.01,
            AtomBest3: 0,
            AtomBestWoConstraint: 0.01,
            AtomWorst1: 0.005,
            CustomSample1: 0.005,
            fullMark: 100
        },
        {
            variable: 'Rosemary',
            AtomBest1: 0,
            AtomBest2: 0.016,
            AtomBest3: 0.008,
            AtomBestWoConstraint: 0.008,
            AtomWorst1: 0.008,
            CustomSample1: 0.016,
            fullMark: 100
        },
        {
            variable: 'Acerola',
            AtomBest1: 0.17,
            AtomBest2: 0,
            AtomBest3: 0,
            AtomBestWoConstraint: 0.17,
            AtomWorst1: 0.085,
            CustomSample1: 0.085,
            fullMark: 100
        },
        {
            variable: 'VegaBrite Black Carrot',
            AtomBest1: 0,
            AtomBest2: 0,
            AtomBest3: 0.5,
            AtomBestWoConstraint: 0.5,
            AtomWorst1: 0,
            CustomSample1: 0.5,
            fullMark: 100
        },
        {
            variable: 'Anthocyanin Extract',
            AtomBest1: 1,
            AtomBest2: 2,
            AtomBest3: 0,
            AtomBestWoConstraint: 0,
            AtomWorst1: 0,
            CustomSample1: 1,
            fullMark: 100
        },
        {
            variable: 'Em-Seal Beta Carotine',
            AtomBest1: 0,
            AtomBest2: 0,
            AtomBest3: 0.5,
            AtomBestWoConstraint: 0,
            AtomWorst1: 0.5,
            CustomSample1: 0.5,
            fullMark: 100
        },
        {
            variable: 'Storage Time',
            AtomBest1: 6,
            AtomBest2: 2,
            AtomBest3: 2,
            AtomBestWoConstraint: 6,
            AtomWorst1: 2,
            CustomSample1: 6,
            fullMark: 100
        },
        {
            variable: 'Storage',
            AtomBest1: 'Hotbox',
            AtomBest2: 'Hotbox',
            AtomBest3: 'Hotbox',
            AtomBestWoConstraint: 'ColdBox',
            AtomWorst1: 'ColdBox',
            CustomSample1: 'ColdBox',
            fullMark: 0
        },
        {
            variable: 'Flavour Type',
            AtomBest1: 'Apple 9',
            AtomBest2: 'Lemon 7',
            AtomBest3: 'Strawberry 2',
            AtomBestWoConstraint: 'Strawberry 7',
            AtomWorst1: 'Apple 6',
            CustomSample1: 'Orange 5',
            fullMark: 0
        },
      ];

    const [dolState, setDolState] = useState([]);
    const [optumState, setOptumState] = useState(optumdata);
    const [passedOptumState, setPassedOptumState] = useState([])

    function scaleBetween(unscaledNum, minAllowed, maxAllowed, min, max) {
        return (maxAllowed - minAllowed) * (unscaledNum - min) / (max - min) + minAllowed;
    }

    useEffect(() => {

        const newState = [...optumState];

        for (var i = 0; i < optumState.length; i++) {

            var unscaledNums = [optumState[i].AtomBest1, optumState[i].AtomBest2, optumState[i].AtomBest3, optumState[i].AtomBestWoConstraint, optumState[i].AtomWorst1, optumState[i].CustomSample1];
            var maxRange = Math.max.apply(Math, unscaledNums);
            //var minRange = Math.min.apply(Math, unscaledNums);
            var minRange = 0;
            let scaledArray = []
            for (var x = 0; x < 6; x++) {
            var unscaled = unscaledNums[x];
            var scaled = scaleBetween(unscaled, 0, 100, minRange, maxRange);
            scaledArray.push(scaled)
            }
            newState[i] = {
                ...newState[i],
                AtomBest1: scaledArray[0],
                AtomBest2: scaledArray[1],
                AtomBest3: scaledArray[2],
                AtomBestWoConstraint: scaledArray[3],
                AtomWorst1: scaledArray[4],
                CustomSample1: scaledArray[5]
            }
        }
        setPassedOptumState(newState)


    }, optumState)

    const handleChange = (event, newValue) => {
        setValue(newValue);
        if (newValue === '100000') {
            setValueVertical('1');
            setPbExpanded(true);
            setSumExpanded(false);
        } else {
            setValueVertical('2');
            setSumExpanded(true);
            setPbExpanded(false);
            fetchIndividualAnalysisCriterias(newValue);
            fetchDol(newValue);
            //fetchOptimum(newValue);
        }
      };

    function handlePbNavClick() {
        if (pbExpanded === true) {
            setPbExpanded(false) 
            document.getElementById("pbNav").style.color = '#212529';
        } else {
            setPbExpanded(true) 
            document.getElementById("pbNav").style.color = '#1976d2'; 
        }
    }

    function handleUdNavClick() {
        if (udExpanded === true) {
            setUdExpanded(false) 
            document.getElementById("udNav").style.color = '#212529';
        } else {
            setUdExpanded(true)  
            document.getElementById("udNav").style.color = '#1976d2';
        }
    }

    function handleAcNavClick() {
        if (acExpanded === true) {
            setAcExpanded(false) 
            document.getElementById("acNav").style.color = '#212529';
        } else {
            setAcExpanded(true) 
            document.getElementById("acNav").style.color = '#1976d2'; 
        }
    }

    function handlePaNavClick() {
        if (paExpanded === true) {
            setPaExpanded(false) 
            document.getElementById("paNav").style.color = '#212529';
        } else {
            setPaExpanded(true)  
            document.getElementById("paNav").style.color = '#1976d2';
        }
    }

    function handleDolNavClick() {
        if (dolExpanded === true) {
            setDolExpanded(false) 
            document.getElementById("dolNav").style.color = '#212529';
        } else {
            setDolExpanded(true)  
            document.getElementById("dolNav").style.color = '#1976d2';
        }
    }

    function handleSumNavClick() {
        if (sumExpanded === true) {
            setSumExpanded(false) 
            document.getElementById("sumNav").style.color = '#212529';
        } else {
            setSumExpanded(true)  
            document.getElementById("sumNav").style.color = '#1976d2';
        }
    }

    function handleONavClick() {
        if (optExpanded === true) {
            console.log("close me wtf")
            document.getElementById("oNav").style.color = '#212529';
            setOptExpanded(false)
        } else {
            console.log("open me wtf")
            document.getElementById("oNav").style.color = '#1976d2';
            setOptExpanded(true)
        }
    }

    function handleViNavClick() {
        if (viewExpanded === true) {
            setViewExpanded(false)
            document.getElementById("viNav").style.color = '#212529';
        } else {
            setViewExpanded(true)
            document.getElementById("viNav").style.color = '#1976d2';
        }
    }

    function addToDropDown(item, index) {
        if (item.includes("Output") || item.includes("Constraint") || item.includes("Calculated")) {
            constraintDropDown.push(rowVariable[index])
            setConstraintOptions(constraintDropDown)
        } else if (item.includes("Constraint")){
            const temp = {}
            setConstraintDisabled(false)
            setAreConstraints(true)
            constraintObject['constraint'] = rowVariable[index]
            constraintObject['limit'] = ''
            constraintObject['type'] = ''
            Object.assign(temp, constraintObject)
            addedConstraints.push(temp)
            setConstraintTable(addedConstraints)
        } else {

        }
    }

    const handleAreConstraints = (event) => {
        if (event.target.value === true) {
            setAreConstraints(true)
            rowCategory.forEach(addToDropDown)
        } else {
            setAreConstraints(false)
            constraintDropDown = []
        }
    };

    const Header = styled(Paper)(({ theme }) => ({
        backgroundColor: 'lightBlue',
        padding: theme.spacing(1),
        textAlign: 'center',
        color: 'black',
        borderStyle: 'solid',
        borderColor: 'dimgrey',
        borderWidth: 'thin'
      }));

    const ItemData = styled("div")(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: 'center',
    color: 'black',
    }));

    const fetchSurveyResults = async () => {
        try {
            const data = await fetch(`${process.env.REACT_APP_API_URL}/api/analyze/get_survey_results`, {
            method: 'post',
            headers: {
                'content-type': 'application/json',
                'Authorization': `JWT ${access_token}`,
                'Accept': 'application/json'
            },
                body: JSON.stringify({
                'survey_id': surveyID
                })
            })
            const response = await data.json()
            setFullSurveyResults(response.survey_results.uploadedDataEntire);
        } catch (err) {
            console.log(err.message)
        }
    }

    const fetchDol = async ( criteriaId ) => {
        let dolArray = []
        try {
            const data = await fetch(`${process.env.REACT_APP_API_URL}/api/analyze/view_dol`, {
            method: 'post',
            headers: {
                'content-type': 'application/json',
                'Authorization': `JWT ${access_token}`,
                'Accept': 'application/json'
            },
                body: JSON.stringify({
                'id': criteriaId
                })
            })
            const response = await data.json()
            let index = 0
            response.dol.dol_coeff_names.forEach(row => {
                let dolObject = {}
                dolObject['name'] = row
                dolObject['LikingScore'] = response.dol.dol_coeff_values[index]
                dolArray.push(dolObject)
                index = index + 1
            });
            const newState = [...dolState]; 
            setDolState(dolArray)
        } catch (err) {
            console.log(err.message)
            alert('no dol data')
        }
    }

    const fetchOptimum = async ( criteriaId ) => {
        try {
            const data = await fetch(`${process.env.REACT_APP_API_URL}/api/analyze/view_optimum`, {
            method: 'post',
            headers: {
                'content-type': 'application/json',
                'Authorization': `JWT ${access_token}`,
                'Accept': 'application/json'
            },
                body: JSON.stringify({
                'id': criteriaId
                })
            })
            const response = await data.json()
            setCurrentOptimum(response);
            console.log(response)
        } catch (err) {
            console.log(err.message)
        }
    }

    const fetchIndividualAnalysisCriterias = async ( criteriaId ) => {
        try {
            const data = await fetch(`${process.env.REACT_APP_API_URL}/api/analyze/view_individual_analysis_criterias`, {
            method: 'post',
            headers: {
                'content-type': 'application/json',
                'Authorization': `JWT ${access_token}`,
                'Accept': 'application/json'
            },
                body: JSON.stringify({
                'id': criteriaId
                })
            })
            const response = await data.json()
            setCurrentCriteria(response);
            console.log(response)
        } catch (err) {
            console.log(err.message)
        }
    }

    const fetchAnalysisCriterias = async () => {
        try {
            const data = await fetch(`${process.env.REACT_APP_API_URL}/api/analyze/view_analysis_criterias`, {
            method: 'post',
            headers: {
                'content-type': 'application/json',
                'Authorization': `JWT ${access_token}`,
                'Accept': 'application/json'
            },
                body: JSON.stringify({
                'survey_id': surveyID
                })
            })
            const response = await data.json()
            setAnalysisCriterias(response);
            console.log(response)
            if (response.all_criterias?.length > 0) {
                setUploadDisabled(true)
                setConstraintDisabled(false)
                setPerformAnalysisDisabled(false)
                isMapped();
            } else {
                isMapped();
            }
        } catch (err) {
            console.log(err.message)
        }
    }

    const isMapped = async () => {
            try {
                const data = await fetch(`${process.env.REACT_APP_API_URL}/api/analyze/is_mapped`, {
                method: 'post',
                headers: {
                    'content-type': 'application/json',
                    'Authorization': `JWT ${access_token}`,
                    'Accept': 'application/json'
                },
                    body: JSON.stringify({
                    'survey_id': surveyID
                    })
                })
                const response = await data.json()
                console.log(response);
                if (response.ismapped.mapped === true) {
                    setUploadDisabled(true)
                    fetchSurveyResults();
                    setConstraintDisabled(false)
                    setPerformAnalysisDisabled(false)
                    response.ismapped.output_names.map((output) => constraintDropDown.push(output))
                    setConstraintOptions(constraintDropDown)
                } else {
                    setUploadDisabled(false)
                }
            } catch (err) {
                console.log(err.message)
            }
    }

    // This function fetches the design and adminConfig from the database on load
    useEffect(() => {
        const fetchSurveyData = async () => {
        try {
            const data = await fetch(`${process.env.REACT_APP_API_URL}/survey/view/view_design_to_analyze`, {
            method: 'post',
            headers: {
                'content-type': 'application/json',
                'Authorization': `JWT ${access_token}`,
                'Accept': 'application/json'
            },
                body: JSON.stringify({
                'design_id': surveyID
                })
            })
            const response = await data.json()
            setSurveyDesign(response);
        } catch (err) {
            console.log(err.message)
        }
        }
        fetchSurveyData();
        fetchAnalysisCriterias();
    }, [])

    useEffect(() => {
        if (rowCategory.length != 0) {
            const found = rowCategory.find((row) => row === "--Unmapped--")
            if(!found) {
                setConstraintDisabled(false)
                setPerformAnalysisDisabled(false)
            }
        }
        constraintDropDown = []
        rowCategory.forEach(addToDropDown)
    }, rowCategory)

    function mapVariables(rows) {
    let mappedOnes = []
    rows.forEach(row => {
        const temp = {}
        const found = SurveyDesign.variable_names.find((element) => element === row);

        if (found) {

            let typeIndex = SurveyDesign.variable_names.indexOf(found)

            if (SurveyDesign.variable_names[typeIndex] !== undefined) {
                let type = SurveyDesign.variable_types[typeIndex]
                if (type === "Continuous") {
                    row = "Variable (Continuous)"
                } else if (type === "Discrete") {
                    row = "Variable (Discrete Numeric)"
                } else if (type === "Categorical") {
                    row = "Variable (Categorical)"
                }

            } else {

                row = "--Unmapped--"

            }
        }
        else 
        {
            if (row.includes("Sample") || row.includes("sample")) {
                row = "Sample_Name"
            } 
            else if (row.includes("Participant") || row.includes("participant")) {
                row = "Participant"
            }  
            else if (row.includes("Age") || row.includes("Gender") || row.includes("Race")) {
                row = "Demographic"
            }    
            else {
                row = "--Unmapped--"
            }
        }
        mappedOnes.push(row)
        setRowCategory(mappedOnes)
    });
    }

    function addUploadedConstraints(rows) {
        let tempTable = constraintTable
        rows.forEach(row => {
            if (row.includes("Sample") || row.includes("Participant")) {

            }  else {
                constraintObject = {}
                constraintObject['constraint'] = row
                constraintObject['limit'] = ''
                constraintObject['type'] =  ''
                tempTable.push(constraintObject)       
            }
        });
        setConstraintTable(constraintTable =>
            [
                ...constraintTable,
                tempTable
            ]
        )
    }


    const handleSelectChange = (event, index) => {
    let items = [...rowCategory]
    let item = {...items[index]}
    item = event.target.value
    items[index] = item
    setRowCategory(items)
    };

    function handleConstraintLabelChange(event){
        setConstraintLabel(event.target.value)
        if (event.target.value != 'addfromexcel') {
            setConstraintsFromExcel(false)
        } else {
            setConstraintsFromExcel(true)
        }
    }

    const addConstraint = (event) => {
        constraintObject['constraint'] = constraintLabel
        constraintObject['limit'] = ''
        constraintObject['type'] =  ''
        constraintObject['linear'] =  ''
        setConstraintTable(
            [
                ...constraintTable,
                constraintObject
            ]
        )
    }

    function handleConstraintTypeChange(event, index){
        const newState = [...constraintTable];
        newState[index] = {
            ...newState[index],
            type: event.target.value
        }
        setConstraintTable(newState)
    }

    const handleConstraintLimitChange = (event, index) => {
        const newState = [...constraintTable];
        newState[index] = {
            ...newState[index],
            limit: event.target.value
        }
        setConstraintTable(newState)
    }

    const removeConstraint = (event, index) => {
        const newState = [...constraintTable];
        newState.splice(index, 1)
        setConstraintTable(newState)
    }

    const removeQuestion = (event, index) => {
        const newState = [...analysisTable];
        newState.splice(index, 1)
        setAnalysisTable(newState)
    }

    const checkLinear = async (event, index) => {
        const swal = Swal.fire({
            title: 'Please wait...',
            text: 'Checking Type for Linear Or Non Linear...',
            allowOutsideClick: false,
            backdrop: 'rgba(0, 0, 0, 0.5)',
            didOpen: () => {
              Swal.showLoading();
            }
          });
        let data = {"index": index}
        console.log("axios now")
        const res = await axios.post(`${process.env.REACT_APP_API_URL}/api/analyze/get_linear`, data, config);
        let result = res;
        if (result.data.linear === "1"){
            // alert("Linear")
            swal.close();
            const newState = [...constraintTable];
            newState[index] = {
                ...newState[index],
                linear: "Linear"
            }
            setConstraintTable(newState)
        } else {
            swal.close();
            const newState = [...constraintTable];
            newState[index] = {
                ...newState[index],
                linear: "Non Linear"
            }
            setConstraintTable(newState)
        }
    }

    function handleAnalysisQuestionLabelChange(event){
        setAnalysisLabel(event.target.value)
    }

    function handleMmmLabelChange(event){
        setMmmlabel(event.target.value)
    }

    const addAnalysis = (event) => {
        analysisObject['question'] = analysisLabel
        analysisObject['weightage'] = ''
        analysisObject['mmm'] =  mmmLabel
        setAnalysisTable(
            [
                ...analysisTable,
                analysisObject
            ]
        )  
    }

    function updateModelName(event) {
        setModelName(event.target.value)
    }

    function handleWeightageChange(event, index){
        const newState = [...analysisTable];
        newState[index] = {
            ...newState[index],
            weightage: event.target.value
        }
        setAnalysisTable(newState)
    }

    async function saveAndMove() {

        console.log(SurveyDesign)
        console.log(uploadedData)

        let survey_results_id = [...new Array(uploadedData.length -2).keys()]
        let add1 = survey_results_id.length
        survey_results_id.push(add1)
        survey_results_id.shift()
        let sample_names = []
        let sample_types = []
        let participants = []
        for (var i = 0; i < uploadedData.length - 1; i++)
        {
            sample_names.push(uploadedData[i][0])
            participants.push(uploadedData[i][1])
            if (uploadedData[i][0] === "Dynamic") {
                sample_types.push("Dynamic")
            } else if (uploadedData[i][0] === "Custom Sample Not Part of Design") {
                sample_types.push("Custom Sample Not Part Of Design")
            } else if (uploadedData[i][0] === "Custom") {
                sample_types.push("Custom Sample Part Of Design")
            } else {
                sample_types.push("Design Sample")
            }
        }
        participants.shift()
        sample_names.shift()
        sample_types.shift()
        let inputArray = []
        let outputArray = []
        for (var x = 1; x < uploadedData.length - 1; x++)
        {

            let rowInputArray = []
            let rowOutputArray = []
            for (var i = 1; i < uploadedData[0].length - 1; i++)
            {
                const isVar = SurveyDesign.variable_names.includes(uploadedData[0][i])
                if (isVar) {
                    rowInputArray.push(uploadedData[x][i])
                }
                const isOutput = constraintOptions.includes(uploadedData[0][i])
                if (isOutput) {
                    rowOutputArray.push(uploadedData[x][i])
                }
            }
            inputArray.push(rowInputArray)
            outputArray.push(rowOutputArray)

        }
        let outputTypes = rowCategory.filter((cat) => (cat.includes("Output") || cat.includes("Calculated") || cat.includes("Constraint")))
        let outputTypesConverted = []
        outputTypes.map((output, index) => {
            if (output == 'Output(Numeric or Slider)') {
                outputTypesConverted.push(1)
            } else if (output == 'Output(Checkbox or Multiple Choice)') {
                outputTypesConverted.push(2)
            } else if (output == 'Output(JAR)') {
                outputTypesConverted.push(3)
            } else {
                outputTypesConverted.push(4)
            }
        })



        //console.log("survey_id : " + SurveyDesign.survey_id)
        //console.log("survey_results_id : " + survey_results_id)
        //console.log("sample_name : " + sample_names)
        //console.log("sample_type : " + sample_types)
        //console.log("participant : " + participants)
        //console.log("max_samples : " + SurveyDesign.num_samples) // do we need this really? pain in the arse
        //console.log("samples_per_panelist : " + SurveyDesign.num_samples) // do we need this really? pain in the arse
        //console.log("variable_names : " + SurveyDesign.variable_names)
        //console.log("variable_types : " + SurveyDesign.variable_types)
        console.log("input_samples : " + inputArray)
        //console.log("output_names : " + constraintOptions)
        //console.log("survey_results_output : " + outputArray)
        //console.log("output_type : " + outputTypes)
        //console.log("mapped : " + 1)

        setPerformAnalysisDisabled(false)

        let data = {
            "survey_id" : SurveyDesign.survey_id,
            "survey_results_id" :  survey_results_id,
            "sample_name" : sample_names,
            "sample_type" : sample_types,
            "participant" : participants,
            "max_samples" : SurveyDesign.num_samples, // do we need this really? pain in the arse
            "samples_per_panelist" : SurveyDesign.num_samples, // do we need this really? pain in the arse
            "variable_names" : SurveyDesign.variable_names,
            "variable_types" : SurveyDesign.variable_types,
            "input_samples" : inputArray,
            "output_names" : constraintOptions,
            "survey_results_output" : outputArray,
            "output_type" : outputTypesConverted,
            "mapped" : 1,
            "uploadedDataEntire" : uploadedData
        }

        try {
          const response = await fetch(`${process.env.REACT_APP_API_URL}/api/analyze/save_survey_results`, {
            method: 'post',
            headers: {
              'content-type': 'application/json',
              'Authorization': `JWT ${access_token}`,
              'Accept': 'application/json'
            },
            body: JSON.stringify(data)
          }).then(response => response.json())
            .then(response => {
              console.log(response)
              setUploadDisabled(true)
              setUdExpanded(false)
              fetchSurveyResults();
              Swal.fire({
                icon: 'success',
                title: 'SAVE SUCCESSFUL.',
                text: 'survey results have been mapped and saved.'
              })
            })
        } catch (err) {
          console.log(err.message)
          Swal.fire({
            icon: 'error',
            title: 'wtf.',
            text: 'You are likely signed out and need to sign back in.'
          })
        }


    }

    function checkweightage() {
        //console.log(analysisTable)
        let totalweightage = 0
        analysisTable.map((row, index) => {
            totalweightage = totalweightage + parseInt(row.weightage)
        })
        //console.log(totalweightage)
        return totalweightage
    }

    async function performAnalysis() {

        let is100 = checkweightage()
        let outputindexes = []
        let outputweights = []
        console.log(constraintOptions)
        console.log(analysisTable)
        for (var i = 0; i < constraintOptions.length; i++) {
            outputweights.push(0)
            for (var x = 0; x < analysisTable.length; x++) {
                if (analysisTable[x].question.includes(constraintOptions[i])){
                    outputindexes.push(i)
                    outputweights[i] = analysisTable[x].weightage
                }  
                

            }
            console.log(outputindexes)
            console.log(outputweights)
        }

        if (is100 === 100) {
            //console.log(SurveyDesign)
            //console.log(constraintTable)
            //console.log(analysisTable)
    
            let outputCriteriaAnalyzed = []
            let outputCriteriaTypes = []
            let outputCriteriaWeight = []
            for (var x = 0; x < analysisTable.length; x++)
            {
                outputCriteriaAnalyzed.push(analysisTable[x].question)
                outputCriteriaTypes.push(analysisTable[x].mmm)
                outputCriteriaWeight.push(analysisTable[x].weightage)
    
            }
            let constraintNames = []
            let constraintTypes = []
            let constraintLimits = []
            for (var x = 0; x < constraintTable.length; x++)
            {
                constraintNames.push(constraintTable[x].constraint)
                constraintTypes.push(constraintTable[x].type)
                constraintLimits.push(constraintTable[x].limit)
    
            }
    
            //console.log("survey_id : " + SurveyDesign.survey_id)
            //console.log("analysis_name : " + modelName)
            //console.log("output_criteria_analyzed : " + outputCriteriaAnalyzed)
            //console.log("output_criteria_types : " + outputCriteriaTypes)
            //console.log("output_weights : " + outputCriteriaWeight)
            //console.log("additional_constraints : TBD")
            //console.log("constraint_name : " + constraintNames)
            //console.log("constraint_type : " + constraintTypes)
            //console.log("constraint_limit : " + constraintLimits)
    
            let data = {
                "survey_id" : SurveyDesign.survey_id,
                "analysis_name" :  modelName,
                "output_criteria_analyzed" : outputindexes,
                "output_criteria_types" : outputCriteriaTypes,
                "output_weights" : outputweights,
                "additional_constraints" : [],
                "constraint_name" : constraintNames,
                "constraint_type" : constraintTypes,
                "constraint_limit" : constraintLimits
            }

            combineblobs(data)
    
            try {
              const response = await fetch(`${process.env.REACT_APP_API_URL}/api/analyze/save_survey_analysis_criteria`, {
                method: 'post',
                headers: {
                  'content-type': 'application/json',
                  'Authorization': `JWT ${access_token}`,
                  'Accept': 'application/json'
                },
                body: JSON.stringify(data)
              }).then(response => response.json())
                .then(response => {
                  console.log(response)
                  fetchAnalysisCriterias()
                  Swal.fire({
                    icon: 'success',
                    title: 'SAVE SUCCESSFUL.',
                    text: 'survey analysis criteria has been saved.'
                  })
                  //sendCriteriaToMatlab()
                })
            } catch (err) {
              console.log(err.message)
              Swal.fire({
                icon: 'error',
                title: 'wtf.',
                text: 'You are likely signed out and need to sign back in.'
              })
            }
        } else {
            alert("weightage must equal 100%")
        }  
    }

    async function combineblobs(data2) {
        try {
            const data = await fetch(`${process.env.REACT_APP_API_URL}/api/analyze/is_mapped`, {
            method: 'post',
            headers: {
                'content-type': 'application/json',
                'Authorization': `JWT ${access_token}`,
                'Accept': 'application/json'
            },
                body: JSON.stringify({
                'survey_id': surveyID
                })
            })
            const response = await data.json()
            console.log(response);
            if (response.ismapped.mapped === true) {
                let fullAnalysisData = Object.assign({}, response.ismapped, data2)
                delete fullAnalysisData.mapped
                console.log("--vvv-- Send This Data To Matlab for Analysis --vvv--")
                console.log(fullAnalysisData)
                setFullAnalysisData(fullAnalysisData)
            } else {
                setUploadDisabled(false)
            }
        } catch (err) {
            console.log(err.message)
        }
    }

    async function sendCriteriaToMatlab() {
        alert("here we are")
        let data = {"surveyresultsid": 14, "surveycriteriaid": 10}
        const res = await axios.post(`${process.env.REACT_APP_API_URL}/api/analyze/get_analysis`, data, config);
        let result = res;
        if (result.data){

        } else {

        }
    }

    if (!value && !SurveyDesign && !vertTabs) return <div>loading</div>

    //console.log(fullSurveyResults)
    console.log(dolState)
  
    return (
    <div style={{ width: '100%', padding: '20px' }}>
      <TabContext value={value}>
      <Box sx={{ display: 'flex', width: '75%', marginLeft: '20%' }}>
        <TabList variant="scrollable" value={value} onChange={handleChange}>
            <Tab style={{maxWidth:"25%"}} label="Project Details" value="100000"/>
            { analysisCriterias?.all_criterias?.map((vert, index) => (
            <Tab style={{maxWidth:"25%"}} key={index} label={vert.analysis_name} value={vert.id} />
            ))}
            {/*
            <Tab style={{fontSize: '1.1em'}} label="Texture Model" value="3" />
            <Tab style={{fontSize: '1.1em'}} label="Taste + Texture Model" value="4" />
            */}
        </TabList>
      </Box>
      </TabContext>
      <Box sx={{ display: 'flex', width: '100%', marginTop: '25px'}}>
        { value === "100000" ?
        <div style={{display: 'flex', width: '100%'}}> 
            <div style={{width: '16%', marginLeft: '2%', marginRight: '2%'}}> 
                <List>
                <ListItem id="pbNav" disablePadding onClick={() => handlePbNavClick()} style={{marginBottom: '12px', color: '#1976d2'}}>
                    <ListItemButton>
                    <ListItemIcon>
                        <SummarizeIcon />
                    </ListItemIcon>
                    <ListItemText primary="Project Background" />
                    </ListItemButton>
                </ListItem>
                <ListItem id="udNav" disablePadding onClick={() => handleUdNavClick()} style={{marginBottom: '12px'}}>
                    <ListItemButton>
                    <ListItemIcon>
                        <DriveFolderUploadIcon />
                    </ListItemIcon>
                    { uploadDisabled === true   
                    ?   <ListItemText primary="Uploaded Data Summary" />
                    :   <ListItemText primary="Upload Data" />
                    }
                    </ListItemButton>
                </ListItem>
                <ListItem id="acNav" disablePadding onClick={() => handleAcNavClick()} style={{marginBottom: '12px'}}>
                    <ListItemButton>
                    <ListItemIcon>
                        <AddCircleOutlineIcon />
                    </ListItemIcon>
                    <ListItemText primary="Add Constraints" />
                    </ListItemButton>
                </ListItem>
                <ListItem id="paNav" disablePadding onClick={() => handlePaNavClick()} style={{marginBottom: '12px'}}>
                    <ListItemButton>
                    <ListItemIcon>
                        <InsightsIcon />
                    </ListItemIcon>
                    <ListItemText primary="Perform Analysis" />
                    </ListItemButton>
                </ListItem>
                </List>
            </div>
            <div style={{width: '75%', marginTop: '7px'}}> 
            <Accordion expanded={pbExpanded} style={{marginBottom: '10px', borderStyle: 'solid', borderColor: 'dimgrey', borderWidth: 'thin'}}>
                <AccordionSummary
                expandIcon={<ExpandMoreIcon onClick={() => handlePbNavClick()} />}
                aria-controls="panel1a-content"
                id="panel1a-header"
                >
                <Typography>Project Background</Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <ProjectBackground design={SurveyDesign}/>
                </AccordionDetails>
            </Accordion>
            <Accordion expanded={udExpanded} style={{marginBottom: '10px', borderStyle: 'solid', borderColor: 'dimgrey', borderWidth: 'thin'}}>
                <AccordionSummary
                expandIcon={<ExpandMoreIcon onClick={() => handleUdNavClick()} />}
                aria-controls="panel1a-content"
                id="panel1a-header"
                >
                { uploadDisabled === true   
                    ?   <Typography>Uploaded Data Summary</Typography>
                    :   <Typography>Upload Data</Typography>
                }
                </AccordionSummary>
                <AccordionDetails>
                { uploadDisabled === true 
                    ?   <Grid container spacing={2} style={{paddingTop: '20px'}}>          
                            <Grid item xs={12}>
                            <div style={{overflowX: 'auto'}}>
                            <table>
                                <thead>
                                    <tr>
                                        {fullSurveyResults[0]?.map(header => <th style={{minWidth: 200, textAlign: 'center'}} key={header}></th>)}
                                    </tr>
                                </thead>
                                <tbody>
                                    {fullSurveyResults.map((row, index) => (
                                    <tr key={index}>
                                        {row.map((cell, index) => <td key={index}>{cell}</td>)}
                                    </tr>
                                    ))}
                                </tbody>
                            </table>
                            </div>
                            </Grid> 
                        </Grid>              
                    :   <Grid container spacing={2} style={{paddingTop: '20px'}}>          
                        <Grid item xs={12}>
                            <ItemData>Browse and upload your excel file containing the raw data to be analyzed.</ItemData>
                        </Grid>
                        <Grid item xs={12}>
                        <CSVReader
                        onUploadAccepted={(results) => {
                            setShowUploaded(true)
                            setRowVariable(results.data[0])
                            setUploadedData(results.data)
                            mapVariables(results.data[0])
                        }}
                        >
                        {({
                            getRootProps,
                            acceptedFile,
                            ProgressBar,
                            getRemoveFileProps,
                        }) => (
                            <>
                            <div style={styles.csvReader}>
                                <Button variant="contained" {...getRootProps()} style={{width: '40%', marginRight: '10px'}}>
                                Browse file
                                </Button>
                                <div style={styles.acceptedFile}>
                                {acceptedFile && acceptedFile.name}
                                </div>
                                <Button variant="contained" {...getRemoveFileProps()} style={{width: '40%', marginLeft: '10px', backgroundColor: 'red'}}>
                                Remove
                                </Button>
                            </div>
                            <ProgressBar style={styles.progressBarBackgroundColor} />
                            </>
                        )}
                        </CSVReader>
                        </Grid>
                        { showUploaded && 
                        <Grid container>
                            <Grid item xs={12} style={{marginTop: '20px', marginLeft: '16px'}}>
                                <Header>Summary Of Data Collected</Header>
                            </Grid>
                            <Grid item xs={12}>
                                <Grid container spacing={2}>    
                                    <Grid item xs={1}>

                                    </Grid>
                                    <Grid item xs={6} style={{fontWeight: 'bold', marginBottom: '10px', marginTop: '20px', paddingLeft: '0px'}}>
                                        Row Variable
                                    </Grid>
                                    <Grid item xs={4} style={{fontWeight: 'bold', marginBottom: '10px', marginTop: '20px', paddingLeft: '10px'}}>
                                        Category
                                    </Grid>
                                    <Grid item xs={1}>

                                    </Grid>
                                    { rowCategory.map((row, index) => (
                                    <Grid container key={index} spacing={2}>   
                                        <Grid item xs={1}>

                                        </Grid>
                                        <Grid item xs={6} style={{overflow:'hidden'}} >
                                            {rowVariable[index]}
                                        </Grid>
                                        <Grid item xs={4} >
                                            <Select
                                                labelId="demo-simple-select-label"
                                                id="demo-simple-select"
                                                style={{height: '30px', minWidth: '320px', marginBottom: '5px', color: row === '--Unmapped--' ? 'red' : 'black'}}
                                                onChange={(e) => handleSelectChange(e, index)}
                                                value={row}
                                            >
                                                <MenuItem value={"Variable (Continuous)"}>Variable (Continuous)</MenuItem>
                                                <MenuItem value={"Variable (Categorical)"}>Variable (Categorical)</MenuItem>
                                                <MenuItem value={"Variable (Discrete Numeric)"}>Variable (Discrete Numeric)</MenuItem>
                                                <MenuItem value={"Participant"}>Participant</MenuItem>
                                                <MenuItem value={"Sample_Name"}>Sample Name</MenuItem>
                                                <MenuItem value={"Base / Constant"}>Base / Constant</MenuItem>
                                                <MenuItem value={"Output(Numeric or Slider)"}>Output(Numeric or Slider)</MenuItem>
                                                <MenuItem value={"Output(Checkbox or Multiple Choice)"}>Output(Checkbox or Multiple Choice)</MenuItem>
                                                <MenuItem value={"Output(JAR)"}>Output(JAR)</MenuItem>
                                                <MenuItem value={"Demographic"}>Demographic</MenuItem>
                                                <MenuItem value={"Constraint"}>Constraint</MenuItem>
                                                <MenuItem value={"--Unmapped--"}>--Unmapped--</MenuItem>
                                            </Select>
                                        </Grid>
                                        <Grid item xs={1}>

                                        </Grid>
                                    </Grid>
                                    ))}
                                </Grid>
                            </Grid>
                            <Grid item xs={12} >
                                <Button variant="contained" onClick={saveAndMove} style={{width:"90%", backgroundColor: 'green', marginLeft: '6%', marginTop: '30px'}}>Save Mapping and Survey Results Table</Button>
                            </Grid>   
                        </Grid>              
                        }
                    </Grid>
                }
                </AccordionDetails>
            </Accordion>
            <Accordion disabled={constraintDisabled} expanded={acExpanded} style={{marginBottom: '10px', borderStyle: 'solid', borderColor: 'dimgrey', borderWidth: 'thin'}}>
                <AccordionSummary
                expandIcon={<ExpandMoreIcon onClick={() => handleAcNavClick()} />}
                aria-controls="panel1a-content"
                id="panel1a-header"
                >
                <Typography>Add Constraints</Typography>
                </AccordionSummary>
                <AccordionDetails>
                <Grid container spacing={2}>             
                    <Grid item xs={12}>
                        <Header>Do you have any constraints? (such as regulatory or cost or nutritional constraints etc.)</Header>
                    </Grid>
                    <Grid item xs={12} style={{textAlign: 'center'}}>
                    <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={areConstraints}
                        style={{height: '30px', minWidth: '350px', marginBottom: '5px'}}
                        onChange={(e) => handleAreConstraints(e)}
                    >
                        <MenuItem value={true}>Yes</MenuItem>
                        <MenuItem value={false}>No</MenuItem>
                    </Select>    
                    </Grid>
                    { areConstraints && 
                    <Grid container spacing={2} style={{paddingLeft: '16px', marginTop: '50px'}}>  
                        <Grid item xs={11} >
                            <Header>Do you want to apply any of the following as constraints in your project?</Header>
                            <Select
                            labelId="demo-simple-select-label"
                            id="constraintLabel"
                            onChange={handleConstraintLabelChange}
                            style={{height: '30px', marginTop: '10px', width: '100%'}}
                            >
                                { constraintOptions.map((output, index) => (
                                <MenuItem key={index} value={output}>{output}</MenuItem>
                                ))}
                                <MenuItem key="addfromexcel" value="addfromexcel" >-- Add My Own Constraint(s) From Excel --</MenuItem>
                            </Select> 
                            </Grid>  
                            <Grid item xs={1} style={{paddingTop: '8px'}}>
                            { !constraintsFromExcel && 
                            <IconButton onClick={(e) => addConstraint(e)}><AddCircleOutlineIcon style={{color: 'blue', fontSize: '60px', marginTop: '10px'}} /></IconButton>
                            }
                            </Grid>
                            { constraintsFromExcel && 
                            <Grid item xs={12} style={{marginTop: '40px'}}>
                            <CSVReader
                            onUploadAccepted={(results) => {
                                setShowUploaded(true)
                                addUploadedConstraints(results.data[0])
                            }}
                            >
                            {({
                                getRootProps,
                                acceptedFile,
                                ProgressBar,
                                getRemoveFileProps,
                            }) => (
                                <>
                                <div style={styles.csvReader}>
                                    <Button variant="contained" {...getRootProps()} style={{width: '40%', marginRight: '10px'}}>
                                    Browse file
                                    </Button>
                                    <div style={styles.acceptedFile}>
                                    {acceptedFile && acceptedFile.name}
                                    </div>
                                    <Button variant="contained" {...getRemoveFileProps()} style={{width: '40%', marginLeft: '10px', backgroundColor: 'red'}}>
                                    Remove
                                    </Button>
                                </div>
                                <ProgressBar style={styles.progressBarBackgroundColor} />
                                </>
                            )}
                            </CSVReader>
                        </Grid>
                        }
                        <ConstraintTable constraints={constraintTable} checkLinear={checkLinear} handleConstraintTypeChange={handleConstraintTypeChange} handleConstraintLimitChange={handleConstraintLimitChange} removeConstraint={removeConstraint}/>      
                    </Grid> 
                    }
                </Grid>
                </AccordionDetails>
            </Accordion>
            <Accordion disabled={performAnalysisDisabled} expanded={paExpanded} style={{marginBottom: '10px', borderStyle: 'solid', borderColor: 'dimgrey', borderWidth: 'thin'}}>
                <AccordionSummary
                expandIcon={<ExpandMoreIcon onClick={() => handlePaNavClick()} />}
                aria-controls="panel1a-content"
                id="panel1a-header"
                >
                <Typography>Perform Analysis</Typography>
                </AccordionSummary>
                <AccordionDetails>
                <Grid container spacing={2}> 
                    <Grid item xs={6}>
                        <Grid item xs={12}>
                            <Header>Select the question you want to analyze</Header>
                        </Grid>
                        <Grid item xs={12} style={{textAlign: 'center'}}>
                            <Select
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                onChange={handleAnalysisQuestionLabelChange}
                                style={{height: '30px', width: '100%', marginTop: '10px'}}
                            >
                                { constraintOptions.map((output, index) => (
                                <MenuItem key={index} value={output}>{output}</MenuItem>
                                ))}
                            </Select>    
                        </Grid>
                    </Grid>            
                    <Grid item xs={5}>
                        <Grid item xs={12} >
                            <Header>Select what you want to do with the output criteria</Header>
                        </Grid>
                        <Grid item xs={12} >
                            <Select
                                labelId="demo-simple-select-label"
                                id="constraintLabel"
                                onChange={handleMmmLabelChange}
                                style={{height: '30px', marginTop: '10px', width: '100%'}}
                            >
                                <MenuItem value="maximize">Maximize (Option for Numeric only)</MenuItem>
                                <MenuItem value="minimize">Minimize (Option for Numeric only)</MenuItem>
                                <MenuItem value="combined">Combined (Option for Numeric and JAR)</MenuItem>
                                <MenuItem value="match">Match Target (Option for JAR only)</MenuItem>

                            </Select> 
                        </Grid>
                    </Grid>
                    <Grid item xs={1}>
                            <IconButton onClick={(e) => addAnalysis(e)}><AddCircleOutlineIcon style={{color: 'blue', fontSize: '60px'}} /></IconButton>
                    </Grid>

                    
                    {/*<Grid item xs={12} style={{marginTop: '40px'}}>
                        <Header>While ATOM finds the Optimum do you want to apply any of the following constraints?</Header>
                    </Grid>
                    <Grid item xs={12} style={{marginTop: '10px'}}>
                    { constraintTable.map((constraint, index) => (
                        <Grid item xs={12} style={{textAlign: 'center'}}>{constraint.constraint + ' ' + constraint.type + ' ' + constraint.limit}<CheckBox></CheckBox></Grid>
                    ))}
                    </Grid>*/}
                    

                    <Grid container spacing={2} style={{paddingLeft: '16px', marginTop: '50px'}}>  
                        <PerformAnalysisTable analysis={analysisTable} handleWeightageChange={handleWeightageChange} removeQuestion={removeQuestion} />
                        <Grid item xs={12} style={{marginTop: '40px'}}>
                            <Header>Enter a name for this group (Please note that the results will be saved as a new tab)</Header>
                            <input id="modelname" style={{width: '100%', height: '35px', textAlign: 'center', marginTop: '15px'}} defaultValue={modelName} onBlur={(e) => updateModelName(e)}></input>
                        </Grid>
                        <Grid item xs={12} >
                            <Button variant="contained" onClick={performAnalysis} style={{width:"100%", backgroundColor: 'green'}}>Perform Analysis</Button>
                        </Grid>   
                    </Grid> 
                </Grid>
                </AccordionDetails>
            </Accordion>
            </div>
        </div>
        : value !== "1" ?
        <div style={{display: 'flex', width: '100%'}}> 
            <div style={{width: '16%', marginLeft: '2%', marginRight: '2%'}}> 
                <List>
                <ListItem id="sumNav" disablePadding onClick={() => handleSumNavClick()} style={{marginBottom: '12px', color: '#1976d2'}}>
                    <ListItemButton>
                    <ListItemIcon>
                        <InfoIcon />
                    </ListItemIcon>
                    <ListItemText primary="Analyze Overview" />
                    </ListItemButton>
                </ListItem>
                <ListItem id="dolNav" disablePadding onClick={() => handleDolNavClick()} style={{marginBottom: '12px'}}>
                    <ListItemButton>
                    <ListItemIcon>
                        <BarChartIcon />
                    </ListItemIcon>
                    <ListItemText primary="Drivers Of Liking" />
                    </ListItemButton>
                </ListItem>
                <ListItem id="oNav" disablePadding onClick={() => handleONavClick()} style={{marginBottom: '12px'}}>
                    <ListItemButton>
                    <ListItemIcon>
                        <RadarIcon />
                    </ListItemIcon>
                    <ListItemText primary="Optimums" />
                    </ListItemButton>
                </ListItem>
                <ListItem id="viNav" disablePadding onClick={() => handleViNavClick()} style={{marginBottom: '12px'}}>
                    <ListItemButton>
                    <ListItemIcon>
                        <PreviewIcon />
                    </ListItemIcon>
                    <ListItemText primary="View Interactions" />
                    </ListItemButton>
                </ListItem>
                </List>
            </div>
            <AnalysisAccordion currentCriteria={currentCriteria}></AnalysisAccordion>
        </div>
        :
        <div></div>
        }
      </Box>
    </div>
    );

    function AnalysisAccordion(props) {
        return (
            <div style={{width: '75%', marginTop: '7px'}}> 
                <Accordion expanded={sumExpanded} style={{marginBottom: '10px', borderStyle: 'solid', borderColor: 'dimgrey', borderWidth: 'thin'}}>
                    <AccordionSummary
                    expandIcon={<ExpandMoreIcon onClick={() => handleSumNavClick()}  />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                    >
                    <Typography>Analyze Overview</Typography>
                    </AccordionSummary>
                    <AccordionDetails style={{width: '100%'}}>
                    <Grid container>
                        <Grid item xs={12}>
                            <Grid container spacing={2}>    
                                <Grid item xs={1}>

                                </Grid>
                                <Grid item xs={4} style={{fontWeight: 'bold', marginBottom: '10px', marginTop: '20px', paddingLeft: '0px'}}>
                                    Applied Constraint
                                </Grid>
                                <Grid item xs={2} style={{fontWeight: 'bold', marginBottom: '10px', marginTop: '20px', paddingLeft: '10px'}}>
                                    Type
                                </Grid>
                                <Grid item xs={2} style={{fontWeight: 'bold', marginBottom: '10px', marginTop: '20px', paddingLeft: '10px'}}>
                                    Limit
                                </Grid>
                                <Grid item xs={2} style={{fontWeight: 'bold', marginBottom: '10px', marginTop: '20px', paddingLeft: '10px'}}>
                                    Linear/Non-Linear
                                </Grid>
                                <Grid item xs={1}>

                                </Grid>
                                <Grid container spacing={2}>   
                                    <Grid item xs={1}>

                                    </Grid>
                                    <Grid item xs={4} style={{overflow:'hidden'}} >
                                        { props.currentCriteria.criteria?.constraint_name.map((name, index) => (
                                        <div>{name}</div>
                                        ))}
                                    </Grid>
                                    <Grid item xs={2} >
                                        { props.currentCriteria.criteria?.constraint_type.map((type, index) => (
                                        <div>{type}</div>
                                        ))}
                                    </Grid>
                                    <Grid item xs={2} >
                                        { props.currentCriteria.criteria?.constraint_limit.map((limit, index) => (
                                        <div>{limit}</div>
                                        ))}
                                    </Grid>
                                    <Grid item xs={2} >
                                        { props.currentCriteria.criteria?.constraint_name.map((con, index) => (
                                        <div>Linear</div>
                                        ))}
                                    </Grid>
                                    <Grid item xs={1}>

                                    </Grid>
                                </Grid>                             
                            </Grid>
                        </Grid>
                        <Grid item xs={12}>
                            <Grid container spacing={2}>    
                                <Grid item xs={1}>

                                </Grid>
                                <Grid item xs={5} style={{fontWeight: 'bold', marginBottom: '10px', marginTop: '20px', paddingLeft: '0px'}}>
                                    Output Analyzed
                                </Grid>
                                <Grid item xs={3} style={{fontWeight: 'bold', marginBottom: '10px', marginTop: '20px', paddingLeft: '10px'}}>
                                    Maximize/Minimize/Match
                                </Grid>
                                <Grid item xs={2} style={{fontWeight: 'bold', marginBottom: '10px', marginTop: '20px', paddingLeft: '10px'}}>
                                    Weightage
                                </Grid>
                                <Grid item xs={1} style={{fontWeight: 'bold', marginBottom: '10px', marginTop: '20px', paddingLeft: '10px'}}>

                                </Grid>
                                <Grid container spacing={2}>   
                                    <Grid item xs={1}>

                                    </Grid>
                                    <Grid item xs={5} style={{overflow:'hidden'}} >
                                        { props.currentCriteria.criteria?.output_criteria_analyzed.map((output, index) => (
                                        <div>{output}</div>
                                        ))}
                                    </Grid>
                                    <Grid item xs={3} >
                                        { props.currentCriteria.criteria?.output_criteria_types.map((type, index) => (
                                        <div>{type}</div>
                                        ))}
                                    </Grid>
                                    <Grid item xs={2} >
                                        { props.currentCriteria.criteria?.output_weights.map((weight, index) => (
                                        <div>{weight}</div>
                                        ))}
                                    </Grid>
                                    <Grid item xs={1} >

                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>   
                    </AccordionDetails>
                </Accordion>
                <Accordion expanded={dolExpanded} style={{marginBottom: '10px', borderStyle: 'solid', borderColor: 'dimgrey', borderWidth: 'thin'}}>
                    <AccordionSummary
                    expandIcon={<ExpandMoreIcon onClick={() => handleDolNavClick()}  />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                    >
                    <Typography>Drivers Of Liking</Typography>
                    </AccordionSummary>
                    <AccordionDetails style={{width: '80%'}}>
                        <DolChart data={dolState}/>
                    </AccordionDetails>
                </Accordion>
                <Accordion expanded={optExpanded} style={{marginBottom: '10px', borderStyle: 'solid', borderColor: 'dimgrey', borderWidth: 'thin'}}>
                    <AccordionSummary
                    expandIcon={<ExpandMoreIcon onClick={() => handleONavClick()}  />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                    >
                    <Typography>Optimums</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                    <Typography>
                        <OptumChart data={optumState} radardata={passedOptumState}/>
                    </Typography>
                    </AccordionDetails>
                </Accordion>
                <Accordion expanded={viewExpanded} style={{marginBottom: '10px', borderStyle: 'solid', borderColor: 'dimgrey', borderWidth: 'thin'}}>
                    <AccordionSummary
                    expandIcon={<ExpandMoreIcon onClick={() => handleViNavClick()}  />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                    >
                    <Typography>View Interactions</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                    <Typography>
                        <AnalyzeKCl></AnalyzeKCl>
                    </Typography>
                    </AccordionDetails>
                </Accordion>
            </div>            
        )
    }

  }
  
  const mapStateToProps = state => ({
    isAuthenticated: state.auth.isAuthenticated,
    access_token: state.auth.access
  });
  
  export default connect(mapStateToProps, {})(ProjectDetails);