import * as React from 'react';
import { useEffect } from 'react';
import { connect } from 'react-redux';
import { useNavigate, Link, useLocation } from 'react-router-dom';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Drawer from '@mui/material/Drawer';
import Toolbar from '@mui/material/Toolbar';
import Divider from '@mui/material/Divider';
import Grid from '@mui/material/Grid';
import { Fragment, useState, useRef } from 'react';
import axios from 'axios';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import DeleteIcon from '@mui/icons-material/Delete';
import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';
import Swal from 'sweetalert2';
import 'sweetalert2/src/sweetalert2.scss';

const drawerWidth = 415;
const drawerHeight = 400;


const ScreeningViewSamples = ({ setScreeningDOE, configInputs, setConfigInputs, updateSamples, access_token, surveyID, gridRef, setGridData }) => {

  useEffect(() => {

    console.log('in useEffect')
    setConfigInputs(prevState => ({ ...prevState, survey_id: surveyID }));
  }, [surveyID, setConfigInputs, access_token]);


  const [isLoading, setIsLoading] = useState(true)
  const [rowData, setRowData] = useState([]);
  const [columnDefs, setColumnDefs] = useState([{ headerName: '', field: 'sample', editable: true }]);

  
  let generatedBy = ["Generated By"];
  const variable_names = generatedBy.concat(configInputs.variable_names);

  // USE EFFECT
  useEffect(() => {
    const newColumnDefs = [...columnDefs];

    const initialRowData = [];
    for (let i = 0; i < variable_names.length; i++) { // 0 -> 2
      if (!newColumnDefs.some(column => column.field === variable_names[i])) {
          newColumnDefs.push({
            headerName: variable_names[i],
            field: variable_names[i],
            editable: true,
            cellClassRules: {
              'ag-cell-editable': () => true,
            },
          });
        }
        const newRow = {
          sample: 'Sample ' + (i + 1),
          'Generated By': 'System',
        };

        variable_names.forEach((name, index) => {
          if(index === i && index < configInputs.variable_highs.length) {
            newRow[name] = configInputs.variable_highs[index];
          } else {
            newRow[name] = 0;
          }
        });

        initialRowData.push(newRow);
      }

      setRowData(initialRowData);
      setColumnDefs(newColumnDefs);
      setIsLoading(false);
  }, [configInputs.variable_names, configInputs.variable_highs]);
  

  // This is where the custom and dummy samples are being added
  let [customCountPD, setCustomCountPD] = useState(1);
  let [customCountNPD, setCustomCountNPD] = useState(1);
  let [dummyCount, setDummyCount] = useState(1);

  const addCustomPDSample = () => {
    const newRow = { sample: 'PD Custom Sample ' + (customCountPD), 'Generated By': 'Custom (PD)' };
    variable_names.forEach((variable_name) => {
      if (variable_name !== 'Generated By') {
        newRow[variable_name] = '';
      }
    });

    // Add the new row to rowData and update the state
    const updatedRowData = [...rowData, newRow];
    setRowData(updatedRowData);
    // updateSamplesData(updatedRowData);
    setCustomCountPD(customCountPD+1)
  }

  const addCustomNPDSample = () => {
    const newRow = { sample: 'NPD Custom Sample ' + (customCountNPD), 'Generated By': 'Custom (NPD)' };
    variable_names.forEach((variable_name) => {
      if (variable_name !== 'Generated By') {
        newRow[variable_name] = '';
      }
    });

    // Add the new row to rowData and update the state
    const updatedRowData = [...rowData, newRow];
    setRowData(updatedRowData);
    // updateSamplesData(updatedRowData);
    setCustomCountNPD(customCountNPD+1)
  }

  const addDummySample = () => {
    const newRow = { sample: 'Dummy Sample ' + (dummyCount), 'Generated By': 'Dummy' };
    variable_names.forEach((variable_name) => {
      if (variable_name !== 'Generated By') {
        newRow[variable_name] = '';
      }
    });

    // Add the new row to rowData and update the state
    const updatedRowData = [...rowData, newRow];
    setRowData(updatedRowData);
    // updateSamplesData(updatedRowData);
    setDummyCount(dummyCount+1)
  }

  const generateRowData = () => {
    const rowData = [];
    const variables = configInputs.variable_names;
    const variableHighs = configInputs.variable_highs;
  
    // Total number of samples is the sum of variables and constants
    const totalSamples = variables.length
  
    for (let i = 0; i < totalSamples; i++) {
      const row = { sample: `Sample ${i + 1}`, 'Generated By': 'ATOM Generated' };
  
      // Add variables with their highs
      for (let j = 0; j < variables.length; j++) {
        if (i === j) {
          row[variables[j]] = variableHighs[j];
        } else {
          row[variables[j]] = 0;
        }
      }
  
      rowData.push(row);
    }
  
    return rowData;
  };
  
  

  useEffect(() => {
    setRowData(generateRowData());
  }, [configInputs]);  

  // This is what shows the delete icons in the right-hand column
  const deleteIconCellRenderer = (params) => {
    return (
      <button
        style={{ border: 'none', background: 'transparent' }}
        onClick={(e) => {
          e.stopPropagation();
          deleteRow(params.rowIndex, params.data['sample']);
        }}
      >
        <DeleteIcon />
      </button>
    );
  };

  const deleteRow = (rowIndex, sample) => {
    const rowNode = gridRef.current.api.getRowNode(rowIndex);
    gridRef.current.api.applyTransaction({ remove: [rowNode.data] });

    // Update the rowData state to reflect the deleted row
    setRowData(oldRowData => oldRowData.filter((row, index) => index !== rowIndex));

    console.log(`Deleted row ${rowIndex + 1} : ${sample}`);
};

  

  const deleteColumn = {
    headerName: "Delete",
    cellRenderer: 'deleteIconCellRenderer',
    cellRendererParams: {
      clicked: function(field) {
        console.log(`${field} was clicked`);
      }
    },
    suppressCsvExport: true,
    width: 100,
  };
  

  // This updates the columns after they are deleted
  const updatedColumnDefs = [...columnDefs, deleteColumn];

  const updateSamplesData = (newRowData) => {
    const newSamples = newRowData.map(row => {
      let sampleObj = {};
      columnDefs.forEach(column => {
        // Exclude the delete column
        if (column.headerName !== 'Delete') {
          sampleObj[column.field] = row[column.field];
        }
      });
      return sampleObj;
    });
    console.log("Updating samples with: ", newSamples);
    setScreeningDOE(newSamples); // Save row data to the screeningDOE state variable
  };
  


  const gridOptions = {
    components: {
      deleteIconCellRenderer: deleteIconCellRenderer
    },
    onFirstDataRendered: params => {
      const allNodes = params.api.getRenderedNodes();
      const allData = allNodes.map(node => node.data);
      setGridData(allData);
      // Save the data when it is first rendered
      updateSamplesData(allData);
    },
    singleClickEdit: true,
    autoHeight: true,
    stopEditingWhenCellsLoseFocus: false
  };


  const resetGrid = () => {
    setRowData(generateRowData());
  };

  const exportDataAsCsv = () => {
    const params = {
      columnKeys: columnDefs.filter(columnDef => columnDef.headerName !== "Delete").map(columnDef => columnDef.field),
      // the rest of your parameters here
    };
    const csvData = gridRef.current.api.getDataAsCsv(params);
    
    // Create a blob and download
    const blob = new Blob([csvData], {type: 'text/csv'});
    const link = document.createElement('a');
    link.href = window.URL.createObjectURL(blob);
    link.download = 'export.csv';
    link.click();
  };  

  return (
      <Box sx={{ p: 2, display: 'inline-flex', width: '100%' }}>
        {/* The drawer is where all of the survey settings are located */}
        <Drawer
          variant="permanent"
          containerstyle={{ height: 'calc(100%-64px)', top: 64, width: 415 }}
          sx={{
            width: 320,
            flexShrink: 0,
            [`& .MuiDrawer-paper`]: { width: drawerWidth, height: drawerHeight, boxSizing: 'border-box', position: 'relative', zIndex: 'tootip', left: '-23%' },
          }}
        >
  
          {/* This box holds all of the variable input fields. */}
          <Box sx={{ overflow: 'auto', position: 'absolute', top: 0, left: 30 }}>
          <Divider orientation='horizontal' sx={{ height: '20px' }} />
                  <Box display="flex" alignItems="end" justifyContent="center">
                    <Button variant="contained" color="primary" size="large" sx={{ display: 'flex', justifyContent: 'end'}} onClick={resetGrid}>
                            Regenerate Samples
                    </Button>
                  </Box>
              <Divider orientation='horizontal' sx={{ height: '20px' }} />
                  <Box display="flex" alignItems="end" justifyContent="space-between">
                      <span><h5>Add Custom Sample (Part of Design)</h5></span>
                      <Button color="primary" size="large" onClick={addCustomPDSample} sx={{ display: 'flex', justifyContent: 'end'}}>
                          <AddCircleIcon />
                      </Button>
                  </Box>
              <Divider orientation='horizontal' sx={{ height: '20px' }} />
              <Box display="flex" alignItems="end" justifyContent="space-between">
                      <span><h5>Add Custom Sample (Not Part of Design)</h5></span>
                      <Button color="primary" size="large" onClick={addCustomNPDSample} sx={{ display: 'flex', justifyContent: 'end'}}>
                          <AddCircleIcon />
                      </Button>
                  </Box>
              <Divider orientation='horizontal' sx={{ height: '20px' }} />
              <Box display="flex" alignItems="end" justifyContent="space-between">
                  <span><h5>Add Dummy Sample</h5></span>
                  <Button color="primary" size="large" onClick={addDummySample} sx={{ display: 'flex', justifyContent: 'end'}}>
                      <AddCircleIcon />
                  </Button>
              </Box>
              <Divider orientation='horizontal' sx={{ height: '20px' }} />
              <Box display="flex" alignItems="end" justifyContent="center">
                  <Button variant="contained" onClick={exportDataAsCsv}>
                    Export Samples (.csv)
                  </Button>
              </Box>
            <Divider orientation='horizontal' sx={{ height: '20px' }} />
          </Box>
        </Drawer>
        <Box sx={{width: "100%"}}>
          <h3>Design of Experiment</h3>
        <Divider orientation='horizontal' sx={{ height: '10px' }} />
              {isLoading === false ?
                <div className="parent-container" style={{height: "90%"}}>
                  <div className="ag-theme-alpine" style={{ height: '100%', width: 'calc(100%-515px)' }}>
                    <AgGridReact
                      ref={gridRef}
                      columnDefs={updatedColumnDefs}
                      rowData={rowData}

                      gridOptions={gridOptions}

                      onCellValueChanged={() => updateSamplesData(rowData)}
                    />
                  </div>
                </div>
              : <div></div>}
            </Box>
        </Box>
  )
}

export default ScreeningViewSamples;
