import React, {useEffect} from "react";
import { Checkbox, FormControlLabel, FormGroup, FormControl } from '@mui/material';

const CheckboxQuestion = ({ question, questionResponseData, setQuestionResponseData }) => {

  // Input the response into "setQuestionResponseData" UseState.
  // the question object is a JSON with all the data needed to fill in the question inputs.

    // useEffect(() => {
    //     setQuestionResponseData(1)
    // }, [])
    const handleChange = (event) => {
      const { name, checked } = event.target;
  
      // Create a new copy of checkbox_response array
      let updatedResponses = [...(questionResponseData.checkbox_response || [])];
  
      if (checked && !updatedResponses.includes(name)) {
        updatedResponses.push(name);
      } else if (!checked && updatedResponses.includes(name)) {
        updatedResponses = updatedResponses.filter(value => value !== name);
      }
  
      setQuestionResponseData({
        checkbox_response: updatedResponses,
      });
    };


  console.log(questionResponseData);
  console.log("Qeustion:", question)

    // This is just a template. Fix me
  return (
    <div class="questionObject">
      <FormControl component="fieldset">
        <FormGroup aria-label="position" row>
          {question.fields.map((field, index) => (
            <FormControlLabel
              key={index}
              value={field}
              control={
                <Checkbox
                  checked={questionResponseData.checkbox_response && questionResponseData.checkbox_response.includes(field)}
                  onChange={handleChange}
                  name={field}
                />
              }
              label={field}
              labelPlacement="bottom"
            />
          ))}
        </FormGroup>
      </FormControl>
    </div>
  );
}

export default CheckboxQuestion;
