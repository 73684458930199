import React, { useState, useEffect } from 'react';
import { TextField, Grid, Divider, Typography } from '@mui/material';
import ArrowBackIosNewRoundedIcon from "@mui/icons-material/ArrowBackIosNewRounded";

const Introduction = ({ setState, SurveyDesign, loadNextSample }) => {
  let IFU = SurveyDesign.instructions;

    useEffect(() => {
      console.log(SurveyDesign)

  }, [])
  // Set input states


    return ( <>
    <h1>{SurveyDesign.instructions.title}</h1>
    <h4 style={{whiteSpace: 'pre-line'}}>{SurveyDesign.instructions.description}</h4>
     <button onClick={() => setState('demographics')}>Continue</button>
    </>)

};


export default Introduction;
