import React, { useState, useEffect } from 'react';
import { Grid, Select, FormControl, MenuItem, Divider, InputLabel } from '@mui/material';

// File Inputs
import NumericScale from '../Add Pages/Primary Survey Question/NumericScale';
import CheckboxesMC from '../Add Pages/Primary Survey Question/Checkboxes';
import JARScale from '../Add Pages/Primary Survey Question/JAR_scale';

const PrimarySurveyQuestion = ({ addPages, setAddPages }) => {
  let PSQ = addPages.primary_survey_question;


  const [inputs, setInputs] = useState({
    format: PSQ.format || "Numeric Scale"
  });

  useEffect(() => {
    
    const reset = {
      question: PSQ.question || "",
      format: inputs.format || "",
      show_rating_label: PSQ.show_rating_label || true,
      minimum: PSQ.minimum || null,
      maximum: PSQ.maximum || null,
      step: PSQ.step || null,
      scale: PSQ.scale || null,
      shape: PSQ.shape || "",
      fields: PSQ.fields || [],
    }

    setAddPages(prevState => ({ ...prevState, primary_survey_question: reset }));

  }, [inputs.format, PSQ]);

  // Handle the inputs as they are received
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setInputs(prevState => ({ ...prevState, [name]: value }));
  };

  return (
    <>
        <Grid container direction="row" alignItems="end">
            <Grid item>
                <FormControl>
                <InputLabel variant="outlined" id="format-label">Format</InputLabel>
                    <Select
                    sx={{ width: 160 }}
                    label="Format *"
                    labelId="format-label"
                    id="format"
                    value={inputs.format}
                    name="format"
                    onChange={handleInputChange}
                    >
                    <MenuItem value="Numeric Scale">Numeric Scale</MenuItem>
                    <MenuItem value="JAR Scale">JAR Scale</MenuItem>
                    <MenuItem value="Checkbox">Checkbox</MenuItem>
                    <MenuItem value="Multiple Choice">Multiple Choice</MenuItem>
                    <MenuItem value="Slider">Slider</MenuItem>
                    </Select>
                </FormControl>
            </Grid>
        </Grid>
        <Divider orientation='horizontal' sx={{ height: '10px' }} />
            {inputs.format === "Numeric Scale" || inputs.format === "Slider" ? 
            <NumericScale format={inputs.format} addPages={addPages} setAddPages={setAddPages} /> : <div></div>
            }{inputs.format === "JAR Scale" ?
            <JARScale format={inputs.format} addPages={addPages} setAddPages={setAddPages} /> : <div></div>
            }{inputs.format === "Checkbox" || inputs.format === "Multiple Choice" ? 
            <CheckboxesMC format={inputs.format} addPages={addPages} setAddPages={setAddPages} /> : <div></div>
            }
            <Divider orientation='horizontal' sx={{ height: '10px' }} />        
    </>
  );
};

export default PrimarySurveyQuestion;
